import Api from '../../../api';
import store from '../../../store';

const fetchUnits = async (data) => {
    return fetchInfo('/MasterData/GetUnits', data);
};

const fetchSimpleProductInfo = async (data) => {
    return fetchInfo('/Product/GetSimpleProductInfo', data);
};
const fetchSimpleProductionInfo = async (data) => {
    return fetchInfo('/Production/GetSimpleProductionInfo', data);
};
const fetchSimpleProductionInfoOverPeriod = async (data) => {
    return fetchInfo('/Production/GetSimpleProductionInfoOverPeriod', data);
};

const fetchSimpleRecipes = async (data) => {
    return fetchInfo('/Recipe/GetSimpleRecipeInfo', data);
};

//int productid
const fetchSimpleRecipe = async (data) => {
    return fetchInfo('/Recipe/GetSimpleRecipe', data);
};
//int productionID
const fetchSimpleProduction = async (data) => {
    return fetchInfo('/Production/GetProduction', data);
};

//int productID
const fetchProductUsedProducts = async (data) => {
    return fetchInfo('/StockItem/GetSimpleProductionUsedProducts', data);
};
//int productID
const fetchProductSerialNumber = async (data) => {
    return fetchInfo('/StockItem/GetSimpleProductMaxSerialNumber', data);
};

const fetchSimpleProductionValueTypes = async (data) => {
    return fetchInfo('/Production/GetProductionValueTypes', data);
};
const fetchSimpleProductionValues = async (data) => {
    return fetchInfo('/Production/GetSimpleProductionValues', data);
};
//int productionValueID
const fetchProductionValue = async (data) => {
    return fetchInfo('/Production/GetProductionValue', data);
};

// FileUpLoadModel pm
const storeSimpleProductionAttachment = async (data) => {
    const headers = {
        'Content-Type': 'multipart/form-data' //;boundary=<calculated when request is sent></calculated>
    };
    return await Api(store, headers).post(
        '/Production/StoreSimpleProductionAttachment',
        data
    );
};

// ProductionModel pm
const storeSimpleProduction = async (data) => {
    return await Api(store).put('/Production/StoreProduction', data);
};
// ProductModel pm
const storeSimpleProduct = async (data) => {
    return await Api(store).put('/Product/StoreSimpleProduct', data);
};
// SimpleRecipe recipe
const storeSimpleRecipe = async (data) => {
    return await Api(store).put('/Recipe/StoreSimpleRecipe', data);
};
// ProductionValueModel pvm
const storeProductionValue = async (data) => {
    return await Api(store).put('/Production/StoreProductionValue', data);
};


//  int id, DateTime lastupdated
const deleteSimpleProduction = async (data) => {
    return deleteInfo('/Production/DeleteProduction', data);
};
//  int productionID, int attachmentID
const deleteSimpleProductionAttachment = async (data) => {
    return deleteInfo('/Production/DeleteSimpleProductionAttachment', data);
};

//  int id, DateTime lastupdated
const deleteProductionValue = async (data) => {
    return deleteInfo('/Production/DeleteProductionValue', data);
};



const fetchInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};


const fetchInfoAtt = async (apicall, data, headers, responsetype) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: headers,
                responseType: responsetype
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: headers,
            responseType: responsetype
        });
    }
};

const deleteInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).delete(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};

export default {
    fetchUnits,
    fetchSimpleProductInfo,
    fetchSimpleProductionInfo,
    fetchSimpleProductionInfoOverPeriod,
    fetchProductUsedProducts,
    fetchProductSerialNumber,
    fetchSimpleProduction,
    storeSimpleProduction,
    storeSimpleProduct,
    fetchSimpleRecipes,
    fetchSimpleRecipe,
    storeSimpleRecipe,
    fetchProductionValue,
    storeProductionValue,
    storeSimpleProductionAttachment,
    deleteSimpleProduction,
    deleteSimpleProductionAttachment,
    fetchSimpleProductionValueTypes,
    fetchSimpleProductionValues,
    deleteProductionValue
};
