import store from '../../store';
import Helper from '../../models/Helper';
import DateHelper from '../../models/DateHelper';
import { loadItem } from '../../models/DataHelper';

class ChartData {
    constructor(data) {
        this.id = -1;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false
            },
            chartID: {
                type: String,
                value: '',
                writable: true,
                enumerable: true
            },
            labels: {
                type: Array,
                value: [],
                writable: true,
                enumerable: true
            },
            datasets: {
                type: Array,
                value: [],
                writable: true,
                enumerable: true
            },
            datasetIdKey: {
                type: String,
                value: null,
                writable: true,
                enumerable: true
            },
            chartOptions: {
                type: Object,
                value: {
                    responsive: true,
                    maintainAspectRatio: false
                },
                writable: true,
                enumerable: true
            }
        });
    }

    chartDataDef() {
        const chartDataDef = {
            labels: this.labels,
            datasets: this.datasets
        };
        return chartDataDef;
    }
}

const DATASOURCE_ENUM = Object.freeze({
    kilosMelkperKiloKaas: 'kilosMelkperKiloKaas',
    inslagUitslag: 'inslagUitslag',
    kaassoortVerdeling: 'kaassoortVerdeling',
    histoStremTemp: 'histoStremTemp',
    histoNawarmTemp: 'histoNawarmTemp',
    lijnMelkOpbrengstFactor: 'lijnMelkOpbrengstFactor'
});

// pietjes
const backgroundColorArray = [
    '#E6BB7A',
    '#ADE68A',
    '#E68473',
    '#5CAAE6',
    '#E267E6',
    '#41B883',
    '#E46651',
    '#00D8FF',
    '#DD1B16'
];

const callFunction = async (
    van,
    tot,
    delta,
    min,
    max,
    functionString,
    func
) => {
    const selectionData = {
        from: DateHelper.localDateToUTC(van),
        until: DateHelper.localDateToUTC(tot)
    };
    if (delta != undefined) selectionData.deltaT = delta;
    if (min != undefined) selectionData.minTemp = min;
    if (max != undefined) selectionData.maxTemp = max;
    const payload = {
        data: selectionData,
        report: functionString
    };
    return await loadItem('rapportage/loadFunction', payload, func);
};

const callFunctionProduct = async (
    van,
    tot,
    delta,
    min,
    max,
    functionString,
    productID,
    func
) => {
    const selectionData = {
        from: DateHelper.localDateToUTC(van),
        until: DateHelper.localDateToUTC(tot),
        productid: productID ?? -1
    };
    if (delta != undefined) selectionData.deltaT = delta;
    if (min != undefined) selectionData.minTemp = min;
    if (max != undefined) selectionData.maxTemp = max;
    const payload = {
        data: selectionData,
        report: functionString
    };
    return await loadItem('rapportage/loadFunction', payload, func);
};

const callFunctionItem = async (functionString, dataSourceString, ID, func) => {
    const selectionData = {};
    switch (dataSourceString) {
        case DATASOURCE_ENUM.lijnMelkOpbrengstFactor: {
            selectionData.milkGroupTypeID = ID;
        }
    }
    const payload = {
        data: selectionData,
        report: functionString
    };
    const result = await loadItem('rapportage/loadFunction', payload, func);
    return result;
};

const makeDataSetHistogram = (lijst, volgnummer) => {
    const data = lijst.map((x) => x.freq);
    const label = 'Frequentie';
    const backgroundColor = backgroundColorArray[volgnummer];
    const dataset = {
        label: label,
        backgroundColor: backgroundColor,
        data: data
    };
    return dataset;
};
const makeLabelsHistogram = (lijst) => {
    const labels = lijst.map((x) => x.range);
    return labels;
};

const makeDataSetDoughnut = (lijst) => {
    const bcArray = backgroundColorArray.slice();
    const times = Math.ceil(lijst.length / 9);
    if (times > 1) {
        for (let i = 1; i < times; i++) {
            const temp = backgroundColorArray.slice();
            bcArray.push(...temp);
        }
    }
    const dataset = {
        backgroundColor: bcArray,
        data: lijst
    };
    return dataset;
};

const makeDataSetLine = (lijst, volgnummer, serietekst) => {
    const data = lijst;
    const label = serietekst;
    const backgroundColor = backgroundColorArray[volgnummer];
    const dataset = {
        label: label,
        backgroundColor: backgroundColor,
        data: data
    };
    return dataset;
};

const loadSubObject = async (van, tot, productID, dataSourceString, func) => {
    // welk object moeten we terug geven?
    switch (dataSourceString) {
        case DATASOURCE_ENUM.kilosMelkperKiloKaas: {
            let nieuw = null;
            // eslint-disable-next-line no-unused-vars
            const melkLijst = await callFunction(
                van,
                tot,
                null,
                null,
                null,
                '/Reporting/ProductionMilkConsumption',
                func
            );
            nieuw = new ChartData();
            return nieuw;
        }
        case DATASOURCE_ENUM.histoStremTemp: {
            let nieuw = null;
            const histStremTempLijst = await callFunctionProduct(
                van,
                tot,
                0.2,
                29,
                32,
                '/Reporting/HistogramStremTemp',
                productID,
                func
            );
            const arrDataSets = [];
            const dataset1 = makeDataSetHistogram(histStremTempLijst, 1);
            arrDataSets.push(dataset1);
            const labels1 = makeLabelsHistogram(histStremTempLijst);
            nieuw = new ChartData();
            nieuw.labels = labels1;
            nieuw.datasets = arrDataSets;
            nieuw.chartID = 'Histogram';
            nieuw.datasetIdKey = 'label';
            return nieuw;
        }
        case DATASOURCE_ENUM.histoNawarmTemp: {
            let nieuw = null;
            const histTempLijst = await callFunctionProduct(
                van,
                tot,
                0.4,
                32,
                38,
                '/Reporting/HistogramNaWarmTemp',
                productID,
                func
            );
            const arrDataSets = [];
            const dataset1 = makeDataSetHistogram(histTempLijst, 1);
            arrDataSets.push(dataset1);
            const labels1 = makeLabelsHistogram(histTempLijst);
            nieuw = new ChartData();
            nieuw.labels = labels1;
            nieuw.datasets = arrDataSets;
            nieuw.chartID = 'Histogram';
            nieuw.datasetIdKey = 'label';
            return nieuw;
        }
        case DATASOURCE_ENUM.kaassoortVerdeling: {
            let nieuw = null;
            const lijst = await callFunction(
                van,
                tot,
                null,
                null,
                null,
                '/Reporting/CheeseProductionReport',
                func
            );
            const arrDataSets = [];
            const data1 = lijst.map((x) => x.melk_kg_rel * 100);
            const dataset1 = makeDataSetDoughnut(data1);
            arrDataSets.push(dataset1);
            const labels1 = lijst.map((x) => x.kaassoort);
            nieuw = new ChartData();
            nieuw.labels = labels1;
            nieuw.datasets = arrDataSets;
            nieuw.chartID = 'Doughnut';
            nieuw.datasetIdKey = 'label';
            return nieuw;
        }
        case DATASOURCE_ENUM.inslagUitslag: {
            let nieuw = null;
            let lijst = [];
            if (productID && productID > -1) {
                lijst = await callFunction(
                    van,
                    tot,
                    null,
                    null,
                    null,
                    '/Reporting/InOutBrineWeightsReportMonth',
                    func
                );
                lijst = lijst.filter((x) => x.id == productID);
            } else {
                lijst = await callFunction(
                    van,
                    tot,
                    null,
                    null,
                    null,
                    '/Reporting/InOutBrineWeightsReport',
                    func
                );
            }
            const arrDataSets = [];
            const data1 = lijst.map((x) => x.voor_pekel_melk_kg);
            const data2 = lijst.map((x) => x.kaas_kg_obv_melk);
            const data3 = lijst.map((x) => x.kaas_kg_obv_vorm);
            const strMelk = func('prod_melk', { ns: 'production' }); //'Melk';
            const strKaasMelk =
                func('com_kaas', { ns: 'common' }) +
                '(' +
                func('prod_melk', { ns: 'production' }).toLowerCase() +
                ')'; // 'Kaas (melk)';
            const strKaasVorm =
                func('com_kaas', { ns: 'common' }) +
                '(' +
                func('prod_vorm', { ns: 'production' }).toLowerCase() +
                ')'; // 'Kaas (vorm)';
            const dataset1 = makeDataSetBar(data1, strMelk, 1, 'A');
            const dataset2 = makeDataSetBar(data2, strKaasMelk, 2, 'B');
            const dataset3 = makeDataSetBar(data3, strKaasVorm, 3, 'B');
            arrDataSets.push(dataset1);
            arrDataSets.push(dataset2);
            arrDataSets.push(dataset3);
            const labels1 = lijst.map((x) =>
                Helper.stringformat('{0}-{1}', x.jaar, x.maand)
            );
            nieuw = new ChartData();
            nieuw.labels = labels1;
            nieuw.datasets = arrDataSets;
            nieuw.chartOptions.scales = Make2Axis();
            nieuw.chartID = 'Bar';
            nieuw.datasetIdKey = 'label';
            return nieuw;
        }
        default:
            break;
    }
    return null;
};

const loadSubObjectItem = async (dataSourceString, anID, func) => {
    switch (dataSourceString) {
        case DATASOURCE_ENUM.lijnMelkOpbrengstFactor: {
            let nieuw = null;
            const lijst = await callFunctionItem(
                '/Ingredient/GetMilkusagefactorForYear',
                dataSourceString,
                anID,
                func
            );
            const arrDataSets = [];
            let labels1 = [];
            if (lijst != 1) {
                const data1 = lijst.map((x) => x.factor);
                const dataset1 = makeDataSetLine(data1, 1, 'Factor');
                arrDataSets.push(dataset1);
                labels1 = lijst.map((x) =>
                    DateHelper.convertedToMonthName(x.month, null)
                );
                const lab = lijst.m;
            }
            nieuw = new ChartData();
            nieuw.labels = labels1;
            nieuw.datasets = arrDataSets;
            nieuw.chartID = 'Line';
            nieuw.datasetIdKey = 'label';
            return nieuw;
        }
        default:
            break;
    }
    return null;
};

const makeDataSetBar = (data, label, volgnummer, axis) => {
    const backgroundColor = backgroundColorArray[volgnummer];
    const dataset = {
        label: label,
        backgroundColor: backgroundColor,
        yAxisID: axis,
        data: data
    };
    return dataset;
};

const Make2Axis = () => {
    const scales = {
        A: {
            type: 'linear',
            position: 'left'
        },
        B: {
            type: 'linear',
            position: 'right'
        }
    };
    return scales;
};

export { loadSubObject, loadSubObjectItem };

