var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',[_c('b-alert',{attrs:{"show":_vm.dismissCountDown,"variant":_vm.variantType,"dismissible":""},on:{"dismissed":_vm.resetVariant},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")]),_c('b-card-text',[_c('b-row',{staticClass:"mx-0 px-0 my-1 py-0 form-inline"},[_c('b-col',{staticClass:"mx-0 px-0 my-0 py-0",staticStyle:{"width":"50%"}},[_c('lookup-combo',{attrs:{"placeholder":_vm.$t('com_kaassoort', {
                            ns: 'common'
                        }),"combotype":_vm.lctypeCheeses,"doCheckState":true},on:{"change":_vm.setChanged},model:{value:(_vm.selectedProductID),callback:function ($$v) {_vm.selectedProductID=$$v},expression:"selectedProductID"}})],1),_c('b-col',{staticClass:"mx-0 px-0 my-0 py-0",staticStyle:{"width":"30%"}},[_c('lookup-combo',{attrs:{"placeholder":_vm.$t('prod_vorm', {
                            ns: 'production'
                        }),"combotype":_vm.lctypeShapes,"doCheckState":true},on:{"change":_vm.setChanged},model:{value:(_vm.selectedShapeID),callback:function ($$v) {_vm.selectedShapeID=$$v},expression:"selectedShapeID"}})],1),_c('b-col',{staticClass:"mx-0 px-0 my-0 py-0",staticStyle:{"width":"20%"}},[_c('b-input-group',{attrs:{"append":_vm.$t('com_st', { ns: 'common' }),"label":_vm.$t('com_stuks', { ns: 'common' })}},[_c('b-form-input',{attrs:{"placeholder":_vm.aantKaas,"type":"number","step":"1","state":_vm.selectedAmount > 0 ? true : false},on:{"change":_vm.setChanged},model:{value:(_vm.selectedAmount),callback:function ($$v) {_vm.selectedAmount=_vm._n($$v)},expression:"selectedAmount"}})],1)],1)],1),_c('b-row',{staticClass:"mx-0 px-0 my-1 py-0 form-inline"},[_c('b-input-group',{staticStyle:{"width":"100%"},attrs:{"prepend":_vm.$t('com_reference', { ns: 'common' }),"label":_vm.$t('com_reference', { ns: 'common' })}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('com_reference', { ns: 'common' })},on:{"change":_vm.setChanged},model:{value:(_vm.klantRef),callback:function ($$v) {_vm.klantRef=$$v},expression:"klantRef"}})],1)],1),_c('b-row',{staticClass:"mx-0 px-0 my-1 py-0 form-inline"},[_c('b-col',{attrs:{"sm":"6"}},[_c('b-input-group',{staticClass:"mb-2"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('b-form-checkbox',{on:{"change":_vm.setChanged},model:{value:(_vm.gebruikProductiedatum),callback:function ($$v) {_vm.gebruikProductiedatum=$$v},expression:"gebruikProductiedatum"}},[_c('span',[_vm._v(_vm._s(_vm.$t('log_optdatum', { ns: 'logistics' })))])])],1),_c('b-form-datepicker',{attrs:{"min":new Date(),"date-format-options":{
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit'
                        },"disabled":!_vm.gebruikProductiedatum},on:{"input":_vm.setChanged},model:{value:(_vm.productiedatum),callback:function ($$v) {_vm.productiedatum=$$v},expression:"productiedatum"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-input-group',{staticClass:"mb-2"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('b-form-checkbox',{on:{"change":_vm.setChanged},model:{value:(_vm.gebruikLeverdatum),callback:function ($$v) {_vm.gebruikLeverdatum=$$v},expression:"gebruikLeverdatum"}},[_c('span',[_vm._v(_vm._s(_vm.$t('log_leverdatum', { ns: 'logistics' })))])])],1),_c('b-form-datepicker',{attrs:{"min":_vm.minDate,"max":_vm.maxDate,"date-format-options":{
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit'
                        },"disabled":!_vm.gebruikLeverdatum || _vm.forcedDelDate},on:{"input":_vm.setChanged},model:{value:(_vm.leverdatum),callback:function ($$v) {_vm.leverdatum=$$v},expression:"leverdatum"}})],1)],1)],1),_c('b-row',{staticClass:"mx-0 px-0 my-1 py-0 form-inline"},[_c('b-col',{attrs:{"sm":"9"}}),_c('b-col',{attrs:{"sm":"1"}},[(!_vm.isEdit)?_c('b-form-checkbox',{staticClass:"float-right",model:{value:(_vm.oneTimeOnly),callback:function ($$v) {_vm.oneTimeOnly=$$v},expression:"oneTimeOnly"}}):_vm._e()],1),_c('b-col',{attrs:{"sm":"2"}},[_c('b-button',{staticClass:"bgc_aux_normal float-right",on:{"click":_vm.addItem}},[_vm._v(" "+_vm._s(_vm.btnTekst)+"  "),_c('font-awesome-icon',{attrs:{"icon":"arrow-down","size":"1x"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }