<template>
    <b-card no-body>
        <b-alert
            :show="dismissCountDown"
            v-model="showDismissibleAlert"
            :variant="variantType"
            dismissible
            @dismissed="resetVariant"
        >
            {{ alertText }}
        </b-alert>
        <b-row>
            <b-col sm="8" class="mx-0 px-0 my-0 py-0" />
            <b-col sm="3" class="mx-0 px-0 my-0 py-0">
                <b-button
                    @click="linkStarter()"
                    class="bgc_aux_normal float-right"
                    v-b-tooltip.hover
                    :title="$t('com_koppel', { ns: 'common' })"
                    :disabled="!editAllowed"
                    ><font-awesome-icon icon="link" size="1x" />
                </b-button>
            </b-col>
            <b-col sm="1" class="mx-0 px-0 my-0 py-0" />
        </b-row>
        <b-table
            :items="displayList"
            :fields="fields"
            stacked="md"
            show-empty
            :empty-text="$t('com_geen_data', { ns: 'common' })"
            small
            striped
            multi
            selectable
            ref="selectableTable"
            @row-selected="onRowSelected"
        >
            <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                    <span aria-hidden="true">&check;</span>
                    <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                    <span aria-hidden="true">&nbsp;</span>
                    <span class="sr-only">Not selected</span>
                </template>
            </template>
            <template v-slot:cell(actions)="row">
                <b-button
                    v-if="row.item.zuurselSubType != undefined"
                    size="sm"
                    @click="unlinkStarter(row.item)"
                    class="bgc_aux_alarm"
                    ><font-awesome-icon icon="unlink" size="1x" />
                </b-button>
            </template>
        </b-table>
        <p>
            <b-button size="sm" v-if="erisselected" @click="clearSelected">{{
                $t('com_wisselectie', {
                    ns: 'common'
                })
            }}</b-button>
        </p>
    </b-card>
</template>

<script>
import { Feature } from '../../models/Features';
import { loadItem } from '../../models/DataHelper';
import Helper from '../../models/Helper';
import { INGRCAT_ENUM } from '../../models/IngredientCategorie';
import { loadData } from '../masterdata/loaddata';

export default {
    name: 'CardZuursel',
    data() {
        return {
            Zuursel: INGRCAT_ENUM.Zuursel,
            zuurselgroep: null,
            showDismissibleAlert: false,
            alertText: '',
            dismissCountDown: 5,
            selectCount: 0, // was 1 maar voor MG is dit 0
            approvedText: 'danger',
            selected: []
        };
    },
    components: {},
    model: {},
    props: {},
    computed: {
        variantType() {
            return this.approvedText;
        },
        erisselected() {
            return this.selected.length > this.selectCount;
        },
        editAllowed() {
            return this.selected.length > this.selectCount;
        },
        displayList() {
            let items = [];
            if (this.zuurselgroep) {
                if (this.zuurselgroep.length > 0) {
                    items = this.zuurselgroep.slice(0);
                    items.forEach((item) => {
                        if (this.getDisabled(item)) {
                            item = this.setRowVariant(item);
                        }
                    });
                }
            }
            return items;
        },
        fields() {
            const fieldsInit = [
                { key: 'selected', label: ' ', visible: true },
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'naam',
                    label: this.$t('com_naam', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'internID',
                    label: this.$t('com_internalid', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'zuurselSubType',
                    label: this.$t('prod_startergroup', { ns: 'production' }),
                    sortable: true,
                    visible: true,
                    formatter: (value, key, item) => {
                        const temp = item.zuurselSubType;
                        return temp ? this.$t('com_groep', { ns: 'common' }) + temp : '';
                    }
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        }
    },
    methods: {
        getDisabled(item) {
            const distList = Helper.distinctList(
                this.selected,
                'zuurselSubType'
            );
            const selectedNummerList = distList
                .filter((x) => x.zuurselSubType != undefined)
                .map((x) => x.zuurselSubType);
            if (selectedNummerList.length > 1) {
                this.showAlert('Hier mag je nooit komen'); // todo betere tekst
                return true;
            }
            const nummer =
                selectedNummerList.length == 1
                    ? selectedNummerList[0]
                    : undefined;
            const res = nummer
                ? item.zuurselSubType != undefined &&
                  item.zuurselSubType != nummer
                : false;
            return res;
        },
        onRowSelected(items) {
            //this.selected = items;
            const selected = [];
            items.forEach((element, index) => {
                if (this.getDisabled(element)) {
                    this.$refs.selectableTable.unselectRow(index);
                } else {
                    selected.push(element);
                }
            });
            this.selected = selected;
        },
        setRowVariant(item) {
            return (item._rowVariant = 'row-disabled');
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected();
        },
        showAlert(tekst) {
            this.dismissCountDown = 5;
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        resetVariant() {
            this.approvedText = 'danger';
        },
        async linkStarter() {
            const list = this.selected.map((x) => x.id);
            await loadItem(
                'product/saveGroupStarterIngredients',
                list,
                this.$t
            );
            this.approvedText = 'warning';
            this.showAlert(this.$t('prod_checkrecipes', { ns: 'production' }));
            this.loadData();
        },
        async unlinkStarter(item) {
            const doClose = await this.$root.showConfirmationBox(
                this.$t('com_unlink', {
                    ns: 'common',
                    val: item.naam.toLowerCase()
                })
            );
            if (doClose) {
                const data = { productID: item.id };
                await loadItem('product/removeStarterFromGroup', data, this.$t);
                this.approvedText = 'warning';
                this.showAlert(
                    this.$t('prod_checkrecipes', { ns: 'production' })
                );
                this.loadData();
            }
        },
        async loadData() {
            let cat = this.$store.getters['product/getIngredientCategorieByID'](
                INGRCAT_ENUM.Zuursel
            );
            if (cat == undefined) {
                await loadItem(
                    'product/loadIngredientCategories',
                    null,
                    this.$t
                );
                cat = this.$store.getters['product/getIngredientCategorieByID'](
                    INGRCAT_ENUM.Zuursel
                );
            }
            const ingrcat = await loadItem(
                'product/loadIngredientInfo',
                { category: cat.naam },
                this.$t
            );
            this.zuurselgroep = ingrcat;
        }
    },
    mounted() {},
    created() {
        this.loadData();
    }
};
</script>

<style scoped>
::v-deep .table > tbody > tr.b-table-row-selected,
::v-deep .table > tbody > tr.b-table-row-selected > td,
::v-deep .table > tbody > tr.b-table-row-selected > th {
    background-color: #00a1de;
    color: ghostwhite !important;
}
.overflow-class /deep/ .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
}
.table-row-disabled {
    pointer-events: none !important;
}
</style>
