<template>
    <div v-if="isNormaal" class="form-inline">
        <b-col style="width: 16%" class="mx-0 px-0 my-0 py-0">
            <b-input-group>
                <b-form-timepicker
                    :key="aangeraakt"
                    v-model="localSelected.uitpekelTijd"
                    locale="nl"
                    @input="set_inputTijd"
                    :disabled="!editAllowed"
                />
                <cheese-tijd
                    v-model="datumtijd"
                    @change="set_inputUitpekeltijd"
                    :disabled="!editAllowed"
                />
            </b-input-group>
        </b-col>

        <b-col class="mx-0 px-0 my-0 py-0" style="width: 16%">
            <b-input-group
                :append="$t('com_st', { ns: 'common' })"
                :label="$t('com_stuks', { ns: 'common' })"
            >
                <b-form-input
                    :placeholder="aantKaas"
                    type="number"
                    step="1"
                    v-model.number="localSelected.amount"
                    @change="set_input"
                    :disabled="!editAllowed"
                ></b-form-input>
            </b-input-group>
        </b-col>
        <b-col v-if="useWeight" style="width: 16%" class="mx-0 px-0 my-0 py-0">
            <b-input-group
                :append="$t('com_kg', { ns: 'common' })"
                :label="$t('com_kilos', { ns: 'common' })"
            >
                <b-form-input
                    :placeholder="weightKaas"
                    type="number"
                    step="1"
                    v-model.number="localSelected.kilos"
                    @change="set_input"
                    :disabled="!editAllowed"
                ></b-form-input>
            </b-input-group>
        </b-col>
        <b-col style="setSize" class="mx-0 px-0 my-0 py-0">
            <b-form-input
                v-b-tooltip.hover
                :title="maakTekst"
                :disabled="true"
                v-model="maakTekst"
            ></b-form-input>
        </b-col>
        <b-col style="width: 16%" class="mx-0 px-0 my-0 py-0">
            <lookup-combo
                v-model="localSelected.stocklocationID"
                v-bind:combotype="lctypeLocations"
                :disabled="!editAllowed"
                @change="set_input"
            ></lookup-combo>
        </b-col>
    </div>
    <div v-else class="form-inline">
        <b-col style="width: 16%" class="mx-0 px-0 my-0 py-0">
            <b-input-group
                :append="$t('com_st', { ns: 'common' })"
                :label="$t('com_stuks', { ns: 'common' })"
            >
                <b-form-input
                    :placeholder="aantKaas"
                    type="number"
                    step="1"
                    v-model.number="localSelected.amount"
                    @change="set_input"
                    :disabled="!editAllowed"
                ></b-form-input>
            </b-input-group>
        </b-col>
        <b-col style="width: 16%" class="mx-0 px-0 my-0 py-0">
            <lookup-combo
                v-model="localSelected.vormID"
                v-bind:combotype="lctypeShapes"
                :disabled="!editAllowed"
                @change="set_input"
            ></lookup-combo>
        </b-col>
        <!--         <b-col sm="3" class="mx-0 px-0 my-0 py-0">
            <lookup-combo
                id="input-kaasalternatief"
                v-model="localSelected.uitvalProductNaam"
                v-bind:combotype="lctypeFailure"
                :disabled="!editAllowed"
            ></lookup-combo>
        </b-col> -->
        <b-col style="width: 25%" class="mx-0 px-0 my-0 py-0">
            <b-form-input
                v-model="localSelected.uitvalProductNaam"
                :disabled="true"
            ></b-form-input>
        </b-col>
        <b-col style="width: 25%" class="mx-0 px-0 my-0 py-0">
            <lookup-combo
                v-model="localSelected.stocklocationID"
                v-bind:combotype="lctypeLocations"
                :disabled="!editAllowed"
                @change="set_input"
            ></lookup-combo>
        </b-col>
    </div>
</template>
<script>
import { UitpekelItem } from '../../models/BakInfo_helper';
import Helper from '../../models/Helper';
import DateHelper from '../../models/DateHelper';
import { LookupCombo } from './index';
import CheeseTijd from './CheeseTijd';
import { Feature } from '../../models/Features';
import { server } from 'typescript';

export default {
    name: 'UitpekelItemCombo',
    data: function () {
        return {
            lctypeShapes: window.constants.LCSHAPES,
            lctypeCheese: window.constants.LCCHEESE,
            lctypeLocations: window.constants.LCLOCATION,
            lctypeFailure: window.constants.LCFAILURECHEESE,
            aangeraakt: 1,
            errorMessage: '',
            eenheidID: null,
            flexList: [],
            datumtijd: null
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {
            type: UitpekelItem,
            required: true
        },
        editAllowed: {
            type: Boolean,
            default: false
        },
        baseDateTime: {
            type: Date
        }
    },
    components: {
        LookupCombo,
        CheeseTijd
    },
    computed: {
        aantKaas() {
            return Helper.stringformat(
                '{0} {1}',
                this.$t('com_number', { ns: 'common' }),
                this.$t('com_kazen', { ns: 'common' }).toLowerCase()
            );
        },
        weightKaas() {
            return Helper.stringformat(
                '{0} {1}',
                this.$t('com_weight', { ns: 'common' }),
                this.$t('com_kazen', { ns: 'common' }).toLowerCase()
            );
        },
        localSelected() {
            return this.selected;
        },
        useWeight() {
            return this.checkFeature('conf_UseCheeseWeights');
        },
        setSize() {
            return this.useWeight ? 'width: 33%' : 'width: 50%';
        },
        isValid() {
            return (
                this.localSelected.amount > 0 &&
                this.localSelected.vormID > 0 &&
                this.localSelected.stocklocationID > 0
            );
        },
        isNormaal() {
            return this.selected.uitvalProductID == undefined;
        },
        maakTekst() {
            return Helper.stringformat(
                '{0}- {1} ' +
                    this.$t('prod_kooi', { ns: 'production' }).toLowerCase() +
                    ' {2}:{3}',
                this.selected.productNaam,
                this.selected.vormNaam,
                this.selected.kooi ? this.selected.kooi : '',
                this.selected.laag ? this.selected.laag : ''
            );
        }
    },
    methods: {
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return this.$root.handleFeature(key);
        },
        set_input: function () {
            this.localSelected.isChanged = true;
            this.$emit('validComponent', this.isValid);
            this.$emit('change', true);
            this.$emit('input', this.localSelected);
        },
        set_inputTijd(value) {
            const tijd =
                this.localSelected.uitpekelTijdOri ??
                this.baseDateTime ??
                new Date();
            this.localSelected.uitpekelTijd =
                DateHelper.convertedFromTimepicker(tijd, value);
            this.localSelected.uitpekelTijdOri =
                this.localSelected.uitpekelTijd;
            this.set_input();
        },
        set_inputUitpekeltijd(value) {
            if (DateHelper.dateIsValid(value)) {
                this.localSelected.uitpekelTijdOri = value;
                this.localSelected.uitpekelTijd =
                    DateHelper.convertedToTime(value);
                this.aangeraakt += 1;
                this.set_input();
            }
        }
    },
    mounted() {
        this.localAmount = this.selected.amount;
        this.datumtijd =
            this.localSelected.uitpekelTijdOri ??
            this.baseDateTime ??
            new Date();
    },
    created() {}
};
</script>

<style scoped></style>

