<template>
    <b-card @submit.stop.prevent="handleSubmit" no-body>
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>
        <b-tabs card>
<!--             <b-tab
                :title-link-class="['bg-primary', 'text-light']"
                :title="showKaasOpbrengst"
                disabled
            /> -->

            <b-tab :title="$t('com_basis', { ns: 'common' })" active>
                <b-card-text>
                    <div>
                        <b-row>
                            <b-col sm="6">
                                <b-row>
                                    <b-col sm="3">
                                        <div v-if="editAllowed">
                                            <label
                                                >{{
                                                    $t('prod_validfrom', {
                                                        ns: 'production'
                                                    })
                                                }}
                                            </label>
                                        </div>
                                        <div v-else>
                                            <label
                                                >{{
                                                    $t('com_recipe', {
                                                        ns: 'common'
                                                    })
                                                }}#:
                                            </label>
                                            <b> &nbsp; {{ giveRecipeID }}</b>
                                        </div>
                                    </b-col>
                                    <b-col sm="9">
                                        <div v-if="editAllowed">
                                            <b-form-datepicker
                                                id="dateVanaf"
                                                :min="minDate"
                                                :max="maxDate"
                                                v-model="localItem.vanaf"
                                            ></b-form-datepicker>
                                        </div>
                                        <div v-else>
                                            {{ giveRecipeTimestring }}
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row v-if="editAllowed">
                                    <b-col sm="3">
                                        <label for="input-itemnaam"
                                            >{{
                                                $t('com_kaassoort', {
                                                    ns: 'common'
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <lookup-combo
                                            id="input-itemnaam"
                                            :placeholder="
                                                $t('com_kaassoort', {
                                                    ns: 'common'
                                                })
                                            "
                                            v-model="localItem.productID"
                                            :disabled="kaasenable"
                                            v-bind:combotype="locallctype"
                                            v-bind:zelf="productCheese"
                                            @inputnaam="setChangedKaassoort"
                                        ></lookup-combo>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col sm="3">
                                        <label for="input-yield"
                                            >{{
                                                $t('com_opbrengst', {
                                                    ns: 'common'
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9"
                                        ><b-form-group class="form-inline">
                                            <b-form-input
                                                v-b-tooltip.hover
                                                :title="
                                                    $t('com_opbrengstmaart', {
                                                        ns: 'common'
                                                    })
                                                "
                                                id="input-yield"
                                                :placeholder="
                                                    $t('com_opbrengstplace', {
                                                        ns: 'common'
                                                    })
                                                "
                                                type="number"
                                                step="0.1"
                                                v-model.number="
                                                    localItem.melkgebruik
                                                "
                                                :disabled="!editAllowed"
                                                @change="setChangedCalcYield"
                                            ></b-form-input>
                                            <b-form-input
                                                v-model="showKaasOpbrengst"
                                                readonly
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col sm="3">
                                        <label for="input-itemremark"
                                            >{{
                                                $t('com_opmerking', {
                                                    ns: 'common'
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-textarea
                                            id="input-itemremark"
                                            :placeholder="
                                                $t('com_opmerking', {
                                                    ns: 'common'
                                                })
                                            "
                                            v-model="localItem.opmerking"
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                            rows="3"
                                        ></b-form-textarea>
                                    </b-col>
                                    <b-col sm="3"> </b-col>
                                    <b-col sm="9">
                                        <b-form-checkbox
                                            v-if="configUseWei2a"
                                            v-model="useWei2a"
                                            value="true"
                                            unchecked-value="false"
                                            @change="rerender"
                                        >
                                            {{ strUseWei2a }}
                                        </b-form-checkbox>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="6">
                                <b-row>
                                    <b-col sm="3">
                                        <label for="input-strem"
                                            >{{
                                                $t('prod_xtemp', {
                                                    ns: 'production',
                                                    val: $t('prod_strem', {
                                                        ns: 'production'
                                                    })
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <temperature-input
                                            id="input-strem"
                                            v-model.number="localItem.stremTemp"
                                            @change="setChanged"
                                            :editAllowed="editAllowed"
                                            :showFahrenheit="
                                                configUseFahrenheit
                                            "
                                            :minvalue="minStremTemp"
                                            :maxvalue="maxStremTemp"
                                            :placeholderC="
                                                $t('prod_xtemperatuur', {
                                                    ns: 'production',
                                                    val: $t('prod_strem', {
                                                        ns: 'production'
                                                    })
                                                })
                                            "
                                        />
                                    </b-col>
                                </b-row>
                                <b-row v-if="useWei2a" key="rerenderkey">
                                    <b-col sm="3">
                                        <label for="input-tussen"
                                            >{{
                                                $t('prod_xtemp', {
                                                    ns: 'production',
                                                    val: $t('prod_tussentemp', {
                                                        ns: 'production'
                                                    })
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <temperature-input
                                            id="input-tussen"
                                            v-model.number="
                                                localItem.tussenTemp
                                            "
                                            @change="setChanged"
                                            :editAllowed="editAllowed"
                                            :showFahrenheit="
                                                configUseFahrenheit
                                            "
                                            :minvalue="minNawarmTemp"
                                            :maxvalue="maxNawarmTemp"
                                            :placeholderC="
                                                $t('prod_xtemperatuur', {
                                                    ns: 'production',
                                                    val: $t('prod_tussentemp', {
                                                        ns: 'production'
                                                    })
                                                })
                                            "
                                        />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col sm="3">
                                        <label for="input-nawarm"
                                            >{{
                                                $t('prod_xtemp', {
                                                    ns: 'production',
                                                    val: $t('prod_nawarm', {
                                                        ns: 'production'
                                                    })
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <temperature-input
                                            id="input-nawarm"
                                            v-model.number="
                                                localItem.nawarmTemp
                                            "
                                            @change="setChanged"
                                            :editAllowed="editAllowed"
                                            :showFahrenheit="
                                                configUseFahrenheit
                                            "
                                            :minvalue="minNawarmTemp"
                                            :maxvalue="maxNawarmTemp"
                                            :placeholderC="
                                                $t('prod_xtemperatuur', {
                                                    ns: 'production',
                                                    val: $t('prod_nawarm', {
                                                        ns: 'production'
                                                    })
                                                })
                                            "
                                        />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col sm="3">
                                        <b-button
                                            @click="calcWaterTemperatuur"
                                            :disabled="
                                                !editAllowed && !useWei2a
                                            "
                                            >{{
                                                $t('prod_xtemp', {
                                                    ns: 'production',
                                                    val: $t('prod_water', {
                                                        ns: 'production'
                                                    })
                                                })
                                            }}<!--font-awesome-icon
                                                icon="calculator"
                                                size="1x"
                                        /--></b-button
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <temperature-input
                                            id="input-itemwatertemperatuur"
                                            v-model.number="waterTemp"
                                            @input="set_inputTemperatuur"
                                            :editAllowed="editAllowed"
                                            :showFahrenheit="
                                                configUseFahrenheit
                                            "
                                            :minvalue="minWaterTemp"
                                            :maxvalue="maxWaterTemp"
                                            :placeholderC="
                                                $t('prod_xtemperatuur', {
                                                    ns: 'production',
                                                    val: $t('prod_water', {
                                                        ns: 'production'
                                                    })
                                                })
                                            "
                                        />
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col sm="3">
                                        <label for="input-itemRWW"
                                            >{{
                                                $t('prod_rww', {
                                                    ns: 'production'
                                                })
                                            }}%:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            v-b-tooltip.hover
                                            :title="strRWWtovMelk"
                                            id="input-itemRWW"
                                            :placeholder="strRWWtovMelk"
                                            v-model="
                                                localItem.restWeiWrongel1Perc
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col sm="3">
                                        <label for="input-percentageWater1"
                                            >{{
                                                $t('prod_water', {
                                                    ns: 'production'
                                                })
                                            }}%:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-percentageWater1"
                                            :placeholder="strWatertovRWW"
                                            type="number"
                                            step="0.1"
                                            v-model.number="
                                                localItem.percentageWater1
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                </b-row>

                                <b-row v-if="useWei2a">
                                    <b-col sm="3">
                                        <label for="input-itemRWW2a"
                                            >{{
                                                $t('prod_rww2', {
                                                    ns: 'production'
                                                })
                                            }}%:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            v-b-tooltip.hover
                                            :title="strRWW2tovRest"
                                            id="input-itemRWW2a"
                                            :placeholder="strRWW2tovRest"
                                            v-model="
                                                localItem.restWeiWrongel2Perc
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                                <b-row v-if="useWei2a">
                                    <b-col sm="3">
                                        <label for="input-percentageWater2"
                                            >{{
                                                $t('prod_waswater2a', {
                                                    ns: 'production'
                                                })
                                            }}%:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-percentageWater2"
                                            :title="strWatertovRWW2"
                                            :placeholder="strWatertovRWW2"
                                            type="number"
                                            step="0.1"
                                            v-model.number="
                                                localItem.percentageWater2
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </div>
                </b-card-text>
            </b-tab>
            <b-tab :title="$t('prod_ingredienten', { ns: 'production' })">
                <b-card-text>
                    <b-row v-if="editAllowed">
                        <b-col sm="3">
                            <label for="lcingredient">
                                {{
                                    $t('prod_add', {
                                        ns: 'production',
                                        val: $t('prod_ingredient', {
                                            ns: 'production'
                                        }).toLowerCase()
                                    })
                                }}:</label
                            >
                        </b-col>
                        <b-col sm="9">
                            <div class="form-inline">
                                <ingredient-selector
                                    v-model="newIngredientID"
                                />
                                <b-button
                                    @click="addRecIngredient()"
                                    class="bgc_aux_normal"
                                    :disabled="buttonDisabled"
                                    ><font-awesome-icon
                                        icon="arrow-down"
                                        size="1x"
                                    />
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                    <rec-ingredient-card
                        v-for="item in groupedRIList"
                        v-bind:key="item.id"
                        v-bind:ingredientCat="item.categorie"
                        v-bind:rilist="item.recingredients"
                        v-bind:editAllowed="editAllowed"
                        @input="kaasOpbrengst"
                        @deleteRI="deleteRecIngredient"
                    />
                </b-card-text>
            </b-tab>
            <b-tab :title="$t('com_normen', { ns: 'common' })">
                <b-card-text>
                    <div>
                        <b-row>
                            <b-col sm="6">
                                <b-row>
                                    <b-col sm="3">
                                        <label for="input-vocht">
                                            {{
                                                $t('an_xkaas', {
                                                    ns: 'analytics',
                                                    val:
                                                        $t('an_vocht', {
                                                            ns: 'analytics'
                                                        }).toLowerCase() + '%'
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-vocht"
                                            :placeholder="
                                                $t('an_xkaas', {
                                                    ns: 'analytics',
                                                    val: $t('an_vochtgehalte', {
                                                        ns: 'analytics'
                                                    }).toLowerCase()
                                                })
                                            "
                                            type="number"
                                            step="0.1"
                                            v-model.number="
                                                localRecNorm.vochtPerc
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                    <b-col sm="3">
                                        <label for="input-vet"
                                            >{{
                                                $t('an_xkaas', {
                                                    ns: 'analytics',
                                                    val:
                                                        $t('an_vetds', {
                                                            ns: 'analytics'
                                                        }).toLowerCase() + '%'
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-vet"
                                            :placeholder="
                                                $t('an_xkaas', {
                                                    ns: 'analytics',
                                                    val: $t('an_vetgehalte', {
                                                        ns: 'analytics'
                                                    }).toLowerCase()
                                                })
                                            "
                                            type="number"
                                            step="0.1"
                                            v-model.number="
                                                localRecNorm.vetDSPerc
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                    <b-col sm="3">
                                        <label for="input-zout"
                                            >{{
                                                $t('an_xkaas', {
                                                    ns: 'analytics',
                                                    val:
                                                        $t('an_zoutds', {
                                                            ns: 'analytics'
                                                        }).toLowerCase() + '%'
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-zout"
                                            :placeholder="
                                                $t('an_xds', {
                                                    ns: 'analytics',
                                                    val: $t('an_zoutgehalte', {
                                                        ns: 'analytics'
                                                    }).toLowerCase()
                                                })
                                            "
                                            type="number"
                                            step="0.1"
                                            v-model.number="
                                                localRecNorm.zoutDSPerc
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                                <hr />
                                <b-row>
                                    <b-col sm="3">
                                        <label for="input-therm"
                                            >{{
                                                $t('prod_xtemperatuur', {
                                                    ns: 'production',
                                                    val: $t('prod_therm', {
                                                        ns: 'production'
                                                    })
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <temperature-input
                                            id="input-therm"
                                            v-model.number="
                                                localRecNorm.thermisatieTemp
                                            "
                                            @change="setChanged"
                                            :editAllowed="editAllowed"
                                            :showFahrenheit="
                                                configUseFahrenheit
                                            "
                                            :minvalue="minThermTemp"
                                            :maxvalue="maxThermTemp"
                                            :placeholderC="
                                                $t('com_temperatuur', {
                                                    ns: 'common'
                                                })
                                            "
                                        />
                                    </b-col>
                                    <b-col sm="3">
                                        <label for="input-past"
                                            >{{
                                                $t('prod_xtemperatuur', {
                                                    ns: 'production',
                                                    val: $t('prod_past', {
                                                        ns: 'production'
                                                    })
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <temperature-input
                                            id="input-past"
                                            v-model.number="
                                                localRecNorm.pasteurisatieTemp
                                            "
                                            @change="setChanged"
                                            :editAllowed="editAllowed"
                                            :showFahrenheit="
                                                configUseFahrenheit
                                            "
                                            :minvalue="minPastTemp"
                                            :maxvalue="maxPastTemp"
                                            :placeholderC="
                                                $t('com_temperatuur', {
                                                    ns: 'common'
                                                })
                                            "
                                        />
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="6">
                                <b-row>
                                    <b-col sm="3">
                                        <label for="input-pekel">
                                            {{
                                                $t('prod_pekelverlies', {
                                                    ns: 'production'
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-pekel"
                                            :placeholder="
                                                $t('prod_pekelverlies', {
                                                    ns: 'production'
                                                })
                                            "
                                            type="number"
                                            step="0.1"
                                            v-model.number="
                                                localRecNorm.pekelVerlies
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col sm="3">
                                        <label for="input-itempeni"
                                            >{{
                                                $t('prod_peniverlies', {
                                                    ns: 'production'
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-itempeni"
                                            :placeholder="
                                                $t('prod_peniremark', {
                                                    ns: 'production'
                                                })
                                            "
                                            type="number"
                                            step="0.1"
                                            v-model.number="
                                                localRecNorm.indroogPerc
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col sm="3">
                                        <label for="input-pekelDuur"
                                            >{{
                                                $t('prod_pekelduur', {
                                                    ns: 'production'
                                                })
                                            }}&nbsp; ({{
                                                $t('an_uur', {
                                                    ns: 'analytics'
                                                })
                                            }}):</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-pekelDuur"
                                            :placeholder="
                                                $t('prod_pekelduur', {
                                                    ns: 'production'
                                                })
                                            "
                                            type="number"
                                            step="1"
                                            v-model.number="
                                                localRecNorm.pekelDuur
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                                <hr />
                                <b-row>
                                    <b-col sm="3">
                                        <label for="input-pHvoorpekel">
                                            {{
                                                $t('an_phvp', {
                                                    ns: 'analytics'
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-pHvoorpekel"
                                            :placeholder="
                                                $t('an_phvp', {
                                                    ns: 'analytics'
                                                })
                                            "
                                            type="number"
                                            step="0.1"
                                            v-model.number="
                                                localRecNorm.pHvoorpekel
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col sm="3">
                                        <label for="input-pHna1dag">
                                            {{
                                                $t('an_ph1dag', {
                                                    ns: 'analytics'
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-pHna1dag"
                                            :placeholder="
                                                $t('an_ph1dag', {
                                                    ns: 'analytics'
                                                })
                                            "
                                            type="number"
                                            step="0.1"
                                            v-model.number="
                                                localRecNorm.pHna1dag
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </div>
                </b-card-text>
            </b-tab>
            <b-tab :title="$t('com_production', { ns: 'common' })">
                <b-card-text>
                    <div>
                        <b-row>
                            <b-col sm="6">
                                <b-row>
                                    <b-col sm="3">
                                        <label for="input-progWB"
                                            >{{
                                                $t('prod_xprogramma', {
                                                    ns: 'production',
                                                    val: $t('prod_ost', {
                                                        ns: 'production'
                                                    })
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-progWB"
                                            :placeholder="
                                                $t('prod_xprogramma', {
                                                    ns: 'production',
                                                    val: $t('prod_ost', {
                                                        ns: 'production'
                                                    })
                                                })
                                            "
                                            v-model="localRecProduction.progWB"
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                    <b-col sm="3">
                                        <label for="input-progDrain"
                                            >{{
                                                $t('prod_xprogramma', {
                                                    ns: 'production',
                                                    val: $t('prod_drainage', {
                                                        ns: 'production'
                                                    })
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-progDrain"
                                            :placeholder="
                                                $t('prod_xprogramma', {
                                                    ns: 'production',
                                                    val: $t('prod_drainage', {
                                                        ns: 'production'
                                                    })
                                                })
                                            "
                                            v-model="
                                                localRecProduction.progDrain
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                    <b-col sm="3">
                                        <label for="input-progPers"
                                            >{{
                                                $t('prod_xprogramma', {
                                                    ns: 'production',
                                                    val: $t('prod_pers', {
                                                        ns: 'production'
                                                    })
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-progPers"
                                            :placeholder="
                                                $t('prod_xprogramma', {
                                                    ns: 'production',
                                                    val: $t('prod_pers', {
                                                        ns: 'production'
                                                    })
                                                })
                                            "
                                            v-model="
                                                localRecProduction.progPers
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                    <b-col sm="3">
                                        <label for="input-tijdWBProductie"
                                            >{{
                                                $t('prod_prodtimeost', {
                                                    ns: 'production'
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-tijdWBProductie"
                                            :placeholder="
                                                $t('prod_prodtimeost', {
                                                    ns: 'production'
                                                })
                                            "
                                            v-model="
                                                localRecProduction.tijdWBProductie
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <!--                             <b-col sm="6">
                                <b-row>
                                    <b-col sm="3">
                                        <label for="input-tijdVulWB"
                                            >Vultijd wrongelbereider
                                            (min):</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-tijdVulWB"
                                            placeholder="Vultijd wrongelbereider"
                                            v-model="
                                                localRecProduction.tijdVulWB
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                    <b-col sm="3">
                                        <label for="input-tijdStrem"
                                            >Tijd stremmen (min):</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-tijdStrem"
                                            placeholder="Stremtijd"
                                            v-model="
                                                localRecProduction.tijdStremmen
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                    <b-col sm="3">
                                        <label for="input-tijdSnijden"
                                            >Tijd snijden (min):</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-tijdSnijden"
                                            placeholder="Tijd snijden"
                                            v-model="
                                                localRecProduction.tijdSnijden
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                    <b-col sm="3">
                                        <label for="input-tijdWei1"
                                            >Tijd afzuigen wei1 (min):</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-tijdWei1"
                                            placeholder="Afzuigtijd wei1"
                                            v-model="
                                                localRecProduction.tijdDrainenWei1
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                    <b-col sm="3">
                                        <label for="input-tijdWas1"
                                            >Tijd roeren waswater1 (min):</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-tijdWas1"
                                            placeholder="Roertijd waswater1"
                                            v-model="
                                                localRecProduction.tijdWassen1
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                    <b-col sm="3">
                                        <label for="input-tijdWei2"
                                            >Tijd afzuigen wei2 (min):</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-tijdWei2"
                                            placeholder="Afzuigtijd wei2"
                                            v-model="
                                                localRecProduction.tijdDrainenWei2
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                    <b-col sm="3">
                                        <label for="input-tijdWas2"
                                            >Tijd roeren waswater2 (min):</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-tijdWas2"
                                            placeholder="Roertijd waswater2"
                                            v-model="
                                                localRecProduction.tijdWassen2
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-col> -->
                        </b-row>
                    </div>
                </b-card-text>
            </b-tab>
            <b-tab :title="$t('prod_defaults', { ns: 'production' })">
                <b-card-text>
                    <div>
                        <b-row>
                            <b-col sm="4">
                                <b-row>
                                    <b-col sm="3">
                                        <label for="input-eiwitMelk"
                                            >{{
                                                $t('an_xpercmelk', {
                                                    ns: 'analytics',
                                                    val: $t('an_eiwit', {
                                                        ns: 'analytics'
                                                    })
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-eiwitMelk"
                                            :placeholder="
                                                $t('an_xpercmelk', {
                                                    ns: 'analytics',
                                                    val: $t('an_eiwit', {
                                                        ns: 'analytics'
                                                    })
                                                })
                                            "
                                            type="number"
                                            step="0.01"
                                            v-model.number="
                                                localRecComposition.eiwitMelk
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                    <b-col sm="3">
                                        <label for="input-vetMelk"
                                            >{{
                                                $t('an_xpercmelk', {
                                                    ns: 'analytics',
                                                    val: $t('an_vet', {
                                                        ns: 'analytics'
                                                    })
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-vetMelk"
                                            :placeholder="
                                                $t('an_xpercmelk', {
                                                    ns: 'analytics',
                                                    val: $t('an_vet', {
                                                        ns: 'analytics'
                                                    })
                                                })
                                            "
                                            type="number"
                                            step="0.01"
                                            v-model.number="
                                                localRecComposition.vetMelk
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                    <b-col sm="3">
                                        <label for="input-densMelk"
                                            >{{
                                                $t('an_xmelk', {
                                                    ns: 'analytics',
                                                    val: $t('an_density', {
                                                        ns: 'analytics'
                                                    })
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-densMelk"
                                            :placeholder="
                                                $t('an_xmelk', {
                                                    ns: 'analytics',
                                                    val: $t('an_density', {
                                                        ns: 'analytics'
                                                    })
                                                })
                                            "
                                            type="number"
                                            step="0.001"
                                            v-model.number="
                                                localRecComposition.densMelk
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="4">
                                <b-row>
                                    <b-col sm="3">
                                        <label for="input-eiwitWei1"
                                            >{{
                                                $t('an_xpercwei1', {
                                                    ns: 'analytics',
                                                    val: $t('an_eiwit', {
                                                        ns: 'analytics'
                                                    })
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-eiwitWei1"
                                            :placeholder="
                                                $t('an_xpercwei1', {
                                                    ns: 'analytics',
                                                    val: $t('an_eiwit', {
                                                        ns: 'analytics'
                                                    })
                                                })
                                            "
                                            type="number"
                                            step="0.01"
                                            v-model.number="
                                                localRecComposition.eiwitWei1
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                    <b-col sm="3">
                                        <label for="input-vetWei1"
                                            >{{
                                                $t('an_xpercwei1', {
                                                    ns: 'analytics',
                                                    val: $t('an_vet', {
                                                        ns: 'analytics'
                                                    })
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-vetWei1"
                                            :placeholder="
                                                $t('an_xpercwei1', {
                                                    ns: 'analytics',
                                                    val: $t('an_vet', {
                                                        ns: 'analytics'
                                                    })
                                                })
                                            "
                                            type="number"
                                            step="0.01"
                                            v-model.number="
                                                localRecComposition.vetWei1
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                    <b-col sm="3">
                                        <label for="input-densWei1"
                                            >{{
                                                $t('an_xwei1', {
                                                    ns: 'analytics',
                                                    val: $t('an_density', {
                                                        ns: 'analytics'
                                                    })
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-densWei1"
                                            :placeholder="
                                                $t('an_xwei1', {
                                                    ns: 'analytics',
                                                    val: $t('an_density', {
                                                        ns: 'analytics'
                                                    })
                                                })
                                            "
                                            type="number"
                                            step="0.001"
                                            v-model.number="
                                                localRecComposition.densWei1
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="4">
                                <b-row>
                                    <b-col sm="3">
                                        <label for="input-eiwitWei2"
                                            >{{
                                                $t('an_xpercwei2', {
                                                    ns: 'analytics',
                                                    val: $t('an_eiwit', {
                                                        ns: 'analytics'
                                                    })
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-eiwitWei2"
                                            :placeholder="
                                                $t('an_xpercwei2', {
                                                    ns: 'analytics',
                                                    val: $t('an_eiwit', {
                                                        ns: 'analytics'
                                                    })
                                                })
                                            "
                                            type="number"
                                            step="0.01"
                                            v-model.number="
                                                localRecComposition.eiwitWei2
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                    <b-col sm="3">
                                        <label for="input-vetWei2"
                                            >{{
                                                $t('an_xpercwei2', {
                                                    ns: 'analytics',
                                                    val: $t('an_vet', {
                                                        ns: 'analytics'
                                                    })
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-vetWei2"
                                            :placeholder="
                                                $t('an_xpercwei2', {
                                                    ns: 'analytics',
                                                    val: $t('an_vet', {
                                                        ns: 'analytics'
                                                    })
                                                })
                                            "
                                            type="number"
                                            step="0.01"
                                            v-model.number="
                                                localRecComposition.vetWei2
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                    <b-col sm="3">
                                        <label for="input-densWei2">
                                            {{
                                                $t('an_xwei2', {
                                                    ns: 'analytics',
                                                    val: $t('an_density', {
                                                        ns: 'analytics'
                                                    })
                                                })
                                            }}:</label
                                        >
                                    </b-col>
                                    <b-col sm="9">
                                        <b-form-input
                                            id="input-densWei2"
                                            :placeholder="
                                                $t('an_xwei2', {
                                                    ns: 'analytics',
                                                    val: $t('an_density', {
                                                        ns: 'analytics'
                                                    })
                                                })
                                            "
                                            type="number"
                                            step="0.001"
                                            v-model.number="
                                                localRecComposition.densWei2
                                            "
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                        ></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </div>
                </b-card-text>
            </b-tab>
            <b-tab
                :title="$t('com_historie', { ns: 'common' })"
                v-if="closedRecipes != undefined"
            >
                <b-card-text>
                    <b-table
                        striped
                        responsive
                        :fields="fields"
                        :items="closedRecipes"
                        primary-key
                    >
                    </b-table>
                </b-card-text>
            </b-tab>
            <activity-log :lijst="activityList" />
        </b-tabs>
    </b-card>
</template>

<script>
import {
    Recept,
    RecNorm,
    RecProduction,
    RecComposition,
    RecIngredient
} from '../../models/Recept';
import { INGRCAT_ENUM } from '../../models/IngredientCategorie';
import { LookupCombo, TemperatureInput, ActivityLog } from '../components';
import { Feature } from '../../models/Features';
import RecIngredientCard from './RecIngredientCard';
import TechCalcHelper from '../../models/TechCalcHelper';
import { loadItem } from '../../models/DataHelper';
import Helper from '../../models/Helper';
import IngredientSelector from './IngredientSelector.vue';
import _ from 'lodash';
export default {
    name: 'RecipeCard',
    data: function () {
        return {
            lctypeCheesesWithoutRecipeAndSelf:
                window.constants.LCCHEESEWITHOUTRECIPEANDSELF,
            lctypeCheesesWithoutRecipe: window.constants.LCCHEESEWITHOUTRECIPE,
            lctypeIngredient: window.constants.LCINGREDIENT,
            minPastTemp: window.constants.MIN_TEMP_PAST,
            maxPastTemp: window.constants.MAX_TEMP_PAST,
            minThermTemp: window.constants.MIN_TEMP_THERM,
            maxThermTemp: window.constants.MAX_TEMP_THERM,
            minStremTemp: window.constants.MIN_TEMP_STREM,
            maxStremTemp: window.constants.MAX_TEMP_STREM,
            minNawarmTemp: window.constants.MIN_TEMP_NAWARM,
            maxNawarmTemp: window.constants.MAX_TEMP_NAWARM,
            minWaterTemp: window.constants.MIN_TEMP_WASWATER,
            maxWaterTemp: window.constants.MAX_TEMP_WASWATER,
            refRecNorm: null,
            refRecProduction: null,
            refRecComposition: null,
            ingredientCatLijst: null,
            aangeraakt: 1,
            rerenderkey: 1,
            waterTemp: null,
            ingredientteller: 0,
            newIngredientID: -1,
            showDismissibleAlert: false,
            disableButton: false,
            showKaasOpbrengst: '',
            alertText: '',
            fields: [
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'kaasnaam',
                    label: this.$t('com_kaassoort', { ns: 'common' })
                },
                {
                    key: 'vanaf',
                    label: this.$t('prod_validfrom', { ns: 'production' })
                },
                { key: 'tot', label: this.$t('com_tot', { ns: 'common' }) },
                {
                    key: 'progWB',
                    label: this.$t('prod_wbprog', { ns: 'production' })
                }
            ],
            strRWWtovMelk: this.$t('prod_perctovmelk', {
                ns: 'production',
                val: this.$t('prod_rww', {
                    ns: 'production'
                })
            }),
            strWatertovRWW: this.$t('prod_perctovrww', {
                ns: 'production',
                val: this.$t('prod_water', {
                    ns: 'production'
                })
            }),
            strRWW2tovRest: this.$t('prod_perctovinhoud', {
                ns: 'production',
                val: this.$t('prod_rww2', {
                    ns: 'production'
                })
            }),
            strWatertovRWW2: this.$t('prod_perctovrww', {
                ns: 'production',
                val: this.$t('prod_water', {
                    ns: 'production'
                })
            }),
            useWei2a: false
        };
    },
    model: {
        prop: 'theRecipe',
        event: 'changedRecipe'
    },
    props: {
        theRecipe: {
            type: Recept,
            default: null
        },
        editAllowed: {
            type: Boolean,
            default: false
        },
        closedRecipesList: {
            type: Array
        },
        activityList: {
            type: Array
        },
        kaasNaam: {
            type: String,
            default: ''
        }
    },
    components: {
        LookupCombo,
        TemperatureInput,
        ActivityLog,
        IngredientSelector,
        RecIngredientCard
    },
    watch: {},
    computed: {
        buttonDisabled() {
            return this.disableButton;
        },
        localItem() {
            return this.theRecipe;
        },
        localRecNorm() {
            return this.refRecNorm ?? new RecNorm();
        },
        localRecProduction() {
            return this.refRecProduction ?? new RecProduction();
        },
        localRecComposition() {
            return this.refRecComposition ?? new RecComposition();
        },
        locallctype() {
            return this.localItem.productID > -1
                ? this.lctypeCheesesWithoutRecipeAndSelf
                : this.lctypeCheesesWithoutRecipe;
        },
        productCheese() {
            return {
                value: this.localItem.productID,
                text: this.kaasNaam
            };
        },
        setSize() {
            return this.configUseFahrenheit ? 6 : 12;
        },
        configUseFahrenheit() {
            const useF = this.checkFeature('conf_useFahrenheitinProduction');
            return useF;
        },
        minDate() {
            const now = new Date();
            now.setDate(now.getDate() - 1);
            return now;
        },
        maxDate() {
            const now = new Date();
            now.setDate(now.getDate() + 1);
            return now;
        },
        chechkStateStrem() {
            // todo naar eindcheck
            const test = this.localItem.stremTemp
                ? this.localItem.stremTemp > 25 && this.localItem.stremTemp < 50
                : true;
            return test;
        },
        chechkStateNawarm() {
            // todo naar eindcheck
            const test1 = this.localItem.nawarmTemp
                ? this.localItem.nawarmTemp > 25 &&
                  this.localItem.nawarmTemp < 75
                : true;
            return test1;
        },
        giveRecipeTimestring() {
            if (this.localItem) {
                const van = this.localItem.vanaf;
                const tot = this.localItem.tot;
                return Helper.stringformat(
                    '{0} {1} {2}',
                    this.$t('prod_validfrom', { ns: 'production' }),
                    van,
                    tot
                        ? this.$t('com_tot', { ns: 'common' }).toLowerCase() +
                              ' ' +
                              tot
                        : '.'
                );
            } else {
                return '-- ?? -- ';
            }
        },
        giveRecipeID() {
            return this.localItem ? this.localItem.id : '-- ?? -- ';
        },
        groupedRIList() {
            // eslint-disable-next-line no-unused-vars
            const tel = this.aangeraakt;
            if (this.localItem.recIngredienten == undefined) return null;
            const x = _(this.localItem.recIngredienten.slice(0))
                .groupBy((x) => x.ingredientCatID)
                .map((value, key) => ({
                    categorie: key,
                    recingredients: value
                }))
                .value();
            return x;
        },
        closedRecipes() {
            return this.closedRecipesList;
        },
        kaasenable() {
            return false; //this.localItem.productID > -1;
        },
        configUseWei2a() {
            return this.checkFeature('conf_useWhey2a');
        },
        strUseWei2a() {
            const trans = this.$t('conf_usewhey2a', { ns: 'permissies' });
            return trans;
        }
    },
    methods: {        
        kaasOpbrengst() {
            const melkfactorVoorSeizoen = 1;
            let kilosmilk = 0;
            let kilos = 0;
            let aantal = 0;
            if (this.localItem) {
                const melkLijst = this.localItem.recIngredienten.filter(
                    (x) => x.ingredientCatID == INGRCAT_ENUM.Melk
                );
                if (melkLijst.length > 0) {
                    melkLijst.forEach((x) => {
                        kilosmilk += x.hoeveelheid ?? 0;
                    });
                    kilosmilk = melkLijst[0].hoeveelheid;
                }
                if (kilosmilk > 1) {
                    kilos =
                        kilosmilk /
                        (this.localItem.melkgebruik * melkfactorVoorSeizoen);
                    const kaaslijst =
                        this.$store.getters['product/getCheeseTypesInfo'];
                    const kaas = kaaslijst.find(
                        (x) => x.productID == this.localItem.productID
                    );
                    if (kaas?.standaardGewicht) {
                        aantal = Math.round(kilos / kaas.standaardGewicht);
                    }
                }
            }
            this.showKaasOpbrengst = Helper.stringformat(
                '{0} {1}: {2}',
                this.$t('com_number', { ns: 'common' }),
                this.$t('com_kazen', { ns: 'common' }).toLowerCase(),
                aantal > 0 ? aantal : '--'
            );
        },
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return this.$root.handleFeature(key);
        },
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        rerender() {
            this.rerenderkey += 1;
        },
        set_inputTemperatuur(val) {
            this.localItem.waterTemp = val ? val : null;
            this.setChanged();
        },
        setChangedCalcYield() {
            this.kaasOpbrengst();
            this.localItem.isChanged = true;
        },
        setChanged() {
            this.localItem.isChanged = true;
        },
        setChangedKaassoort(value) {
            this.localItem.isChanged = true;
            const selectedText = value;
            if (this.kaasNaam != selectedText) {
                this.updated = this.updated ?? this.localItem.gewijzigd;
                //this.localItem.gewijzigd = null;
            } else {
                //this.localItem.gewijzigd =  this.localItem.gewijzigd ??  this.updated;
            }
            this.kaasOpbrengst();
        },
        hide() {
            this.toegekendGroepID = -1;
            this.groepInfo = [];
            this.personNaam = '';
            this.personEmail = '';
            this.$emit('hide');
        },
        change(visible) {
            this.$emit('change', visible);
        },
        createRecIngredientsBase() {
            // standaard hebben we altijd melk, stremsel en zuursel
            const tempArr = [];
            tempArr.push(this.createRecIngredient(INGRCAT_ENUM.Melk));
            tempArr.push(this.createRecIngredient(INGRCAT_ENUM.Stremsel));
            tempArr.push(this.createRecIngredient(INGRCAT_ENUM.Zuursel));
            this.localItem.recIngredienten.push(...tempArr);
            this.aangeraakt += 1;
        },
        createRecIngredient(catid, productID, productBaseunitid) {
            const ingredientCat =
                this.$store.getters['product/getIngredientCategorieByID'](
                    catid
                );
            this.ingredientteller -= 1;
            const locRecIng = new RecIngredient({ id: this.ingredientteller });
            locRecIng.recipeID = this.localItem.id;
            locRecIng.ingredientCatID = ingredientCat.id;
            locRecIng.eenheid = ingredientCat.eenheid;
            if (productID != undefined) {
                locRecIng.productID = productID;
                locRecIng.eenheid = productBaseunitid;
            }
            return locRecIng;
        },
        addRecIngredient() {
            const found = this.localItem.recIngredienten.find(
                (x) => x.productID === this.newIngredientID
            );
            if (found) {
                const errormessage = this.$t('prod_ingredientinrecept', {
                    ns: 'production'
                });
                this.showAlert(errormessage);
            } else {
                const ingredient = this.$store.getters[
                    'components/getIngredientComboLijstbyID'
                ](this.newIngredientID);
                const cat = this.$store.getters[
                    'product/getIngredientCategorieByDescription'
                ](ingredient.category);

                const l = this.createRecIngredient(
                    cat.id,
                    this.newIngredientID,
                    ingredient.baseunit
                );
                this.localItem.recIngredienten.push(l);
                this.aangeraakt += 1;
            }
        },
        async deleteRecIngredient(riId) {
            const index = this.localItem.recIngredienten.findIndex((x) => {
                return x.id === riId;
            });
            if (index > -1) {
                const doClose = await this.$root.showConfirmationBox(
                    this.$t('com_delete', {
                        ns: 'common',
                        val: this.$t('prod_ingredient', { ns: 'production' })
                    }) + riId
                );
                if (doClose) {
                    this.localItem.recIngredienten.splice(index, 1);
                    this.aangeraakt += 1;
                }
            }
        },
        async calcWaterTemperatuur() {
            if (
                this.localItem.restWeiWrongel1Perc == undefined ||
                this.localItem.percentageWater1 == undefined
            ) {
                this.showAlert(this.$t('prod_nocalc', { ns: 'production' }));
                return;
            }
            const melk = 500; // maakt voor de berekening niet uit
            const rwwperc = this.localItem.restWeiWrongel1Perc;
            const wwwperc = this.localItem.percentageWater1;
            const rwwperc2a = this.localItem.restWeiWrongel2Perc;
            const waswaterperc2a = this.localItem.percentageWater2;

            const temp = this.useWei2a
                ? TechCalcHelper.calcWWWtemperature2a(
                      melk,
                      rwwperc,
                      wwwperc,
                      rwwperc2a,
                      waswaterperc2a,
                      [],
                      this.localItem.stremTemp,
                      this.localItem.nawarmTemp,
                      this.localItem.tussenTemp
                  )
                : TechCalcHelper.calcWWWtemperature(
                      melk,
                      rwwperc,
                      wwwperc,
                      [],
                      this.localItem.stremTemp,
                      this.localItem.nawarmTemp
                  );
            const soll = isNaN(temp) ? '?' : Math.round(temp * 10) / 10;
            const ist = this.waterTemp;
            if (ist != undefined && ist != soll) {
                const tempWWWtekst = Helper.stringformat(
                    this.$t('prod_recalcwatertemp', { ns: 'production' }),
                    ist,
                    soll
                );
                const overschrijven = await this.$root.showConfirmationBox(
                    this.$t('com_replace', { ns: 'common' }) +
                        '?  ' +
                        tempWWWtekst
                );
                if (overschrijven) {
                    this.localItem.waterTemp = soll;
                    this.waterTemp = soll;
                }
            } else {
                this.localItem.waterTemp = soll;
                this.waterTemp = soll;
            }
        }
    },
    mounted() {
        if (!this.theRecipe.recNorm) this.theRecipe.recNorm = new RecNorm();
        if (!this.theRecipe.recProduction)
            this.theRecipe.recProduction = new RecProduction();
        if (!this.theRecipe.recComposition)
            this.theRecipe.recComposition = new RecComposition();
        this.refRecNorm = this.theRecipe.recNorm;
        this.refRecProduction = this.theRecipe.recProduction;
        this.refRecComposition = this.theRecipe.recComposition;
        if (this.theRecipe.recIngredienten.length == 0)
            this.createRecIngredientsBase();
        this.theRecipe.vanaf = new Date();
        this.useWei2a = this.localItem.restWeiWrongel2Perc != undefined;
        this.waterTemp = this.localItem.waterTemp;
    },
    created() {
        this.ingredientCatLijst =
            this.$store.getters['product/getIngredientCategorieLijst'];
        if (this.ingredientCatLijst.length == 0) {
            loadItem('product/loadIngredientCategories', null, this.$t)
                .then(async (ingrcatArray) => {
                    await Promise.all(
                        ingrcatArray.map(async (ingrcat) => {
                            ingrcat.lijst = await loadItem(
                                'product/loadIngredientInfo',
                                { category: ingrcat.naam },
                                this.$t
                            );
                        })
                    );
                })
                .then(
                    (this.ingredientCatLijst =
                        this.$store.getters[
                            'product/getIngredientCategorieLijst'
                        ])
                );
        }
        loadItem('product/loadCheeseTypeInfo', null, this.$t);
    }
};
</script>

<style scoped></style>
