/* eslint-disable no-console */
import { UserManager }  from 'oidc-client-ts';
import store from '../../store';

export class SecurityService {
    mgr;

    constructor() {
        const mgr = new UserManager({
            authority: window.VUE_APP_IDENTTY, // identityserver
            client_id: window.APPID, // zelf_naam
            redirect_uri: window.VUE_APP_URI + 'signin-oidc', // zelf
            response_type: 'code', // code = authorization code
            scope: window.APPSCOPE,
            post_logout_redirect_uri: window.VUE_APP_URI + 'signout-oidc', // zelf
            automaticSilentRenew: false,
            // userStore: new OidcClient .WebStorageStateStore({
            //     store: window.localStorage
            // }),
            // silent_redirect_uri: window.VUE_APP_URI + 'silent-renew.html',
            // accessTokenExpiringNotificationTime: 10
        });

        this.mgr = mgr;

        //UserManager.Log.logger = console; // ToDo alleen in test
        //UserManager.Log.level = UserManager.Log.DEBUG; // ToDo alleen in test

        mgr.events.addUserLoaded(function (user) {
            if (window.APP_ENV == 'test')
                console.log('New User Loaded：', arguments);
            if (window.APP_ENV == 'test')
                console.log('Access_token: ', user.access_token);
            store.commit('account/setJWTToken', user.access_token);
            if (window.APP_ENV == 'test')
                console.log('stored AUX: ', user.access_token);
        });

        mgr.events.addAccessTokenExpiring(function () {
            if (window.APP_ENV == 'test')
                console.log('AccessToken Expiring：', arguments);
        });

        mgr.events.addAccessTokenExpired(function () {
            if (window.APP_ENV == 'test')
                console.log('AccessToken Expired：', arguments);
            alert('Session expired. Going out!');
            mgr.signoutRedirect()
                .then(function (resp) {
                    console.log('signed out', resp);
                })
                .catch(function (err) {
                    console.log(err);
                });
        });

        mgr.events.addSilentRenewError(function () {
            console.error('Silent Renew Error：', arguments);
        });

        mgr.events.addUserSignedOut(function () {
            alert('Going out!');
            if (window.APP_ENV == 'test')
                console.log('UserSignedOut：', arguments);
            mgr.signoutRedirect()
                .then(function (resp) {
                    console.log('signed out', resp);
                })
                .catch(function (err) {
                    console.log(err);
                });
        });
    }

    // Renew the token manually
    renewToken() {
        const self = this;
        return new Promise((resolve, reject) => {
            self.mgr
                .signinSilent()
                .then(function (user) {
                    if (user == null) {
                        self.signIn(null);
                    } else {
                        return resolve(user);
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    return reject(err);
                });
        });
    }

    // Get the user who is logged in
    getUser() {
        const self = this;
        return new Promise((resolve, reject) => {
            self.mgr
                .getUser()
                .then(function (user) {
                    if (user == null) {
                        self.signIn();
                        return resolve(null);
                    } else {
                        return resolve(user);
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    return reject(err);
                });
        });
    }

    // Check if there is any user logged in
    getSignedIn() {
        const self = this;
        return new Promise((resolve, reject) => {
            self.mgr
                .getUser()
                .then(function (user) {
                    if (user == null) {
                        self.signIn();
                        return resolve(false);
                    } else {
                        return resolve(true);
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    return reject(err);
                });
        });
    }

    // Redirect of the current window to the authorization endpoint.
    signIn(returnPath) {
        //  let fullReturnUrl = returnPath
        //      ? window.VUE_APP_URI + returnPath.replace(/^\/+/, '')
        //      : null;
        const fullReturnUrl = returnPath ? returnPath : null;

        return this.mgr
            .signinRedirect({
                state: fullReturnUrl
            })
            .catch(function (err) {
                console.log(err);
            });
    }

    signinRedirectCallback() {
        return this.mgr.signinRedirectCallback();
    }

    // Redirect of the current window to the end session endpoint
    signOut() {
        this.mgr
            .signoutRedirect()
            .then(function (resp) {
                console.log('signed out', resp);
            })
            .catch(function (err) {
                console.log(err);
            });
    }

    // Get the profile of the user logged in
    getProfile() {
        const self = this;
        return new Promise((resolve, reject) => {
            self.mgr
                .getUser()
                .then(function (user) {
                    if (user == null) {
                        self.signIn();
                        return resolve(null);
                    } else {
                        return resolve(user.profile);
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    return reject(err);
                });
        });
    }

    // Get the token id
    getIdToken() {
        const self = this;
        return new Promise((resolve, reject) => {
            self.mgr
                .getUser()
                .then(function (user) {
                    if (user == null) {
                        self.signIn();
                        return resolve(null);
                    } else {
                        return resolve(user.id_token);
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    return reject(err);
                });
        });
    }

    // Get the session state
    getSessionState() {
        const self = this;
        return new Promise((resolve, reject) => {
            self.mgr
                .getUser()
                .then(function (user) {
                    if (user == null) {
                        self.signIn();
                        return resolve(null);
                    } else {
                        return resolve(user.session_state);
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    return reject(err);
                });
        });
    }

    // Get the access token of the logged in user
    getAccessToken() {
        const self = this;
        return new Promise((resolve, reject) => {
            self.mgr
                .getUser()
                .then(function (user) {
                    if (user == null) {
                        self.signIn();
                        return resolve(null);
                    } else {
                        return resolve(user.access_token);
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    return reject(err);
                });
        });
    }

    // Takes the scopes of the logged in user
    getScopes() {
        const self = this;
        return new Promise((resolve, reject) => {
            self.mgr
                .getUser()
                .then(function (user) {
                    if (user == null) {
                        self.signIn();
                        return resolve(null);
                    } else {
                        return resolve(user.scopes);
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    return reject(err);
                });
        });
    }

    // Get the user roles logged in
    getRole() {
        const self = this;
        return new Promise((resolve, reject) => {
            self.mgr
                .getUser()
                .then(function (user) {
                    if (user == null) {
                        self.signIn();
                        return resolve(null);
                    } else {
                        return resolve(user.profile.role);
                    }
                })
                .catch(function (err) {
                    console.log(err);
                    return reject(err);
                });
        });
    }
}
