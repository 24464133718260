export { OrderHeader, OrderInfo, OrderLine };
import { RecurrencyPattern, RecurrencyModel } from './Recurrency';
import Helper from './Helper';
import DateHelper from './DateHelper';

class OrderHeader {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.iD ?? data.id
            },
            gewijzigd: {
                value: data.updated,
                writable: false
            },
            internID: {
                type: String,
                value: data.internalid,
                writable: true,
                enumerable: true
            },
            externID: {
                type: String,
                value: data.externalid,
                writable: true,
                enumerable: true
            },
            status: {
                type: Number,
                value: data.status,
                writable: true,
                enumerable: true
            },
            type: {
                type: Number,
                value: data.type,
                writable: true,
                enumerable: true
            },
            klantID: {
                type: Number,
                value: data.customerID,
                writable: true,
                enumerable: true
            },
            klant: {
                type: String,
                value: data.customer,
                writable: true,
                enumerable: true
            },
            opmerking: {
                type: String,
                value: data.remark ?? data.opmerking,
                writable: true,
                enumerable: true
            },
            klantRef: {
                type: String,
                value: data.reference,
                writable: true,
                enumerable: true
            },
            bevestigdOp: {
                type: Date,
                value: data.confirmed,
                writable: true,
                enumerable: true
            },
            bevestigdDoor: {
                type: String,
                value: data.confirmedBy,
                writable: true,
                enumerable: true
            },
            // lijsten
            regels: {
                value: data.orderLines
                    ? data.orderLines.map((x) => new OrderLine(x))
                    : [],
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }
    SetForSave() {
        const orderRegels = [];
        for (let i = 0; i < this.regels.length; i++) {
            const obj = this.regels[i].SetForSave();
            orderRegels.push(obj);
        }
        const OrderModel = {
            OrderLines: orderRegels,
            Confirmed: DateHelper.localDateToUTC(this.bevestigdOp),
            Confirmedby: this.bevestigdDoor,
            Remark: this.opmerking,
            Customer: this.klant,
            CustomerID: this.klantID,
            Reference: this.klantRef,
            Internalid: this.internID,
            Externalid: this.externID,
            Type: this.type
        };
        if (this.id > 0) {
            OrderModel.ID = this.id;
        }
        return OrderModel;
    }
    CheckValidity(func) {
        const warnText = '';
        return warnText;
    }
}

class OrderLine {
    constructor(data) {
        this.id = -1000;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.iD ?? data.id
                //writable: false
            },
            gewijzigd: {
                value: data.updated
                //writable: false
            },
            internID: {
                type: String,
                value: data.internalid,
                writable: true,
                enumerable: true
            },
            externID: {
                type: String,
                value: data.externalid,
                writable: true,
                enumerable: true
            },
            status: {
                type: Number,
                value: data.orderLineStatus,
                writable: true,
                enumerable: true
            },
            type: {
                type: Number,
                value: data.orderLineType,
                writable: true,
                enumerable: true
            },
            opmerking: {
                type: String,
                value: data.remark ?? data.opmerking,
                writable: true,
                enumerable: true
            },
            klantRef: {
                type: String,
                value: data.reference,
                writable: true,
                enumerable: true
            },
            productID: {
                type: Number,
                value: data.productID,
                writable: true,
                enumerable: true
            },
            productNaam: {
                type: String,
                value: data.productName,
                writable: true,
                enumerable: true
            },
            vraagProddatum: {
                type: Date,
                value:
                    data.requestedProductionDate ??
                    data.requestedproductiondate, // data.
                writable: true,
                enumerable: true
            },
            vraagDeldatum: {
                type: Date,
                value: data.requestedDeliveryDate ?? data.requesteddeliverydate, // data.
                writable: true,
                enumerable: true
            },
            vraagAantal: {
                type: Number,
                value: data.requestedAmount ?? data.requestedamount,
                writable: true,
                enumerable: true
            },
            vormNaam: {
                type: String,
                value: data.shape,
                writable: true,
                enumerable: true
            },
            vormID: {
                type: Number,
                value: data.shapeID,
                writable: true,
                enumerable: true
            }
        });
    }
    SetForSave() {
        const OrderLineModel = {
            ShapeID: this.vormID,
            RequestedAmount: this.vraagAantal,
            RequestedProductionDate: DateHelper.localDateToUTC(
                this.vraagProddatum
            ),
            RequestedDeliveryDate: DateHelper.localDateToUTC(
                this.vraagDeldatum
            ),
            ProductID: this.productID,
            Reference: this.klantRef,
            Updated: this.gewijzigd
        };
        if (this.id > 0) {
            OrderLineModel.ID = this.id;
        }
        return OrderLineModel;
    }
}

class OrderInfo {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        this.editAllowed = true;
        data = data ?? {};
        this.id = data.id;
        this.orderID = data.oh_id;
        this.oh_externalid = data.order;
        this.ol_externalid = data.line;
        this.pt_id = data.pt_id ?? data.productID; //
        this.pt_internalname = data.product;
        this.klantID = data.customerid ?? data.customerID; //
        this.klant = data.customer; //
        this.klantRef = data.reference; //
        this.amount = data.requested;
        this.ol_requestedamount = data.requested;
        this.ol_requesteddate = data.requestedproddate; //
        this.productiedatum = data.requestedproddate; //
        this.afleverdatum = data.requesteddeldate; //
        this.toegewezen = data.assigned ?? 0;
        this.productions = data.productions;
        this.rest = data.unassigned;
        this.externID = this.oh_externalid + '-' + this.ol_externalid;
    }
}

