<template>
    <b-card no-body>
        <b-card-header class="container-fluid">
            {{ $t('prod_inpekelgeg', { ns: 'production' }) }}

            <b-button
                v-if="isVersion30"
                @click="showMaesurementInsert()"
                class="float-right bgc_aux_warning"
                ><font-awesome-icon icon="microscope" size="1x" />
            </b-button>
        </b-card-header>
        <div>
            <div
                class="form-inline"
                v-for="(inpekelitem, index) in inpekellijst"
                v-bind:key="index"
            >
                <b-col sm="10" class="mx-0 px-0 my-1 py-0">
                    <inpekel-item-combo
                        v-bind:key="index"
                        v-model="inpekellijst[index]"
                        v-bind:editAllowed="editAllowed"
                        v-bind:cheeseProductID="theBakInpekel.productID"
                        v-bind:baseDateTime="theBakInpekel.boekDatum"
                        @change="set_input"
                        @input="check_doubles"
                        @validComponent="setGewichtenValid"
                    />
                </b-col>
                <b-col sm="2" class="mx-0 px-0 my-1 py-0">
                    <b-button
                        v-if="editAllowed"
                        @click="copyItem(inpekelitem)"
                        :disabled="setDisabledShape"
                        class="bgc_aux_normal"
                        ><font-awesome-icon icon="copy" size="1x" />
                    </b-button>
                    <b-button
                        v-if="editAllowed"
                        @click="verwijderItem(inpekelitem)"
                        :disabled="setDisabledShape"
                        class="bgc_aux_alarm"
                        ><font-awesome-icon icon="trash-alt" size="1x" />
                    </b-button>
                </b-col>
            </div>
            <b-alert
                v-model="showDismissibleAlert"
                variant="danger"
                dismissible
            >
                {{ alertText }}
            </b-alert>
            <b-alert v-model="showWarningAlert" variant="warning" dismissible>
                {{ reminder }}
            </b-alert>
        </div>
        <modal-measurement
            v-if="showDetailsMeas"
            v-model="showDetailsMeas"
            v-bind:isNew="true"
            v-bind:idIQ="idIQphvp"
            :idEntityObject="idEO"
            :idExecutor="idPhMeter"
            @hide="reset"
        />
    </b-card>
</template>

<script>
import _ from 'lodash';
import Helper from '../../models/Helper';
import DateHelper from '../../models/DateHelper';
import { loadItem } from '../../models/DataHelper';
import { BakInpekel, InpekelItem } from '../../models/BakInfo_helper';
import { Feature } from '../../models/Features';
import ModalMeasurement from '../analytics/ModalMeasurement.vue';
import { InpekelItemCombo } from '../components';

export default {
    name: 'CardInpekel',
    data() {
        return {
            lctypeShapes: window.constants.LCSHAPES,
            idIQphvp: window.constants.ADATA_IQ_PHVP,
            idPhMeter: window.constants.ADATA_EX_PHMETER,
            idEO: -1,
            showDismissibleAlert: false,
            showWarningAlert: false,
            alertText: '',
            reminder: '',
            aangeraakt: 1,
            readonly: false,
            showDetailsMeas: false,
            gewichtValid: true,
            strMerkenverschil: this.$t('prod_merkentovkaas', {
                ns: 'production'
            }),
            strOpbrengstTeveel: this.$t('prod_minderopbrengst', {
                ns: 'production'
            }),
            strOpbrengstTekort: this.$t('prod_meeropbrengst', {
                ns: 'production'
            }),
            strUniekeVorm: this.$t('prod_warnuniekinlijst2', {
                ns: 'production'
            }),
            strGeenMerken: this.$t('prod_geenmerken', {
                ns: 'production'
            })
        };
    },
    components: { InpekelItemCombo, ModalMeasurement },
    model: {
        prop: 'theBakInpekel',
        event: 'changedRecipe'
    },
    props: {
        theBakInpekel: {
            type: BakInpekel,
            default: null
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isVersion30() {
            return (
                this.$store.getters['masterdata/getPackageVersion'] >=
                window.constants.VERSION30
            );
        },
        inpekellijst() {
            // eslint-disable-next-line no-unused-vars
            const tel = this.aangeraakt;
            return this.theBakInpekel.inpekelAmounts.filter(
                (x) =>
                    x.isDeleted == false &&
                    (x.nouse == undefined || x.nouse == false)
            );
        },
        setDisabledShape() {
            const disableMultipleProductShapes = this.checkFeature(
                'conf_disableMultipleProductShapes'
            );
            return disableMultipleProductShapes;
        }
    },
    methods: {
        reset() {
            this.showDetailsMeas = false;
        },
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        showReminder(tekst) {
            this.reminder = tekst;
            this.showWarningAlert = true;
        },
        refresh() {
            this.aangeraakt += 1;
            this.$forceUpdate();
        },
        set_input() {
            this.checkMerkenEqualsKaas();
            this.checkMerkenOpgelegd();
            this.$emit('validComponent', this.gewichtValid);
            this.$emit('change', true);
        },
        setGewichtenValid: function (valid) {
            this.gewichtValid = valid;
        },
        check_doubles() {
            const x = _(this.inpekellijst.slice(0))
                .groupBy((x) => `"${x.vormID}+${x.productID}"`)
                .map((value, key) => ({
                    categorie: key,
                    inpekel: value
                }))
                .value();
            const y = x.filter((o) => o.inpekel.length > 1);
            this.gewichtValid = !(y.length > 0);
            if (y.length > 0) {
                this.showAlert(this.strUniekeVorm);
            }
        },
        async showMaesurementInsert() {
            if (this.isVersion30) {
                const o = await loadItem(
                    'analytics/loadEntityObjectByKeyType',
                    {
                        key: this.theBakInpekel.bakId,
                        typeName: window.constants.ADATA_BATCH
                    },
                    this.$t
                );
                this.idEO = o ? o.id : -1;
                this.showDetailsMeas = !this.showDetailsMeas;
            }
        },
        async verwijderItem(inpekelitem) {
            const item = this.theBakInpekel.inpekelAmounts.find((x) => {
                return (
                    x.bakId === inpekelitem.bakId &&
                    x.vormID === inpekelitem.vormID &&
                    x.nouse == undefined
                );
            });
            if (item) {
                if (item.isNew) {
                    const index = this.theBakInpekel.inpekelAmounts.findIndex(
                        (x) => {
                            return (
                                x.bakId === inpekelitem.bakId &&
                                x.vormID === inpekelitem.vormID &&
                                x.nouse == undefined
                            );
                        }
                    );
                    if (index > -1) {
                        this.theBakInpekel.inpekelAmounts.splice(index, 1);
                        this.aangeraakt += 1;
                        this.showDismissibleAlert = false;
                    }
                } else {
                    const doClose = await this.$root.showConfirmationBox(
                        this.$t('com_delete', {
                            ns: 'common',
                            val: this.$t('prod_inpekel', { ns: 'production' })
                        }) +
                            inpekelitem.bakId +
                            ':' +
                            inpekelitem.vormID
                    );
                    if (doClose) {
                        item.isDeleted = true;
                        this.aangeraakt += 1;
                        this.set_input();
                    }
                }
                this.check_doubles();
            }
        },
        copyItem(inpekelitem) {
            const nieuw = new InpekelItem({
                bakId: inpekelitem.bakId,
                productID: inpekelitem.productID
            });
            nieuw.inpekelTijd = DateHelper.convertedToTime();
            this.theBakInpekel.inpekelAmounts.push(nieuw);
            this.aangeraakt += 1;
            this.set_input();
        },
        addInpekelAantal() {
            const nieuw = new InpekelItem({
                bakId: this.theBakInpekel.bakId
            });
            nieuw.inpekelTijd = DateHelper.convertedToTime();
            this.theBakInpekel.inpekelAmounts.push(nieuw);
            this.aangeraakt += 1;
            this.set_input();
        },
        checkMerkenOpgelegd() {
            if (this.theBakInpekel.bakStats.length > 0) return;
            if (this.theBakInpekel.merkenOpgelegd == undefined) return;
            if (!this.theBakInpekel.merkenOpgelegd) {
                this.showAlert(this.strGeenMerken);
            }
        },
        checkMerkenEqualsKaas() {
            if (this.theBakInpekel.bakStats.length > 0) return false;
            if (this.theBakInpekel.merkenOpgelegd == undefined) return;
            this.showWarningAlert = false;
            const tot = this.inpekellijst.reduce(
                (accumulator, currentValue) =>
                    accumulator + currentValue.amount,
                0
            );
            const result = tot - this.theBakInpekel.opgelegdeMerken;
            if (result != 0) {
                const message = Helper.stringformat(
                    this.strMerkenverschil,
                    this.theBakInpekel.opgelegdeMerken,
                    tot
                );
                this.showReminder(message);
            }
            return result == 0;
        },
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return this.$root.handleFeature(key);
        }
    },
    mounted() {
        const item = this.theBakInpekel.inpekelAmounts.find(
            (x) => x.isDeleted == false && x.nouse == true
        );
        if (item) {
            let message = '';
            if (item.kilos > 0) {
                message = Helper.stringformat(
                    this.strOpbrengstTeveel,
                    item.kilos
                );
            } else {
                if (item.kilos < 0) {
                    message = Helper.stringformat(
                        this.strOpbrengstTekort,
                        -item.kilos
                    );
                }
            }
            this.showReminder(message);
        }
        const newitem = this.theBakInpekel.inpekelAmounts.find(
            (x) => x.isNew == true && x.nouse == undefined
        );
        if (newitem) this.set_input();
    },
    created() {}
};
</script>
