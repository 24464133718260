<template>
    <b-modal
        id="modal-norm"
        size="lg"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>

        <div>
            <b-tabs card>
                <b-card>
                    <b-tab
                        :title="
                            $t('an_normen', {
                                ns: 'analytics'
                            })
                        "
                    >
                        <b-card-text>
                            <b-row>
                                <b-col sm="3">
                                    <label for="input-itemnaam"
                                        >{{
                                            $t('an_subject', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <lookup-combo
                                        v-model="localItem.meetplekID"
                                        v-bind:combotype="lctypeEO"
                                        v-bind:disabled="fixEO"
                                        @change="setChanged"
                                    ></lookup-combo>
                                </b-col>

                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('an_meting', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <lookup-combo
                                        v-model="
                                            localItem.onderzoeksgrootheidID
                                        "
                                        v-bind:combotype="lctypeIQ"
                                        v-bind:disabled="fixIQ"
                                        @change="setChanged"
                                    ></lookup-combo>
                                </b-col>
                            </b-row>
                            <hr />
                            <b-row>
                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('an_norm', { ns: 'analytics' })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <b-form-input
                                        :placeholder="
                                            $t('an_hoeveelheid', {
                                                ns: 'analytics'
                                            })
                                        "
                                        type="number"
                                        step="0.1"
                                        v-model.number="normwaarde"
                                        @change="setChangedNorm"
                                        :disabled="!editAllowed"
                                    ></b-form-input>
                                </b-col>
                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('an_grenzen', {
                                                ns: 'analytics'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <limit-formula-combo
                                        v-model="localItem.formule"
                                        v-bind:normvalue="normwaarde"
                                        @change="setChanged"
                                        :editAllowed="editAllowed"
                                    ></limit-formula-combo>
                                </b-col>

                                <b-col sm="3">
                                    <label
                                        >{{
                                            $t('com_actie', { ns: 'common' })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <b-form-input
                                        v-model="localItem.actie"
                                        @change="setChanged"
                                        :placeholder="
                                            $t('an_verwijsbeschrijving', {
                                                ns: 'analytics'
                                            })
                                        "
                                    ></b-form-input>
                                </b-col>
                            </b-row>
                            <recurrency-base
                                :editAllowed="editAllowed"
                                :pattern="localItem.herhaling"
                                @changed="setChangePattern"
                                @deleted="setDeletePattern"
                            />
                        </b-card-text>
                    </b-tab>
                    <activity-log
                        paramName="eonormID"
                        action="analytics/loadNormActiviteitenLijst"
                        :id="locItem.id"
                    />
                </b-card>
            </b-tabs>
        </div>
    </b-modal>
</template>

<script>
import { NormObject } from '../../models/Anayltics';
import LookupCombo from './LookupCombo.vue';
import ActivityLog from '../components/ActivityLog.vue';
import { loadItem } from './utils';
import LimitFormulaCombo from './LimitFormulaCombo.vue';
import { RecurrencyBase } from '../recurrency';
export default {
    name: 'ModalNorm',
    data: function () {
        return {
            lctypeEO: window.constants.LCA_ENTITYOBJECT,
            lctypeIQ: window.constants.LCA_INVQUANTITY,
            showDismissibleAlert: false,
            alertText: '',
            isChanged: false,
            locItem: null,
            savePattern: null,
            deletePattern: null,
            normwaarde: null,
            editAllowed: true
        };
    },
    model: {
        prop: 'visible',
        event: 'changed'
    },
    props: {
        inItem: {
            type: NormObject,
            required: false
        },
        idEntityObject: {
            type: Number,
            required: false
        },
        idIQ: {
            type: Number,
            required: false
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LookupCombo,
        ActivityLog,
        LimitFormulaCombo,
        RecurrencyBase
    },
    watch: {},
    computed: {
        formtitle() {
            const low = this.$t('an_norm', { ns: 'analytics' }).toLowerCase();
            return this.locItem.id < 0
                ? this.$t('com_makenew', {
                      ns: 'common',
                      val: low
                  })
                : this.$t('com_edit', {
                      ns: 'common',
                      val: low
                  });
        },
        localItem() {
            return this.locItem;
        },
        fixIQ() {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.locItem.onderzoeksgrootheidID =
                this.locItem.onderzoeksgrootheidID ?? this.idIQ;
            return this.editAllowed ? !(this.idIQ == undefined) : true;
        },
        fixEO() {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.locItem.meetplekID =
                this.locItem.meetplekID ?? this.idEntityObject;
            return this.editAllowed
                ? !(this.idEntityObject == undefined)
                : true;
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.$emit('hide', this.returnObject);
        },
        setChanged() {
            this.isChanged = true;
        },
        setChangedNorm(value) {
            this.locItem.normwaarde = value
            this.isChanged = true;
        },
        setChangePattern(data) {
            if (data) {
                this.savePattern = data;
                this.isChanged = true;
            }
        },
        async setDeletePattern(data) {
            if (data) {
                const bericht = this.$t('com_delete', {
                    ns: 'common',
                    val: this.$t('rec_pattern', { ns: 'recurrency' })
                });
                const doClose = await this.$root.showConfirmationBox(bericht);
                if (doClose) {
                    this.deletePattern = data;
                    this.isChanged = true;
                } else {
                    this.deletePattern = null;
                    this.isChanged = true;
                }
            }
        },
        async checkFormValidity() {
            const invalidBasis = this.locItem.CheckValidity(this.$t);
            if (invalidBasis) this.showAlert(invalidBasis);
            const valid = this.locItem != undefined && !invalidBasis;
            return valid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSubmit() {
            // Exit when the form isn't valid
            if (this.isChanged) {
                if (!(await this.checkFormValidity())) {
                    return;
                }
                this.returnObject = await this.saveThisNorm();
                this.$emit('forcerefresh', true);
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-norm');
            });
        },
        async saveThisNorm() {
            if (this.savePattern) this.locItem.herhaling = this.savePattern;
            if (this.deletePattern) this.locItem.herhaling = this.deletePattern;
            const addObject = this.locItem.SetForAdd();
            return loadItem('analytics/saveNorm', addObject, this.$t);
        }
    },
    mounted() {},
    created() {
        if (this.inItem) {
            this.locItem = this.inItem;
            this.normwaarde = this.locItem.normwaarde;
        } else {
            this.locItem = new NormObject();
            this.locItem.meetplekID = this.idEntityObject;
            this.locItem.onderzoeksgrootheidID = this.idIQ;
        }
    }
};
</script>

<style scoped></style>

