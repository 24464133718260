<template>
    <b-modal
        id="modal-stockitemdetail"
        size="xl"
        ref="modal"
        :title="formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <b-card @submit.stop.prevent="handleSubmit">
            <div>
                <h1>{{ productNaam }}</h1>
                <b-button
                    size="sm"
                    @click="dataToClipboard()"
                    class="float-right"
                    ><font-awesome-icon icon="copy" size="1x" />
                </b-button>
            </div>
            <b-tabs card v-if="hasData">
                <b-tab
                    :title="$t('com_stock', { ns: 'common' })"
                    v-bind:key="1"
                    active
                >
                    <b-card-text id="stockData">
                        <div>
                            <b-row>
                                <b-col sm="6">
                                    <show-row-col
                                        :showLabel="
                                            $t('com_amount', { ns: 'common' })
                                        "
                                        :showObject="refreshedStockItem.aantal"
                                    />
                                    <show-row-col
                                        :showLabel="
                                            $t('com_unit', { ns: 'common' })
                                        "
                                        :showObject="refreshedStockItem.eenheid"
                                    />
                                    <show-row-col
                                        :showLabel="
                                            $t('com_location', { ns: 'common' })
                                        "
                                        :showObject="refreshedStockItem.locatie"
                                    />
                                    <div v-if="isKaas">
                                        <show-row-col
                                            :showLabel="
                                                $t('prod_proddatum', {
                                                    ns: 'production'
                                                })
                                            "
                                            :showObject="
                                                showDate(
                                                    refreshedStockItem.productiedatum
                                                )
                                            "
                                        />
                                        <show-row-col
                                            :showLabel="
                                                $t('prod_leeftijd', {
                                                    ns: 'production'
                                                })
                                            "
                                            :showObject="
                                                refreshedStockItem.leeftijdKlasse
                                            "
                                        />
                                        <show-row-col
                                            :showLabel="
                                                $t('com_dagen', {
                                                    ns: 'common'
                                                })
                                            "
                                            :showObject="
                                                refreshedStockItem.leeftijdDagen
                                            "
                                        />
                                    </div>
                                    <div v-else>
                                        <show-row-col
                                            :showLabel="
                                                $t('com_tht', {
                                                    ns: 'common'
                                                })
                                            "
                                            :showObject="
                                                showDate(
                                                    refreshedStockItem.datum
                                                )
                                            "
                                        />
                                        <show-row-col
                                            :showLabel="
                                                $t('com_start', {
                                                    ns: 'common'
                                                })
                                            "
                                            :showObject="
                                                refreshedStockItem.vanaf ||
                                                strAtStock
                                            "
                                        />
                                        <show-row-col
                                            :showLabel="
                                                $t('com_eind', {
                                                    ns: 'common'
                                                })
                                            "
                                            :showObject="
                                                refreshedStockItem.tot || '-'
                                            "
                                        />
                                    </div>
                                </b-col>
                                <b-col sm="6">
                                    <b-row class="mx-0 px-0 my-0 py-0">
                                        <div v-if="isKaas">
                                            <b-table
                                                :items="marksList"
                                                :fields="visibleMarksFields"
                                                stacked="md"
                                                show-empty
                                                :empty-text="
                                                    $t('com_geen_data', {
                                                        ns: 'common'
                                                    })
                                                "
                                                small
                                                striped
                                                responsive
                                            >
                                                <template
                                                    #cell(startmerk)="data"
                                                >
                                                    {{
                                                        data.item
                                                            .startcharacters
                                                    }}
                                                    &nbsp;
                                                    {{ data.item.startnumber }}
                                                </template>
                                                <template
                                                    #cell(eindmerk)="data"
                                                >
                                                    {{
                                                        data.item.endcharacters
                                                    }}
                                                    &nbsp;
                                                    {{ data.item.endnumber }}
                                                </template>
                                            </b-table>
                                        </div>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </div>
                    </b-card-text>
                </b-tab>
                <div v-if="isKaas">
                    <b-tab
                        v-for="element in marksList"
                        v-bind:key="element.id"
                        @click="loadBakData(element.id)"
                        lazy
                    >
                        <template #title>
                            <b-spinner
                                v-if="showSpinner"
                                type="border"
                                small
                            ></b-spinner>
                            {{ giveBakTitle(element) }}
                        </template>
                        <p>
                            <b-form-input
                                v-if="showBakDetails"
                                :placeholder="
                                    $t('prod_verantw', { ns: 'production' })
                                "
                                v-model="currentBak.verantwoordelijke"
                                size="sm"
                                readonly
                            ></b-form-input>
                            <b-form-textarea
                                v-if="showBakDetails"
                                v-model="currentBak.opmerking"
                                :placeholder="
                                    $t('com_opmerking', { ns: 'common' })
                                "
                                rows="3"
                                max-rows="6"
                                readonly
                            ></b-form-textarea>

                            <recipe-card
                                v-if="showBakDetails"
                                v-model="selectedRecipe"
                                v-bind:editAllowed="false"
                                v-bind:closedRecipesList="null"
                                v-bind:activityList="activiteitenList"
                            >
                            </recipe-card>
                        </p>
                    </b-tab>
                </div>

                <b-tab
                    :title="$t('prod_use', { ns: 'production' })"
                    @click="loadIngredientengebruik"
                    lazy
                >
                    <b-card-text>
                        <b-table
                            :items="gebruikList"
                            :fields="visibleTTFields"
                            stacked="md"
                            show-empty
                            :empty-text="$t('com_geen_data', { ns: 'common' })"
                            small
                            striped
                            responsive
                        >
                        </b-table>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>
    </b-modal>
</template>

<script>
import { PRODCAT_ENUM } from '../../models/ProductCategorie';
import DateHelper from '../../models/DateHelper';
import Helper from '../../models/Helper';
import { loadItem, loadList } from '../../models/DataHelper';
import RecipeCard from '../recipe/RecipeCard';
import { ShowRowCol } from '../components';

export default {
    name: 'ItemDetailsModal',
    data: function () {
        return {
            refreshedStockItem: null,
            refreshedMarks: null,
            currentBak: null,
            currentProdRecept: null,
            bakID: -1,
            aangeraakt_teller: 1,
            showSpinner: false,
            showBakDetails: false,
            gebruikList: [],
            strAtStock: this.$t('log_atstock', { ns: 'logistics' })
        };
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        stockItemId: {
            type: Number,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        RecipeCard,
        ShowRowCol
    },
    watch: {},
    computed: {
        formtitle() {
            return (
                this.$t('com_detail', { ns: 'common' }) +
                ' ' +
                this.$t('prod_stockitem', { ns: 'production' })
            );
        },
        hasData() {
            return this.refreshedStockItem != undefined;
        },
        productNaam() {
            return this.hasData
                ? Helper.stringformat(
                      '{0}: {1}',
                      this.refreshedStockItem.ingrcatNaam ??
                          this.refreshedStockItem.prodcatNaam,
                      this.refreshedStockItem.naam
                  )
                : this.$t('com_geen_data', { ns: 'common' });
        },
        isKaas() {
            const o =
                this.refreshedStockItem.prodcatID == PRODCAT_ENUM.Kaas ||
                this.refreshedStockItem.prodcatID == PRODCAT_ENUM.Onvolwaardig;
            return o;
        },
        localItem() {
            if (this.inItem == undefined) return null;
            return this.inItem;
        },
        marksList() {
            return this.refreshedMarks ? this.refreshedMarks.data.Items : [];
        },
        selectedRecipe() {
            // eslint-disable-next-line no-unused-vars
            const veranderd = this.aangeraakt_teller;
            return this.currentProdRecept;
        },
        selectedBatch() {
            // eslint-disable-next-line no-unused-vars
            const veranderd = this.aangeraakt_teller;
            //return this.$store.getters['production/getSelectedBatch'];
            return this.currentBak;
        },
        activiteitenList() {
            return this.$store.getters['production/getBatchActivityList'];
        },
        visibleMarksFields() {
            const fieldsInit = [
                {
                    key: 'number',
                    label: this.$t('prod_bak', {
                        ns: 'production'
                    }),
                    visible: true
                },
                {
                    key: 'marktype',
                    label: this.$t('com_marktype', {
                        ns: 'common'
                    }),
                    visible: true
                },
                {
                    key: 'startmerk',
                    label: this.$t('prod_startmerk', {
                        ns: 'production'
                    }),
                    visible: true
                },
                {
                    key: 'eindmerk',
                    label: this.$t('prod_eindmerk', {
                        ns: 'production'
                    }),
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        visibleTTFields() {
            const fieldsInit = [
                {
                    key: 'productie',
                    label: this.$t('com_production', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'baknummer',
                    label: this.$t('prod_bak', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'ingrediënt',
                    label: this.$t('prod_ingredient', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'partijnr',
                    label: this.$t('com_batch', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'leverancier',
                    label: this.$t('log_leverancier', {
                        ns: 'logistics'
                    }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        }
    },
    methods: {
        hide() {
            this.$emit('hide');
        },
        showDate(aDate) {
            return DateHelper.convertedToDateShort(aDate);
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-stockitemdetail');
            });
        },
        giveBakTitle(element) {
            return 'Bak ' + element.number;
        },
        async loadBakData(bakID) {
            this.showBakDetails = false;
            this.showSpinner = true;
            this.bakID = bakID;
            await this.loaddataBak();
            await this.loaddataRecIngredienten();
            // todo finally
            this.showSpinner = false;
            this.showBakDetails = true;
        },
        async loaddataBak() {
            this.currentBak = await loadItem(
                'production/loadBatch',
                { batchID: this.bakID },
                this.$t
            );
            await loadItem(
                'production/loadBatchDefaults',
                {
                    batchID: this.bakID
                },
                this.$t
            ); // todo qend
            const toegevoegd = await loadItem(
                'production/loadBatchIngredients',
                { batchid: this.bakID },
                this.$t
            );
            await loadItem(
                'production/loadBatchActivityLog',
                {
                    batchid: this.bakID
                },
                this.$t
            );
            this.currentBak.bakIngredienten = toegevoegd ?? [];
        },
        async loaddataRecIngredienten() {
            this.currentProdRecept = await loadItem(
                'recipe/loadRecipeComplete',
                { recipeid: this.currentBak.receptID },
                this.$t
            );
            //this.currentProdRecept= recept.recProduction;
            this.currentBak.recIngredienten =
                this.currentProdRecept.recIngredienten ?? [];
        },
        async loadIngredientengebruik() {
            let from = null;
            let until = null;
            if (this.isKaas) {
                const baseTime = this.refreshedStockItem.productiedatum;
                from = DateHelper.addHoursToDate(baseTime, -24);
                until = DateHelper.addHoursToDate(baseTime, 24);
            } else {
                if (this.refreshedStockItem.vanaf) {
                    const baseStart = this.refreshedStockItem.vandatum;
                    const baseEnd =
                        this.refreshedStockItem.totdatum ?? new Date();
                    from = DateHelper.addHoursToDate(baseStart, -48);
                    until = DateHelper.addHoursToDate(baseEnd, 48);
                }
            }
            if (from != undefined) {
                const selectionData = {
                    from: DateHelper.localDateToUTC(from),
                    until: DateHelper.localDateToUTC(until)
                };
                await loadItem(
                    'rapportage/loadTrackTrace',
                    selectionData,
                    this.$t
                );
                const list = this.$store.getters['rapportage/getReportItems'];
                const batchID = this.refreshedStockItem.batchID;
                if (this.isKaas) {
                    this.gebruikList = list.filter(
                        (x) => x.bakgroep == batchID
                    );
                } else {
                    const ing = this.refreshedStockItem.naam.toLowerCase();
                    this.gebruikList = list.filter(
                        (x) =>
                            x.partijnr == batchID &&
                            x.ingrediënt.toLowerCase() == ing
                    );
                }
            } else {
                this.gebruikList = [];
            }
        },
        async getData() {
            if (this.stockItemId > -1) {
                this.refreshedMarks = null;
                this.refreshedStockItem = await loadItem(
                    'stock/loadStockItemInfo',
                    {
                        stockitemid: this.stockItemId
                    },
                    this.$t
                );
                if (this.isKaas) {
                    this.refreshedMarks = await loadItem(
                        'stock/loadStockItemMarksInfo',
                        {
                            batchgroup: this.refreshedStockItem.batchID
                        },
                        this.$t
                    );
                }
            }
        },
        async dataToClipboard() {
            let textArea = this.productNaam;
            textArea += '\n';
            textArea += '\n';
            textArea +=
                this.$t('com_amount', { ns: 'common' }) +
                ': \t' +
                this.refreshedStockItem.aantal;
            textArea +=
                '\n' +
                this.$t('com_unit', { ns: 'common' }) +
                ': \t' +
                this.refreshedStockItem.eenheid;
            textArea +=
                '\n' +
                this.$t('com_location', { ns: 'common' }) +
                ': \t' +
                this.refreshedStockItem.locatie;
            if (this.isKaas) {
                textArea +=
                    '\n' +
                    this.$t('prod_proddatum', { ns: 'production' }) +
                    ': \t' +
                    this.showDate(this.refreshedStockItem.productiedatum);
                textArea +=
                    '\n' +
                    this.$t('prod_leeftijd', { ns: 'production' }) +
                    ': \t' +
                    this.refreshedStockItem.leeftijdKlasse;
                textArea +=
                    '\n' +
                    this.$t('com_dagen', { ns: 'common' }) +
                    ': \t' +
                    this.refreshedStockItem.leeftijdDagen;
                textArea += '\n';
                
                for (let i = 0; i < this.marksList.length; i++) {
                    textArea +=
                        '\n' +
                        this.$t('prod_bak', { ns: 'production' }) +
                        ': \t' +
                        this.marksList[i].number ?? '-';
                    textArea +=
                        '\n' +
                        this.$t('com_marktype', { ns: 'common' }) +
                        ': \t' +
                        this.marksList[i].marktype ?? '-';
                    textArea +=
                        '\n' +
                        this.$t('prod_startmerk', { ns: 'production' }) +
                        ': \t' +
                        this.marksList[i].startcharacters ?? '' +
                        ' ' +
                        this.marksList[i].startnumber ?? '-';
                    textArea +=
                        '\n' +
                        this.$t('prod_eindmerk', { ns: 'production' }) +
                        ': \t' +
                        this.marksList[i].endcharacters ?? '' +
                        ' ' +
                        this.marksList[i].endnumber ?? '-';
                }
            } else {
                textArea +=
                    '\n' +
                    this.$t('com_tht', { ns: 'common' }) +
                    ': \t' +
                    this.showDate(this.refreshedStockItem.datum);
                textArea +=
                    '\n' +
                        this.$t('com_start', { ns: 'common' }) +
                        ': \t' +
                        this.refreshedStockItem.vanaf || this.strAtStock;
                textArea +=
                    '\n' +
                        this.$t('com_eind', { ns: 'common' }) +
                        ': \t' +
                        this.refreshedStockItem.tot || '-';
            }
            await this.loadIngredientengebruik();
            if (this.gebruikList.length > 0) {
                textArea += '\n';
                textArea += '\n';
                textArea += this.$t('prod_use', { ns: 'production' });
                textArea += '\n';
                textArea += '\n';
                textArea +=
                    this.$t('com_production', { ns: 'common' }) +
                    '\t' +
                    this.$t('prod_bak', { ns: 'production' }) +
                    '\t' +
                    this.$t('prod_ingredient', { ns: 'production' }) +
                    '\t' +
                    this.$t('com_batch', { ns: 'common' }) +
                    '\t' +
                    this.$t('log_leverancier', { ns: 'logistics' });
                for (let i = 0; i < this.gebruikList.length; i++) {
                    textArea +=
                        '\n' +
                        this.gebruikList[i].productie +
                        '\t' +
                        this.gebruikList[i].baknummer +
                        '\t' +
                        this.gebruikList[i].ingrediënt +
                        '\t' +
                        this.gebruikList[i].partijnr +
                        '\t' +
                        this.gebruikList[i].leverancier;
                }
            }

            navigator.clipboard.writeText(textArea);
        }
    },
    async mounted() {},
    async created() {
        const l = await loadList(
            'product/getProductCategorieLijst',
            'product/loadProductCategories',
            null,
            false,
            this.$t
        );
        await this.getData();
    }
};
</script>

<style scoped></style>

