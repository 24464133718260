<template>
    <div>
        <div class="form-inline">
            <b-input-group>
                <b-form-select
                    v-model="selFormula"
                    :options="formulaList"
                    :disabled="!editAllowed"
                    @change="set_input"
                >
                    <template #first>
                        <b-form-select-option value=""
                            >--
                            {{ $t('com_selecteer', { ns: 'common' }) }}
                            --</b-form-select-option
                        >
                    </template>
                </b-form-select>
                <b-form-input
                    id="input-hoeveelheid"
                    :placeholder="$t('an_hoeveelheid', { ns: 'analytics' })"
                    type="number"
                    step="0.1"
                    v-model.number="selVariabele"
                    @change="set_input"
                    :disabled="!editAllowed"
                    :readonly="readonly"
                ></b-form-input>
                <b-button
                    @click="showDetailInfo()"
                    class="bgc_aux_normal"
                    v-b-tooltip.hover
                    :title="$t('an_grenzen', { ns: 'analytics' })"
                    ><font-awesome-icon icon="search" size="1x" />
                </b-button>
            </b-input-group>
        </div>
        <div v-if="detailInfo">
            <b-input-group :prepend="$t('an_voorbeeld', { ns: 'analytics' })">
                <b-form-input
                    :placeholder="$t('an_norm', { ns: 'analytics' })"
                    type="number"
                    v-model.number="selNorm"
                    @change="calcMinMax()"
                ></b-form-input>
            </b-input-group>
            <div>
                <b-input-group>
                    <b-input-group :prepend="$t('com_min', { ns: 'common' })">
                        <b-form-input
                            :placeholder="$t('com_minimum', { ns: 'common' })"
                            v-model="minimum"
                            readonly
                        ></b-form-input>
                    </b-input-group>
                    <b-input-group :prepend="$t('com_max', { ns: 'common' })">
                        <b-form-input
                            :placeholder="$t('com_maximum', { ns: 'common' })"
                            v-model="maximum"
                            readonly
                        ></b-form-input>
                    </b-input-group>
                </b-input-group>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'LimitFormulaCombo',
    data: function () {
        return {
            formulaList: [
                {
                    value: 'var',
                    text: this.$t('an_tussenx', {
                        ns: 'analytics',
                        val: '±'
                    })
                },
                {
                    value: 'var *',
                    text: this.$t('an_tussenx', {
                        ns: 'analytics',
                        val:
                            '± ' +
                            this.$t('an_percentage', {
                                ns: 'analytics'
                            }).toLowerCase()
                    })
                },
                {
                    value: '< var',
                    text: this.$t('an_xdan', {
                        ns: 'analytics',
                        val: this.$t('an_kleiner', { ns: 'analytics' })
                    })
                },
                {
                    value: '> var',
                    text: this.$t('an_xdan', {
                        ns: 'analytics',
                        val: this.$t('an_groter', { ns: 'analytics' })
                    })
                }
            ],
            selFormula: null,
            selVariabele: null,
            selNorm: null,
            detailInfo: false,
            minlimiet: null,
            maxlimiet: null,
            readonly: false,
            localselected: null
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {
            type: String,
            required: false
        },
        normvalue: {
            type: Number,
            required: false
        },
        editAllowed: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        normvalue: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal != undefined && newVal != oldVal) {
                    this.selNorm = newVal;
                    this.set_input();
                }
            }
        }
    },
    components: {},
    computed: {
        minimum() {
            return this.minlimiet;
        },
        maximum() {
            return this.maxlimiet;
        }
    },
    methods: {
        showDetailInfo() {
            this.detailInfo = !this.detailInfo;
            if (this.detailInfo) this.calcMinMax();
        },
        set_input() {
            this.$emit('change', true);

            switch (this.selFormula) {
                case 'var': {
                    this.localselected = this.selVariabele;
                    this.readonly = false;
                    break;
                }
                case 'var *': {
                    this.localselected = this.selVariabele / 100 + '*V';
                    this.readonly = false;
                    break;
                }
                case '< var': {
                    this.selVariabele = this.selNorm;
                    this.readonly = true;
                    this.localselected = '< ';
                    break;
                }
                case '> var': {
                    this.selVariabele = this.selNorm;
                    this.readonly = true;
                    this.localselected = '> ';
                    break;
                }
                default:
                    this.localselected = null;
                    break;
            }
            this.$emit('input', this.localselected);
            if (this.detailInfo) this.calcMinMax();
        },
        calcMinMax() {
            this.minlimiet = null;
            this.maxlimiet = null;
            if (this.selNorm) {
                switch (this.selFormula) {
                    case 'var': {
                        this.minlimiet = this.selNorm - this.selVariabele;
                        this.maxlimiet = this.selNorm + this.selVariabele;
                        this.readonly = false;
                        break;
                    }
                    case 'var *': {
                        const cl = this.selVariabele * this.selNorm;
                        this.minlimiet = this.selNorm - cl;
                        this.maxlimiet = this.selNorm + cl;
                        this.readonly = false;
                        break;
                    }
                    case '< var': {
                        this.selVariabele = this.selNorm;
                        this.minlimiet = null;
                        this.maxlimiet = this.selNorm;
                        this.readonly = true;
                        break;
                    }
                    case '> var': {
                        this.selVariabele = this.selNorm;
                        this.minlimiet = this.selNorm;
                        this.maxlimiet = null;
                        this.readonly = true;
                        break;
                    }
                    default:
                        break;
                }
            }
        }
    },
    mounted() {},
    created() {
        if (this.selected) {
            this.localselected = this.selected;
            this.selNorm = this.normvalue;
            // bepaal welke formule te selectern
            const test = this.selected;
            const tempformulakleiner = test.indexOf('<') == -1 ? null : '< var';
            const tempformulakgroter = test.indexOf('>') == -1 ? null : '> var';
            const tempformulaPerc = test.indexOf('*') == -1 ? null : 'var *';
            if (tempformulakgroter || tempformulakleiner) {
                this.selFormula = tempformulakleiner ?? tempformulakgroter;
                this.selVariabele = this.selNorm;
                this.readonly = true;
            } else {
                this.selFormula = tempformulaPerc ?? 'var';
                // haal de V eruit en de formule dingen
                this.selVariabele = test
                    .replace(/V/g, '')
                    .replace(/\*/g, '')
                    .trim();
                this.readonly = false;
            }
        }
    }
};
</script>

<style scoped></style>

