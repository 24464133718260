var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-modal',{ref:"modal",attrs:{"id":"modal-deliverynote","size":"xl","title":this.formtitle,"visible":_vm.visible},on:{"hidden":_vm.hide,"ok":_vm.handleOk,"change":_vm.change}},[_c('b-card',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-alert',{attrs:{"variant":"danger","dismissible":""},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")]),_c('b-tabs',{attrs:{"card":""}},[_c('b-tab',{key:1,attrs:{"title":_vm.headertitle,"active":""}},[_c('b-card-text',[_c('div',[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"dn_reference"}},[_vm._v(_vm._s(_vm.$t('com_reference', { ns: 'common' }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-input',{attrs:{"id":"dn_reference","placeholder":_vm.$t('com_reference', {
                                                    ns: 'common'
                                                }),"disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(_vm.localItem.referentie),callback:function ($$v) {_vm.$set(_vm.localItem, "referentie", $$v)},expression:"localItem.referentie"}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"dn_boekdate"}},[_vm._v(" "+_vm._s(_vm.$t('prod_boekdatum', { ns: 'production' }))+":")])]),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-datepicker',{attrs:{"id":"dn_boekdate","date-format-options":{
                                                year: 'numeric',
                                                month: 'short',
                                                day: '2-digit'
                                            },"disabled":!_vm.editAllowed},on:{"input":_vm.setChanged},model:{value:(_vm.datum),callback:function ($$v) {_vm.datum=$$v},expression:"datum"}})],1)],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{attrs:{"for":"dn_location"}},[_vm._v(" "+_vm._s(_vm.$t('com_location', { ns: 'common' })))])]),_c('b-col',{attrs:{"sm":"9"}},[_c('lookup-combo',{attrs:{"id":"dn_location","combotype":_vm.lctypeLocation,"disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(_vm.localItem.locatie),callback:function ($$v) {_vm.$set(_vm.localItem, "locatie", $$v)},expression:"localItem.locatie"}})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('div',{staticClass:"form-inline"},[_c('label',[_vm._v(_vm._s(_vm.$t('com_klant', { ns: 'common' }))+":")]),_vm._v("    "),_c('b-form-checkbox',{attrs:{"value":"true","unchecked-value":"false","disabled":!_vm.editAllowed},model:{value:(_vm.freeinput),callback:function ($$v) {_vm.freeinput=$$v},expression:"freeinput"}},[_c('span',[_vm._v(_vm._s(_vm.$t('com_free', { ns: 'common' })))])])],1)]),(!_vm.showFreeInput)?_c('b-col',{attrs:{"sm":"9"}},[_c('lookup-combo',{attrs:{"combotype":_vm.lctypeCompany,"disabled":!_vm.editAllowed},on:{"change":_vm.setChanged},model:{value:(_vm.localItem.klant),callback:function ($$v) {_vm.$set(_vm.localItem, "klant", $$v)},expression:"localItem.klant"}})],1):_vm._e(),(_vm.showFreeInput)?_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-textarea',{attrs:{"placeholder":_vm.$t('prod_vrijadres', {
                                                    ns: 'production'
                                                }),"disabled":!_vm.editAllowed,"rows":"3","no-resize":""},on:{"change":_vm.setChanged},model:{value:(_vm.localItem.klanttekst),callback:function ($$v) {_vm.$set(_vm.localItem, "klanttekst", $$v)},expression:"localItem.klanttekst"}})],1):_vm._e()],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"1"}},[_c('label',{attrs:{"for":"input-itemremark"}},[_vm._v(_vm._s(_vm.$t('com_opmerking', { ns: 'common' }))+":")])]),_c('b-col',{attrs:{"sm":"11"}},[_c('b-form-textarea',{attrs:{"id":"input-itemremark","placeholder":_vm.$t('com_opmerking', {
                                            ns: 'common'
                                        }),"rows":"3"},on:{"change":_vm.setChanged},model:{value:(_vm.localItem.opmerking),callback:function ($$v) {_vm.$set(_vm.localItem, "opmerking", $$v)},expression:"localItem.opmerking"}})],1)],1)],1)])],1),(_vm.localItem.id > 0)?_c('special-attachments',{attrs:{"itemId":_vm.localItem.id,"attType":_vm.attachmentType}}):_vm._e()],1)],1),_c('b-card',{attrs:{"header":_vm.$t('log_lines', { ns: 'logistics' })},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',[_c('b-row',[(_vm.localItem != undefined)?_c('delivery-note-items',{attrs:{"items":_vm.localItem.regels,"geprint":!_vm.editAllowed},on:{"change":_vm.setChanged}}):_vm._e()],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }