<template>
    <div>
        <div class="form-inline">
            <b-col sm="3" class="mx-0 px-0 my-0 py-0">
                <b-input-group>
                    <b-form-timepicker
                        :key="aangeraakt"
                        v-model="localSelected.inpekelTijd"
                        locale="nl"
                        @input="set_inputTijd"
                        :disabled="!editAllowed"
                    />
                    <cheese-tijd
                        v-model="datumtijd"
                        @change="set_inputInpekeltijd"
                        :disabled="!editAllowed"
                    />
                </b-input-group>
            </b-col>

            <b-col :sm="setSize" class="mx-0 px-0 my-0 py-0">
                <b-input-group
                    :append="$t('com_st', { ns: 'common' })"
                    :label="$t('com_stuks', { ns: 'common' })"
                >
                    <b-form-input
                        :placeholder="aantKaas"
                        type="number"
                        step="1"
                        v-model.number="localSelected.amount"
                        @change="set_input"
                        :disabled="!editAllowed"
                    ></b-form-input>
                </b-input-group>
            </b-col>

            <b-col v-if="useWeight" sm="2" class="mx-0 px-0 my-0 py-0">
                <b-input-group
                    :append="$t('com_kg', { ns: 'common' })"
                    :label="$t('com_kilos', { ns: 'common' })"
                >
                    <b-form-input
                        :placeholder="weightKaas"
                        type="number"
                        step="1"
                        v-model.number="localSelected.kilos"
                        @change="set_input"
                        :disabled="!editAllowed"
                    ></b-form-input>
                </b-input-group>
            </b-col>

            <b-col :sm="setSize" class="mx-0 px-0 my-0 py-0">
                <lookup-combo
                    v-model="localSelected.vormID"
                    v-bind:combotype="lctypeShapes"
                    :disabled="!editAllowed || setDisabledShape"
                    @change="set_input"
                ></lookup-combo>
            </b-col>
            <b-col sm="2" class="mx-0 px-0 my-0 py-0">
                <lookup-combo
                    id="input-kaasalternatief"
                    v-model="localSelected.productID"
                    v-bind:combotype="lctypeCheeseAlternatives"
                    :disabled="setDisabled"
                    v-bind:discrim="maakString(cheeseProductID)"
                    @change="set_input"
                ></lookup-combo>
            </b-col>

            <b-col sm="1" class="mx-0 px-0 my-0 py-0">
                <b-dropdown
                    id="dropdown-form"
                    text="..."
                    ref="dropdown"
                    menu-class="w-100"
                    class="w-100"
                    boundary="viewport"
                >
                    <div class="fluid-container">
                        <b-dropdown-form>
                            <div>
                                <b-form-group
                                    :label="
                                        $t('prod_plaats', { ns: 'production' })
                                    "
                                    label-for="dropdown-form-correct"
                                    @submit.stop.prevent
                                >
                                    <b-input-group
                                        :append="
                                            $t('prod_kooi', {
                                                ns: 'production'
                                            })
                                        "
                                        :label="
                                            $t('prod_kooi', {
                                                ns: 'production'
                                            })
                                        "
                                    >
                                        <b-form-input
                                            v-model="localSelected.kooi"
                                            @change="set_input"
                                            :disabled="!editAllowed"
                                        ></b-form-input>
                                    </b-input-group>
                                    <b-input-group
                                        :append="
                                            $t('prod_laag', {
                                                ns: 'production'
                                            })
                                        "
                                        :label="
                                            $t('prod_laag', {
                                                ns: 'production'
                                            })
                                        "
                                    >
                                        <b-form-input
                                            v-model="localSelected.laag"
                                            @change="set_input"
                                            :disabled="!editAllowed"
                                        ></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </div>
                            <b-dropdown-divider></b-dropdown-divider>
                        </b-dropdown-form>
                    </div>
                </b-dropdown>
            </b-col>
        </div>
    </div>
</template>
<script>
import { InpekelItem } from '../../models/BakInfo_helper';
import { LookupCombo } from './index';
import CheeseTijd from './CheeseTijd';
import DateHelper from '../../models/DateHelper';
import Helper from '../../models/Helper';
import { Feature } from '../../models/Features';

export default {
    name: 'InpekelItemCombo',
    data: function () {
        return {
            lctypeCheeseAlternatives: window.constants.LCCHEESEALTERNATIVES,
            lctypeShapes: window.constants.LCSHAPES,
            errorMessage: '',
            eenheidID: null,
            flexList: [],
            aangeraakt: 1,
            datumtijd: null
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {
            type: InpekelItem,
            required: true
        },
        editAllowed: {
            type: Boolean,
            default: false
        },
        cheeseProductID: {
            type: Number,
            required: false
        },
        baseDateTime: {
            type: String
        }
    },
    components: {
        LookupCombo,
        CheeseTijd
    },
    computed: {
        aantKaas() {
            return Helper.stringformat(
                '{0} {1}',
                this.$t('com_number', { ns: 'common' }),
                this.$t('com_kazen', { ns: 'common' }).toLowerCase()
            );
        },
        weightKaas() {
            return Helper.stringformat(
                '{0} {1}',
                this.$t('com_weight', { ns: 'common' }),
                this.$t('com_kazen', { ns: 'common' }).toLowerCase()
            );
        },
        localSelected() {
            return this.selected;
        },
        useWeight() {
            return this.checkFeature('conf_UseCheeseWeights');
        },
        setSize() {
            return this.useWeight ? 2 : 3;
        },
        isValid() {
            return (
                this.localSelected.amount > 0 && this.localSelected.vormID > 0
            );
        },
        setDisabled() {
            return this.localSelected.wijzigbaar || !this.editAllowed;
        },
        setDisabledShape() {
            const disableMultipleProductShapes = this.checkFeature(
                'conf_disableMultipleProductShapes'
            );
            return disableMultipleProductShapes;
        }
    },
    methods: {
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return this.$root.handleFeature(key);
        },
        maakString(item) {
            return String(item);
        },
        set_input: function () {
            this.localSelected.isChanged = true;
            this.$emit('validComponent', this.isValid);
            this.$emit('change', true);
            this.$emit('input', this.localSelected);
        },
        set_inputTijd(value) {
            const tijd =
                this.localSelected.inpekelTijdOri ??
                this.baseDateTime ??
                new Date();
            this.localSelected.inpekelTijd = DateHelper.convertedFromTimepicker(
                tijd,
                value
            );
            this.localSelected.inpekelTijdOri = this.localSelected.inpekelTijd;
            this.set_input();
        },
        set_inputInpekeltijd(value) {
            if (DateHelper.dateIsValid(value)) {
                this.localSelected.inpekelTijdOri = value;
                this.localSelected.inpekelTijd =
                    DateHelper.convertedToDateTimeString(value);
                this.aangeraakt += 1;
                this.set_input();
            }
        }
    },
    mounted() {
        this.localAmount = this.selected.amount;
        this.datumtijd =
            this.localSelected.inpekelTijdOri ??
            this.baseDateTime ??
            new Date();
    },
    created() {}
};
</script>

<style scoped></style>
