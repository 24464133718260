<template>
    <b-tab @click="loadActData()" lazy v-if="isVisible">
        <template #title>
            <b-spinner v-if="showSpinner" type="border" small></b-spinner>
            {{ $t('com_activitylog', { ns: 'common' }) }}
        </template>
        <b-card-text>
            <b-table
                striped
                responsive
                :fields="fieldsActiviteiten"
                :items="activiteitenLijst"
                primary-key
            >
            </b-table>
        </b-card-text>
    </b-tab>
</template>
<script>
import { loadItem } from '../../models/DataHelper';
export default {
    name: 'ActivityLog',
    data() {
        return {
            showSpinner: false,
            isVisible: true,
            fieldsActiviteiten: [
                {
                    key: 'gemaakt',
                    label: this.$t('prod_gemaakt', { ns: 'production' })
                },
                {
                    key: 'actie',
                    label: this.$t('prod_activity', { ns: 'production' })
                },
                {
                    key: 'persoon',
                    label: this.$t('prod_loggedin', { ns: 'production' })
                }
            ],
            activityList: []
        };
    },
    props: {
        lijst: {
            type: Array
        },
        action: {
            type: String
        },
        id: {
            type: Number
        },
        paramName: {
            type: String
        }
    },
    watch: {},
    components: {},
    computed: {
        activiteitenLijst() {
            return this.activityList;
        }
    },
    methods: {
        async loadActData() {
            this.showSpinner = true;
            if (this.lijst != undefined) {
                this.activityList = this.lijst;
                this.showSpinner = false;
                return;
            }
            if (this.id != undefined) {
                this.showSpinner = true;
                this.activityList = await loadItem(
                    this.action,
                    { [this.paramName]: this.id },
                    this.$t
                );
            }
            this.showSpinner = false;
        }
    },
    mounted() {},
    created() {}
};
</script>

<style scoped></style>
