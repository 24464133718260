<template>
    <div v-if="toonSelectie" class="form-inline">
        <b-input-group :prepend="giveNaam">
            <b-form-select
                v-model="catID"
                :options="lookupCategories"
                @change="findLabels"
            ></b-form-select>
            <b-form-select
                v-if="showCard"
                v-model="labelID"
                :options="geefLijst"
                @change="changed"
            >
            </b-form-select>
            <b-dropdown
                v-if="showCard"
                id="dropdown-form"
                text="..."
                ref="dropdown"
                size="sm"
                boundary="viewport"
                @hide="handleHide($event)"
            >
                <div class="fluid-container">
                    <b-dropdown-form>
                        <b-input-group prepend="Expiration date">
                            <b-form-datepicker
                                v-model="expirationDate"
                                :date-format-options="{
                                    year: 'numeric',
                                    month: 'short',
                                    day: '2-digit'
                                }"
                                :min="new Date()"
                                @input="changedWait"
                                @shown="isCloseable = false"
                            ></b-form-datepicker>
                        </b-input-group>
                        <b-input-group prepend="Valid from">
                            <b-form-datepicker
                                v-model="validFrom"
                                :date-format-options="{
                                    year: 'numeric',
                                    month: 'short',
                                    day: '2-digit'
                                }"
                                @input="changedWait"
                                @shown="isCloseable = false"
                            ></b-form-datepicker>
                        </b-input-group>
                        <b-input-group prepend="Valid till">
                            <b-form-datepicker
                                v-model="validTill"
                                :date-format-options="{
                                    year: 'numeric',
                                    month: 'short',
                                    day: '2-digit'
                                }"
                                :min="new Date()"
                                @input="changedWait"
                                @shown="isCloseable = false"
                            ></b-form-datepicker>
                        </b-input-group>
                        <b-input-group prepend="Description">
                            <b-form-input
                                v-model="niceName"
                                @input="changedWait"
                                @shown="isCloseable = false"
                            ></b-form-input>
                        </b-input-group>
                        <b-button
                            class="float-right"
                            variant="primary"
                            @click="closeMe()"
                            >Ok</b-button
                        >
                    </b-dropdown-form>
                </div>
            </b-dropdown>
        </b-input-group>
    </div>
    <div v-else>{{ $t('com_nodata', { ns: 'common' }) }}</div>
</template>
<script>
import { loadList } from '../../models/DataHelper';
import DateHelper from '../../models/DateHelper';
import Helper from '../../models/Helper';
export default {
    name: 'LookupLabelCombo',
    data() {
        return {
            errorMessage: '',
            flexList: [],
            lookupCategories: [],
            lookupLabels: [],
            catID: 1,
            labelID: 1,
            showCard: false,
            niceName: '',
            expirationDate: null,
            validFrom: null,
            validTill: null,
            refresh: false,
            isCloseable: false
        };
    },
    props: {
        clear: {
            type: Number,
            required: true,
            default: 0
        }
    },
    components: {},
    watch: {
        clear: {
            handler(newVal, oldVal) {
                if (newVal != undefined && newVal > oldVal) {
                    this.catID = 1;
                    this.labelID = 1;
                    this.expirationDate = null;
                    this.validFrom = null;
                    this.validTill = null;
                    this.niceName = '';
                    this.showCard = false;
                    this.isCloseable = false;
                    this.changed(this.labelID);
                }
            }
        }
    },
    computed: {
        toonSelectie() {
            return this.lookupLabels.length > 0;
        },
        giveNaam() {
            return this.withPrepend ? 'Bijlagetype' : '';
        },
        geefLijst() {
            return this.flexList;
        }
    },
    methods: {
        findLabels(cat) {
            this.flexList = this.lookupLabels.filter((x) => x.cat == cat);
            this.showCard = cat > 99;
        },
        closeMe() {
            this.changedWait();
            this.isCloseable = true;
            this.$refs.dropdown.hide();
        },
        handleHide(bvEvent) {
            if (!this.isCloseable) {
                bvEvent.preventDefault();
            } else {
                this.$refs.dropdown.hide();
            }
        },
        changedWait() {
            this.$nextTick(() => {
                this.changed(this.labelID);
            });
        },
        changed(val) {
            const sel = this.lookupLabels.find((x) => x.value == val);
            const temp = { cat: sel.cat, label: val, text: sel.text };
            if (this.expirationDate != undefined) {
                temp.expirationDate = DateHelper.localDateToUTC(
                    this.expirationDate
                );
            }
            if (this.validFrom != undefined) {
                temp.validFrom = DateHelper.localDateToUTC(this.validFrom);
            }
            if (this.validTill != undefined) {
                temp.validTill = DateHelper.localDateToUTC(this.validTill);
            }
            if (this.niceName != ' ') {
                temp.niceName = this.niceName;
            }
            this.$emit('change', true);
            this.$emit('input', temp);
        }
    },
    mounted() {},
    async created() {
        // vul de lijsten
        const locList = await loadList(
            'components/getLookupLabelCategories',
            'components/loadLookupLabelCategories',
            null,
            this.refresh,
            this.$t
        );
        if (locList.length > 0) {
            this.lookupCategories = locList.map((x) => {
                return { value: x.cat_id, text: x.categorie };
            });
            this.lookupCategories = Helper.distinctList(
                this.lookupCategories,
                'value'
            );
            this.lookupLabels = locList.map((x) => {
                return { value: x.label_id, text: x.label, cat: x.cat_id };
            });
        }
    }
};
</script>

<style scoped></style>
