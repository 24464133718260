<template>
    <b-modal
        id="modal-plusproduction"
        size="xl"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        no-close-on-backdrop
        @hidden="hide"
        @ok="handleOk"
        @change="change"
    >
        <b-card @submit.stop.prevent="handleSubmit" :header="strProduct">
            <b-tabs card>
                <b-tab :title="strHoofdProduct" v-bind:key="1" active>
                    <b-card-text>
                        <plus-production-combo
                            v-model="localItem"
                            v-bind:editAllowed="!disabled"
                            v-bind:readonly="readonly"
                            v-bind:discrimArray="theDiscrimList"
                            v-bind:showBatchNumber="false"
                            @productChange="setRecipe"
                        />

                        <b-input-group prepend="Locatie" label="Locatie">
                            <lookup-combo
                                id="input-itemloc"
                                v-model="localItem.locatieID"
                                v-bind:combotype="lctypeLocation"
                                v-bind:discrim="null"
                                :disabled="editProduct"
                            ></lookup-combo>
                        </b-input-group>
                    </b-card-text>
                </b-tab>
                <b-tab :title="strBijProduct" v-bind:key="2">
                    <b-card-text>
                        <b-button
                            size="sm"
                            @click="addBijItem"
                            class="bgc_aux_normal"
                            ><font-awesome-icon icon="arrow-down" size="1x" />
                        </b-button>
                        <b-alert
                            v-model="showDismissibleAlert"
                            variant="danger"
                            dismissible
                        >
                            {{ alertText }}
                        </b-alert>

                        <div
                            v-for="(bij, index) in localBijProdList"
                            v-bind:key="index"
                        >
                            <b-row class="mx-0 px-0 my-1 py-0">
                                <b-col sm="10" class="mx-0 px-0 my-1 py-0">
                                    <plus-production-combo
                                        v-model="localBijProdList[index]"
                                        v-bind:editAllowed="!disabled"
                                        v-bind:readonly="readonly"
                                        v-bind:discrimArray="theItemArray"
                                        v-bind:showBatchNumber="false"
                                        @input="acceptInputBij"
                                    />
                                </b-col>
                                <b-col sm="2" class="mx-0 px-0 my-1 py-0">
                                    <b-button
                                        v-if="!disabled"
                                        @click="verwijderBijItem(bij.productID)"
                                        class="bgc_aux_alarm"
                                        ><font-awesome-icon
                                            icon="trash-alt"
                                            size="1x"
                                        />
                                    </b-button>
                                </b-col>
                            </b-row></div
                    ></b-card-text>
                </b-tab>
                <b-tab :title="strProductionValues" v-bind:key="3">
                    <b-card-text>
                        <b-input-group
                            :prepend="$t('com_prodvalue', { ns: 'common' })"
                            :label="$t('com_prodvalue', { ns: 'common' })"
                        >
                            <lookup-combo
                                id="input-prodvalue"
                                v-model="productionValueID"
                                v-bind:combotype="lctypeProdValues"
                                :disabled="disabled"
                                @input="addProdValue"
                            ></lookup-combo>
                        </b-input-group>

                        <div
                            class="mx-0 px-0 my-0 py-0"
                            v-for="(pv, index) in localProdValueList"
                            v-bind:key="index"
                        >
                            <b-col sm="11" class="mx-0 px-0 my-0 py-0">
                                <production-value-combo
                                    v-model="localProdValueList[index]"
                                    v-bind:editAllowed="!disabled"
                                    v-bind:readonly="readonly"
                                    @input="acceptInputPV"
                                />
                            </b-col>
                            <b-col sm="1" class="mx-0 px-0 my-0 py-0">
                                <b-button
                                    v-if="!disabled"
                                    @click="verwijderProdValue(pv.id)"
                                    class="bgc_aux_alarm"
                                    ><font-awesome-icon
                                        icon="trash-alt"
                                        size="1x"
                                    />
                                </b-button>
                            </b-col></div
                    ></b-card-text>
                </b-tab>
                <special-attachments
                    v-if="inItem.id > 0"
                    :itemId="inItem.id"
                    :attType="attachmentType"
                />
            </b-tabs>
        </b-card>
        <b-card @submit.stop.prevent="handleSubmit" :header="strMengsel">
            <b-button size="sm" @click="addMixItem" class="bgc_aux_normal"
                ><font-awesome-icon icon="arrow-down" size="1x" />
            </b-button>
            <b-alert
                v-model="showDismissibleAlert"
                variant="danger"
                dismissible
            >
                {{ alertText }}
            </b-alert>

            <div v-for="(mix, index) in localMixList" v-bind:key="mix.id">
                <b-row>
                    <b-col sm="10">
                        <plus-production-combo
                            v-model="localMixList[index]"
                            v-bind:editAllowed="!disabled"
                            v-bind:readonly="readonly"
                            v-bind:discrimArray="theItemArray"
                            v-bind:showSerial="
                                localMixList[index].productType < 0
                            "
                            v-bind:isIngredient="true"
                            @input="acceptInput"
                        />
                    </b-col>
                    <b-col sm="2">
                        <b-button
                            v-if="!disabled"
                            @click="verwijderItem(mix.productID)"
                            class="bgc_aux_alarm"
                            ><font-awesome-icon icon="trash-alt" size="1x" />
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </b-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VueExtension } from '../../models/VueExtension';
import { LookupCombo, SpecialAttachments } from '../components';
import PlusProductionCombo from './PlusProductionCombo.vue';
import ProductionValueCombo from './ProductionValueCombo.vue';
import { loadItem, loadList, loadGetItem } from '../../models/DataHelper';
import { SimpleRecipe, SimpleRecipeElement } from '../../models/Recept';
import {
    SimpleProduction,
    SimpleProductionValue,
    SimpleProductionElement,
    AttachmentInfo
} from '../../models/PlusProduction';
import Helper from '@/models/Helper';

export default defineComponent({
    name: 'PlusProductionModal',
    data: function () {
        return {
            showDismissibleAlert: false,
            alertText: '',
            lctypeLocation: window.constants.LCLOCATION,
            lctypeProdValues: window.constants.LCPRODUCTIONVALUES,
            attachmentType: window.constants.ATT_STOCKITEM,
            perUnitsId: 0,
            localNaam: '',
            opmerking: '',
            nextID: -1,
            aangeraakt: 0,
            disabled: false,
            onlyRecipes: true,
            productionValueID: -1,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            productLookupLijst: [] as Array<any>,
            prodvalueLookupLijst: [] as Array<SimpleProductionValue>,
            attachmentLijst: [] as Array<AttachmentInfo>,
            localItem: {} as SimpleProduction,
            strNewSimpleProduction: this.$t('com_makenew', {
                ns: 'common',
                val: this.$t('com_production', { ns: 'common' })
            }),
            strEditSimpleProduction: this.$t('com_edit', {
                ns: 'common',
                val: this.$t('com_production', { ns: 'common' })
            }),
            strProduct: this.$t('com_product', { ns: 'common' }),
            strMengsel: this.$t('tien_mix', { ns: 'tien' }),
            strErrorDoubles: this.$t('com_doubleinlist', {
                ns: 'common',
                val: this.$t('com_product', { ns: 'common' })
            }),
            strBijProduct: this.$t('tien_byproducts', { ns: 'tien' }),
            strHoofdProduct: this.$t('tien_mainproduct', { ns: 'tien' }),
            strProductionValues: this.$t('com_prodvalues', { ns: 'common' }),
            strDeleteAttachment: this.$t('com_delete', {
                ns: 'common',
                val: this.$t('com_bijlage', { ns: 'common' })
            }),
            strQueuedAttachments: this.$t('com_queuedbijlagen', {
                ns: 'common'
            })
        };
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        inItem: {
            type: SimpleProduction,
            required: true
        },
        discrimList: {
            type: Array<SimpleProduction>,
            default: []
        },
        visible: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    components: {
        PlusProductionCombo,
        ProductionValueCombo,
        LookupCombo,
        SpecialAttachments
    },
    watch: {},
    computed: {
        setDisabled() {
            return this.disabled;
        },
        formtitle() {
            return this.inItem.isNew
                ? this.strNewSimpleProduction
                : this.strEditSimpleProduction;
        },
        // localItem(): SimpleProduction {
        //     return this.inItem;
        // },
        localMixList() {
            return this.inItem.elementen;
        },
        localBijProdList() {
            return this.inItem.bijproducten;
        },
        localProdValueList() {
            return this.inItem.prodwaarden;
        },
        editProduct() {
            return !this.inItem.isNew;
        },
        theItemArray() {
            const arr = new Array<SimpleProductionElement>();
            const o = new SimpleProductionElement({
                productID: this.inItem.id,
                productName: this.inItem.naam
            });
            arr.push(o);
            return arr;
        },
        withRecipes() {
            return this.onlyRecipes;
        },
        theDiscrimList() {
            return this.onlyRecipes ? this.discrimList : [];
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                { key: 'naam', label: 'Naam', sortable: true, visible: true },
                {
                    key: 'mimetype',
                    label: 'Type',
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.$emit('hide', this.inItem);
        },
        change(visible) {
            this.setChanged();
            this.$emit('change', visible);
        },
        setChanged() {
            this.localItem.isChanged = true;
        },
        async setRecipe(id) {
            const recept: SimpleRecipe = await loadItem(
                'tienplus/loadSimpleRecipe',
                { productid: id },
                this.$t
            );
            const product = this.productLookupLijst.find((x) => x.id == id);
            this.localItem.eenheidID = product ? product.eenheidID : -1;
            // maak de oude items leeg
            this.clearLists();
            // maak items aan voor ingredienten
            for (let i = 0; i < recept.elementen.length; i++) {
                const obj = recept.elementen[i];
                const ing = this.productLookupLijst.find((x) => x.id == obj.id);
                obj.productType = ing.productType;
                this.addMixItem(obj.id);
            }
            // maak bijproducten aan
            for (let i = 0; i < recept.bijproducten.length; i++) {
                const obj = recept.bijproducten[i];
                this.addBijItem(obj.id);
            }
        },
        clearLists() {
            this.inItem.bijproducten = [];
            this.inItem.elementen = [];
        },
        listHasDoubles() {
            // check of item niet dubbel voor komt
            const aantMixList = this.localMixList.length;
            const distinctList = Helper.distinctList(
                this.localMixList,
                'productID'
            );
            const aantDistict = distinctList.length;
            return aantDistict != aantMixList;
        },
        listBijHasDoubles() {
            // check of item niet dubbel voor komt
            const aantMixList = this.localBijProdList.length;
            const hasDoubles = Helper.checkDoubles(this.localBijProdList, [
                'productID',
                'vormID'
            ]);
            return hasDoubles;
        },
        acceptInput(altered) {
            this.$nextTick(() => {
                if (this.listHasDoubles()) {
                    this.showAlert(this.strErrorDoubles);
                    return;
                }
            });
        },
        acceptInputBij(altered) {
            this.$nextTick(() => {
                if (this.listBijHasDoubles()) {
                    this.showAlert(this.strErrorDoubles);
                    return;
                }
            });
        },
        acceptInputPV(altered) {
            this.$nextTick(() => {});
        },
        addMixItem(id = null) {
            const obj = new SimpleProductionElement({
                productID: id ?? this.nextID
            });
            const product = this.productLookupLijst.find(
                (x) => x.id == obj.productID
            );
            obj.eenheidID = product ? product.eenheidID : -1;
            obj.productType = product ? product.type : 0;
            this.inItem.elementen.push(obj);
            this.nextID = this.nextID - 1;
        },
        addBijItem(id = null) {
            const obj = new SimpleProductionElement({
                productID: id ?? this.nextID
            });
            const product = this.productLookupLijst.find(
                (x) => x.id == obj.productID
            );
            obj.eenheidID = product ? product.eenheidID : -1;
            obj.productType = product ? product.type : 0;
            this.inItem.bijproducten.push(obj);
            this.nextID = this.nextID - 1;
        },
        addProdValue(id) {
            const obj = new SimpleProductionValue({
                id: this.productionValueID
            });
            const product = this.prodvalueLookupLijst.find(
                (x) => x.id == obj.id
            );
            obj.type = product ? product.type : -1;
            obj.naam = product ? product.naam : 0;
            this.inItem.prodwaarden.push(obj);
            this.nextID = this.nextID - 1;
        },
        async checkFormValidity() {
            if (this.listHasDoubles()) {
                this.showAlert(this.strErrorDoubles);
                return false;
            }
            const invalidBasis = this.localItem.CheckValidity(this.$t);
            if (invalidBasis) this.showAlert(invalidBasis);
            const valid = this.localItem != undefined && !invalidBasis;
            return valid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSubmit() {
            // Exit when the form isn't valid
            if (!(await this.checkFormValidity())) {
                return;
            }
            this.saveThisProduction();
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-plusproduction');
            });
        },
        async saveThisProduction() {
            this.localItem.isChanged = true;
            const model = this.localItem.SetForSave();
            const terug = await loadItem(
                'tienplus/saveSimpleProduction',
                model,
                this.$t
            );
        },
        verwijderItem(theID) {
            let deleted = 0;
            for (let i = this.localMixList.length - 1; i >= 0; i--) {
                const id = this.localMixList[i].productID;
                const test = id === theID;
                if (test) this.localMixList.splice(i, 1);
                if (test) deleted += 1;
            }
        },
        verwijderBijItem(theID) {
            for (let i = this.localBijProdList.length - 1; i >= 0; i--) {
                const id = this.localBijProdList[i].productID;
                const test = id === theID;
                if (test) this.localBijProdList.splice(i, 1);
            }
        },
        verwijderProdValue(theID) {
            for (let i = this.localProdValueList.length - 1; i >= 0; i--) {
                const id = this.localProdValueList[i].id;
                const test = id === theID;
                if (test) this.localProdValueList.splice(i, 1);
            }
        }
    },
    async mounted() {
        const lijst = await loadList(
            'components/getSimpleProdComboLijst',
            'components/loadSimpleProducts',
            null,
            true,
            this.$t
        );
        this.productLookupLijst = lijst;

        this.prodvalueLookupLijst = await loadList(
            'tienplus/getProductValueLijst',
            'tienplus/loadProductionValues',
            null,
            true,
            this.$t
        );
        //if (this.localItem.isNew && this.localItem.productID>0) this.setRecipe(this.localItem.productID);
    },
    created() {
        this.localItem = this.inItem;
    }
});
</script>

<style scoped></style>
