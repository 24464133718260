import Api from '../../../api';
import store from '../../../store';


// DateTime from, DateTime until
const fetchSalesOrderInfo = async data => {
    return fetchInfo('/SalesOrder/GetSalesOrderInfo', data);
};
// int orderID
const fetchSalesOrder = async data => {
    return fetchInfo('/SalesOrder/GetSalesOrder', data);
};
// int orderLineID
const fetchSalesOrderLine = async data => {
    return fetchInfo('/SalesOrder/GetSalesOrderLine', data);
};
// int orderLineID
const fetchAssignedCheeseAmount = async data => {
    return fetchInfo('/SalesOrder/GetAssignedCheeseAmount', data);
};

// int orderLineID
const deleteSalesOrderLine = async data => {
    return deleteInfo('/SalesOrder/DeleteSalesOrderLine', data);
};

// OrderModel salesOrder
const storeSalesOrder = async (data) => {
    return await Api(store).put('/SalesOrder/StoreSalesOrder', data);
};

const fetchInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};
const deleteInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).delete(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } 
};

export default {
    fetchSalesOrderInfo,
    fetchSalesOrder,
    fetchSalesOrderLine,
    fetchAssignedCheeseAmount,
    storeSalesOrder,
    deleteSalesOrderLine
};
