<template>
    <b-modal
        id="modal-salesorder"
        size="xl"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
        @change="change"
    >
        <b-card @submit.stop.prevent="handleSubmit">
            <b-alert
                v-model="showDismissibleAlert"
                variant="danger"
                dismissible
            >
                {{ alertText }}
            </b-alert>
            <b-tabs card>
                <b-tab :title="headertitle" v-bind:key="1" active>
                    <b-card-text>
                        <b-row class="mx-0 px-0 my-0 py-0">
                            <b-col sm="6">
                                <b-col sm="3">
                                    <label for="so_reference"
                                        >{{
                                            $t('com_reference', {
                                                ns: 'common'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <b-form-input
                                        id="so_reference"
                                        :placeholder="
                                            $t('com_reference', {
                                                ns: 'common'
                                            })
                                        "
                                        v-model="localItem.klantRef"
                                        @change="setChanged"
                                        :disabled="!editAllowed"
                                    ></b-form-input>
                                </b-col>
                            </b-col>
                            <b-col sm="6">
                                <b-row>
                                    <b-col sm="3">
                                        <div class="form-inline">
                                            <label
                                                >{{
                                                    $t('com_klant', {
                                                        ns: 'common'
                                                    })
                                                }}:</label
                                            >
                                        </div>
                                    </b-col>
                                    <b-col sm="9">
                                        <lookup-combo
                                            v-model="localItem.klantID"
                                            v-bind:combotype="lctypeCompany"
                                            :disabled="!editAllowed"
                                            @change="setChanged"
                                            :doCheckState="true"
                                        ></lookup-combo>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="mx-0 px-0 my-0 py-0">
                            <b-col sm="12">
                                <b-col sm="1">
                                    <label for="input-itemremark"
                                        >{{
                                            $t('com_opmerking', {
                                                ns: 'common'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="11">
                                    <b-form-textarea
                                        id="input-itemremark"
                                        :placeholder="
                                            $t('com_opmerking', {
                                                ns: 'common'
                                            })
                                        "
                                        v-model="localItem.opmerking"
                                        @change="setChanged"
                                        rows="3"
                                    ></b-form-textarea>
                                </b-col>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-tab>
                <special-attachments
                    v-if="localItem.id > 0"
                    :itemId="localItem.id"
                    :attType="attachmentType"
                />
            </b-tabs>
        </b-card>
        <b-card no-body>
            <b-card-header class="container-fluid">
                <b-col :sm="10" class="mx-0 px-0 my-0 py-0">
                    {{ regelsHeader }}
                </b-col>
                <b-col :sm="2" class="mx-0 px-0 my-0 py-0">
                    <b-button
                        @click="addItem"
                        class="bgc_aux_succes float-right"
                        ><font-awesome-icon icon="edit" size="1x" />
                    </b-button>
                </b-col>
            </b-card-header>
            <b-card-text>
                <card-sales-order
                    v-if="showCardSalesOrder"
                    :inItem="currentOrderLine"
                    :delDate="delDate"
                    :editAllowed="editAllowed"
                    @changed="setChangedLine"
                />
                <b-table
                    :key="aangeraakt"
                    striped
                    responsive
                    :fields="visibleFields"
                    :items="sortedList"
                    show-empty
                    :empty-text="$t('com_geen_data', { ns: 'common' })"
                >
                    <template v-slot:cell(actions)="row">
                        <b-button
                            v-if="checkRight('OrderUpdate')"
                            size="sm"
                            @click="editItem(row.item)"
                            class="bgc_aux_warning"
                            ><font-awesome-icon icon="edit" size="1x" />
                        </b-button>
                        <b-button
                            v-if="checkRight('OrderDelete')"
                            size="sm"
                            @click="verwijderItem(row.item)"
                            class="bgc_aux_alarm"
                            ><font-awesome-icon icon="trash-alt" size="1x" />
                        </b-button>
                    </template>
                </b-table>
            </b-card-text>
        </b-card>
    </b-modal>
</template>

<script>
import { loadItem } from '../../models/DataHelper';
import DateHelper from '../../models/DateHelper';
import Helper from '../../models/Helper';
import { LookupCombo, SpecialAttachments } from '../components';
import { defineComponent } from 'vue';
import { OrderInfo, OrderHeader, OrderLine } from '@/models/Orders';
import { Permission } from '../../models/Permissions';
import CardSalesOrder from './CardSalesOrder.vue';

export default defineComponent({
    name: 'SalesOrderModal',
    data: function () {
        return {
            showDismissibleAlert: false,
            alertText: '',
            lctypeCompany: window.constants.LCCOMPANY,
            attachmentType: window.constants.ATT_ORDER,
            opmerking: '',
            showCardSalesOrder: false,
            isEdit: true,
            currentOrderLine: null,
            delDate: null,

            salesorder: {},
            forcerefresh: false,
            aangeraakt: 1
        };
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        inID: {
            required: true
        },
        copyOrder: {},
        visible: {
            type: Boolean,
            default: false
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LookupCombo,
        SpecialAttachments,
        CardSalesOrder
    },
    watch: {},
    computed: {
        formtitle() {
            if (this.salesorder == undefined) return 'Geen selectie';
            const naam = this.$t('com_salesorder', {
                ns: 'common'
            }).toLowerCase();
            return this.salesorder.isNew
                ? this.$t('com_makenew', { ns: 'common', val: naam })
                : this.$t('com_edit', { ns: 'common', val: naam });
        },
        headertitle() {
            const naam = this.$t('com_salesorder', {
                ns: 'common'
            }).toLowerCase();
            const s = Helper.stringformat(
                '{0} {1}',
                this.$t('prod_header', { ns: 'production' }),
                naam
            );
            return this.salesorder.isNew ? s : s + ' #:  ' + this.salesorder.id;
        },
        regelsHeader() {
            const a = this.aangeraakt;
            const s = Helper.stringformat(
                '{0} {1}: {2}',
                this.$t('log_lines', { ns: 'logistics' }),
                this.$t('com_number', { ns: 'common' }).toLowerCase(),
                this.salesorder.regels?.length
            );
            return s;
        },
        localItem() {
            return this.salesorder;
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                {
                    key: 'klantRef',
                    label: this.$t('log_reference', { ns: 'logistics' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'productNaam',
                    label: this.$t('com_product', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'vraagAantal',
                    label: this.$t('com_number', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'vormNaam',
                    label: this.$t('prod_vorm', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'vraagProddatum',
                    label: this.$t('log_optdatum', { ns: 'logistics' }),
                    sortable: true,
                    visible: true,
                    formatter: this.displayDate
                },
                {
                    key: 'vraagDeldatum',
                    label: this.$t('log_leverdatum', { ns: 'logistics' }),
                    sortable: true,
                    visible: true,
                    formatter: this.displayDate
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        sortedList() {
            const a = this.aangeraakt;
            return this.salesorder.regels
                ? _.sortBy(this.salesorder.regels, ['productNaam'])
                : [];
        }
    },
    methods: {
        displayDate(value) {
            return DateHelper.convertedToDate(value, false);
        },
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.opmerking = '';
            this.$emit('hide');
        },
        addItem() {
            this.isEdit = false;
            this.currentOrderLine = new OrderLine({});
            this.showCardSalesOrder = !this.showCardSalesOrder;
        },
        editItem(item) {
            this.isEdit = true;
            this.currentOrderLine = item;
            this.showCardSalesOrder = true;
        },
        async verwijderItem(item) {
            this.currentOrderLine = item;
            let doDelete = true;
            if (item.id > 0) {
                doDelete = await this.$root.showConfirmationBox(
                    this.$t('com_delete', {
                        ns: 'common',
                        val: this.$t('com_salesorderline', { ns: 'common' })
                    })
                );
            }
            if (doDelete) {
                for (let i = this.salesorder.regels.length - 1; i >= 0; i--) {
                    const gevonden = this.salesorder.regels[i].id == item.id;
                    if (gevonden) {
                        if (item.id > 0) this.$emit('delete', item.id);
                        this.salesorder.regels.splice(i, 1);
                        this.aangeraakt += 1;
                    }
                }
            }
        },
        productIsInList(item) {
            const result = this.localItem.regels.find(
                (x) => x.productID == item.productID && x.vormID == item.vormID
            );
            return result;
        },
        setDelDate() {
            const result = this.localItem.regels.filter(
                (x) => x.vraagDeldatum != undefined
            );
            const result2 = [
            ...new Map(
                result.map((x) => [x[key], x])
            ).values().filter(Boolean)
        ].map((x) => (x.vraagDeldatum = DateHelper.localDateToUTC(x.vraagDeldatum)));
            const distinctList = Helper.distinctList(result, 'vraagDeldatum');
            if (distinctList.length > 1) {
                this.showAlert(
                    this.$t('com_onedeldate', {
                        ns: 'common'
                    })
                );
                return;
            }
            return distinctList[0].vraagDeldatum;
        },
        setChangedLine(item) {
            const compareProduct = this.productIsInList(item);
            if (compareProduct) {
                if (compareProduct.id != item.id) {
                    this.showAlert(
                        this.$t('com_doubleinlist', {
                            ns: 'common',
                            val: item.productNaam
                        })
                    );
                    return;
                }
            }
            if (this.isEdit) {
                const temp = this.salesorder.regels.find(
                    (x) => x.id == item.id
                );
                Object.assign(temp, item);
            } else {
                this.salesorder.regels.push(item);
            }
            this.aangeraakt += 1;
            this.delDate = this.setDelDate();
            this.setChanged();
            this.showCardSalesOrder = item.closeaction;
        },
        setChanged() {
            this.salesorder.isChanged = true;
        },
        change(visible) {
            this.setChanged();
            this.$emit('change', visible);
        },
        async checkFormValidity() {
            const invalidBasis = this.salesorder.CheckValidity(this.$t);
            if (invalidBasis) this.showAlert(invalidBasis);
            const valid = this.salesorder != undefined && !invalidBasis;
            return valid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (this.salesorder.isChanged) {
                if (!this.checkFormValidity()) {
                    return;
                }
                this.saveThisOrder();
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$emit('changed', this.salesorder);
                this.$bvModal.hide('modal-salesorder');
            });
        },
        async saveThisOrder() {
            const orderModel = this.salesorder.SetForSave();
            await loadItem('salesorder/saveSalesOrder', orderModel, this.$t);
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        },
        makeCopy(item) {
            let regelteller = -1;
            const copy = new OrderHeader({});
            copy.klantID = item.klantID;
            copy.klantRef = item.klantRef;
            copy.opmerking = 'Kopie';
            for (let i = 0; i < item.regels.length; i++) {
                const regel = item.regels[i];
                const copyRegel = new OrderLine({});
                copyRegel.id = regelteller;
                regelteller -= 1;
                copyRegel.productID = regel.productID;
                copyRegel.productNaam = regel.productNaam;
                copyRegel.vormID = regel.vormID;
                copyRegel.vormNaam = regel.vormNaam;
                copyRegel.vraagAantal = regel.vraagAantal;
                //copyRegel.vraagProddatum = regel.vraagProddatum;
                //copyRegel.vraagDeldatum = regel.vraagDeldatum;
                copy.regels.push(copyRegel);
            }
            return copy;
        }
    },
    async created() {
        // id = -1 is een nieuwe order
        // copyOrder is een order als basis => id>0 = edit anders een nieuwe kopie
        if (this.inID < 0) {
            this.salesorder = new OrderHeader({});
            if (this.copyOrder != undefined) {
                const item = await loadItem(
                    'salesorder/loadSalesOrder',
                    {
                        orderID: this.copyOrder.orderID
                    },
                    this.$t
                );
                this.salesorder = this.makeCopy(item);
            }
        } else {
            const item = await loadItem(
                'salesorder/loadSalesOrder',
                {
                    orderID: this.inID
                },
                this.$t
            );
            this.salesorder = item;
            this.delDate = this.setDelDate();
        }
    }
});
</script>

<style scoped></style>
