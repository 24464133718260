export { PERMISSION_ENUM, Permission };

const PERMISSION_ENUM = Object.freeze([
    { id: 1, name: 'UserCreate', isAdminRight: true },
    { id: 2, name: 'UserDelete', isAdminRight: true },
    { id: 3, name: 'UserAuthorize', isAdminRight: true },
    { id: 4, name: 'ChangeAppConfiguration', isAdminRight: true },
    { id: 5, name: 'ChangeExternalCommnication', isAdminRight: true },
    { id: 11, name: 'BatchCreate', isAdminRight: false },
    { id: 12, name: 'BatchDelete', isAdminRight: false },
    { id: 13, name: 'BatchUpdate', isAdminRight: false },
    { id: 14, name: 'BatchAddIngredients', isAdminRight: false },
    { id: 15, name: 'BatchInBrine', isAdminRight: false },
    { id: 16, name: 'BatchOutBrine', isAdminRight: false },
    { id: 17, name: 'BatchSetCheeseMaks', isAdminRight: false },
    { id: 18, name: 'BatchDestroy', isAdminRight: false },
    { id: 19, name: 'StockLocationAddIngredients', isAdminRight: false },
    { id: 21, name: 'ProductCreate', isAdminRight: false },
    { id: 22, name: 'ProductUpdate', isAdminRight: false },
    { id: 23, name: 'ProductDelete', isAdminRight: false },
    { id: 24, name: 'ProductGroupStarters', isAdminRight: false },    
    { id: 31, name: 'StockIn', isAdminRight: false },
    { id: 32, name: 'StockUpdate', isAdminRight: false },
    { id: 33, name: 'StockDelete', isAdminRight: false },
    { id: 34, name: 'StockMove', isAdminRight: false },
    { id: 35, name: 'StockCorrect', isAdminRight: false },
    { id: 36, name: 'StockClose', isAdminRight: false },
    { id: 37, name: 'StockOut', isAdminRight: false },
    { id: 41, name: 'RecipeCreate', isAdminRight: false },
    { id: 42, name: 'RecipeUpdate', isAdminRight: false },
    { id: 43, name: 'RecipeClose', isAdminRight: false },
    { id: 51, name: 'MasterDataCreate', isAdminRight: false },
    { id: 52, name: 'MasterDataUpdate', isAdminRight: false },
    { id: 53, name: 'MasterDataDelete', isAdminRight: false },
    { id: 61, name: 'MaintenanceCleaningCreate', isAdminRight: false },
    { id: 62, name: 'MaintenanceCleaningDelete', isAdminRight: false },
    { id: 1001, name: 'AnalyticsEnityObjectCreate', isAdminRight: false },
    { id: 1002, name: 'AnalyticsEnityObjectDelete', isAdminRight: false },
    { id: 1011, name: 'AnalyticsMeasurementCreate', isAdminRight: false },
    { id: 1012, name: 'AnalyticsMeasurementUpdate', isAdminRight: false },
    { id: 1013, name: 'AnalyticsMeasurementDelete', isAdminRight: false },
    { id: 1021, name: 'AnalyticsSampleCreate', isAdminRight: false },
    { id: 1022, name: 'AnalyticsSampleUpdate', isAdminRight: false },
    { id: 1023, name: 'AnalyticsSampleDelete', isAdminRight: false },
    { id: 1031, name: 'AnalyticsConfigure', isAdminRight: false },
    { id: 1041, name: 'DeliveryCreate', isAdminRight: false },
    { id: 1042, name: 'DeliveryUpdate', isAdminRight: false },
    { id: 1043, name: 'DeliveryDelete', isAdminRight: false },
    { id: 1051, name: 'PrintedNoteCreate', isAdminRight: false },
    { id: 1052, name: 'PrintedNotePrint', isAdminRight: false },
    { id: 1061, name: 'CheeseTreatmentCreate', isAdminRight: false },
    { id: 1062, name: 'CheeseTreatmentUpdate', isAdminRight: false },
    { id: 1063, name: 'CheeseTreatmentAssign', isAdminRight: false },
    { id: 1064, name: 'CheeseTreatmentConfirm', isAdminRight: false },
    { id: 1071, name: 'OrderCreate', isAdminRight: false },
    { id: 1072, name: 'OrderUpdate', isAdminRight: false },
    { id: 1073, name: 'OrderAssign', isAdminRight: false },
    { id: 1074, name: 'OrderDelete', isAdminRight: false }
]);
export default PERMISSION_ENUM;

class Permission {
    constructor(data, func) {
        this.type = 'Permissions';
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            naam: {
                //value: data.description ?? Permission.giveName(data.id),
                value: Permission.giveName(data.id, func),
                writable: true,
                enumerable: true
            },
            onderdeel: {
                value: data.module,
                writable: true,
                enumerable: true
            }
        });
    }
    static enum(naam) {
        if (naam == undefined) return -1;
        const ob = PERMISSION_ENUM.find((x) => x.name == naam);
        return ob ? ob.id : -1;
    }
    static giveName(id, func) {
        if (id == undefined) return undefined;
        const ob = PERMISSION_ENUM.find((x) => x.id == id);
        return ob ? func(ob.id.toString(), { ns: 'permissies' }) : undefined;
    }
}

