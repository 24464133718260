<template class="mx-0 px-0 my-1 py-0">
    <b-card :header="biProductCat" header-tag="header">
        <div
            v-for="(bilijst, index) in bakingredientLijst"
            v-bind:key="bilijst.productID"
        >
            <card-bakingredient-lijst-detail
                class="mx-0 px-0 my-1 py-0"
                v-model="bakingredientLijst[index]"
                v-bind:editAllowed="editAllowed"
                v-bind:baseDateTime="baseDateTime"
                v-bind:newDateTime="newDateTime"
                v-bind:preselected="preselected"
                v-bind:recipeCatProducts="recipeCatProducts"
                @zuurseltijd="set_inputZuurselTijd"
                @changeMilk="recalcMilk"
                @newDateTime="emitNewDateTime"
            />
        </div>
        <h4>
            <b-badge variant="light" v-if="multipleItems && isMilk"
                >{{
                    $t('com_totaal', {
                        ns: 'common'
                    })
                }}
                = {{ biTotaal }}</b-badge
            >
        </h4>
    </b-card>
</template>

<script>
import _ from 'lodash';
import CardBakingredientLijstDetail from './CardBakingredientLijstDetail.vue';
import { INGRCAT_ENUM } from '../../models/IngredientCategorie';
//import { BIlijst } from '../../models/BakInfo_helper';

export default {
    name: 'CardBakingredientLijst',
    data() {
        return {
            aangeraakt: 1,
            rerenderKey: 1,
            newDateTime: undefined
        };
    },
    components: {
        CardBakingredientLijstDetail
    },
    model: {
        prop: 'bakingredientLijst',
        event: 'changedLijst'
    },
    props: {
        bakingredientLijst: {
            type: Array,
            required: true
        },
        editAllowed: {
            type: Boolean,
            default: false
        },
        baseDateTime: {
            type: String
        },
        preselected: {
            type: Number,
            default: -1
        }
    },
    computed: {
        biProductCat() {
            return this.bakingredientLijst[0].ingredientCategorieNaam;
        },
        isMilk() {
            return (
                this.bakingredientLijst[0].ingredientCategorieID ==
                INGRCAT_ENUM.Melk
            );
        },
        multipleItems() {
            return this.bakingredientLijst.length > 1;
        },
        recipeCatProducts() {
            return this.bakingredientLijst.map((x) => x.productID);
        },
        biTotaal() {
            // eslint-disable-next-line no-unused-vars
            const tel = this.aangeraakt;
            let tot = 0;
            for (let i = 0; i < this.bakingredientLijst.length; i++) {
                const bilijst = this.bakingredientLijst[i];
                const filteredlijst = bilijst.bakIngredienten.filter(
                    (x) =>
                        x.isDeleted == false &&
                        (x.nouse == undefined || x.nouse == false)
                );
                const tempArr = filteredlijst.map((x) => x.hoeveelheid);
                const prodAmount = _.sum(tempArr);
                tot += prodAmount;
            }
            return tot;
        }
    },
    methods: {
        recalcMilk() {
            this.aangeraakt += 1;
        },
        set_inputZuurselTijd(value) {
            let kleinste = value;
            for (let i = 0; i < this.bakingredientLijst.length; i++) {
                // de laagste zet de tijd
                if (this.bakingredientLijst[i].toegevoegdTijd) {
                    if (value < this.bakingredientLijst[i].toegevoegdTijd) {
                        // doe niets
                    } else {
                        if (
                            this.bakingredientLijst[i].toegevoegdTijd < kleinste
                        ) {
                            kleinste =
                                this.bakingredientLijst[i].toegevoegdTijd;
                        }
                    }
                }
            }
            if (value <= kleinste) {
                this.$emit('zuurseltijd', value);
            }
        },
        refresh_stocklist() {
            this.rerenderKey += 1;
        },
        emitNewDateTime(value) {
            this.newDateTime = value;
        }
    },
    mounted() {}
};
</script>
