<template>
    <b-card>
        <b-alert
            :show="dismissCountDown"
            v-model="showDismissibleAlert"
            :variant="variantType"
            dismissible
            @dismissed="resetVariant"
        >
            {{ alertText }}
        </b-alert>
        <b-card-text>
            <b-row class="mx-0 px-0 my-1 py-0 form-inline">
                <b-col style="width: 50%" class="mx-0 px-0 my-0 py-0">
                    <lookup-combo
                        :placeholder="
                            $t('com_kaassoort', {
                                ns: 'common'
                            })
                        "
                        v-model="selectedProductID"
                        v-bind:combotype="lctypeCheeses"
                        :doCheckState="true"
                        @change="setChanged"
                    ></lookup-combo>
                </b-col>
                <b-col class="mx-0 px-0 my-0 py-0" style="width: 30%">
                    <lookup-combo
                        :placeholder="
                            $t('prod_vorm', {
                                ns: 'production'
                            })
                        "
                        v-model="selectedShapeID"
                        v-bind:combotype="lctypeShapes"
                        :doCheckState="true"
                        @change="setChanged"
                    ></lookup-combo>
                </b-col>
                <b-col class="mx-0 px-0 my-0 py-0" style="width: 20%">
                    <b-input-group
                        :append="$t('com_st', { ns: 'common' })"
                        :label="$t('com_stuks', { ns: 'common' })"
                    >
                        <b-form-input
                            :placeholder="aantKaas"
                            type="number"
                            step="1"
                            v-model.number="selectedAmount"
                            :state="selectedAmount > 0 ? true : false"
                            @change="setChanged"
                        ></b-form-input>
                    </b-input-group>
                </b-col>
            </b-row>
            <b-row class="mx-0 px-0 my-1 py-0 form-inline">
                <b-input-group
                    style="width: 100%"
                    :prepend="$t('com_reference', { ns: 'common' })"
                    :label="$t('com_reference', { ns: 'common' })"
                >
                    <b-form-input
                        :placeholder="$t('com_reference', { ns: 'common' })"
                        v-model="klantRef"
                        @change="setChanged"
                    ></b-form-input>
                </b-input-group>
            </b-row>
            <b-row class="mx-0 px-0 my-1 py-0 form-inline">
                <b-col sm="6">
                    <b-input-group class="mb-2">
                        <b-input-group-prepend is-text>
                            <b-form-checkbox
                                v-model="gebruikProductiedatum"
                                @change="setChanged"
                            >
                                <span>{{
                                    $t('log_optdatum', { ns: 'logistics' })
                                }}</span>
                            </b-form-checkbox>
                        </b-input-group-prepend>
                        <b-form-datepicker
                            :min="new Date()"
                            v-model="productiedatum"
                            :date-format-options="{
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit'
                            }"
                            :disabled="!gebruikProductiedatum"
                            @input="setChanged"
                        ></b-form-datepicker>
                    </b-input-group>
                </b-col>
                <b-col sm="6">
                    <b-input-group class="mb-2">
                        <b-input-group-prepend is-text>
                            <b-form-checkbox
                                v-model="gebruikLeverdatum"
                                @change="setChanged"
                            >
                                <span>{{
                                    $t('log_leverdatum', { ns: 'logistics' })
                                }}</span>
                            </b-form-checkbox>
                        </b-input-group-prepend>
                        <b-form-datepicker
                            :min="minDate"
                            :max="maxDate"
                            v-model="leverdatum"
                            :date-format-options="{
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit'
                            }"
                            :disabled="!gebruikLeverdatum || forcedDelDate"
                            @input="setChanged"
                        ></b-form-datepicker>
                    </b-input-group>
                </b-col>
            </b-row>
            <b-row class="mx-0 px-0 my-1 py-0 form-inline">
                <b-col sm="9" />
                <b-col sm="1">
                    <b-form-checkbox
                        v-if="!isEdit"
                        v-model="oneTimeOnly"
                        class="float-right"
                    />
                </b-col>
                <b-col sm="2">
                    <b-button
                        @click="addItem"
                        class="bgc_aux_normal float-right"
                    >
                        {{ btnTekst }} &nbsp;<font-awesome-icon
                            icon="arrow-down"
                            size="1x"
                        />
                    </b-button>
                </b-col>
            </b-row>
        </b-card-text>
    </b-card>
</template>

<script>
import { OrderLine } from '@/models/Orders';
import { LookupCombo } from '../components';
import Helper from '../../models/Helper';
import DateHelper from '../../models/DateHelper';
export default {
    name: 'CardSalesOrder',
    data() {
        return {
            lctypeCheeses: window.constants.LCCHEESE,
            lctypeShapes: window.constants.LCSHAPES,
            approvedText: 'warning',
            dismissCountDown: 5,
            alertText: '',
            showDismissibleAlert: false,
            oneTimeOnly: true,
            selectedProductID: null,
            selectedShapeID: null,
            selectedAmount: 0,
            klantRef: '',
            productiedatum: new Date(),
            leverdatum: new Date(),
            minDate: new Date(),
            maxDate: null,
            gebruikLeverdatum: false,
            gebruikProductiedatum: false,
            regelteller: -1,
            changed: false
        };
    },
    components: {
        LookupCombo
    },
    props: {
        inItem: {
            type: OrderLine
        },
        delDate: {},
        editAllowed: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        btnTekst() {
            return this.isEdit || this.oneTimeOnly
                ? this.$t('com_onetime', { ns: 'common' })
                : this.$t('com_opslaan', { ns: 'common' });
        },
        aantKaas() {
            return Helper.stringformat(
                '{0} {1}',
                this.$t('com_number', { ns: 'common' }),
                this.$t('com_kazen', { ns: 'common' }).toLowerCase()
            );
        },
        variantType() {
            return this.approvedText;
        },
        isEdit() {
            return this.inItem.id > -1000;
        },
        forcedDelDate() {
            return this.delDate != null || this.forceDelDate;
        }
    },
    methods: {
        setDeldate() {
            return this.delDate ?? new Date();
        },
        showAlert(tekst) {
            this.dismissCountDown = 5;
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        resetVariant() {
            this.approvedText = 'warning';
        },
        addItem() {
            if (this.changed) {
                this.regelteller -= 1;
                const currentOrderLine = new OrderLine({
                    id: this.isEdit ? this.inItem.id : this.regelteller
                });
                if (this.isEdit) Object.assign(currentOrderLine, this.inItem);
                if (this.selectedProductID == null) {
                    this.showAlert(
                        this.$t('com_isrequired', {
                            ns: 'common',
                            val: '',
                            prop: this.$t('com_product', {
                                ns: 'common'
                            }).toLowerCase()
                        })
                    );
                    return;
                }
                if (this.selectedShapeID == null) {
                    this.showAlert(
                        this.$t('com_isrequired', {
                            ns: 'common',
                            val: '',
                            prop: this.$t('com_kaasvorm', {
                                ns: 'common'
                            }).toLowerCase()
                        })
                    );
                    return;
                }
                if (this.selectedAmount == null || this.selectedAmount <= 0) {
                    this.showAlert(
                        this.$t('com_isrequired', {
                            ns: 'common',
                            val: '',
                            prop: this.$t('com_amount', {
                                ns: 'common'
                            }).toLowerCase()
                        })
                    );
                    return;
                }
                if (!this.gebruikLeverdatum && !this.gebruikProductiedatum) {
                    this.showAlert(
                        this.$t('com_isrequired', {
                            ns: 'common',
                            val: '',
                            prop: this.$t('com_datum', {
                                ns: 'common'
                            }).toLowerCase()
                        })
                    );
                    return;
                }
                const product = this.$store.getters['components/getCheesebyID'](
                    this.selectedProductID
                );
                const vorm = this.$store.getters['components/getShapebyID'](
                    this.selectedShapeID
                );
                currentOrderLine.productID = this.selectedProductID;
                currentOrderLine.productNaam = product.text;
                currentOrderLine.vormID = this.selectedShapeID;
                currentOrderLine.vormNaam = vorm.text;
                currentOrderLine.klantRef = this.klantRef;
                currentOrderLine.vraagAantal = this.selectedAmount;
                currentOrderLine.vraagProddatum = this.gebruikProductiedatum
                    ? this.productiedatum
                    : null;
                if (this.gebruikLeverdatum) {
                    this.minDate = this.leverdatum;
                    this.maxDate = this.leverdatum;
                    this.forceDelDate = true;
                    currentOrderLine.vraagDeldatum = this.leverdatum;
                }

                this.emitObject(currentOrderLine);
            }
        },
        setChanged() {
            this.changed = true;
        },
        emitObject(o) {
            o.closeaction = !(this.isEdit || this.oneTimeOnly);
            this.$emit('changed', o);
            this.changed = false;
        }
    },
    mounted() {},
    created() {
        if (this.inItem) {
            this.selectedProductID = this.inItem.productID;
            this.selectedShapeID = this.inItem.vormID;
            this.selectedAmount = this.inItem.vraagAantal;
            this.klantRef = this.inItem.klantRef;
            if (this.inItem.vraagProddatum) {
                this.productiedatum = DateHelper.dateToString(
                    this.inItem.vraagProddatum
                );
                this.gebruikProductiedatum = true;
            }
            if (this.inItem.vraagDeldatum) {
                this.leverdatum = DateHelper.dateToString(
                    this.inItem.vraagDeldatum
                );
                this.gebruikLeverdatum = true;
            }
            if (this.delDate) {
                //const max=  setDate(new Date().getDate() + 365),;
                // en check of de datum klopt
                const min = DateHelper.convertedToDateTimeJS(this.delDate);
                const max = DateHelper.convertedToDateTimeJS(this.delDate);
                this.minDate = min;
                this.maxDate = max;
                this.leverdatum = DateHelper.dateToString(min);
            }
        }
    }
};
</script>

