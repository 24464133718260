<template>
    <b-container fluid style="margin-top: 60px">
        <b-row class="form-inline mx-0 px-0 my-0 py-0">
            <b-col sm="10" class="form-inline mx-0 px-0 my-0 py-0">
                <h1 v-on:click="flipClosed">
                    {{ title }} &nbsp;
                    <b-button>
                        {{ btnTekst }}
                    </b-button>
                </h1>
                &nbsp;
                <b-button
                    v-if="!isVersion10"
                    @click="flipKaas()"
                    class="bgc_aux_normal"
                    >{{ btnFlipKaas }}
                </b-button>
                &nbsp;
                <b-button @click="scan()" class="bgc_aux_unimportant"
                    ><font-awesome-icon icon="barcode" size="1x" />
                </b-button>
                &nbsp;
                <b-button
                    v-if="!useExternals"
                    @click="makeList()"
                    class="bgc_aux_unimportant"
                    ><font-awesome-icon icon="edit" size="1x" />
                </b-button>
                &nbsp;
            </b-col>
            <b-col sm="2" class="mx-0 px-0 my-0 py-0">
                <b-form-checkbox class="float-right" v-model="landscape"
                    >{{ $t('com_landscape', { ns: 'common' }) }}
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12">
                <div v-if="showFilter">
                    <b-row no-gutters>
                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_filter', { ns: 'common' })"
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                        id="filter-input"
                                        v-model="filter"
                                        type="search"
                                        :placeholder="
                                            $t('com_typetosearch', {
                                                ns: 'common'
                                            })
                                        "
                                    ></b-form-input>

                                    <b-input-group-append>
                                        <b-button
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            >{{
                                                $t('com_wis', { ns: 'common' })
                                            }}</b-button
                                        >
                                    </b-input-group-append>
                                </b-input-group>

                                <b-input-group size="sm">
                                    <b-button
                                        v-if="
                                            !gesloten &&
                                            !useExternals &&
                                            checkRight('StockIn') &&
                                            !isVersion10
                                        "
                                        size="sm"
                                        @click="createNewItem"
                                        class="mr-1"
                                        ><font-awesome-icon
                                            icon="folder-plus"
                                            size="1x"
                                        />
                                        {{ strNieuw }}
                                    </b-button>
                                    &nbsp;

                                    <b-form-checkbox
                                        v-if="!this.showkaas && !isVersion10"
                                        v-model="filterTHT"
                                        value="dofiltertht"
                                        unchecked-value="dontfiltertht"
                                        :disabled="!enableTHT"
                                    >
                                        {{ strTHTkritisch }}
                                    </b-form-checkbox>
                                    &nbsp;

                                    <b-form-checkbox
                                        v-if="!this.showkaas && !isVersion10"
                                        v-model="filterExpirationdate"
                                        value="dofilterexp"
                                        unchecked-value="dontfilterexp"
                                        :disabled="!enableEXP"
                                    >
                                        {{ strEXPkritisch }}
                                    </b-form-checkbox>
                                </b-input-group>
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_perpagina', { ns: 'common' })"
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                ></b-form-select>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                ></b-pagination>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div v-else>
                    <b-input-group>
                        <b-button
                            v-if="
                                !gesloten &&
                                !useExternals &&
                                checkRight('StockIn') &&
                                !isVersion10
                            "
                            size="sm"
                            @click="createNewItem"
                            class="mr-1"
                            ><font-awesome-icon icon="folder-plus" size="1x" />
                            {{ strNieuw }}
                        </b-button>
                        &nbsp;

                        <b-form-checkbox
                            v-if="!this.showkaas && !isVersion10"
                            v-model="filterTHT"
                            value="dofiltertht"
                            unchecked-value="dontfiltertht"
                            :disabled="!enableTHT"
                        >
                            {{ strTHTkritisch }}
                        </b-form-checkbox>
                        &nbsp;

                        <b-form-checkbox
                            v-if="!this.showkaas && !isVersion10"
                            v-model="filterExpirationdate"
                            value="dofilterexp"
                            unchecked-value="dontfilterexp"
                            :disabled="!enableEXP"
                        >
                            {{ strEXPkritisch }}
                        </b-form-checkbox>
                    </b-input-group>
                </div>

                <b-table
                    :items="groupedDisplayList"
                    :fields="visibleFields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    stacked="md"
                    show-empty
                    :empty-text="$t('com_geen_data', { ns: 'common' })"
                    small
                    striped
                    @filtered="onFiltered"
                    @row-clicked="toonVoorraad"
                >
                    <template v-slot:cell(actions)="row">
                        <b-button
                            v-if="
                                !gesloten &&
                                !pekel(row.item) &&
                                !useExternals &&
                                !tienplus(row.item) &&
                                checkRight('StockIn') &&
                                !isVersion10
                            "
                            size="sm"
                            @click="addItem(row.item)"
                            class="bgc_aux_normal"
                            ><font-awesome-icon icon="folder-plus" size="1x" />
                        </b-button>
                        <b-button
                            size="sm"
                            @click="toonVoorraad(row.item)"
                            class="bgc_aux_unknown"
                            ><font-awesome-icon icon="info-circle" size="1x" />
                        </b-button>
                    </template>

                    <template v-slot:row-details>
                        <stock-items
                            v-bind:stocklist="stockList"
                            v-bind:gesloten="gesloten"
                            v-bind:pekel="isInPekel"
                            v-bind:tienplus="isTienPLus"
                            v-bind:tienpluskaas="isTienPLusKaas"
                            v-bind:landscape="landscape"
                        />
                    </template>
                </b-table>
            </b-col>
        </b-row>

        <item-stock-modal
            v-if="detailsVisible"
            :inItem="currentItem"
            v-model="detailsVisible"
            v-bind:editAllowed="true"
            @hide="resetISM"
        />
        <scan-barcode
            v-if="showScanner"
            v-model="showScanner"
            @result="showDetailStock"
            @hide="resetSB"
        />
        <item-details-modal
            v-if="showDetails"
            v-model="showDetails"
            :stockItemId="itemNumber"
            @hide="resetIDM"
        />
        <quick-list-modal
            v-if="makeDelList"
            v-model="makeDelList"
            @hide="reset"
        />
    </b-container>
</template>

<script>
import _ from 'lodash';
import { VoorraadItem } from '../../models/Voorraad';
import { PRODCAT_ENUM } from '../../models/ProductCategorie';
import StockItems from './StockItems';
import QuickListModal from './QuickListModal';
import ItemStockModal from './ItemStockModal';
import ItemDetailsModal from './ItemDetailsModal';
import ScanBarcode from '../components/ScanBarcode.vue';
import { Permission } from '../../models/Permissions';
import Helper from '@/models/Helper';
import DateHelper from '../../models/DateHelper';
import { loadItem, loadList } from '@/models/DataHelper';
import { Feature } from '../../models/Features';

export default {
    name: 'StockInfoBase',
    data: function () {
        return {
            tienpluscons: window.constants.TIENPLUS,
            tienpluskaas: window.constants.TIENPLUSKAAS,
            inpekel: this.$t('prod_pekelbad', { ns: 'production' }),
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            currentItem: null,
            detailsVisible: false,
            gesloten: false,
            isInPekel: false,
            isTienPLus: false,
            showkaas: false,
            showScanner: false,
            landscape: false,
            makeDelList: false,
            showDetails: false,
            inpekelLijst: {},
            localSIlist: [],
            displayFilterCount: 10,
            itemNumber: null,
            filterTHT: 'dontfiltertht',
            filterExpirationdate: 'dontfilterexp',
            enableTHT: true,
            enableEXP: true,
            inpekelstatus: this.$t('prod_inpekel', { ns: 'production' }),
            strTHTkritisch:
                this.$t('com_tht', {
                    ns: 'common'
                }) +
                ' ' +
                this.$t('com_kritisch', {
                    ns: 'common'
                }).toLowerCase(),
            strEXPkritisch:
                this.$t('com_verloopdatum', {
                    ns: 'common'
                }) +
                ' ' +
                this.$t('com_bijlage', {
                    ns: 'common'
                }).toLowerCase() +
                ' ' +
                this.$t('com_kritisch', {
                    ns: 'common'
                }).toLowerCase()
        };
    },
    props: {},
    components: {
        StockItems,
        ItemStockModal,
        ScanBarcode,
        ItemDetailsModal,
        QuickListModal
    },
    computed: {
        strNieuw() {
            return this.$t('com_newx', {
                ns: 'common',
                val: this.$t('prod_stockitem', {
                    ns: 'production'
                })
            });
        },
        title() {
            return this.gesloten
                ? this.$t('com_gesloten', {
                      ns: 'common',
                      val: this.$t('com_stock', {
                          ns: 'common'
                      }).toLowerCase()
                  })
                : this.$t('com_beschikbaar', {
                      ns: 'common',
                      val: this.$t('com_stock', {
                          ns: 'common'
                      }).toLowerCase()
                  });
        },
        btnTekst() {
            return Helper.stringformat(
                '{0} {1}',
                this.$t('com_show', { ns: 'common' }),
                this.gesloten
                    ? this.$t('com_open', { ns: 'common' }).toLowerCase()
                    : this.$t('com_gesloten', {
                          ns: 'common',
                          val: ''
                      }).toLowerCase()
            );
        },
        btnFlipKaas() {
            const toon = this.$t('com_show', { ns: 'common' });
            if (this.isVersion10)
                return Helper.stringformat(
                    '{0} {1}',
                    toon,
                    this.$t('com_stock', {
                        ns: 'common'
                    }).toLowerCase()
                );
            return Helper.stringformat(
                '{0} {1}',
                toon,
                this.showkaas
                    ? this.$t('com_overige', {
                          ns: 'common'
                      }).toLowerCase()
                    : this.$t('com_kaas', {
                          ns: 'common'
                      }).toLowerCase()
            );
        },
        showFilter() {
            return this.displayList
                ? this.displayList.length > this.displayFilterCount
                : false;
        },
        isVersion10() {
            return (
                this.$store.getters['masterdata/getPackageVersion'] ==
                window.constants.VERSION10
            );
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'cat',
                    label: this.$t('com_categorie', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'naam',
                    label: this.$t('com_product', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'vorm',
                    label: this.$t('prod_vorm', { ns: 'production' }),
                    sortable: true,
                    visible: this.showkaas
                },
                {
                    key: 'stockitems.length',
                    label: this.$t('com_number', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'hh',
                    label: this.$t('com_amount', { ns: 'common' }),
                    sortable: true,
                    visible: true,
                    tdClass: (value) =>
                        value < 0 ? 'text-danger' : 'text-success'
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        displayList() {
            const tempList = this.gesloten
                ? this.$store.getters['stock/getStockInfoArchived']
                : this.$store.getters['stock/getStockInfo'];
            tempList.forEach((t) => {
                this.$set(t, '_showDetails', false);
            });
            let result = null;
            if (this.showkaas) {
                result = tempList.filter(
                    (x) =>
                        x.prodcatID == PRODCAT_ENUM.Kaas ||
                        x.prodcatID == PRODCAT_ENUM.Onvolwaardig
                );
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.totalRows = result ? result.length : 0;
                return result;
            } else {
                result = tempList.filter(
                    (x) =>
                        x.prodcatID != PRODCAT_ENUM.Kaas &&
                        x.prodcatID != PRODCAT_ENUM.Onvolwaardig
                );
            }
            this.disableCheckboxes();
            const result2 =
                this.filterTHT == 'dofiltertht'
                    ? result.filter((x) => {
                          const vandaag = new Date();
                          const dagen = DateHelper.dateDiffinDays(
                              x.datum,
                              vandaag
                          );
                          const waarschuw =
                              dagen < window.constants.ALARM_THTDATE
                                  ? true
                                  : false;
                          return waarschuw;
                      })
                    : this.filterExpirationdate == 'dofilterexp'
                    ? result.filter((x) => {
                          return x.docstatus > 0;
                      })
                    : result;

            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.totalRows = result2 ? result2.length : 0;
            return result2;
        },
        groupedDisplayList() {
            if (this.displayList == undefined) return null;
            const x = _(this.displayList.slice(0))
                .groupBy((x) => `"${x.vorm}+${x.naam}"`)
                .map((value, key) => {
                    const quantity = Math.round(_.sumBy(value, 'aantal'));
                    const loccat = value[0].categorie;
                    const prodcat = value[0].prodcatNaam;
                    const prodcatID = value[0].prodcatID;
                    const ingrcat = value[0].ingrcatNaam;
                    const locid = value[0].id;
                    const vorm = value[0].vorm;
                    let naam = value[0].naam;
                    const tientype = value[0].productType;
                    if (tientype == window.constants.TIENPLUSKAAS) {
                        naam = Helper.stringformat(
                            '{0} - ({1})',
                            naam,
                            vorm ?? '---'
                        );
                    }
                    return {
                        naam: naam,
                        vorm: vorm,
                        cat: loccat,
                        prodcat: prodcat,
                        prodcatID: prodcatID,
                        ingrcat: ingrcat,
                        hh: quantity,
                        id: locid,
                        stockitems: value
                    };
                })
                .value();
            const y = _.sortBy(x, ['cat', 'naam']);
            if (this.showkaas) {
                const lijst = this.inpekelLijst;
                if (this.filter)
                    lijst.stockitems = Helper.filterByValue(
                        lijst.stockitems,
                        this.filter
                    );
                y.push(lijst);
            }
            return y;
        },
        stockList() {
            return this.localSIlist;
        },
        useExternals() {
            return this.checkFeature('conf_useExternalConnections');
        }
    },
    methods: {
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return this.$root.handleFeature(key);
        },
        pekel(item) {
            return item.prodcat == this.inpekel ? true : false;
        },
        tienplus(item) {
            return item.prodcat == this.tienpluscons ? true : false;
        },
        flipClosed() {
            this.gesloten = !this.gesloten;
            this.reloadData();
        },
        flipKaas() {
            if (this.isVersion10) return;
            this.showkaas = !this.showkaas;
            this.filter = null;
            this.reloadData();
        },
        scan() {
            this.showScanner = !this.showScanner;
        },
        makeList() {
            this.makeDelList = !this.makeDelList;
        },
        async getDetailItem(item) {
            return await loadItem(
                'stock/loadStockItem',
                {
                    stockitemid: item.id
                },
                this.$t
            );
        },
        createNewItem() {
            this.currentItem = new VoorraadItem();
            if (this.showkaas) this.currentItem.prodcatID = PRODCAT_ENUM.Kaas;
            this.currentItem.baseUnit = '';
            this.detailsVisible = true;
        },
        async addItem(item) {
            const t = await this.getDetailItem(item);
            const oUnit = this.$store.getters['components/getUnitbyID'](
                t.unitID
            );
            this.currentItem = new VoorraadItem();
            this.currentItem.prodcatNaam = item.prodcat;
            this.currentItem.prodcatID = item.prodcatID;
            this.currentItem.ingrcatNaam = item.ingrcat;
            this.currentItem.categorie = item.categorie;
            this.currentItem.naam = item.naam;
            this.currentItem.eenheid = item.stockitems[0].eenheid;
            this.currentItem.locatie = item.stockitems[0].locatie;

            this.currentItem.productID = t.productID;
            this.currentItem.unitID = t.unitID;
            this.currentItem.baseUnit = oUnit
                ? oUnit.base ?? oUnit.value
                : t.unitID;
            this.currentItem.locationID = t.locationID;
            this.currentItem.supplierID = t.supplierID;
            this.detailsVisible = true;
        },
        toonVoorraad(row) {
            if (row._showDetails) {
                this.$set(row, '_showDetails', false);
            } else {
                this.groupedDisplayList.forEach((row) => {
                    this.$set(row, '_showDetails', false);
                });

                const item = row.item ? row.item : row;
                this.isInPekel = this.pekel(item);
                this.isTienPLus = this.tienplus(item);
                this.isTienPLusKaas =
                    item.stockitems[0].productType != undefined &&
                    item.stockitems[0].productType == this.tienpluskaas;
                this.localSIlist = item.stockitems;
                this.$set(row, '_showDetails', true);
            }
        },
        reset() {
            this.makeDelList = false;
        },
        resetSB() {
            this.showScanner = false;
        },
        resetIDM() {
            this.showDetails = false;
        },
        resetISM() {
            this.currentItem = null;
            this.detailsVisible = false;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        disableCheckboxes() {
            if (this.filterTHT == 'dofiltertht') {
                this.enableEXP = false;
                this.enableTHT = true;
            } else if (this.filterExpirationdate == 'dofilterexp') {
                this.enableEXP = true;
                this.enableTHT = false;
            } else {
                this.enableEXP = true;
                this.enableTHT = true;
            }
        },
        reloadData() {
            if (this.gesloten) {
                loadItem('stock/loadStockInfoArchived', null, this.$t);
            } else {
                loadItem('stock/loadStockInfo', null, this.$t);
                if (!this.isVersion10) this.loadInpekelList();
            }
        },
        async loadInpekelList() {
            const lijst = await loadItem(
                'stock/loadInBrineInfo',
                null,
                this.$t
            );
            lijst.map((x) => (x.categorie = this.inpekel));
            lijst.map((x) => (x.prodcatNaam = this.inpekel));
            lijst.map((x) => (x.status = this.inpekelstatus));
            lijst.map((x) => (x.eenheid = 'Stuk'));
            //lijst.map(x=>x.locid = ??);
            const hhlijst = lijst.map((x) => x.aantal);
            const quantity = Math.round(Helper.sumList(hhlijst));
            const loccat = this.inpekel;
            const prodcat = this.inpekel;
            const ingrcat = null;
            const locid = -1;
            const vorm = '-';
            const naam = '-';
            this.inpekelLijst = {
                naam: naam,
                vorm: vorm,
                cat: loccat,
                prodcat: prodcat,
                ingrcat: ingrcat,
                hh: quantity,
                id: locid,
                stockitems: lijst
            };
        },
        showDetailStock(value) {
            if (value == undefined) return;
            this.itemNumber = Number(value);
            this.showDetails = !this.showDetails;
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.displayList ? this.displayList.length : 0;
        this.landscape = this.checkFeature('conf_labelLandscape');
    },
    created() {
        loadList(
            'product/getProductCategorieLijst',
            'product/loadProductCategories',
            null,
            false,
            this.$t
        );
        loadItem('stock/loadStockInfo', null, this.$t);
        if (!this.isVersion10) this.loadInpekelList();
    }
};
</script>

<style scoped></style>
