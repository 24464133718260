var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.toonSelectie)?_c('div',{staticClass:"form-inline"},[_c('b-col',{staticClass:"mx-0 px-0 my-0 py-0",attrs:{"sm":"2"}},[_c('b-input-group',[(!_vm.isKlaarZetten)?_c('b-form-timepicker',{class:_vm.giveBGclass,attrs:{"locale":"nl","disabled":!_vm.editAllowed},on:{"input":_vm.set_inputTijd},model:{value:(_vm.localTijd),callback:function ($$v) {_vm.localTijd=$$v},expression:"localTijd"}}):_vm._e()],1)],1),_c('b-col',{staticClass:"mx-0 px-0 my-0 py-0",attrs:{"sm":"4"}},[_c('b-input-group',{attrs:{"prepend":_vm.giveNaam}},[(!_vm.existing)?_c('b-form-select',{class:{ tc_aux_highlighted: _vm.warnForStockAmount },attrs:{"disabled":!_vm.editAllowed},on:{"change":_vm.set_inputPartij},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":""}},[_vm._v("-- "+_vm._s(_vm.$t('com_selecteer', { ns: 'common' }))+" --")])]},proxy:true}],null,false,2446721565),model:{value:(_vm.partijID),callback:function ($$v) {_vm.partijID=$$v},expression:"partijID"}},_vm._l((_vm.itemStockList),function(option){return _c('option',{key:option.value,class:{ tc_aux_highlighted: option.warn },domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0):_vm._e(),(_vm.existing)?_c('b-form-input',{attrs:{"readonly":""},model:{value:(_vm.localSelected.partijNummer),callback:function ($$v) {_vm.$set(_vm.localSelected, "partijNummer", $$v)},expression:"localSelected.partijNummer"}}):_vm._e()],1)],1),_c('b-col',{staticClass:"mx-0 px-0 my-0 py-0",attrs:{"sm":"3"}},[_c('b-input-group',{attrs:{"append":"","label":_vm.$t('com_amount', { ns: 'common' })}},[_c('b-form-input',{style:(_vm.localSelected.isNew
                        ? _vm.localSelected.isChanged
                            ? ''
                            : {
                                  'background-color': '#33FF90',
                                  color: '#33FF90'
                              }
                        : ''),attrs:{"id":"input-hoeveelheid","placeholder":_vm.$t('com_amount', { ns: 'common' }),"type":"number","step":"1","disabled":!_vm.editAllowed},on:{"change":_vm.set_input},model:{value:(_vm.localSelected.hoeveelheid),callback:function ($$v) {_vm.$set(_vm.localSelected, "hoeveelheid", _vm._n($$v))},expression:"localSelected.hoeveelheid"}})],1)],1),_c('b-col',{staticClass:"mx-0 px-0 my-0 py-0",attrs:{"sm":"3"}},[_c('lookup-combo',{attrs:{"combotype":_vm.lctypeUnits,"discrim":_vm.baseUnitString,"disabled":!_vm.editAllowed},on:{"change":_vm.set_input},model:{value:(_vm.localSelected.eenheidID),callback:function ($$v) {_vm.$set(_vm.localSelected, "eenheidID", $$v)},expression:"localSelected.eenheidID"}})],1),(_vm.detailsVisible)?_c('maintenance-pop-form',{attrs:{"displayNumber":_vm.selectedRadio,"editAllowed":true},on:{"hide":_vm.reset},model:{value:(_vm.detailsVisible),callback:function ($$v) {_vm.detailsVisible=$$v},expression:"detailsVisible"}}):_vm._e()],1):_c('div',[_vm._v(_vm._s(_vm.$t('com_createstock', { ns: 'common' })))])
}
var staticRenderFns = []

export { render, staticRenderFns }