import DateHelper from './DateHelper';
import { RecurrencyPattern, RecurrencyModel } from './Recurrency';
import Helper from './Helper';
export {
    EntityObject,
    TemplateObject,
    TemplateView,
    QuantityView,
    QunitView,
    QLabelView,
    QualityView,
    ExecutorQView,
    MaesurableIQView,
    InvestigationQuantity,
    Maesurement,
    MaesureObject,
    AssesmentObject,
    NormObject,
    NormView,
    Assesment,
    ExecutorBaseView,
    Device,
    Organisation,
    Person,
    SampleObject,
    SampleType,
    ReportGroup
};
class EntityObject {
    /**
     * Assign the data to a new EntityObject instance
     *
     * @param data
     */
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        this.updated = null;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.eo_id ?? data.id,
                writable: false
            },
            gewijzigd: {
                value: data.updated,
                writable: false
            },
            naam: {
                type: String,
                value: data.description,
                writable: true,
                enumerable: true
            },
            typenaambasis: {
                type: String,
                value: data.typeName ?? data.typename,
                writable: true,
                enumerable: true
            },
            typenaam: {
                type: String,
                value: data.translatedTypeName ?? data.translatedtypename,
                writable: true,
                enumerable: true
            },
            veldid: {
                type: Number,
                value: data.keynummeric ?? data.key,
                writable: true,
                enumerable: true
            },
            veldref: {
                type: String,
                value: data.keystring ?? data.keytext,
                writable: true,
                enumerable: true
            },
            ref: {
                value: null,
                writable: true,
                enumerable: true
            },
            barcode: {
                type: String,
                value: data.barcode,
                writable: true,
                enumerable: true
            },
            template: {
                type: TemplateObject,
                value: data.templateobject,
                writable: true,
                enumerable: true
            },
            templateID: {
                type: Number,
                value: data.to_id ?? data.templateobjectid,
                writable: true,
                enumerable: true
            },
            referentieDatum: {
                type: String,
                value: data.referenceDate ?? data.referencedate,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
        this.ref = this.veldref ?? this.veldid;
    }

    SetForAdd() {
        const RegisterEntityObjectModel = {
            Key: this.veldid,
            KeyText: this.veldref,
            TypeName: this.typenaambasis,
            Description: this.naam,
            TemplateObjectID: this.templateID,
            CreateBarcode: true
        };
        return RegisterEntityObjectModel;
    }
    SetForDelete() {
        //TODO
        const RemoveBatchIngredientModel = {
            batchid: this.bakID ?? this.bakId,
            id: this.id,
            lastupdated: this.gewijzigd
        };
        return RemoveBatchIngredientModel;
    }

    CheckValidity(func) {
        let warnText = '';
        if (!this.veldref && !this.naam) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('com_naam', { ns: 'common' })
                })
            );
        } else {
            if (this.veldref?.length < 2 && this.naam?.length < 2) {
                warnText = Helper.concatString(
                    warnText,
                    func('com_xlangerdan', {
                        ns: 'common',
                        val: '3',
                        prop: func('com_naam', { ns: 'common' })
                    })
                );
            }
        }
        if (!this.typenaambasis) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('com_categorie', { ns: 'common' })
                })
            );
        }
        return warnText;
    }
}

class Assesment {
    /**
     * Assign the data to a new Assesment instance
     * naam?
     *
     * @param data
     */
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        this.updated = null;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false
            },
            gewijzigd: {
                value: data.updated,
                writable: false
            },
            naam: {
                type: String,
                value: data.description ?? data.quality,
                writable: true,
                enumerable: true
            },
            opmerking: {
                type: String,
                value: data.remark,
                writable: true,
                enumerable: true
            },
            melding: {
                type: String,
                value: data.alert,
                writable: true,
                enumerable: true
            },
            boektijdstip: {
                type: String,
                value: data.bookDate ?? data.bookdate,
                writable: true,
                enumerable: true
            },
            meettijdstip: {
                type: String,
                value: data.valueDate ?? data.valuedate,
                writable: true,
                enumerable: true
            },
            waarde: {
                type: Number,
                value: data.value,
                writable: true,
                enumerable: true
            },
            validated: {
                type: Boolean,
                value: data.validated,
                writable: true,
                enumerable: true
            },
            status: {
                type: Number,
                value: data.status,
                writable: true,
                enumerable: true
            },
            monsterID: {
                type: Number,
                value: data.sampleID,
                writable: true,
                enumerable: true
            },
            labelID: {
                type: Number,
                value: data.qLabelID,
                writable: true,
                enumerable: true
            },
            labelNaam: {
                type: String,
                value: data.label ?? data.qLabel,
                writable: true,
                enumerable: true
            },
            uitvoerderID: {
                type: Number,
                value: data.executorID,
                writable: true,
                enumerable: true
            },
            grootheidID: {
                type: Number,
                value: data.qualityID,
                writable: true,
                enumerable: true
            },
            grootheidNaam: {
                type: String,
                value: data.quality,
                writable: true,
                enumerable: true
            },
            meetplekID: {
                type: Number,
                value: data.entityObjectID,
                writable: true,
                enumerable: true
            },
            meetplekNaam: {
                type: String,
                value: data.entityobject ?? data.entityObject,
                writable: true,
                enumerable: true
            },
            linkedlabels: {
                type: Array(Number),
                value: data.linkedQLabelIDs ?? [],
                writable: true,
                enumerable: true
            },
            meetplekEO:  {
                type: EntityObject,
                value: null,
                writable: true,
                enumerable: true
            },
            laatsteKeer: {
                type: String,
                value: data.recurrency?.lastOccurrence,
                writable: true,
                enumerable: true
            },
            volgendeKeer: {
                type: String,
                value: data.recurrency?.nextOccurrence,
                writable: true,
                enumerable: true
            },
            herhaling: {
                type: RecurrencyModel,
                value: data.recurrency
                    ? new RecurrencyModel(data.recurrency)
                    : null,
                writable: true,
                enumerable: true
            },
        });
        this.isNew = this.id ? this.id < 0 : true;
    }

    clearID() {
        this.id = -1;
        this.updated = null;
        this.isNew = true;
    }

    SetForAdd() {
        const AssesmentModel = {
            ID: this.id,
            newEnityObject: this.meetplekEO,
            EntityObjectID: this.meetplekID,
            QualityID: this.grootheidID,
            ExecutorID: this.uitvoerderID,
            QLabelID: this.labelID,
            SampleID: this.monsterID,
            Status: this.status,
            Validated: this.validated,
            Value: this.waarde,
            Remark: this.opmerking,
            BookDate: DateHelper.localDateToUTC(this.boektijdstip),
            ValueDate: DateHelper.localDateToUTC(this.meettijdstip),
            LinkedQLabelIDs: this.linkedlabels,
            Recurrency: this.herhaling ? this.herhaling.SetForAdd() : null
        };
        return AssesmentModel;
    }

    CheckValidity(func) {
        let warnText = '';
        if (!this.meetplekID) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('an_eo', { ns: 'analytics' })
                })
            );
        }
        if (!this.uitvoerderID) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('an_uitvoerder', { ns: 'analytics' })
                })
            );
        }
        if (!this.grootheidID) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('an_grootheid', { ns: 'analytics' })
                })
            );
        }
        if (!this.labelID) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('an_result', { ns: 'analytics' })
                })
            );
        }
        return warnText;
    }
}
class Maesurement {
    /**
     * Assign the data to a new Maesurement instance
     *
     * @param data
     */
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        this.updated = null;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false
            },
            gewijzigd: {
                value: data.updated,
                writable: false
            },
            naam: {
                type: String,
                value: data.description ?? data.invquantity,
                writable: true,
                enumerable: true
            },
            opmerking: {
                type: String,
                value: data.remark,
                writable: true,
                enumerable: true
            },
            melding: {
                type: String,
                value: data.alert,
                writable: true,
                enumerable: true
            },
            boektijdstip: {
                type: String,
                value: data.bookDate ?? data.bookdate,
                writable: true,
                enumerable: true
            },
            meettijdstip: {
                type: String,
                value: data.valueDate ?? data.valuedate,
                writable: true,
                enumerable: true
            },
            waarde: {
                type: Number,
                value: data.value,
                writable: true,
                enumerable: true
            },
            validated: {
                type: Boolean,
                value: data.validated,
                writable: true,
                enumerable: true
            },
            status: {
                type: Number,
                value: data.status,
                writable: true,
                enumerable: true
            },
            monsterID: {
                type: Number,
                value: data.sampleID,
                writable: true,
                enumerable: true
            },
            eenheidID: {
                type: Number,
                value: data.qUnitID,
                writable: true,
                enumerable: true
            },
            eenheidNaam: {
                type: String,
                value: data.unit ?? data.Qunit,
                writable: true,
                enumerable: true
            },
            uitvoerderID: {
                type: Number,
                value: data.executorID,
                writable: true,
                enumerable: true
            },
            onderzoeksgrootheidID: {
                type: Number,
                value: data.investigationQuantityID,
                writable: true,
                enumerable: true
            },
            onderzoeksgrootheidNaam: {
                type: String,
                value:
                    data.invQuantity ??
                    data.invquantity ??
                    data.investigationQuantity,
                writable: true,
                enumerable: true
            },
            meetplekID: {
                type: Number,
                value: data.entityObjectID,
                writable: true,
                enumerable: true
            },
            meetplekNaam: {
                type: String,
                value: data.entityobject ?? data.entityObject,
                writable: true,
                enumerable: true
            },
            maxlimiet: {
                type: Number,
                value: data.maxlimit ?? data.maximumLimit,
                writable: true,
                enumerable: true
            },
            minlimiet: {
                type: Number,
                value: data.minlimit ?? data.minimumLimit,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id ? this.id < 0 : true;
    }

    clearID() {
        this.id = -1;
        this.updated = null;
        this.isNew = true;
    }

    SetForAdd() {
        const MeasurementModel = {
            ID: this.id,
            EntityObjectID: this.meetplekID,
            InvestigationQuantityID: this.onderzoeksgrootheidID,
            ExecutorID: this.uitvoerderID,
            QUnitID: this.eenheidID,
            SampleID: this.monsterID,
            Status: this.status,
            Validated: this.validated,
            Value: this.waarde,
            Remark: this.opmerking,
            MinimumLimit: this.minlimiet,
            MaximumLimit: this.maxlimiet,
            BookDate: DateHelper.localDateToUTC(this.boektijdstip),
            ValueDate: DateHelper.localDateToUTC(this.meettijdstip)
        };
        return MeasurementModel;
    }

    CheckValidity(func) {
        let warnText = '';
        if (!this.meetplekID) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('an_eo', { ns: 'analytics' })
                })
            );
        }
        if (!this.onderzoeksgrootheidID) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('an_grootheid', { ns: 'analytics' })
                })
            );
        }
        if (!this.uitvoerderID) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('an_uitvoerder', { ns: 'analytics' })
                })
            );
        }
        if (!this.eenheidID) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('com_unit', { ns: 'common' })
                })
            );
        }
        if (!this.waarde) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('an_result', { ns: 'analytics' })
                })
            );
        }
        return warnText;
    }
}

class MaesureObject {
    constructor() {
        Object.defineProperties(this, {
            hoeveelheid: {
                type: Number,
                value: null,
                writable: true,
                enumerable: true
            },
            grootheidid: {
                type: Number,
                value: -1,
                writable: true,
                enumerable: true
            },
            eenheidid: {
                type: Number,
                value: -1,
                writable: true,
                enumerable: true
            }
        });
    }
}

class AssesmentObject {
    constructor() {
        Object.defineProperties(this, {
            uitslag: {
                type: Number,
                value: null,
                writable: true,
                enumerable: true
            },
            qualityid: {
                type: Number,
                value: -1,
                writable: true,
                enumerable: true
            },
            qlabelid: {
                type: Number,
                value: -1,
                writable: true,
                enumerable: true
            },
            linkedlabels: {
                type: Array(Number),
                value: [],
                writable: true,
                enumerable: true
            }
        });
    }
}
class NormObject {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false
            },
            gewijzigd: {
                value: data.updated,
                writable: false
            },
            meetplekID: {
                type: Number,
                value: data.entityobjectID ?? data.entityObjectID,
                writable: true,
                enumerable: true
            },
            meetplekNaam: {
                type: String,
                value: data.entityobject ?? data.entityObject,
                writable: true,
                enumerable: true
            },
            onderzoeksgrootheidID: {
                type: Number,
                value: data.invQuantityID ?? data.investigationQuantityID,
                writable: true,
                enumerable: true
            },
            onderzoeksgrootheidNaam: {
                type: String,
                value: data.invQuantity ?? data.investigationQuantity,
                writable: true,
                enumerable: true
            },
            basisProductie: {
                type: Boolean,
                value: data.productionValue,
                writable: true,
                enumerable: true
            },
            normwaarde: {
                type: Number,
                value: data.normValue,
                writable: true,
                enumerable: true
            },
            minwaarde: {
                type: Number,
                value: data.minValue,
                writable: true,
                enumerable: true
            },
            maxwaarde: {
                type: Number,
                value: data.maxValue,
                writable: true,
                enumerable: true
            },
            actie: {
                type: String,
                value: data.action,
                writable: true,
                enumerable: true
            },
            formule: {
                type: String,
                value: data.limitsFormula,
                writable: true,
                enumerable: true
            },
            formuleNorm: {
                type: String,
                value: data.normFormula,
                writable: true,
                enumerable: true
            },
            laatsteKeer: {
                type: String,
                value: data.recurrency?.lastOccurrence,
                writable: true,
                enumerable: true
            },
            volgendeKeer: {
                type: String,
                value: data.recurrency?.nextOccurrence,
                writable: true,
                enumerable: true
            },
            herhaling: {
                type: RecurrencyModel,
                value: data.recurrency ? new RecurrencyModel(data.recurrency) :  null,
                writable: true,
                enumerable: true
            }
        });
    }

    SetForAdd() {
        const EONormModel = {
            ID: this.id,
            Updated: this.gewijzigd,
            InvestigationQuantityID: this.onderzoeksgrootheidID,
            EntityObjectID: this.meetplekID,
            NormValue: this.normwaarde,
            LimitFormula: String(this.formule),
            NormFormula: this.formuleNorm,
            Action: this.actie,
            Recurrency: this.herhaling ? this.herhaling.SetForAdd() : null
        };
        return EONormModel;
    }
    CheckValidity(func) {
        let warnText = '';
        if (!this.meetplekID) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('an_eo', { ns: 'analytics' })
                })
            );
        }
        if (!this.onderzoeksgrootheidID) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('an_grootheid', { ns: 'analytics' })
                })
            );
        }
        if (!this.normwaarde) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('an_norm', { ns: 'analytics' })
                })
            );
        }
        if (!this.formule) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('an_normfomule', { ns: 'analytics' })
                })
            );
        }
        return warnText;
    }
}

class NormView {
    constructor(data) {
        this.id = -1;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id ?? data.iq_id,
                writable: false,
                enumerable: true
            },
            meetplekID: {
                type: Number,
                value: data.entityobjectid,
                writable: true,
                enumerable: true
            },
            meetplekNaam: {
                type: String,
                value: data.entityobject,
                writable: true,
                enumerable: true
            },
            onderzoeksgrootheidID: {
                type: Number,
                value: data.invquantityid,
                writable: true,
                enumerable: true
            },
            onderzoeksgrootheidNaam: {
                type: String,
                value: data.invquantity,
                writable: true,
                enumerable: true
            },
            normwaarde: {
                type: Number,
                value: data.value,
                writable: true,
                enumerable: true
            },
            formule: {
                type: String,
                value: data.limits,
                writable: true,
                enumerable: true
            },
            eenheidSymbool: {
                type: Number,
                value: data.unitsymbol,
                writable: true,
                enumerable: true
            },
            eenheidNaam: {
                type: String,
                value: data.unit,
                writable: true,
                enumerable: true
            },
            grootheidNaam: {
                type: String,
                value: data.quantity,
                writable: true,
                enumerable: true
            },
            formuleDisplay: {
                type: String,
                value: data.limits
                    ? this.displayFormulaString(data.limits)
                    : null,
                writable: false,
                enumerable: true
            }
        });
    }
    displayFormulaString(limits) {
        const tempformulakleiner = limits.indexOf('<') > -1;
        const tempformulagroter = limits.indexOf('>') > -1;
        const tempresult = limits.replace('V', 'NormWaarde');
        return tempformulakleiner || tempformulagroter
            ? tempresult
            : '± ' + tempresult;
    }
}

class TemplateObject {
    /**
     * Assign the data to a new TemplateObject instance
     *
     * @param data
     */
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        this.updated = null;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.to_id ?? data.id,
                writable: false,
                enumerable: true
            },
            gewijzigd: {
                value: data.updated,
                writable: false,
                enumerable: true
            },
            naam: {
                type: String,
                value: data.description,
                writable: true,
                enumerable: true
            },
            opmerking: {
                type: String,
                value: data.remark,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }

    SetForAdd() {
        const TemplateUpdateModel = {
            ID: this.id,
            Updated: this.gewijzigd,
            Description: this.naam,
            Remark: this.opmerking,
            InvestigationQuantiesIDs: []
        };
        return TemplateUpdateModel;
    }
}

class TemplateView {
    constructor(data) {
        this.id = -1;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.iq_id,
                writable: false,
                enumerable: true
            },
            naam: {
                type: String,
                value: data.description,
                writable: false,
                enumerable: true
            },
            templateid: {
                value: data.templateid,
                writable: false,
                enumerable: true
            },
            grootheidid: {
                value: data.quantityid,
                writable: false,
                enumerable: true
            },
            grootheid: {
                type: String,
                value: data.quantity,
                writable: false,
                enumerable: true
            },
            uitleg: {
                type: String,
                value: data.action,
                writable: false,
                enumerable: true
            },
            templatenaam: {
                type: String,
                value: data.template,
                writable: false,
                enumerable: true
            },
            uitvoerderid: {
                value: data.executorid,
                writable: false,
                enumerable: true
            },
            opmerking: {
                type: String,
                value: data.remark,
                writable: false,
                enumerable: true
            }
        });
    }
}
class QuantityView {
    constructor(data) {
        this.id = -1;
        this.typenaam = 'grootheid';
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            naam: {
                type: String,
                value: data.description ?? data.quantity,
                writable: false,
                enumerable: true
            },
            eenheid: {
                type: String,
                value: data.unit ?? data.qunit,
                writable: false,
                enumerable: true
            },
            eenheidid: {
                type: String,
                value: data.qUnitID,
                writable: false,
                enumerable: true
            }
        });
    }
    SetForAdd() {
        const QuantityModel = {
            ID: this.id,
            Quantity: this.naam,
            QUnitID: this.eenheidid,
            QUnit: this.eenheid
        };
        return QuantityModel;
    }
}

class QunitView {
    constructor(data) {
        this.id = -1;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            baseid: {
                value: data.baseid,
                writable: false,
                enumerable: true
            },
            naam: {
                type: String,
                value: data.description,
                writable: false,
                enumerable: true
            },
            symbool: {
                type: String,
                value: data.symbol,
                writable: false,
                enumerable: true
            },
            factor: {
                type: Number,
                value: data.factor,
                writable: false,
                enumerable: true
            },
            type: {
                type: Number,
                value: data.type,
                writable: false,
                enumerable: true
            }
        });
        this.isSI = this.type == 1;
    }
}
class QualityView {
    constructor(data) {
        this.typenaam = 'kwaliteit';
        this.id = -1;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            naam: {
                type: String,
                value: data.description ?? data.quality,
                writable: false,
                enumerable: true
            },
            range: {
                type: Number,
                value: data.range,
                writable: false,
                enumerable: true
            },
            alert: {
                type: Number,
                value: data.alert,
                writable: false,
                enumerable: true
            }
        });
    }

    SetForAdd() {
        const QualityModel = {
            ID: this.id,
            Quality: this.naam
        };
        return QualityModel;
    }
}

class QLabelView {
    constructor(data) {
        this.id = -1;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            naam: {
                type: String,
                value: data.description,
                writable: false,
                enumerable: true
            },
            qualityID: {
                type: Number,
                value: data.qualityid,
                writable: false,
                enumerable: true
            },
            type: {
                type: Number,
                value: data.subtype,
                writable: false,
                enumerable: true
            }
        });
        this.isSI = this.type == 1;
    }
}

class ExecutorQView {
    constructor(data) {
        this.id = -1;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            naam: {
                type: String,
                value: data.description,
                writable: true,
                enumerable: true
            },
            opmerking: {
                type: String,
                value: data.remark,
                writable: true,
                enumerable: true
            },
            isDevice: {
                type: Boolean,
                value: !(data.device == undefined),
                writable: true,
                enumerable: true
            },
            isPerson: {
                type: Boolean,
                value: !(data.person == undefined),
                writable: true,
                enumerable: true
            },
            isOrganisation: {
                type: Boolean,
                value: !(data.organisation == undefined),
                writable: true,
                enumerable: true
            },
            isQuality: {
                type: Boolean,
                value: false,
                writable: true,
                enumerable: true
            },
            isQuantity: {
                type: Boolean,
                value: false,
                writable: true,
                enumerable: true
            },
            // speciaal voor quantity
            eenheidid: {
                value: data.unitid,
                writable: true,
                enumerable: true
            },
            grootheidid: {
                value: data.quantityid,
                writable: true,
                enumerable: true
            },
            barcode: {
                type: String,
                value: data.barcode,
                writable: true,
                enumerable: true
            },
            eenheid: {
                type: String,
                value: data.unit,
                writable: true,
                enumerable: true
            },
            grootheid: {
                type: String,
                value: data.quantity,
                writable: true,
                enumerable: true
            },
            // speciaal voor quality
            qualityid: {
                value: data.qualityid,
                writable: true,
                enumerable: true
            },
            quality: {
                type: String,
                value: data.quality,
                writable: true,
                enumerable: true
            },
            labels: {
                type: String,
                value: data.labels,
                writable: true,
                enumerable: true
            }
        });
        this.typenaam = this.isDevice
            ? window.constants.AEX_DEVICE
            : this.isPerson
            ? window.constants.AEX_PERSON
            : window.constants.AEX_ORG;
    }
}

class ExecutorBaseView {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        this.updated = null;
        this.type = 'ExecutorBase';
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            gewijzigd: {
                value: data.updated,
                writable: false,
                enumerable: true
            },
            naam: {
                type: String,
                value: data.description,
                writable: true,
                enumerable: true
            },
            externref: {
                type: String,
                value: data.externalid,
                writable: true,
                enumerable: true
            },
            opmerking: {
                type: String,
                value: data.remark,
                writable: true,
                enumerable: true
            },
            barcode: {
                type: String,
                value: data.barcode,
                writable: true,
                enumerable: true
            },
            grootheden: {
                type: Array[QuantityView],
                value: data.quantities
                    ? data.quantities.map((x) => new QuantityView(x))
                    : [],
                writable: true,
                enumerable: true
            },
            qualities: {
                type: Array[QualityView],
                value: data.qualities
                    ? data.qualities.map((x) => new QualityView(x))
                    : [],
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
        this.groothedentekst =
            this.grootheden.length > 0
                ? this.grootheden.map((x) => x.naam).join()
                : '';
        this.qualitiestekst =
            this.qualities.length > 0
                ? this.qualities.map((x) => x.naam).join()
                : '';
    }

    SetForSave() {
        const lijstQuantity = [];
        for (let i = 0; i < this.grootheden.length; i++) {
            lijstQuantity.push(this.grootheden[i].SetForAdd());
        }
        const lijstQualities = [];
        for (let i = 0; i < this.qualities.length; i++) {
            lijstQualities.push(this.qualities[i].SetForAdd());
        }

        const ExecutorModel = {
            ID: this.id,
            Updated: this.gewijzigd,
            Description: this.naam,
            Externalid: this.externref,
            Barcode: this.barcode,
            Remark: this.opmerking,
            Quantities: lijstQuantity,
            Qualities: lijstQualities
        };
        return ExecutorModel;
    }

    CheckValidity(func) {
        let warnText = '';
        if (!this.naam) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('com_naam', { ns: 'common' })
                })
            );
        } else {
            if (this.naam.length < 2) {
                warnText = Helper.concatString(
                    warnText,
                    func('com_xlangerdan', {
                        ns: 'common',
                        val: '3',
                        prop: func('com_naam', { ns: 'common' })
                    })
                );
            }
        }
        return warnText;
    }
}

class Device extends ExecutorBaseView {
    constructor(data) {
        data = data ?? {};
        super(data);
        this.type = window.constants.AEX_DEVICE;
    }

    SetForSave() {
        return ExecutorBaseView.prototype.SetForSave.call(this);
    }
}

class Organisation extends ExecutorBaseView {
    constructor(data) {
        data = data ?? {};
        super(data);
        this.type = window.constants.AEX_ORG;
    }

    SetForSave() {
        return ExecutorBaseView.prototype.SetForSave.call(this);
    }
}

class Person extends ExecutorBaseView {
    constructor(data) {
        data = data ?? {};
        super(data);
        this.type = window.constants.AEX_PERSON;
    }

    SetForSave() {
        return ExecutorBaseView.prototype.SetForSave.call(this);
    }
}

class MaesurableIQView {
    constructor(data) {
        this.id = -1;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            grootheidid: {
                value: data.quantityid,
                writable: false,
                enumerable: true
            },
            naam: {
                type: String,
                value: data.description,
                writable: false,
                enumerable: true
            },
            grootheid: {
                type: String,
                value: data.quantity,
                writable: false,
                enumerable: true
            },
            uitvoerder: {
                type: String,
                value: data.executor,
                writable: false,
                enumerable: true
            },
            uitleg: {
                type: String,
                value: data.explanation,
                writable: false,
                enumerable: true
            }
        });
    }
}

class InvestigationQuantity {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        this.updated = null;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            gewijzigd: {
                value: data.updated,
                writable: false,
                enumerable: true
            },
            naam: {
                type: String,
                value: data.description,
                writable: true,
                enumerable: true
            },
            grootheidid: {
                value: data.quantityID,
                writable: true,
                enumerable: true
            },
            grootheid: {
                type: String,
                value: data.quantity,
                writable: false,
                enumerable: true
            },
            uitvoerderID: {
                type: Number,
                value: data.executorID,
                writable: true,
                enumerable: true
            },
            uitvoerder: {
                type: String,
                value: data.executor,
                writable: false,
                enumerable: true
            },
            uitleg: {
                type: String,
                value: data.explanation,
                writable: true,
                enumerable: true
            },
            externID: {
                type: String,
                value: data.externalid,
                writable: true,
                enumerable: true
            },
            actie: {
                type: String,
                value: data.action,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }
    SetForAdd() {
        const InvestigationQuantityModel = {
            ID: this.id,
            Updated: this.gewijzigd,
            Description: this.naam,
            QuantityID: this.grootheidid,
            ExecutorID: this.uitvoerderID,
            Externalid: this.externID,
            Explanation: this.uitleg,
            Action: this.actie
        };
        return InvestigationQuantityModel;
    }
    CheckValidity(func) {
        let warnText = '';
        if (this.naam) {
            if (this.naam.length < 2) {
                warnText = Helper.concatString(
                    warnText,
                    func('com_xlangerdan', {
                        ns: 'common',
                        val: '3',
                        prop: func('com_naam', { ns: 'common' })
                    })
                );
            }
        } else {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('com_naam', { ns: 'common' })
                })
            );
        }
        if (!this.grootheidid) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('an_grootheid', { ns: 'analytics' })
                })
            );
        }
        if (!this.uitvoerderID) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('prod_apparaat', { ns: 'production' })
                })
            );
        }
        return warnText;
    }
}

class SampleObject {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        this.updated = null;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false
            },
            gewijzigd: {
                value: data.updated,
                writable: false
            },
            naam: {
                type: String,
                value: data.description,
                writable: true,
                enumerable: true
            },
            eenheidBeschrijving: {
                type: String,
                value: data.descriptionAmount ?? data.descriptiveamount,
                writable: true,
                enumerable: true
            },
            opmerking: {
                type: String,
                value: data.remark,
                writable: true,
                enumerable: true
            },
            monstertype: {
                type: Number,
                value: data.type ?? data.typeid,
                writable: true,
                enumerable: true
            },
            monsterTypeTekst: {
                type: String,
                value: data.sampletype,
                writable: true,
                enumerable: true
            },
            plantijdstip: {
                type: String,
                value: data.bookDate ?? data.bookdate,
                writable: true,
                enumerable: true
            },
            neemtijdstip: {
                type: String,
                value: data.sampleDate ?? data.sampledate,
                writable: true,
                enumerable: true
            },
            meetplekID: {
                type: Number,
                value: data.entityObjectID ?? data.entityobjectid,
                writable: true,
                enumerable: true
            },
            meetplekNaam: {
                type: String,
                value: data.entityObject ?? data.entityobject,
                writable: true,
                enumerable: true
            },
            internID: {
                type: String,
                value: data.internalID,
                writable: true,
                enumerable: true
            },
            barcode: {
                type: String,
                value: data.barcode,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id ? this.id < 0 : true;
    }

    clearID() {
        this.id = -1;
        this.updated = null;
        this.isNew = true;
    }

    SetForAdd() {
        const SampleModel = {
            ID: this.id,
            EntityObjectID: this.meetplekID,
            EntityObject: this.meetplekNaam,
            Description: this.naam,
            DescriptionAmount: this.eenheidBeschrijving,
            Type: this.monstertype,
            Barcode: this.barcode,
            Updated: this.gewijzigd,
            BookDate: DateHelper.localDateToUTC(this.plantijdstip),
            SampleDate: DateHelper.localDateToUTC(this.neemtijdstip)
        };
        return SampleModel;
    }

    CheckValidity(func) {
        let warnText = '';
        if (!this.meetplekID) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('an_eo', { ns: 'analytics' })
                })
            );
        }
        if (!this.naam) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('an_omschrijving', { ns: 'analytics' })
                })
            );
        } else {
            if (this.naam.length < 2) {
                warnText = Helper.concatString(
                    warnText,
                    func('com_xlangerdan', {
                        ns: 'common',
                        val: '3',
                        prop: func('an_omschrijving', { ns: 'analytics' })
                    })
                );
            }
        }
        if (!this.monstertype) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('an_sampletype', { ns: 'analytics' })
                })
            );
        }
        return warnText;
    }
}

class SampleType {
    constructor(data, func) {
        this.id = -1;
        this.type = 'SampleType';
        data = data ?? {};
        this.isDeleted = false;
        this.isChanged = false;
        Object.defineProperties(this, {
            id: {
                value: data.id ?? data.ID,
                writable: false,
                enumerable: true
            },
            naam: {
                value: SampleType.giveName(data.id, func),
                //value: data.description,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }
    static giveName(id, func) {
        if (id == undefined) return undefined;
        const lookup = 'an_sampletype_' + id.toString();
        return id >= 0 ? func(lookup, { ns: 'analytics' }) : undefined;
    }
}

class ReportGroup {
    constructor(data) {
        this.id = -1;
        this.type = 'ReportGroup';
        data = data ?? {};
        this.isDeleted = false;
        this.isChanged = false;
        Object.defineProperties(this, {
            id: {
                value: data.id ?? data.ID,
                writable: false,
                enumerable: true
            },
            naam: {
                value: data.description,
                writable: true,
                enumerable: true
            },
            typeNaam: {
                value: data.type,
                writable: true,
                enumerable: true
            },
            opmerking: {
                type: String,
                value: data.remark,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }

    SetForUpdate() {
        const ReportingModel = {
            ID: this.id,
            Description: this.naam,
            Type: this.typeNaam,
            Remark: this.opmerking
        };
        return ReportingModel;
    }

    CheckValidity(func) {
        let warnText = '';
        if (!this.naam) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('an_omschrijving', { ns: 'analytics' })
                })
            );
        } else {
            if (this.naam.length < 2) {
                warnText = Helper.concatString(
                    warnText,
                    func('com_xlangerdan', {
                        ns: 'common',
                        val: '3',
                        prop: func('an_omschrijving', { ns: 'analytics' })
                    })
                );
            }
        }
        return warnText;
    }
}
