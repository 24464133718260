import { BatchStats, CheeseMarks } from './BakInfo';
import Helper from './Helper';
import DateHelper from './DateHelper';
export {
    BIlijst,
    Alternativeslijst,
    BakStrem,
    BakMelk,
    BakWWW,
    BakDrain,
    BakOverpomp,
    BakInpekel,
    InpekelItem,
    BakUitpekel,
    UitpekelItem,
    NextStepTimer,
    SiloIngredients,
    SiloIngredient
};

class BakStrem {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            bakId: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            gewijzigd: {
                value: data.gewijzigd,
                writable: false,
                enumerable: true
            },
            receptID: {
                type: Number,
                value: data.receptID,
                writable: true,
                enumerable: true
            },
            stremTijdstip: {
                value: data.stremTijdstip,
                writable: true,
                enumerable: true
            },
            boekDatum: {
                value: data.bookDate ?? data.productieDatum,
                writable: true,
                enumerable: true
            },
            stremTemp: {
                value: data.stremTemp,
                writable: true,
                enumerable: true
            },
            timerIngredient: {
                type: String,
                writable: true,
                enumerable: true
            },
            bakIngredientenStremsel: {
                value: Array(BIlijst),
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }

    SetForUpdate() {
        const UpdateBatchModel = {
            BatchID: this.bakId,
            StremTemp: this.stremTemp,
            Stremtijdstip: DateHelper.localDateToUTC(this.stremTijdstip),
            Updated: this.gewijzigd
        };
        return UpdateBatchModel;
    }

    CheckValidity(func) {
        let warnText = '';
        if (this.stremTemp) {
            if (
                this.stremTemp < window.constants.MIN_TEMP_STREM ||
                this.stremTemp > window.constants.MAX_TEMP_STREM
            )
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('com_temperatuur', { ns: 'common' }),
                        val: this.stremTemp
                    })
                );
        }
        for (let i = 0; i < this.bakIngredientenStremsel.length; i++) {
            warnText += this.bakIngredientenStremsel[i].CheckValidity(func);
        }
        return warnText;
    }
}

class BakMelk {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            bakId: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            gewijzigd: {
                value: data.gewijzigd,
                writable: false,
                enumerable: true
            },
            receptID: {
                type: Number,
                value: data.receptID,
                writable: true,
                enumerable: true
            },
            ostID: {
                value: data.wbId,
                writable: true,
                enumerable: true
            },
            pastTemp: {
                value: data.pastTemp,
                writable: true,
                enumerable: true
            },
            thermTemp: {
                value: data.thermTemp,
                writable: true,
                enumerable: true
            },
            WBprogram: {
                value: data.wbProgram,
                writable: true,
                enumerable: true
            },
            zuurselTijdstip: {
                value: data.zuurselTijdstip,
                writable: true,
                enumerable: true
            },
            boekDatum: {
                value: data.bookDate ?? data.productieDatum,
                writable: true,
                enumerable: true
            },
            timerIngredient: {
                type: String,
                writable: true,
                enumerable: true
            },
            bakIngredientenMelk: {
                value: Array(BIlijst),
                writable: true,
                enumerable: true
            },
            bakIngredientenZuursel: {
                value: Array(BIlijst),
                writable: true,
                enumerable: true
            },
            bakIngredientenCalcium: {
                value: Array(BIlijst),
                writable: true,
                enumerable: true
            },
            bakIngredientenKleursel: {
                value: Array(BIlijst),
                writable: true,
                enumerable: true
            },
            bakIngredientenConserveermiddel: {
                value: Array(BIlijst),
                writable: true,
                enumerable: true
            },
            // voor gebruik,b.v. timer
            prodcalcFactor: {
                value: 1,
                writable: true,
                enumerable: true
            }
        });
        this.handleStarterGroup = false;
        this.zuurselTypeID = undefined;
        this.isNew = this.id == undefined || this.id < 0;
    }

    SetForUpdate() {
        const UpdateBatchModel = {
            BatchID: this.bakId,
            PastTemp: this.pastTemp,
            ThermTemp: this.thermTemp,
            OstID: this.ostID,
            WBProgram: this.WBprogram,
            Zuurseltijdstip: DateHelper.localDateToUTC(this.zuurselTijdstip),
            Updated: this.gewijzigd
        };

        return UpdateBatchModel;
    }
    CheckValidity(func) {
        let warnText = '';
        if (this.pastTemp) {
            if (
                this.pastTemp < window.constants.MIN_TEMP_PAST ||
                this.pastTemp > window.constants.MAX_TEMP_PAST
            )
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('com_temperatuur', { ns: 'common' }),
                        val: this.pastTemp
                    })
                );
        }
        for (let i = 0; i < this.bakIngredientenMelk.length; i++) {
            warnText += this.bakIngredientenMelk[i].CheckValidity(func);
        }
        const specialsTeller = [];
        for (let i = 0; i < this.bakIngredientenZuursel.length; i++) {
            warnText += this.bakIngredientenZuursel[i].CheckValidity(func);
            if (this.bakIngredientenZuursel[i].special) {
                const deze = this.bakIngredientenZuursel[i];
                if (this.handleStarterGroup) {
                    if (deze.bakIngredienten.length > 0) {
                        const dezeSpecial = deze.special;
                        if (
                            specialsTeller.length > 0 &&
                            !specialsTeller.includes(dezeSpecial)
                        ) {
                            if (!warnText.includes(func('prod_multiplesubtypes', { ns: 'production'}))) {
                                warnText = Helper.concatString(
                                    warnText,
                                    func('prod_multiplesubtypes', { ns: 'production'})
                                );
                            }
                        } else {
                            specialsTeller.push(dezeSpecial);
                        }
                    }
                } else {
                    if (deze.bakIngredienten.length > 0) {
                        const dezeSpecial = deze.special;
                        if (specialsTeller.includes(dezeSpecial)) {
                            warnText = Helper.concatString(
                                warnText,
                                func('prod_nosubtypes', {
                                    ns: 'production',
                                    val: dezeSpecial
                                })
                            );
                        } else {
                            specialsTeller.push(dezeSpecial);
                        }
                    }
                }
            }
        }
        for (let i = 0; i < this.bakIngredientenCalcium.length; i++) {
            warnText += this.bakIngredientenCalcium[i].CheckValidity(func);
        }
        for (let i = 0; i < this.bakIngredientenKleursel.length; i++) {
            warnText += this.bakIngredientenKleursel[i].CheckValidity(func);
        }
        return warnText;
    }
}

class BakWWW {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        this.isNew = this.isNew ? this.isNew : this.id < 0;
        Object.defineProperties(this, {
            bakId: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            gewijzigd: {
                value: data.gewijzigd,
                writable: false,
                enumerable: true
            },
            receptID: {
                type: Number,
                value: data.receptID,
                writable: true,
                enumerable: true
            },
            wei1: {
                value: data.wei1liters,
                writable: true,
                enumerable: true
            },
            wasWater: {
                value: data.waswater ?? data.wasWater,
                writable: true,
                enumerable: true
            },
            wei2a: {
                value: data.wei2aliters,
                writable: true,
                enumerable: true
            },
            wasWater2a: {
                value: data.wasWater2a,
                writable: true,
                enumerable: true
            },
            nawarmTemp: {
                value: data.nawarmTemp,
                writable: true,
                enumerable: true
            },
            tussenTemp: {
                value: data.tussenTemp,
                writable: true,
                enumerable: true
            },
            boekDatum: {
                value: data.bookDate ?? data.productieDatum,
                writable: true,
                enumerable: true
            },
            bakIngredientenConserveermiddel: {
                value: Array(BIlijst),
                writable: true,
                enumerable: true
            },
            bakIngredientenKruiden: {
                value: Array(BIlijst),
                writable: true,
                enumerable: true
            },
            bakIngredientenAromas: {
                value: Array(BIlijst),
                writable: true,
                enumerable: true
            },
            // voor defaults
            melk: {
                value: 0,
                writable: true,
                enumerable: true
            },
            prodcalcFactor: {
                value: 1,
                writable: true,
                enumerable: true
            },
            stremTemp: {
                value: data.stremTemp,
                writable: false,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }

    SetForUpdate() {
        const UpdateBatchModel = {
            BatchID: this.bakId,
            FirstWheyLiters: this.wei1,
            WheyLiters2a: this.wei2a,
            Waswater: this.wasWater,
            Waswater2a: this.wasWater2a,
            TussenTemp: this.tussenTemp,
            NawarmTemp: this.nawarmTemp,
            Updated: this.gewijzigd
        };
        return UpdateBatchModel;
    }
    CheckValidity(func) {
        let warnText = '';
        if (this.nawarmTemp) {
            if (
                this.nawarmTemp < window.constants.MIN_TEMP_NAWARM ||
                this.nawarmTemp > window.constants.MAX_TEMP_NAWARM
            )
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('com_temperatuur', { ns: 'common' }),
                        val: this.nawarmTemp
                    })
                );
        }
        for (let i = 0; i < this.bakIngredientenKruiden.length; i++) {
            warnText += this.bakIngredientenKruiden[i].CheckValidity(func);
        }
        for (let i = 0; i < this.bakIngredientenConserveermiddel.length; i++) {
            warnText +=
                this.bakIngredientenConserveermiddel[i].CheckValidity(func);
        }
        for (let i = 0; i < this.bakIngredientenAromas.length; i++) {
            warnText += this.bakIngredientenAromas[i].CheckValidity(func);
        }
        return warnText;
    }
}

class BakOverpomp {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            bakId: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            gewijzigd: {
                value: data.gewijzigd,
                writable: false,
                enumerable: true
            },
            productID: {
                type: Number,
                value: data.productID,
                writable: true,
                enumerable: true
            },
            receptID: {
                type: Number,
                value: data.receptID,
                writable: true,
                enumerable: true
            },
            overpompTijdstip: {
                value: data.overpompTijdstip,
                writable: true,
                enumerable: true
            },
            boekDatum: {
                value: data.bookDate ?? data.productieDatum,
                writable: true,
                enumerable: true
            },
            Drainprogram: {
                value: data.drainProgram,
                writable: true,
                enumerable: true
            },
            bakIngredientenZout: {
                value: Array(BIlijst),
                writable: true,
                enumerable: true
            },
            bakIngredientenAlternatief: {
                value: Array(Alternativeslijst),
                writable: true,
                enumerable: true
            },
            // om naturel kaas te registreren vanuit een kruidenkaas
            // een array met maar 1 item!!!
            bakIngredientenAltBase: {
                value: Array(Alternativeslijst),
                writable: true,
                enumerable: true
            },
            // voor defaults
            melk: {
                value: 0,
                writable: true,
                enumerable: true
            },
            prodcalcFactor: {
                value: 1,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }

    SetForUpdate() {
        const UpdateBatchModel = {
            BatchID: this.bakId,
            Overpomptijdstip: DateHelper.localDateToUTC(this.overpompTijdstip),
            DRProgram: this.Drainprogram,
            Updated: this.gewijzigd
        };
        return UpdateBatchModel;
    }

    CheckValidity(func) {
        let warnText = '';
        for (let i = 0; i < this.bakIngredientenZout.length; i++) {
            warnText += this.bakIngredientenZout[i].CheckValidity(func);
        }
        for (let i = 0; i < this.bakIngredientenAlternatief.length; i++) {
            warnText += this.bakIngredientenAlternatief[i].CheckValidity(func);
        }
        return warnText;
    }
}

class BakDrain {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            bakId: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            gewijzigd: {
                value: data.gewijzigd,
                writable: false,
                enumerable: true
            },
            receptID: {
                type: Number,
                value: data.receptID,
                writable: true,
                enumerable: true
            },
            boekDatum: {
                value: data.bookDate ?? data.productieDatum,
                writable: true,
                enumerable: true
            },
            Persprogram: {
                value: data.pressProgram,
                writable: true,
                enumerable: true
            },
            productID: {
                type: Number,
                value: data.productID,
                writable: true,
                enumerable: true
            },
            bakKaasMerk: {
                value: CheeseMarks,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }
    SetForUpdate() {
        const UpdateBatchModel = {
            BatchID: this.bakId,
            PressProgram: this.Persprogram,
            Updated: this.gewijzigd
        };
        return UpdateBatchModel;
    }
    SetForUpdateCheeseMark(saveSubseries) {
        if (!this.bakKaasMerk) return null;
        if (this.bakKaasMerk.beginStockItemID == undefined) return null;
        let lijst = [];
        if (this.bakKaasMerk.subSeries.length > 0 && saveSubseries) {
            lijst = this.bakKaasMerk.subSeries
                .filter((x) => x.nouse == undefined || x.nouse == false)
                .filter((x) => x.isDeleted == false)
                .map((x) => ({
                    MarkCount: x.kazen,
                    ShapeID: x.vormID,
                    ProductID: x.productID,
                    Updated: x.gewijzigd,
                    StartStockItemID: x.beginStockItemID,
                    EndStockItemID: x.eindStockItemID,
                    Startletter: x.startLetter,
                    Endletter: x.eindLetter,
                    Startnumber: x.startNummer,
                    Endnumber: x.eindNummer
                }));
        }
        const BatchCheeseMarksModel = {
            BatchID: this.bakId,
            ProductID: this.bakKaasMerk.productID,
            MarkTypeID: this.bakKaasMerk.merktypeID,
            Startletter: this.bakKaasMerk.startLetter,
            Endletter: this.bakKaasMerk.eindLetter,
            Startnumber: this.bakKaasMerk.startNummer,
            Endnumber: this.bakKaasMerk.eindNummer,
            Remark: this.bakKaasMerk.opmerking,
            StartStockItemID: this.bakKaasMerk.beginStockItemID,
            EndStockItemID: this.bakKaasMerk.eindStockItemID,
            Updated: this.bakKaasMerk.gewijzigd,
            CheeseMarkSeries: lijst
        };
        return BatchCheeseMarksModel;
    }
    CheckValidity(func) {
        const warnText = '';
        return warnText;
    }
}

class BakInpekel {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            bakId: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            gewijzigd: {
                value: data.gewijzigd,
                writable: false,
                enumerable: true
            },
            receptID: {
                type: Number,
                value: data.receptID,
                writable: true,
                enumerable: true
            },
            inpekelAmounts: {
                value: [],
                writable: true,
                enumerable: true
            },
            bakStats: {
                value: data.bakStatistiek,
                writable: true,
                enumerable: true
            },
            boekDatum: {
                value: data.bookDate ?? data.productieDatum,
                writable: true,
                enumerable: true
            },
            productID: {
                type: Number,
                value: data.productID,
                writable: true,
                enumerable: true
            },
            // voor defaults
            prodcalcFactor: {
                value: 1,
                writable: true,
                enumerable: true
            },
            opgelegdeMerken: {
                type: Number,
                writable: true,
                enumerable: true
            },
            // undefined is geen merken nodig, anders true of false
            merkenOpgelegd: {
                type: Boolean,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }
    SetForBatchUpdate() {
        const UpdateBatchModel = {
            BatchID: this.bakId,
            Updated: this.gewijzigd
        };
        return UpdateBatchModel;
    }

    SetForUpdate() {
        const InBrineModel = {
            BatchID: this.bakId,
            Amounts: []
        };
        const altDatum = DateHelper.convertedToDateTimeStringFromUTC(
            this.boekDatum,
            new Date()
        );
        const lijst = this.inpekelAmounts
            .filter((x) => x.nouse == undefined || x.nouse == false)
            .filter((x) => x.isDeleted == false)
            .map((x) => {
                const datum =
                    DateHelper.localDateToUTC(x.inpekelTijdOri) ??
                    DateHelper.localDateToUTC(altDatum);
                return {
                    CheeseAmount: x.amount,
                    CheeseWeight: x.kilos ? x.kilos : null,
                    ShapeID: x.vormID,
                    ProductID: x.productID,
                    BrineCage: x.kooi ? x.kooi : null,
                    BrineLayer: x.laag ? x.laag : null,
                    InBrineTime: datum,
                    Updated: x.gewijzigd
                };
            });
        InBrineModel.Amounts = lijst;
        return InBrineModel;
    }

    CheckValidity(func) {
        let warnText = '';
        this.inpekelAmounts
            .filter((x) => x.nouse == undefined || x.nouse == false)
            .forEach((element) => (warnText += element.CheckValidity(func)));
        return warnText;
    }
}

class InpekelItem {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        //this.isNew = data.updated == undefined ? true : false;
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            gewijzigd: {
                value: data.updated,
                writable: false,
                enumerable: true
            },
            bakId: {
                value: data.bakId,
                writable: true,
                enumerable: true
            },
            amount: {
                type: Number,
                value: data.CheeseAmount ?? data.cheeseAmount,
                writable: true,
                enumerable: true
            },
            kilos: {
                type: Number,
                value: data.CheeseWeight ?? data.cheeseWeight,
                writable: true,
                enumerable: true
            },
            vormID: {
                type: Number,
                value: data.ShapeID ?? data.shapeID,
                writable: true,
                enumerable: true
            },
            productID: {
                type: Number,
                value: data.productID,
                writable: true,
                enumerable: true
            },
            inpekelTijd: {
                value: DateHelper.convertedToDateTimeString(
                    data.InBrineTime ?? data.inBrineTime
                ),
                writable: true,
                enumerable: true
            },
            inpekelTijdOri: {
                value: data.InBrineTime ?? data.inBrineTime,
                writable: true,
                enumerable: true
            },
            kooi: {
                value: data.brineCage,
                writable: true,
                enumerable: true
            },
            laag: {
                value: data.brineLayer,
                writable: true,
                enumerable: true
            },
            // voor gebruik
            prodFactor: {
                type: Number,
                value: null,
                writable: true,
                enumerable: true
            },
            productNaam: {
                type: String,
                value: null,
                writable: true,
                enumerable: true
            },
            wijzigbaar: {
                type: Boolean,
                value: true,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }
    CheckValidity(func) {
        let warnText = '';
        if (this.amount) {
            if (this.amount < 1 || this.amount > 1000)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('com_number', { ns: 'common' }),
                        val: this.amount
                    })
                );
        } else {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('com_number', { ns: 'common' })
                })
            );
        }
        if (!this.vormID) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('prod_vorm', { ns: 'production' })
                })
            );
        }
        return warnText;
    }
}

class BakUitpekel {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            bakgroep: {
                value: data.bakgroep,
                writable: false,
                enumerable: true
            },
            boekDatum: {
                value: data.bookDate,
                writable: true,
                enumerable: true
            },
            uitpekelAmounts: {
                value: [],
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }

    SetForUpdate() {
        const OutBrineModel = {
            batchGroup: this.bakgroep,
            Amounts: []
        };
        const altDatum = DateHelper.convertedToDateTimeStringFromUTC(
            this.boekDatum,
            new Date()
        );
        const lijst = this.uitpekelAmounts
            .filter((x) => x.nouse == undefined || x.nouse == false)
            .map((x) => {
                const datum = x.uitpekelTijdOri
                    ? DateHelper.localDateToUTCWithBase(
                          x.uitpekelTijdOri,
                          x.uitpekelTijd
                      )
                    : DateHelper.localDateToUTC(altDatum);
                return {
                    CheeseAmount: x.amount,
                    CheeseWeight: x.kilos,
                    ShapeID: x.vormID,
                    ProductID: x.productID,
                    OutBrineTime: datum,
                    StockLocationID: x.stocklocationID,
                    FailureProductID: x.uitvalProductID,
                    Updated: x.gewijzigd
                };
            });
        OutBrineModel.Amounts = lijst;
        return OutBrineModel;
    }

    CheckValidity(func) {
        let warnText = '';
        this.uitpekelAmounts
            .filter((x) => x.nouse == undefined || x.nouse == false)
            .forEach((element) => (warnText += element.CheckValidity(func)));
        return warnText;
    }
}

class UitpekelItem {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        //this.isNew = data.updated == undefined ? true : false;
        Object.defineProperties(this, {
            id: {
                value: data.stockItemID,
                writable: true,
                enumerable: true
            },
            gewijzigd: {
                value: data.Updated ?? data.updated,
                writable: false,
                enumerable: true
            },
            bakgroep: {
                value: data.bakgroep,
                writable: false,
                enumerable: true
            },
            uitvalProductID: {
                value: data.failureProductID > 0 ? data.failureProductID : null,
                writable: true,
                enumerable: true
            },
            amount: {
                type: Number,
                value: data.CheeseAmount ?? data.cheeseAmount,
                writable: true,
                enumerable: true
            },
            kilos: {
                type: Number,
                value: data.CheeseWeight ?? data.cheeseWeight,
                writable: true,
                enumerable: true
            },
            vormID: {
                type: Number,
                value: data.ShapeID ?? data.shapeID,
                writable: true,
                enumerable: true
            },
            productID: {
                type: Number,
                value: data.productID,
                writable: true,
                enumerable: true
            },
            uitpekelTijd: {
                value: DateHelper.convertedToTime(data.outBrineTime),
                writable: true,
                enumerable: true
            },
            uitpekelTijdOri: {
                value: data.outBrineTime,
                writable: true,
                enumerable: true
            },
            // voor gebruik
            kooi: {
                value: data.brineCage,
                writable: true,
                enumerable: true
            },
            laag: {
                value: data.brineLayer,
                writable: true,
                enumerable: true
            },
            prodFactor: {
                type: Number,
                value: null,
                writable: true,
                enumerable: true
            },
            productNaam: {
                type: String,
                value: null,
                writable: true,
                enumerable: true
            },
            uitvalProductNaam: {
                type: String,
                value: data.failureProduct,
                writable: true,
                enumerable: true
            },
            vormNaam: {
                type: String,
                value: data.shape,
                writable: true,
                enumerable: true
            },
            stocklocationID: {
                type: Number,
                value: data.stockLocationID,
                writable: true,
                enumerable: true
            },
            stocklocationNaam: {
                type: String,
                value: data.stockLocation,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }
    CheckValidity(func) {
        let warnText = '';
        if (this.amount) {
            if (this.amount < 1 || this.amount > 2000)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('com_number', { ns: 'common' }),
                        val: this.amount
                    })
                );
        } else {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('com_number', { ns: 'common' })
                })
            );
        }
        if (!this.vormID) {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('prod_vorm', { ns: 'production' })
                })
            );
        }
        if (!this.stocklocationID) {
            warnText = Helper.concatString(warnText, 'Opslaglocatie verplicht');
        }
        return warnText;
    }
}

class BIlijst {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        this.hasData = true;
        this.isChanged = false;
        this.checkAmount = true;
        data = data ?? {};
        Object.defineProperties(this, {
            bakId: {
                value: data.id,
                writable: true,
                enumerable: true
            },
            bakIngredienten: {
                value: [],
                writable: true,
                enumerable: true
            },
            // voor gebruik
            ingredientCategorieNaam: {
                type: String,
                value: null,
                writable: true,
                enumerable: true
            },
            ingredientCategorieID: {
                type: Number,
                value: -1,
                writable: true,
                enumerable: true
            },
            prodFactor: {
                type: Number,
                value: null,
                writable: true,
                enumerable: true
            },
            recipeAmount: {
                type: Number,
                value: null,
                writable: true,
                enumerable: true
            },
            recipeUnitID: {
                type: Number,
                value: null,
                writable: true,
                enumerable: true
            },
            productID: {
                type: Number,
                value: null,
                writable: true,
                enumerable: true
            },
            productNaam: {
                type: String,
                value: null,
                writable: true,
                enumerable: true
            },
            productUnitID: {
                type: Number,
                value: null,
                writable: true,
                enumerable: true
            },
            isAlternatief: {
                type: Boolean,
                value: false,
                writable: true,
                enumerable: true
            },
            special: {
                type: Number,
                value: false,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.isNew ? this.isNew : data.id < 0;
    }
    CheckValidity(func) {
        let warnText = '';
        if (this.hasData) {
            this.bakIngredienten.forEach(
                (element) => (warnText += element.CheckValidity(func))
            );
        }
        return warnText;
    }
}

class Alternativeslijst {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        this.hasData = true;
        this.checkAmount = true;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: true,
                enumerable: true
            },
            bakId: {
                value: data.id,
                writable: true,
                enumerable: true
            },
            bakIngredienten: {
                value: [],
                writable: true,
                enumerable: true
            },
            // voor gebruik
            ingredientCategorieNaam: {
                type: String,
                value: null,
                writable: true,
                enumerable: true
            },
            prodFactor: {
                type: Number,
                value: null,
                writable: true,
                enumerable: true
            },
            aantal: {
                type: Number,
                value: 0,
                writable: true,
                enumerable: true
            },
            productID: {
                type: Number,
                value: null,
                writable: true,
                enumerable: true
            },
            naam: {
                type: String,
                value: null,
                writable: true,
                enumerable: true
            },
            productUnitID: {
                type: Number,
                value: null,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }
    CheckValidity(func) {
        let warnText = '';
        if (this.hasData) {
            this.bakIngredienten.forEach(
                (element) => (warnText += element.CheckValidity(func))
            );
        }
        return warnText;
    }
}
class NextStepTimer {
    constructor() {
        this.bakID = -1;
        this.bakNummer = '';
        this.alarmTime = null;
        this.alarmMessage = '';
        this.componentToOpen = '';
        this.active = 1;
    }
}

class SiloIngredients {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            SiloIngredients: {
                value: [],
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }
    SetForAdd() {
        const StockLocationIngredientModel = [];
        for (let i = 0; i < this.SiloIngredients.length; i++) {
            const o = this.SiloIngredients[i].SetForAdd();
            StockLocationIngredientModel.push(o);
        }
        return StockLocationIngredientModel;
    }

    CheckValidity(func) {
        let warnText = '';
        this.SiloIngredients.filter(
            (x) => x.nouse == undefined || x.nouse == false
        ).forEach((element) => (warnText += element.CheckValidity(func)));
        return warnText;
    }
}

class SiloIngredient {
    constructor(data) {
        this.id = -1;
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false,
                enumerable: true
            },
            gewijzigd: {
                value: data.updated,
                writable: false,
                enumerable: true
            },
            datum: {
                value: data.datum ?? data.used ?? data.useDate,
                writable: true,
                enumerable: true
            },
            plaats: {
                value: data.stockLocation ?? data.location,
                writable: true,
                enumerable: true
            },
            plaatsID: {
                value: data.stockLocationID ?? data.sl_id,
                writable: true,
                enumerable: true
            },
            productID: {
                type: Number,
                value: data.productID ?? data.productid,
                writable: true,
                enumerable: true
            },
            productNaam: {
                type: String,
                value: data.product,
                writable: true,
                enumerable: true
            },
            hoeveelheid: {
                value: data.amount,
                writable: true,
                enumerable: true
            },
            eenheid: {
                type: String,
                value: data.unit,
                writable: true,
                enumerable: true
            },
            eenheidID: {
                type: Number,
                value: data.unitID ?? data.perUnitID,
                writable: true,
                enumerable: true
            },
            partijID: {
                value: data.stockItemID,
                writable: true,
                enumerable: true
            },
            partijNummer: {
                value: data.batchnumber,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }
    CheckValidity(func) {
        let warnText = '';
        if (this.hoeveelheid) {
            if (this.hoeveelheid < 1 || this.hoeveelheid > 20000)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('com_amount', { ns: 'common' }),
                        val: this.amount
                    })
                );
        } else {
            warnText = Helper.concatString(
                warnText,
                func('com_isrequired', {
                    ns: 'common',
                    val: '',
                    prop: func('com_amount', { ns: 'common' })
                })
            );
        }
        return warnText;
    }
    SetForAdd() {
        const AddModel = {
            ID: this.id,
            StockLocationID: this.plaatsID,
            StockItemID: this.partijID,
            Amountused: this.hoeveelheid,
            UseDate: DateHelper.localDateToUTC(this.datum),
            PerUnitID: this.eenheidID
        };
        return AddModel;
    }
}
