<template>
    <div v-if="toonSelectie" class="form-inline">
        <b-col sm="2" class="mx-0 px-0 my-0 py-0">
            <b-input-group>
                <b-form-timepicker
                    v-if="!isKlaarZetten"
                    v-model="localTijd"
                    locale="nl"
                    @input="set_inputTijd"
                    :disabled="!editAllowed"
                    :class="giveBGclass"
                ></b-form-timepicker>
            </b-input-group>
        </b-col>
        <b-col sm="4" class="mx-0 px-0 my-0 py-0">
            <b-input-group :prepend="giveNaam">
                <b-form-select
                    v-if="!existing"
                    v-model="partijID"
                    @change="set_inputPartij"
                    :disabled="!editAllowed"
                    v-bind:class="{ tc_aux_highlighted: warnForStockAmount }"
                >
                    <template #first>
                        <b-form-select-option value=""
                            >--
                            {{
                                $t('com_selecteer', {
                                    ns: 'common'
                                })
                            }}
                            --</b-form-select-option
                        >
                    </template>
                    <option
                        v-for="option in itemStockList"
                        :value="option.value"
                        :key="option.value"
                        v-bind:class="{ tc_aux_highlighted: option.warn }"
                    >
                        {{ option.text }}
                    </option>
                </b-form-select>

                <b-form-input
                    v-if="existing"
                    v-model="localSelected.partijNummer"
                    readonly
                ></b-form-input>
            </b-input-group>
        </b-col>

        <b-col sm="3" class="mx-0 px-0 my-0 py-0">
            <b-input-group
                append=""
                :label="$t('com_amount', { ns: 'common' })"
            >
                <b-form-input
                    id="input-hoeveelheid"
                    :placeholder="$t('com_amount', { ns: 'common' })"
                    type="number"
                    step="1"
                    v-model.number="localSelected.hoeveelheid"
                    :style="
                        localSelected.isNew
                            ? localSelected.isChanged
                                ? ''
                                : {
                                      'background-color': '#33FF90',
                                      color: '#33FF90'
                                  }
                            : ''
                    "
                    @change="set_input"
                    :disabled="!editAllowed"
                ></b-form-input>
            </b-input-group>
        </b-col>

        <b-col sm="3" class="mx-0 px-0 my-0 py-0">
            <lookup-combo
                v-model="localSelected.eenheidID"
                v-bind:combotype="lctypeUnits"
                v-bind:discrim="baseUnitString"
                :disabled="!editAllowed"
                @change="set_input"
            ></lookup-combo>
        </b-col>
        <maintenance-pop-form
            v-if="detailsVisible"
            :displayNumber="selectedRadio"
            v-model="detailsVisible"
            v-bind:editAllowed="true"
            @hide="reset"
        />
    </div>
    <div v-else>{{ $t('com_createstock', { ns: 'common' }) }}</div>
</template>
<script>
import { BakIngredient } from '../../models/BakInfo';
import Helper from '../../models/Helper';
import DateHelper from '../../models/DateHelper';
import { loadItem, loadList, loadGetItem } from '../../models/DataHelper';
import { LookupCombo } from '.';
import { Permission } from '../../models/Permissions';
import { Feature } from '../../models/Features';
import { INGRCAT_ENUM } from '../../models/IngredientCategorie';
export default {
    name: 'BakIngredientCombo',
    data: function () {
        return {
            lctypeUnits: window.constants.LCPERUNITS,
            errorMessage: '',
            partijID: null,
            eenheidID: null,
            flexList: [],
            warnAmount: false,
            warnTHT: undefined,
            selectedIsSet: false,
            selectedRadio: 'silo',
            detailsVisible: false,
            localTijd: undefined,
            rerenderkey: 1,
            strNoStock: 'Is voorraad voor {0} op ?'
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {
            type: BakIngredient,
            required: true
        },
        editAllowed: {
            type: Boolean,
            default: false
        },
        baseUnit: {
            type: Number,
            default: -1
        },
        baseDateTime: {
            type: String
        },
        newDateTime: {
            type: String
        },
        setSelected: {
            type: String
        },
        withPrepend: {
            type: Boolean,
            default: false
        },
        isKlaarZetten: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LookupCombo,
        // aparte import om recusiviteit te voorkomen
        MaintenancePopForm: () =>
            import('../maintenance').then((m) => m.MaintenancePopForm)
    },
    watch: {
        localSelectedHoeveelheid: {
            deep: true,
            // eslint-disable-next-line no-unused-vars
            handler: function (newVal, oldVal) {
                //console.log('Prop changed: ', newVal, ' | was: ', oldVal);
            }
        },
        localSelected: {
            deep: true,
            // eslint-disable-next-line no-unused-vars
            handler: function (newVal, oldVal) {
                //console.log('Object changed: ', newVal, ' | was: ', oldVal);
            }
        },
        newDateTime: {
            handler(newVal, oldVal) {
                if (newVal != undefined && !this.existing) {
                    //console.log('Tijd changed: ', newVal, ' | was: ', oldVal);
                    this.localSelected.toegevoegdTijdPrep = newVal;
                    this.localSelected.toegevoegdTijd =
                        DateHelper.convertedFromTimepicker(
                            this.baseDateTime,
                            newVal
                        );
                    this.localTijd = newVal;
                    this.rerenderkey += 1;
                }
            }
        }
    },
    computed: {
        localSelected() {
            return this.selected;
        },
        localSelectedHoeveelheid() {
            return this.selected.hoeveelheid;
        },
        warnForStockAmount() {
            return this.warnAmount;
        },
        itemStockList() {
            const tempList = this.flexList.map((x) => {
                const tekst = x.datum
                    ? Helper.stringformat(
                          '{0} (' +
                              this.$t('com_tht', { ns: 'common' }) +
                              ': {1})',
                          x.batchID,
                          DateHelper.convertedToDateShort(x.datum)
                      )
                    : x.batchID;
                return {
                    value: x.id,
                    text: tekst,
                    warn: x.waarschuwen
                };
            });

            return tempList;
        },
        baseUnitString() {
            return this.baseUnit > -1
                ? this.baseUnit.toString()
                : this.localSelected.eenheidID;
        },
        existing() {
            return this.localSelected.id > 0;
        },
        toonSelectie() {
            return this.existing || this.itemStockList.length > 0;
        },
        giveNaam() {
            return this.withPrepend ? this.localSelected.naam : '';
        },
        giveBGclass() {
            return this.localSelected.toegevoegdTijd != undefined
                ? 'bg-green'
                : ' bg-blue';
        },
        giveBGstyle() {
            return this.localSelected.toegevoegdTijd != undefined
                ? 'color=ghostwhite'
                : ' fontcolor ';
        }
    },
    methods: {
        reset() {
            this.detailsVisible = false;
            this.$parent.$emit('production_refresh');
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-itemBakInfo');
            });
        },
        findPartijID: function (batchID) {
            let partijID = '';
            const stockItem = this.flexList.find((x) => x.batchID == batchID);
            if (stockItem) partijID = stockItem.id;
            return partijID;
        },
        findPartijNummer: function (partijID) {
            let partijnummer = '';
            const stockItem = this.flexList.find((x) => x.id == partijID);
            if (stockItem) partijnummer = stockItem.batchID;
            return partijnummer;
        },
        findPartijAmount: function (partijID) {
            let hh = 0;
            const stockItem = this.flexList.find((x) => x.id == partijID);
            if (stockItem) hh = stockItem.aantal;
            return hh;
        },
        set_inputPartij: async function () {
            const stockItem = this.flexList.find((x) => x.id == this.partijID);
            const hh = stockItem ? stockItem.aantal : 0;
            this.warnAmount = hh < 0;
            if (stockItem?.datum) {
                const dagen = DateHelper.dateDiffinDays(
                    stockItem.datum,
                    new Date()
                );
                const toegevoegd = this.localSelected.toegevoegdTijd != undefined;
                if (dagen < 0 && !toegevoegd) {
                    this.warnTHT = stockItem.datum;
                    this.$emit('warnTHT', this.warnTHT);
                }
            }
            if (this.localSelected.partijID != undefined) {
                if (this.setSelected && !this.selectedIsSet) {
                    this.partijID = this.findPartijID(this.setSelected);
                    this.selectedIsSet = true;
                }
                if (
                    this.localSelected.partijID &&
                    this.localSelected.partijID != this.partijID
                ) {
                    if (this.checkRight('StockClose')) {
                        const message = Helper.stringformat(
                            this.strNoStock,
                            this.localSelected.partijNummer
                        );
                        const doClose = await this.$root.showConfirmationBox(
                            message
                        );
                        if (doClose) {
                            // zet de voorraad op leeg
                            loadItem(
                                'stock/loadStockItem',
                                { stockitemid: this.localSelected.partijID },
                                this.$t
                            )
                                .then((x) => {
                                    const selectionData = {
                                        ID: x.id,
                                        LastUpdated: x.gewijzigd,
                                        IsEmpty: true,
                                        NeverUseAgain: false
                                    };
                                    loadItem(
                                        'stock/closeStockItem',
                                        selectionData,
                                        this.$t
                                    );
                                })
                                .then(() => {
                                    this.loadStockItemList(
                                        this.selected.productID
                                    );
                                });
                        }

                        const cat = this.$store.getters[
                            'product/getIngredientCategorieByID'
                        ](INGRCAT_ENUM.Melk);
                        const vloeistof =
                            this.localSelected.ingredientCat == cat.id;
                        if (
                            this.checkFeature('conf_storeAskSiloCleaning') &&
                            vloeistof
                        ) {
                            const doClean =
                                await this.$root.showConfirmationBox(
                                    this.$t('prod_newbatchquestion', {
                                        ns: 'production'
                                    })
                                );
                            if (doClean) {
                                this.detailsVisible = !this.detailsVisible;
                                return;
                            }
                        }
                    }
                }
            }
            this.localSelected.partijID = this.partijID;
            this.localSelected.partijNummer = this.findPartijNummer(
                this.partijID
            );
            this.set_input();
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        },
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return this.$root.handleFeature(key);
        },
        set_inputTijd() {
            this.localSelected.toegevoegdTijdPrep = this.localTijd;
            const tijd = this.localSelected.toegevoegdTijdPrep;
            this.localSelected.toegevoegdTijd =
                DateHelper.convertedFromTimepicker(this.baseDateTime, tijd);
            this.$emit('tijd', this.localSelected.toegevoegdTijd);
            if (!this.existing) {
                this.$emit(
                    'newDateTime',
                    this.localSelected.toegevoegdTijdPrep
                );
            }
            this.set_input();
        },
        set_input() {
            this.localSelected.isChanged = true;
            this.$emit('change', true);
            this.$emit('input', this.localSelected);
        },
        async loadStockItemList(productID) {
            const selectionData = { productid: productID };
            const locList = await loadList(
                'product/getStockItemLijst',
                'stock/loadStockByProduct',
                selectionData,
                true,
                this.$t
            );
            if (locList.length > 0) {
                const dezelfde = productID == locList[0].productID;
                if (dezelfde) this.flexList = locList;
            }
            this.partijID =
                this.localSelected.partijID ??
                (this.itemStockList.length > 0
                    ? this.itemStockList[0].value
                    : null);
            if (this.itemStockList.length < 1 && !this.existing)
                this.$emit('nostock', true);
        }
    },
    async mounted() {
        this.localTijd = this.localSelected.toegevoegdTijdPrep;
        if (!this.isKlaarZetten) {
            this.set_input();
        }
        if (this.existing) return;
        const prodID = this.selected.productID;
        const locList = await loadList(
            'product/getStockItemLijst',
            'stock/loadStockByProduct',
            { productid: prodID },
            true,
            this.$t
        );
        if (locList.length > 0) {
            const dezelfde = prodID == locList[0].productID;
            if (dezelfde) {
                this.flexList = locList;
                this.partijID =
                    this.localSelected.partijID ??
                    (this.itemStockList.length > 0
                        ? this.itemStockList[0].value
                        : null);
                this.set_inputPartij();
            } else this.loadStockItemList(prodID);
        } else {
            this.loadStockItemList(prodID);
        }
    },
    created() {
        if (this.selected.eenheidID == undefined) {
            this.selected.eenheidID = this.$store.getters[
                'components/getUnitIDbyName'
            ](this.selected.eenheid);
        }
    }
};
// .bgc_aux_normal
// .bgc_auxzenze_blue
</script>

<style scoped>
.bg-green {
    background-color: #c1e881 !important;
    color: ghostwhite !important;
}
.bg-blue {
    background-color: hwb(203 55% 7%) !important;
    color: ghostwhite !important;
}
.fontcolor {
    color: red;
}
</style>
