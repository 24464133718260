<template>
    <div>
        <b-input-group v-if="existing">
            <b-form-select
                key="rerenderkey"
                v-if="existing"
                v-model="localSelected"
                :options="flexList"
                :disabled="disabled"
                :state="checkState"
                @change="set_input"
            >
                <template #first>
                    <b-form-select-option value=""
                        >--
                        {{
                            $t('com_selecteer', {
                                ns: 'common'
                            })
                        }}
                        --</b-form-select-option
                    >
                </template>
            </b-form-select>

            <b-dropdown
                v-if="showfilter"
                text="..."
                ref="dropdown"
                size="sm"
                boundary="viewport"
            >
                <div class="fluid-container">
                    <b-dropdown-form>
                        <div>
                            <b-form-group
                                label="Filter:"
                                label-for="dropdown-form-filter"
                            >
                                <b-form-row>
                                    <b-col sm="10"
                                        ><b-form-input
                                            id="dropdown-form-filter"
                                            v-model="filterTekst"
                                            :placeholder="
                                                $t('com_filter', {
                                                    ns: 'common'
                                                })
                                            "
                                        ></b-form-input>
                                    </b-col>
                                    <b-col sm="2"
                                        ><b-button
                                            class="float-right"
                                            variant="primary"
                                            @click="filterLijst()"
                                            ><font-awesome-icon
                                                icon="search"
                                                size="1x"
                                        /></b-button>
                                    </b-col>
                                </b-form-row>
                            </b-form-group>
                        </div>
                        <b-dropdown-item
                            v-if="filterActive"
                            @click="clearFilter()"
                            >{{
                                $t('com_wis', { ns: 'common' })
                            }}</b-dropdown-item
                        >
                    </b-dropdown-form>
                </div></b-dropdown
            >
        </b-input-group>
        <b-form-input
            v-if="!existing"
            v-model="productNaam"
            readonly
        ></b-form-input>
    </div>
</template>
<script>
import { loadList, loadItem } from '../../models/DataHelper';
import Helper from '../../models/Helper';
import { PRODCAT_ENUM } from '../../models/ProductCategorie';
import { INGRCAT_ENUM } from '../../models/IngredientCategorie';
export default {
    name: 'LookupCombo',
    data: function () {
        return {
            errorMessage: '',
            localSelected: null,
            rerenderkey: 1,
            flexList: [],
            flexListNonFiltered: [],
            isInList: true,
            productNaam: '',
            filterTekst: '',

            stringPartij:
                this.$t('com_batch', { ns: 'common' }) +
                ': {0}, ' +
                this.$t('prod_vorm', { ns: 'production' }).toLowerCase() +
                ' : {1}, ' +
                this.$t('com_number', { ns: 'common' }).toLowerCase() +
                ': {2}',
            stringPartijLeeftijd:
                this.$t('com_batch', { ns: 'common' }) +
                ': {0}, ' +
                this.$t('prod_vorm', { ns: 'production' }).toLowerCase() +
                ' : {1}, ' +
                this.$t('com_number', { ns: 'common' }).toLowerCase() +
                ': {2} {3} ({4} ' +
                this.$t('com_dagen', { ns: 'common' }).toLowerCase() +
                ')'
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        combotype: {
            type: Number
        },
        selected: {
            //type: Number
        },
        productText: {
            type: String,
            default: ''
        },
        discrim: {
            type: String,
            default: ''
        },
        discrimArray: {
            type: Array
        },
        zelf: {
            type: Object
        },
        disabled: {
            type: Boolean,
            default: false
        },
        forcerefresh: {
            type: Boolean,
            default: false
        },
        showfilter: {
            type: Boolean,
            default: false
        },
        filter: {
            default: ''
        },
        doCheckState: {
            type: Boolean,
            default: false
        },
        include10plus: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        discrim() {
            this.refreshLists();
        },
        discrimArray() {
            this.refreshLists();
        },
        selected() {
            this.localSelected = this.selected;
        },
        filter: {
            handler: function (newVal, oldVal) {
                this.filterTekst = newVal;
                if (this.showfilter) this.filterLijst();
            }
        }
    },
    computed: {
        existing() {
            return this.checkExisting();
        },
        filterActive() {
            return this.filterTekst.length > 0;
        },
        checkState() {
            if (!this.doCheckState) return null;
            if (this.localSelected == undefined || this.localSelected == "") return false;
            return this.localSelected > -1;
        }
    },
    methods: {
        filterLijst(nohide) {
            if (this.flexListNonFiltered.length == 0)
                this.flexListNonFiltered = this.flexList;
            // todo lelijke workaround, moet anders ItemStockModal
            if (this.filterTekst === 'kaas') {
                this.flexList.filter(
                    (x) =>
                        x.categorie == PRODCAT_ENUM.Kaas ||
                        x.categorie == PRODCAT_ENUM.Onvolwaardig
                );
            } else {
                this.flexList = this.flexList.filter((x) =>
                    x.text.includes(this.filterTekst)
                );
            }
            this.rerenderkey += 1;
            if (!nohide) this.$refs.dropdown.hide(true);
        },
        clearFilter() {
            this.filterTekst = '';
            this.flexList = this.flexListNonFiltered;
            this.rerenderkey += 1;
        },
        set_input() {
            const item = this.flexList.find(
                (x) => x.value == this.localSelected
            );
            this.tekst = item ? item.text : '';
            if (
                typeof this.localSelected === 'string' ||
                this.localSelected instanceof String
            ) {
                this.localSelected = this.localSelected
                    ? this.localSelected.trim() == ''
                        ? null
                        : this.localSelected
                    : null;
            }
            this.$emit('change', true);
            this.$emit('input', this.localSelected);
            this.$emit('inputnaam', this.tekst);
        },
        async checkExisting() {
            const item = this.flexList.find((x) => x.value == this.selected);
            this.isInList = item;
            if (this.isInList) return true;
            // probeer hem nog te vinden
            if (!this.isInList && this.selected) {
                switch (this.combotype) {
                    case window.constants.LCSHAPES:
                        this.productNaam = await loadItem(
                            'components/loadShape',
                            { shapeid: this.selected },
                            this.$t
                        );
                        break;
                    case window.constants.LCMILKTYPE:
                    case window.constants.LCMARKTYPE:
                    case window.constants.LCCHEESE:
                    case window.constants.LCCHEESEWITHOUTRECIPE:
                    case window.constants.LCFAILURECHEESE:
                    case window.constants.LCINGREDIENT:
                        this.productNaam = await loadItem(
                            'product/loadProduct',
                            { productID: this.selected },
                            this.$t
                        );
                        break;
                    case window.constants.LCCOMPANY:
                        this.productNaam = await loadItem(
                            'components/loadCompany',
                            { companyid: this.selected },
                            this.$t
                        );
                        break;
                    case window.constants.LCPRODUCT:
                    case window.constants.LCPRODUCTSTOCK:
                    case window.constants.LCBRINEPRODUCT:
                        break;
                    case window.constants.LCLOCATION:
                        this.productNaam = await loadItem(
                            'components/loadLocation',
                            { stockLocationID: this.selected },
                            this.$t
                        );
                        break;
                    case window.constants.LCFATCLASS:
                    case window.constants.LCPERUNITS:
                    case window.constants.LCTRR:
                    default: {
                        return [];
                    }
                }
            }
            if (!this.productNaam || this.productNaam.length < 1)
                this.productNaam = this.productText;
            return false;
        },
        refreshLists: async function () {
            switch (this.combotype) {
                case window.constants.LCPERUNITS: {
                    const templijst = await loadList(
                        'components/getUnitsComboLijst',
                        'components/loadUnitsComboLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    this.flexList = this.discrim
                        ? templijst.filter(
                              (x) =>
                                  x.base == this.discrim ||
                                  x.value == this.discrim
                          )
                        : templijst;
                    break;
                }
                case window.constants.LCPERUNITSBASEONLY: {
                    const templijst = await loadList(
                        'components/getUnitsComboLijst',
                        'components/loadUnitsComboLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    const arrPLus = [
                        window.constants.US_GALLON,
                        window.constants.IMP_GALLON,
                        window.constants.POUNDS
                    ];
                    this.flexList = this.discrim
                        ? templijst.filter(
                              (x) =>
                                  x.value == this.discrim ||
                                  (x.base == this.discrim &&
                                      arrPLus.includes(x.value))
                          )
                        : templijst.filter(
                              (x) =>
                                  x.base == undefined ||
                                  arrPLus.includes(x.value)
                          );
                    break;
                }
                case window.constants.LCSHAPES: {
                    this.flexList = await loadList(
                        'components/getShapesComboLijst',
                        'components/loadShapesComboLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    break;
                }
                case window.constants.LCMILKTYPE: {
                    this.flexList = await loadList(
                        'components/getMilkTypeIGComboLijst',
                        'components/loadGroupsForIngredientCatComboLijst',
                        { ingredientCategoryID: INGRCAT_ENUM.Melk },
                        this.forcerefresh,
                        this.$t
                    );
                    break;
                }
                case window.constants.LCMARKTYPE: {
                    this.flexList = await loadList(
                        'components/getCheeseMarksComboLijst',
                        'components/loadCheeseMarksComboLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    if (this.discrim) {
                        this.flexList = this.flexList.filter(
                            (x) =>
                                x.fatclass == undefined ||
                                x.fatclass == this.discrim
                        );
                    }
                    break;
                }
                case window.constants.LCFATCLASS:
                    this.flexList =
                        this.$store.getters['components/getFatClassComboLijst'];
                    if (this.flexList.length < 1) {
                        await loadItem('components/loadFatClassComboLijst');
                        this.flexList =
                            this.$store.getters[
                                'components/getFatClassComboLijst'
                            ];
                    }
                    break;
                case window.constants.LCCHEESE:
                    this.flexList = await loadList(
                        'components/getCheesesComboLijst',
                        'components/loadCheesesComboLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    if (this.discrim) {
                        this.flexList = this.flexList.filter(
                            (x) => x.value != this.discrim
                        );
                    }
                    break;
                case window.constants.LCCHEESEWITHOUTRECIPE:
                    this.flexList = await loadList(
                        'components/getCheesesWithoutRecipeComboLijst',
                        'components/loadCheesesWithoutRecipeComboLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    break;
                case window.constants.LCCHEESEALTERNATIVES: {
                    const templijst = await loadList(
                        'components/getCheesesAlternativesComboLijst',
                        'components/loadCheeseTypeAlternatives',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    const lijstAlt = this.discrim
                        ? templijst.filter(
                              (x) => x.baseproductid == this.discrim
                          )
                        : templijst;
                    if (lijstAlt.length == 0) {
                        const alt2 = templijst.filter(
                            (x) => x.value == this.discrim
                        );
                        this.flexList = alt2.map((x) => ({
                            value: x.baseproductid,
                            text: x.baseproductnaam,
                            item: x.item
                        }));
                    } else {
                        this.flexList = lijstAlt;
                    }
                    break;
                }
                case window.constants.LCCHEESEWITHOUTRECIPEANDSELF: {
                    this.flexList = await loadList(
                        'components/getCheesesWithoutRecipeComboLijst',
                        'components/loadCheesesWithoutRecipeComboLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    if (this.zelf != undefined) {
                        const test = this.flexList.find(
                            (x) => x.value == this.zelf.value
                        );
                        if (!test) {
                            this.flexList =
                                this.$store.getters[
                                    'components/getCheesesWithoutRecipeComboLijst'
                                ].slice();
                            this.flexList.push(this.zelf);
                        }
                    }
                    break;
                }
                case window.constants.LCFAILURECHEESE: {
                    this.flexList = await loadList(
                        'components/getFailureCheeseComboLijst',
                        'components/loadFailureCheeseComboLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    break;
                }
                case window.constants.LCINGREDIENT: {
                    await loadItem(
                        'components/loadIngredientsForGroupComboLijst',
                        { category: this.discrim }
                    );
                    this.flexList =
                        this.$store.getters[
                            'components/getIngredientComboLijst'
                        ];
                    break;
                }
                case window.constants.LCCOMPANY: {
                    this.flexList = await loadList(
                        'components/getCompaniesComboLijst',
                        'components/loadCompaniesComboLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    break;
                }
                case window.constants.LCPRODUCT: {
                    this.flexList = await loadList(
                        'components/getProductsComboLijst',
                        'components/loadProductsComboLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    if (this.include10plus){
                        const lijst10plus = await loadList(
                        'components/getSimpleProdComboLijst',
                        'components/loadSimpleProducts',
                            null,
                            this.forcerefresh,
                            this.$t
                        );
                        this.flexList = this.flexList.concat(lijst10plus).sort((a,b) => b.text < a.text ? 1 : -1);
                    }
                    if (this.discrim) {
                        if (this.discrim.indexOf('!') < 0) {
                            this.flexList = this.flexList.filter((c) => {
                                return c.catid == this.discrim;
                            });
                        } else {
                            const loc = this.discrim.replace('!', '');
                            this.flexList = this.flexList.filter((c) => {
                                return c.catid != loc;
                            });
                        }
                    }
                    break;
                }
                case window.constants.LCBRINEPRODUCT: {
                    this.flexList = await loadList(
                        'components/getBrineIngrComboLijst',
                        'components/loadBrineIngredientsComboLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    break;
                }
                case window.constants.LCPRODUCTSTOCK: {
                    const words = this.discrim.split('&&');
                    const prod = words[0];
                    const filter = words.length > 1 ? words[1] : null;
                    let tempLijst = await loadItem(
                        'stock/loadStockByProduct',
                        { productid: prod },
                        this.$t
                    );
                    if (filter) {
                        tempLijst = tempLijst.filter(
                            (x) => x.melkBron == filter
                        );
                    }
                    const lijstNoDN = tempLijst.filter(
                        (x) => x.pakbonID == undefined
                    );
                    // vormen bij elkaar
                    lijstNoDN?.sort((a, b) =>
                        a.vorm > b.vorm ? 1 : b.vorm > a.vorm ? -1 : 0
                    );
                    this.flexList = lijstNoDN?.map((x) => ({
                        value: x.id,
                        text: x.leeftijdKlasse
                            ? Helper.stringformat(
                                  this.stringPartijLeeftijd,
                                  x.batchID,
                                  x.vorm ?? '-',
                                  x.aantal,
                                  x.leeftijdKlasse ?? '-',
                                  x.leeftijdDagen ?? '-'
                              )
                            : Helper.stringformat(
                                  this.stringPartij,
                                  x.batchID,
                                  x.vorm ?? '-',
                                  x.aantal
                              )
                    }));
                    if (this.filterTekst != '') {
                        this.filterLijst(true);
                    }
                    break;
                }
                case window.constants.LCTRR: {
                    const templijst = await loadList(
                        'treatment/getTreatmentRecipes',
                        'treatment/loadTreatmentRecipes',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    const res = templijst?.filter((value, index, self) => {
                        return (
                            self.findIndex((v) => v.id === value.id) === index
                        );
                    });
                    this.flexList = res?.map((x) => ({
                        value: x.id,
                        text: x.naam
                    }));
                    break;
                }
                case window.constants.LCLOCATION: {
                    this.flexList = await loadList(
                        'components/getLocationsComboLijst',
                        'components/loadLocationsComboLijst',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    if (this.discrim) {
                        if (this.discrim.indexOf('!') < 0) {
                            this.flexList = this.flexList.filter((c) => {
                                return c.type == this.discrim;
                            });
                        } else {
                            const loc = this.discrim.replace('!', '');
                            this.flexList = this.flexList.filter((c) => {
                                return c.type != loc;
                            });
                        }
                    } else {
                        if (this.discrim == undefined) {
                            this.flexList = this.flexList.filter((c) => {
                                return c.type == undefined;
                            });
                        }
                    }
                    break;
                }
                case window.constants.LCSIMPLEPRODUCT: {
                    const lijst = await loadList(
                        'components/getSimpleProdComboLijst',
                        'components/loadSimpleProducts',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    let newArray = lijst.slice();
                    if (this.discrimArray) {
                        if (this.discrimArray.length > 0) {
                            const lists = Helper.reduceListWithList(
                                newArray,
                                this.discrimArray
                            );
                            newArray = lists.list1;
                        }
                    }
                    this.flexList = newArray;
                    break;
                }
                case window.constants.LCPRODUCTIONVALUES: {
                    const lijst = await loadList(
                        'tienplus/getProductValueLijst',
                        'tienplus/loadProductionValues',
                        null,
                        this.forcerefresh,
                        this.$t
                    );
                    this.flexList = lijst.map((x) => ({
                        value: x.id,
                        text: x.naam,
                        item: x.item
                    }));
                    break;
                }
                default: {
                    return [];
                }
            }
            if (!this.disabled) {
                // if (this.selected == undefined || this.selected < 0) {
                if (this.flexList.length == 1) {
                    this.localSelected = this.flexList[0].value;
                    this.set_input();
                }
            }
        }
    },
    beforeUpdate() {},
    mounted() {
        this.localSelected = this.selected ?? '';
    },
    created() {
        this.refreshLists();
    }
};
</script>

<style scoped></style>

