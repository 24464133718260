import Helper from './Helper';
import DateHelper from './DateHelper';
export { Vorm, Ost, Location, Company, Equipment, Bericht };

class Bericht {
    constructor(data) {
        this.id = -1;
        this.tableName = 'message';
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        this.productiedatum = data.bookdate;
        this.bak = data.batchnumber;
        this.vorm = data.shape;
        this.bericht = data.message;
        this.berichttype = data.messagetype?.toLowerCase();
    }
    getBerichtTitel(func) {
        let warnText = '';
        if (this.bak) {
            warnText = Helper.stringformat(
                func('com_berichttitelbak', {
                    ns: 'common'
                }),
                DateHelper.convertedToDate(this.productiedatum),
                this.bak ?? '?',
                this.vorm ?? '?'
            );
        }
        return warnText;
    }
    getBericht(func) {
        switch (this.bericht) {
            case 'Niet ingepekeld':
                return func('rep_nietinpekel', { ns: 'report' });
            case 'Niet uitgepekeld':
                return func('rep_nietuitpekel', { ns: 'report' });
        }
        return func(this.bericht, { ns: 'report' });
    }
}

class Vorm {
    constructor(data) {
        this.id = -1;
        this.tableName = 'shape';
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false
            },
            gewijzigd: {
                value: data.updated,
                writable: false
            },
            naam: {
                type: String,
                value: data.description ?? data.descriptivetext,
                writable: true,
                enumerable: true
            },
            vorm: {
                type: String,
                value: data.shape,
                writable: true,
                enumerable: true
            },
            gewicht: {
                type: Number,
                value: data.weight,
                writable: true,
                enumerable: true
            },
            diameter: {
                type: Number,
                value: data.diameter,
                writable: true,
                enumerable: true
            },
            lengte: {
                type: Number,
                value: data.length,
                writable: true,
                enumerable: true
            },
            breedte: {
                type: Number,
                value: data.width,
                writable: true,
                enumerable: true
            },
            hoogte: {
                type: Number,
                value: data.height,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }

    SetForSave() {
        this.descriptivetext = this.naam;
        this.shape = this.vorm;
        this.weight = this.gewicht;
    }
    SetForUpdate() {
        const ShapeModel = {
            ID: this.id,
            Descriptivetext: this.naam,
            Shape: this.vorm,
            Weight: this.gewicht,
            Updated: this.gewijzigd
        };
        return ShapeModel;
    }
    CheckValidity(func) {
        let warnText = '';
        if (this.gewicht) {
            if (this.gewicht < 0.1 || this.gewicht > 120)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('com_weight', { ns: 'common' }),
                        val: this.gewicht
                    })
                );
        }
        return warnText;
    }
}

class Ost {
    constructor(data) {
        this.id = -1;
        this.tableName = 'ost';
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false
            },
            gewijzigd: {
                value: data.updated,
                writable: false
            },
            naam: {
                type: String,
                value: data.internalID,
                writable: true,
                enumerable: true
            },
            nummer: {
                type: Number,
                value: data.number,
                writable: true,
                enumerable: true
            },
            maximum: {
                type: Number,
                value: data.maxFill,
                writable: true,
                enumerable: true
            },
            rest: {
                type: Number,
                value: data.minRest,
                writable: true,
                enumerable: true
            },
            lijn: {
                type: Number,
                value: data.productionLineID ?? window.constants.DEFPRODLINE,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }

    SetForSave() {
        this.internalID = this.naam;
        this.number = this.nummer;
    }
    SetForUpdate() {
        const OstModel = {
            ID: this.id,
            InternalID: this.naam,
            Number: this.nummer,
            MaxFill: this.maximum,
            MinRest: this.rest,
            ProductionLineID: this.lijn,
            Updated: this.gewijzigd
        };
        return OstModel;
    }
    CheckValidity(func) {
        let warnText = '';
        if (this.maximum) {
            if (this.maximum < 100 || this.maximum > 30000)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('com_maximum', { ns: 'common' }),
                        val: this.maximum
                    })
                );
        }
        return warnText;
    }
}

class Location {
    constructor(data, siloLookup) {
        this.id = -1;
        this.tableName = 'stocklocation';
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false
            },
            gewijzigd: {
                value: data.updated,
                writable: false
            },
            naam: {
                type: String,
                value: data.descriptivetext,
                writable: true,
                enumerable: true
            },
            loctype: {
                type: Number,
                value: data.type,
                writable: true,
                enumerable: true
            },
            unlimited: {
                type: Number,
                value: data.unlimitedsupply,
                writable: true,
                enumerable: true
            },
            volume: {
                type: Number,
                value: data.volume,
                writable: true,
                enumerable: true
            },
            accepteerIngredient: {
                type: Number,
                value: data.addingredient,
                writable: true,
                enumerable: true
            }
        });
        const o = siloLookup
            ? siloLookup.find((x) => x.value == this.loctype)
            : null;
        this.typeNaam = o ? o.text : '';
        this.isNew = this.id == undefined || this.id < 0;
    }

    SetForSave() {}
    SetForUpdate() {
        const StockLocationModel = {
            ID: this.id,
            Descriptivetext: this.naam,
            Volume: this.volume,
            Type: this.loctype,
            Updated: this.gewijzigd
        };
        return StockLocationModel;
    }
    CheckValidity(func) {
        let warnText = '';
        if (this.volume) {
            if (this.volume < 1 || this.volume > 300000)
                warnText = Helper.concatString(
                    warnText,
                    func('com_notplausible', {
                        ns: 'common',
                        prop: func('prod_volume', { ns: 'production' }),
                        val: this.volume
                    })
                );
        }
        return warnText;
    }
}

class Equipment {
    constructor(data) {
        this.id = -1;
        this.tableName = 'equipment';
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false
            },
            gewijzigd: {
                value: data.updated,
                writable: false
            },
            naam: {
                type: String,
                value: data.descriptivetext,
                writable: true,
                enumerable: true
            },
            opmerking: {
                type: String,
                value: data.remark,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }

    SetForSave() {}
    SetForUpdate() {
        const EquipmentModel = {
            ID: this.id,
            Descriptivetext: this.naam,
            Remark: this.opmerking,
            Updated: this.gewijzigd
        };
        return EquipmentModel;
    }
    CheckValidity(func) {
        const warnText = '';
        return warnText;
    }
}

class Company {
    constructor(data) {
        this.id = -1;
        this.tableName = 'company';
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                value: data.id,
                writable: false
            },
            gewijzigd: {
                value: data.updated,
                writable: false
            },
            naam: {
                type: String,
                value: data.companyName,
                writable: true,
                enumerable: true
            },
            naamkort: {
                type: String,
                value: data.smallName,
                writable: true,
                enumerable: true
            },
            naamopslag: {
                type: String,
                value: data.saveAsCompName,
                writable: true,
                enumerable: true
            },
            opmerking: {
                type: String,
                value: data.remark,
                writable: true,
                enumerable: true
            },
            internID: {
                type: String,
                value: data.internalID,
                writable: true,
                enumerable: true
            },
            externID: {
                type: String,
                value: data.externalID,
                writable: true,
                enumerable: true
            },
            adresStraat: {
                type: String,
                value: data.streetAdress,
                writable: true,
                enumerable: true
            },
            postcodeStraat: {
                type: String,
                value: data.streetZip,
                writable: true,
                enumerable: true
            },
            plaatsStraat: {
                type: String,
                value: data.streetCity,
                writable: true,
                enumerable: true
            },
            land: {
                type: String,
                value: data.country,
                writable: true,
                enumerable: true
            },
            post1: {
                type: String,
                value: data.postalline1,
                writable: true,
                enumerable: true
            },
            post2: {
                type: String,
                value: data.postalline2,
                writable: true,
                enumerable: true
            },
            post3: {
                type: String,
                value: data.postalline3,
                writable: true,
                enumerable: true
            },
            mail: {
                type: String,
                value: data.email,
                writable: true,
                enumerable: true
            },
            telefoon: {
                type: String,
                value: data.phone,
                writable: true,
                enumerable: true
            },
            debiteur: {
                type: String,
                value: data.debno,
                writable: true,
                enumerable: true
            },
            crediteur: {
                type: String,
                value: data.credno,
                writable: true,
                enumerable: true
            },
            attcount: {
                type: Number,
                value: data.attachments,
                writable: true,
                enumerable: true
            },
            verloopdatum: {
                value: data.docexpirationdate,
                writable: true,
                enumerable: true
            },
            doctot: {
                value: data.docuntil,
                writable: true,
                enumerable: true
            },
            // 0 = normal, 1 = warning, 2 = error
            docstatus: {
                value: 0,
                writable: true,
                enumerable: true
            }
        });
        this.isNew = this.id == undefined || this.id < 0;
    }
    
    geefAttInfo(func) {
        const warningdays = window.constants.ALARM_DOCDATE;
        if (this.attcount == undefined || this.attcount < 1) return '-';
        const datum = this.verloopdatum
            ? this.doctot
                ? this.doctot > this.verloopdatum
                    ? this.verloopdatum
                    : this.doctot
                : this.verloopdatum
            : this.doctot;
        if (datum != null) {
            const comparedate = new Date();
            const diff = DateHelper.dateDiffinDays(datum, comparedate);
            this.docstatus = diff < 0 ? 2 : diff < warningdays ? 1 : 0;
            const str = DateHelper.convertedToDate(datum, false);
            return Helper.stringformat('{0}: {1}', this.attcount, str);
        }
        return this.attcount;
    }

    SetForSave() {}
    SetForUpdate() {
        const CompanyModel = {
            ID: this.id,
            Updated: this.gewijzigd,
            CompanyName: this.naam,
            SmallName: this.naamkort,
            SaveAsCompName: this.naamopslag,
            InternalID: this.internID,
            ExternalID: this.externID,
            Remark: this.opmerking,
            StreetAdress: this.adresStraat,
            StreetZip: this.postcodeStraat,
            StreetCity: this.plaatsStraat,
            Country: this.land,
            Email: this.mail,
            Phone: this.telefoon,
            Postalline1: this.post1,
            Postalline2: this.post2,
            Postalline3: this.post3,
            Debno: this.debiteur,
            Credno: this.crediteur
        };
        return CompanyModel;
    }
    CheckValidity(func) {
        const warnText = '';
        return warnText;
    }
}

