<template>
    <b-badge
        @click="showBatches"
        class="bgc_aux_unknown float-right"
        :disabled="!editAllowed"
        ><h3>{{ giveEvents }}</h3>
    </b-badge>
</template>

<script>
import DateHelper from '../../models/DateHelper';
import { loadItem } from '../../models/DataHelper';
export default {
    name: 'PlanBatchesButton',
    data() {
        return {
            numberBatchesToday: 0,
            numberBatchesWeek: 0,
            batchList: [],
            interval: null,
            tijd: 1000 * 60 * 10
            
        };
    },
    components: {},
    model: {},
    props: {
        editAllowed: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        giveEvents() {
            return this.numberBatchesToday + ' / ' + this.numberBatchesWeek;
        }
    },
    methods: {
        showBatches() {
            this.$emit('show', this.batchList);
        },
        async loadPlanBatches() {
            const week = DateHelper.determineVanTot(new Date(), 7);
            const selectionDataWeek = {
                from: DateHelper.localDateToUTC(week.van),
                until: DateHelper.localDateToUTC(week.tot)
            };
            const lijst = await loadItem(
                'productionorder/loadProductionOrderInfo',
                selectionDataWeek,
                this.$t
            );
            this.numberBatchesWeek = lijst.length;

            const today = DateHelper.determineVanTot();
            const selectionData = {
                from: DateHelper.localDateToUTC(today.van),
                until: DateHelper.localDateToUTC(today.tot)
            };
            this.batchList = await loadItem(
                'productionorder/loadProductionOrderInfo',
                selectionData,
                this.$t
            );
            this.numberBatchesToday = this.batchList.length;
        }
    },
    async mounted() {
        await this.loadPlanBatches();
    },
    async created() {
        await this.loadPlanBatches();
        this.interval = setInterval(() => this.loadPlanBatches(), this.tijd);
    }
};
</script>

<style scoped></style>
