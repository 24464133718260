export { FEATURES_ENUM, Feature };

const FEATURES_ENUM = Object.freeze([
    {
        key: 'conf_storeAskOstCleaning'.toLowerCase(),
        name: 'Vraag reiniging wrongelbereider',
        visible: true
    },
    {
        key: 'conf_storeAskSiloCleaning'.toLowerCase(),
        name: 'Vraag reiniging melktank',
        visible: true
    },
    //{ key: 'conf_storeAskTimerProduction'.toLowerCase(), name:'Timer ter ondersteuning productie'},
    {
        key: 'conf_storeCalciumWithRennet'.toLowerCase(),
        name: 'Calciumdosering bij stremsel',
        visible: true
    },
    {
        key: 'conf_storeConserveerWithMilk'.toLowerCase(),
        name: 'Conserveermiddeldosering bij melk',
        visible: true
    },
    {
        key: 'conf_useCheeseMarkSubseries'.toLowerCase(),
        name: 'Detaillering invoer kaasmerken',
        visible: true
    },
    {
        key: 'conf_useMilkSupplier'.toLowerCase(),
        name: 'Detaillering op basis van melkleverancier',
        visible: true
    },
    {
        key: 'conf_CH10Enabled'.toLowerCase(),
        name: '10+ activiteit geactiveerd',
        visible: true
    },
    {
        key: 'conf_UseCheeseWeights'.toLowerCase(),
        name: 'Gebruik van kaas gewichten',
        visible: true
    },
    {
        key: 'conf_useWhey2a'.toLowerCase(),
        name: 'Gebruik 2 maal wrongelwaswater',
        visible: true
    },
    {
        key: 'conf_labelLandscape'.toLowerCase(),
        name: 'Print labels landscape',
        visible: true
    },
    {
        key: 'conf_useFahrenheitinProduction'.toLowerCase(),
        name: 'Gebruik Fahrenheit in productie',
        visible: false
    },
    {
        key: 'conf_useExternalConnections'.toLowerCase(),
        name: 'Externe communicatiemogelijkheden',
        visible: false
    },
    {
        key: 'conf_useProductionOrders'.toLowerCase(),
        name: 'Gebruik productieorders',
        visible: false
    },
    {
        key: 'conf_disableMultipleProductShapes'.toLowerCase(),
        name: 'Meerdere vormen per kaassoort uitschakelen',
        visible: false
    },
    {
        key: 'conf_handleStarterGroup'.toLowerCase(),
        name: 'Type groepsafhandeling bij zuursels',
        visible: false
    }
    
    
]);
export default FEATURES_ENUM;

// applicationconstants
class Feature {
    constructor(data) {
        this.type = 'Features';
        data = data ?? {};
        Object.defineProperties(this, {
            id: {
                type: String,
                value: data.key.toLowerCase(),
                writable: false,
                enumerable: true
            },
            naam: {
                type: String,
                value: data.key,
                writable: true,
                enumerable: true
            },
            datatype: {
                value: data.type,
                writable: true,
                enumerable: true
            }
        });
    }
    static enum(key) {
        if (key == undefined) return -1;
        const ob = FEATURES_ENUM.find((x) => x.key == key.toLowerCase());
        return ob ? ob.key.toLowerCase() : -1;
    }
}

