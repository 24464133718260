import { DateTime, Interval } from 'luxon';

export default class DateHelper {
    static dateIsValid(date) {
        if (
            typeof date === 'object' &&
            date !== null &&
            typeof date.getTime === 'function' &&
            !isNaN(date)
        ) {
            return true;
        }

        return false;
    }

    static localDateToUTC(value) {
        if (value == undefined) return value;
        let d = null;
        let dlux = value.isValid ? value : null;
        if (dlux == null) {
            if (this.dateIsValid(value)) {
                d = value;
            } else {
                const hasTimePart = value.includes(':');
                const hasDatePart = value.includes('-') ?? value.includes('/');
                if (!hasDatePart)
                    value = this.convertedFromTimepicker(new Date(), value);

                const datumtijd = hasTimePart ? value : value + 'T00:00:00'; // stomme work around maar anders gaat het niet goed
                d = new Date(datumtijd);
            }
            dlux = DateTime.fromJSDate(d);
        }
        return dlux.toUTC();
    }

    static localDateToUTCWithBase(basedate, value) {
        if (basedate == undefined) return this.localDateToUTC(value);
        const parsed = this.MakeDateTime(value);
        const today = DateTime.now().startOf('day');
        const timeOfDay = parsed.diff(today);

        const dateToModify = this.MakeDateTime(basedate);
        const result = dateToModify.startOf('day').plus(timeOfDay);
        return result.toUTC();
    }

    static determineVanTot(basedate, value) {
        if (basedate == undefined) {
            basedate = new Date();
        }
        basedate = this.localDateToUTC(basedate);
        const parsed = DateTime.fromISO(basedate);
        let van = null;
        let tot = null;
        if (value < 0) {
            van = parsed.toISODate();
            tot = parsed.minus({ days: value }).toISODate();
        } else {
            tot = parsed.toISODate();
            van = parsed.minus({ days: value }).toISODate();
        }
        const result = {
            van: van,
            tot: tot
        };
        return result;
    }

    static determineVanTotUTC(basedate, vanvalue, totvalue) {
        if (basedate == undefined) {
            basedate = new Date();
        }
        basedate = this.localDateToUTC(basedate);
        const van = basedate.plus({ days: vanvalue });
        const tot = basedate.plus({ days: totvalue });
        const result = {
            van: van,
            tot: tot
        };
        return result;
    }

    static isDateInRange(dateToCheck, normDate, days) {
        const date = this.MakeDateTime(dateToCheck).toISODate();
        const norm = this.MakeDateTime(normDate);
        const start = norm.minus({ days: days }).toISODate();
        const end = norm.plus({ days: days }).toISODate();
        return date >= start && date <= end;
    }

    static isValidDateTimeForDate(dateTime, date) {
        const dt = this.MakeDateTime(dateTime);
        const d = this.MakeDateTime(date).startOf('day');
        return dt.hasSame(d, 'day');
    }

    static TodayEndOfDay() {
        // todo echt end of day
        const t = new Date();
        t.setDate(t.getDate() + 1);
        return t;
    }

    static MakeDateTime(value) {
        if (value == undefined) return value;
        let result = null;
        result = DateTime.fromISO(value);
        if (result.isValid) return result;
        result = DateTime.fromJSDate(value);
        if (result.isValid) return result;
        result = DateTime.fromFormat(value, 'ccc dd LLL yyyy');
        if (result.isValid) return result;
    }

    static isIsoDate(str) {
        //if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str))
        if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:/.test(str)) return false;
        const d = new Date(str);
        return d instanceof Date && !isNaN(d); //&& d.toISOString() === str; // valid date
    }
    /////////////// Display Date ////////////////////////

    static convertDateInListToDateTimeString(list1, metTijd = true) {
        for (let index = 0; index < list1.length; index++) {
            const object = list1[index];
            for (const property in object) {
                if (isNaN(object[property])) {
                    if (this.isIsoDate(object[property])) {
                        const d = new Date(object[property]);
                        if (this.dateIsValid(d)) {
                            if (metTijd) {
                                object[property] =
                                    this.convertedToDateTimeShort(
                                        object[property]
                                    );
                            } else {
                                object[property] = this.convertedToDateShort(
                                    object[property]
                                );
                            }
                        }
                    }
                }
            }
        }
        return list1;
    }

    static convertedToDateTimeString(value, local) {
        local = local ?? [];
        // if (value == undefined) return value;
        // is het js date?
        const nu = this.MakeDateTime(value) ?? DateTime.fromJSDate(new Date());
        const jsdate = DateTime.fromISO(nu).toJSDate();
        return new Date(jsdate).toLocaleTimeString(local, {
            timeStyle: 'short',
            hour12: false
        });
    }

    static convertedToDateTimeJS(value) {
        if (value == undefined) return value;
        // is het js date?
        const nu = this.MakeDateTime(value);
        return DateTime.fromISO(nu ?? value).toJSDate();
    }

    // dit is de goede !! 2023-05-04
    static convertedToDateStringFromUTC(value) {
        //const datetime = DateTime.fromISO(value);
        if (value == undefined) return value;
        const dt = this.MakeDateTime(value);
        return dt.toISODate();
    }

    static dateToString(value) {
        //const datetime = DateTime.fromISO(value);
        if (value == undefined) return value;
        const dt = this.MakeDateTime(value);
        return dt.toISODate().toString();
    }

    static convertedToDateTimeStringFromUTC(date, time) {
        //const datetime = DateTime.fromISO(value);
        if (date == undefined) return date;
        let dt = this.MakeDateTime(date);
        if (dt.hour !== 0 || dt.minute !== 0 || dt.second !== 0)
            return dt.toISO();
        let hours, minutes, seconds;
        if (time) {
            const nu = this.MakeDateTime(time);
            if (nu) {
                [hours, minutes, seconds] = [nu.hour, nu.minute, nu.second];
            } else {
                [hours, minutes, seconds] = time.split(':').map(Number);
            }
        }
        dt = dt.set({ hour: hours, minute: minutes, second: seconds });
        return dt.toISO();
    }

    static convertedToTime(value, local) {
        local = local ?? [];
        let dt = new Date();
        if (value) {
            let hours, minutes, seconds;
            const nu = this.MakeDateTime(value);
            if (nu) {
                [hours, minutes, seconds] = [nu.hour, nu.minute, nu.second];
            } else {
                [hours, minutes, seconds] = value.split(':').map(Number);
            }
            if (hours !== 0 && minutes !== 0) {
                //&& seconds !== 0) {
                let ldt = this.MakeDateTime(value);
                ldt = ldt.set({
                    hour: hours,
                    minute: minutes,
                    second: seconds ?? 0
                });
                dt = ldt.toJSDate();
            }
        }
        return new Date(dt).toLocaleTimeString(local, {
            timeStyle: 'short',
            hour12: false
        });
    }

    static convertedToDateTimeShort(value) {
        if (value == undefined) return value;
        return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT);
    }

    static convertedToDateShort(value) {
        if (value == undefined) return value;
        return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
    }

    // veranderd 20250228 kan waarschijnlijk weg als geen problemen
    static convertedToDateOld(value, withTime = false) {
        if (value == undefined) return value;
        return withTime
            ? DateTime.fromISO(value).toLocaleString(
                  DateTime.DATETIME_MED_WITH_WEEKDAY
              )
            : DateTime.fromISO(value).toLocaleString(
                  DateTime.DATE_MED_WITH_WEEKDAY
              );
    }

    static convertedToDate(value, withTime = false) {
        if (value == undefined) return value;
        const dt = this.MakeDateTime(value);
        return withTime
            ? dt.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
            : dt.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
    }

    static convertedToMonthName(monthNumber, local) {
        local = local ?? [];
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString(local, {
            month: 'short'
        });
    }

    static convertedFromTimepicker(basedate, value) {
        const thisDate = basedate ?? new Date();
        let hours, minutes, seconds;
        const nu = this.MakeDateTime(value);
        if (nu) {
            [hours, minutes, seconds] = [nu.hour, nu.minute, nu.second];
        } else {
            [hours, minutes, seconds] = value.split(':').map(Number);
        }
        let d = this.MakeDateTime(thisDate);
        d = d.set({ hour: hours, minute: minutes, second: seconds });
        return d.toJSDate();
    }

    ///////////
    static addHoursToDate(date, hours) {
        if (date == undefined) return date;
        const dt = this.MakeDateTime(date);
        const min = hours ? hours < 0 : false;
        const uur = hours ? Math.abs(hours) : 0;
        return min ? dt.minus({ hours: uur }) : dt.plus({ hours: uur });
    }

    static dateDiffinDays(date1, date2) {
        if (date1 == undefined || date1 == '' || date2 == undefined) return 0;
        const d1 = this.MakeDateTime(date1);
        const d2 = this.MakeDateTime(date2);
        let diff = 0;
        if (d1 > d2) {
            const i = Interval.fromDateTimes(d2, d1);
            diff = i.length('days');
        } else {
            const i = Interval.fromDateTimes(d1, d2);
            diff = -i.length('days');
        }
        return diff;
    }

    static dateDiffinHours(date1, date2) {
        if (date1 == undefined || date1 == '' || date2 == undefined) return 0;
        const d1 = this.MakeDateTime(date1);
        const d2 = this.MakeDateTime(date2);
        let diff = 0;
        if (d1 > d2) {
            const i = Interval.fromDateTimes(d2, d1);
            diff = i.length('hours');
        } else {
            const i = Interval.fromDateTimes(d1, d2);
            diff = -i.length('hours');
        }
        return diff;
    }

    static getDate(value) {
        const datetime = value ?? new Date();
        return DateTime.fromJSDate(datetime);
    }

    static SubstractYear(value) {
        let aDate = this.getDate(value);
        aDate = aDate.plus({ years: -1 });
        return aDate.toJSDate();
    }

    static DateToYMD() {
        const x = new Date();
        const y = x.getFullYear().toString();
        let m = (x.getMonth() + 1).toString();
        let d = x.getDate().toString();
        d.length == 1 && (d = '0' + d);
        m.length == 1 && (m = '0' + m);
        const yyyymmdd = y + m + d;
        return yyyymmdd;
    }

    static DateToHM() {
        const x = new Date();
        let h = x.getHours().toString();
        let m = x.getMinutes().toString();
        let s = x.getSeconds().toString();
        h.length == 1 && (h = '0' + h);
        m.length == 1 && (m = '0' + m);
        s.length == 1 && (s = '0' + s);
        const hhmmss = h + m + s;
        return hhmmss;
    }

    // static convertedToDateTimeString(value) {
    //     if (value == undefined) return value;
    //     if (typeof value === 'string') return value;
    //     const d = DateTime.fromJSDate(value);
    //     return d.toFormat("yyyy-MM-dd'T'TT"); // workaround voor axios || postgres. toISOSting zou gewenst zijn
    // }

    // static convertedFromDateTime(value) {
    //     if (value == undefined) return value;
    //     return DateTime.toISO(value);
    // }

    // static ToDate(value) {
    //     if (value == undefined) return value;
    //     return DateTime.fromISO(value);
    // }
}

