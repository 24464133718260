import { api } from '../api';
import { showBadToast, showGoodToast } from '../../errors/toast';
import {
    VoorraadItem,
    MerkVoorraadItem,
    StockLocationType
} from '../../../models/Voorraad';
import { loadItem } from '@/models/DataHelper';
import logMessage from '../../errors/foutlog';

const actions = {
    //#region Producten
    async loadStockInfo(context, data) {
        let tempList = [];
        try {
            await api.fetchStockInfo(data.data).then((res) => {
                tempList = res.data.Items.map((x) => {
                    const prod = new VoorraadItem(x);
                    prod.attachmentinfo = prod.geefAttInfo(data.func);
                    return prod;
                });
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_stock', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertVoorraadInfoLijst', tempList);
    },
    async loadInBrineInfo(context, data) {
        let tempList = [];
        try {
            await api.fetchInBrineInfo(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new VoorraadItem(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_pekelvoorraad', { ns: 'production' })
                }),
                error
            );
        }
        //context.commit('insertVoorraadInfoLijst', tempList);
        return tempList;
    },
    async loadStockItemByPartij(context, data) {
        let tempList = [];
        try {
            await api.fetchStockItemByPartij(data.data).then((response) => {
                tempList = response.data.map((x) => new VoorraadItem(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_stockitem', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return tempList;
    },
    async loadStockInfoArchived(context, data) {
        let tempList = [];
        try {
            await api.fetchStockInfoArchived(data.data).then((res) => {
                tempList = res.data.Items.map((x) => {
                    const prod = new VoorraadItem(x);
                    prod.attachmentinfo = prod.geefAttInfo(data.func);
                    return prod;
                });
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_stock', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertVoorraadInfoLijstArchived', tempList);
    },
    async loadStockByProduct(context, data) {
        let tempList = [];
        try {
            await api.fetchStockByProduct(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new VoorraadItem(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_stock', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertVoorraadItemLijst', tempList);
        return tempList;
    },
    async addStockItem(context, data) {
        try {
            await api.addStockItem(data.data);
            loadItem('stock/loadStockInfo', null, data.func);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('prod_stockitem', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('prod_stockitem', { ns: 'production' })
                }),
                error
            );
        }
    },
    async updateStockItem(context, data) {
        try {
            await api.updateStockItem(data.data);
            loadItem('stock/loadStockInfo', null, data.func);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('prod_stockitem', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('prod_stockitem', { ns: 'production' })
                }),
                error
            );
        }
    },
    //#endregion Producten

    //#region Merktype
    async loadMarkStockInfo(context, data) {
        let tempList = [];
        try {
            await api.fetchMarkStockInfo(data.data).then((response) => {
                tempList = response.data.Items.map(
                    (x) => new MerkVoorraadItem(x)
                );
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_stock', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertMarkVoorraadInfoLijst', tempList);
    },
    async loadMarkStockInfoArchived(context, data) {
        let tempList = [];
        try {
            await api.fetchMarkStockInfoArchived(data.data).then((response) => {
                tempList = response.data.Items.map(
                    (x) => new MerkVoorraadItem(x)
                );
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_stock', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertMarkVoorraadInfoLijstArchived', tempList);
    },
    async loadMarkStockByProduct(context, data) {
        let tempList = [];
        try {
            await api.fetchMarkStockByProduct(data.data).then((response) => {
                tempList = response.data.Items.map(
                    (x) => new MerkVoorraadItem(x)
                );
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_stock', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertMarkVoorraadItemLijst', tempList);
        return tempList;
    },
    async addMarkStockItem(context, data) {
        try {
            await api.addMarkStockItem(data.data);
            loadItem('loadMarkStockInfo', null, data.func);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('com_marks', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('com_marks', { ns: 'common' })
                }),
                error
            );
        }
    },
    async updateMarkStockItem(context, data) {
        try {
            await api.updateMarkStockItem(data.data);
            loadItem('loadMarkStockInfo', null, data.func);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('com_marks', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('com_marks', { ns: 'common' })
                }),
                error
            );
        }
    },
    //#endregion Merktype

    async loadStockItem(context, data) {
        let item = null;
        try {
            await api.fetchStockItem(data.data).then((response) => {
                item = new VoorraadItem(response.data);
            });
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_stockitem', { ns: 'production' })
                }),
                error
            );
        }
        context.commit('insertSelectedItem', item);
        return item;
    },
    async loadStockItemInfo(context, data) {
        let item = null;
        try {
            await api.fetchStockItemInfo(data.data).then((response) => {
                if (response.data.Items.length > 1) {
                    showBadToast(
                        context,
                        'Meerdere items gevonden',
                        response.data.Items.length
                    );
                    return;
                }
                item = new VoorraadItem(response.data.Items[0]);
            });
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_stockiteminfo', { ns: 'production' })
                }),
                error
            );
        }
        context.commit('insertSelectedItemInfo', item);
        return item;
    },
    async loadStockItemMarksInfo(context, data) {
        let tempList = [];
        try {
            const res = await api.fetchStockItemMarksInfo(data.data);
            tempList = res ; // res.data.Items;
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_bakgroepmerken', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertStockItemMarksInfoLijst', tempList);
        return tempList;
    },
    async loadMarkStockItem(context, data) {
        let item = null;
        try {
            await api.fetchStockItem(data.data).then((response) => {
                item = new MerkVoorraadItem(response.data);
            });
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val:
                        data.func('com_mark', { ns: 'common' }) +
                        data
                            .func('prod_stockitem', { ns: 'production' })
                            .toLowerCase()
                }),
                error
            );
        }
        context.commit('insertSelectedItem', item);
        return item;
    },
    async deleteStockItem(context, data) {
        try {
            await api.deleteStockItem(data.data);
            loadItem('stock/loadStockInfo', null, data.func);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('prod_stockitem', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('prod_stockitem', { ns: 'production' })
                }),
                error
            );
        }
    },

    async closeStockItem(context, data) {
        try {
            await api.closeStockItem(data.data);
            loadItem('stock/loadStockInfo', null, data.func);
            showGoodToast(
                context,
                data.func('log_closestock', {
                    ns: 'logistics',
                    val: data.func('prod_stockitem', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('log_errorclosestock', {
                    ns: 'logistics',
                    val: data.func('prod_stockitem', { ns: 'production' })
                }),
                error
            );
        }
    },

    async moveStockItem(context, data) {
        try {
            await api.moveStockItem(data.data);
            loadItem('stock/loadStockInfo', null, data.func);
            showGoodToast(
                context,
                data.func('log_replaced', {
                    ns: 'logistics',
                    val: data.func('prod_stockitem', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('log_errorreplace', {
                    ns: 'logistics',
                    val: data.func('prod_stockitem', { ns: 'production' })
                }),
                error
            );
        }
    },

    async correctStockAmount(context, data) {
        try {
            await api.correctStockAmount(data.data);
            loadItem('stock/loadStockInfo', null, data.func);
            showGoodToast(
                context,
                data.func('log_corrected', {
                    ns: 'logistics',
                    val: data.func('prod_stockitem', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('log_errorcorrected', {
                    ns: 'logistics',
                    val: data.func('prod_stockitem', { ns: 'production' })
                }),
                error
            );
        }
    },
    async solledStockAmount(context, data) {
        try {
            await api.solledStockAmount(data.data);
            loadItem('stock/loadStockInfo', null, data.func);
            showGoodToast(
                context,
                data.func('log_corrected', {
                    ns: 'logistics',
                    val: data.func('prod_stockitem', { ns: 'production' })
                }) +
                    ' ' +
                    data
                        .func('log_forsale', {
                            ns: 'logistics'
                        })
                        .toLowerCase()
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('log_errorcorrected', {
                    ns: 'logistics',
                    val: data.func('prod_stockitem', { ns: 'production' })
                }) +
                    ' ' +
                    data
                        .func('log_forsale', {
                            ns: 'logistics'
                        })
                        .toLowerCase(),
                error
            );
        }
    },
    async soldStockAmounts(context, data) {
        try {
            await api.soldStockAmounts(data.data);
            loadItem('stock/loadStockInfo', null, data.func);
            showGoodToast(
                context,
                data.func('log_corrected', {
                    ns: 'logistics',
                    val: data.func('prod_stockitems', { ns: 'production' })
                }) +
                    ' ' +
                    data
                        .func('log_forsale', {
                            ns: 'logistics'
                        })
                        .toLowerCase()
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('log_errorcorrected', {
                    ns: 'logistics',
                    val: data.func('prod_stockitems', { ns: 'production' })
                }) +
                    ' ' +
                    data
                        .func('log_forsale', {
                            ns: 'logistics'
                        })
                        .toLowerCase(),
                error
            );
        }
    }
    // async loadStockLocationTypes(context, data) {
    //     let tempList = [];
    //     try {
    //         await api.fetchStockLocationTypes(data).then((response) => {
    //             tempList = response.data.map((x) => new StockLocationType(x));
    //         });
    //     } catch (error) {
    //         tempList = [];
    //         showBadToast(context, 'Melding laden opslaglocatietypes', error);
    //         logMessage(error, 'error');
    //     }
    //     context.commit('insertStockLocationTypesLijst', tempList);
    // }
};

export default actions;

