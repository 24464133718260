var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.toonSelectie)?_c('div',{staticClass:"form-inline"},[_c('b-input-group',{attrs:{"prepend":_vm.giveNaam}},[_c('b-form-select',{attrs:{"options":_vm.lookupCategories},on:{"change":_vm.findLabels},model:{value:(_vm.catID),callback:function ($$v) {_vm.catID=$$v},expression:"catID"}}),(_vm.showCard)?_c('b-form-select',{attrs:{"options":_vm.geefLijst},on:{"change":_vm.changed},model:{value:(_vm.labelID),callback:function ($$v) {_vm.labelID=$$v},expression:"labelID"}}):_vm._e(),(_vm.showCard)?_c('b-dropdown',{ref:"dropdown",attrs:{"id":"dropdown-form","text":"...","size":"sm","boundary":"viewport"},on:{"hide":function($event){return _vm.handleHide($event)}}},[_c('div',{staticClass:"fluid-container"},[_c('b-dropdown-form',[_c('b-input-group',{attrs:{"prepend":"Expiration date"}},[_c('b-form-datepicker',{attrs:{"date-format-options":{
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit'
                            },"min":new Date()},on:{"input":_vm.changedWait,"shown":function($event){_vm.isCloseable = false}},model:{value:(_vm.expirationDate),callback:function ($$v) {_vm.expirationDate=$$v},expression:"expirationDate"}})],1),_c('b-input-group',{attrs:{"prepend":"Valid from"}},[_c('b-form-datepicker',{attrs:{"date-format-options":{
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit'
                            }},on:{"input":_vm.changedWait,"shown":function($event){_vm.isCloseable = false}},model:{value:(_vm.validFrom),callback:function ($$v) {_vm.validFrom=$$v},expression:"validFrom"}})],1),_c('b-input-group',{attrs:{"prepend":"Valid till"}},[_c('b-form-datepicker',{attrs:{"date-format-options":{
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit'
                            },"min":new Date()},on:{"input":_vm.changedWait,"shown":function($event){_vm.isCloseable = false}},model:{value:(_vm.validTill),callback:function ($$v) {_vm.validTill=$$v},expression:"validTill"}})],1),_c('b-input-group',{attrs:{"prepend":"Description"}},[_c('b-form-input',{on:{"input":_vm.changedWait,"shown":function($event){_vm.isCloseable = false}},model:{value:(_vm.niceName),callback:function ($$v) {_vm.niceName=$$v},expression:"niceName"}})],1),_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.closeMe()}}},[_vm._v("Ok")])],1)],1)]):_vm._e()],1)],1):_c('div',[_vm._v(_vm._s(_vm.$t('com_nodata', { ns: 'common' })))])
}
var staticRenderFns = []

export { render, staticRenderFns }