import { api } from '../api';
import { showBadToast, showGoodToast } from '../../errors/toast';
import {
    DeliveryNote,
    DeliveryNoteView,
    PrintedNote,
    PrintOption
} from '../../../models/Delivery';
import { Company } from '../../../models/MasterData';
import logMessage from '../../errors/foutlog';

const actions = {
    //#region Uitslag
    async addDeliveryNote(context, data) {
        try {
            await api.addDeliveryNote(data.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('prod_pakbon', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('prod_pakbon', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
    },
    async loadDeliveryNotes(context, data) {
        let tempList = [];
        try {
            await api.fetchDeliveryNotes(data.data).then((response) => {
                tempList = response.data.Items.map((x) => {
                    const del = new DeliveryNoteView(x);
                    del.attachmentinfo = del.geefAttInfo(data.func);
                    return del;
                });
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_pakbonnen', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertDeliveryNoteLijst', tempList);
    },
    async loadDeliveryNotesPrinted(context, data) {
        let tempList = [];
        try {
            await api.fetchDeliveryNotesPrinted(data.data).then((response) => {
                tempList = response.data.Items.map(
                    (x) => new DeliveryNoteView(x)
                );
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_pakbonnen', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertDeliveryNotePrintedLijst', tempList);
    },
    async loadDeliveryNote(context, data) {
        let item = null;
        try {
            await api.fetchDeliveryNote(data.data).then((response) => {
                item = new DeliveryNote(response.data);
            });
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_pakbon', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertDelveryNote', item);
        return item;
    },
    async loadDeliveryNotePrinted(context, data) {
        let item = null;
        try {
            await api.fetchDeliveryNotePrinted(data.data).then((response) => {
                item = new PrintedNote(response.data);
            });
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_pakbon', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return item;
    },
    async printOptions(context, data) {
        let tempList = [];
        try {
            await api
                .fetchDeliveryNotePrintOptions(data.data)
                .then((response) => {
                    //tempList = response.data.map((x) => new PrintOption(x));
                    tempList = response.data.map((x) => {
                        const a = new PrintOption(x);
                        let naamtemp = '';
                        switch (a.id) {
                            case 1:
                                naamtemp = data.func('log_optpartij', {
                                    ns: 'logistics'
                                });
                                break;
                            case 2:
                                naamtemp = data.func('log_optvorm', {
                                    ns: 'logistics'
                                });
                                break;
                            case 4:
                                naamtemp = data.func('log_opteenheid', {
                                    ns: 'logistics'
                                });
                                break;
                            case 8:
                                naamtemp = data.func('log_optmerken', {
                                    ns: 'logistics'
                                });
                                break;
                            case 16:
                                naamtemp = data.func('log_optdatum', {
                                    ns: 'logistics'
                                });
                                break;
                            case 32:
                                naamtemp = data.func('log_optage', {
                                    ns: 'logistics'
                                });
                                break;
                            case 64:
                                naamtemp = data.func('log_opttht', {
                                    ns: 'logistics'
                                });
                                break;
                        }
                        const source = { naam: naamtemp };
                        const returnedTarget = Object.assign(a, source);
                        return returnedTarget;
                    });
                });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_printoptions', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertPrintOptionsLijst', tempList);
    },
    async loadStockForMark(context, data) {
        let tempList = [];
        try {
            const response = await api.fetchStockItemsForCheeseMark(data.data);
            tempList = response.data.Items;
        } catch (error) {
            tempList = [];
            showBadToast(
                data.func('com_errorpreview', {
                    ns: 'common',
                    val: data.func('prod_pakbon', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return tempList;
    },
    async viewPrintedNote(context, data) {
        let item = null;
        try {
            const response = await api.fetchPrintedNoteView(data.data);
            item = new PrintedNote(response.data);
        } catch (error) {
            showBadToast(
                data.func('com_errorpreview', {
                    ns: 'common',
                    val: data.func('prod_pakbon', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return item;
    },
    async existsPrintedNote(context, data) {
        let item = false;
        try {
            const response = await api.fetchPrintedNoteCreated(data.data);
            item = response.data;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorpreview', {
                    ns: 'common',
                    val: data.func('prod_pakbon', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return item;
    },
    async savePrintedNote(context, data) {
        let item = null;
        try {
            const response = await api.storePrintedNote(data.data);
            item = new PrintedNote(response.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('prod_pakbon', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return item;
    },
    async savePrintedNotePrinted(context, data) {
        let item = false;
        try {
            const response = await api.storePrintedNotePrinted(data.data);
            item = response.data;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorreprint', {
                    ns: 'common',
                    val: data.func('prod_pakbon', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return item;
    },
    async deleteDeliveryNote(context, data) {
        try {
            await api.deleteDeliveryNote(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('prod_pakbon', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('prod_pakbon', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
    },
    //#endregion Uitslag

    async loadOwnCompany(context, data) {
        let item = null;
        data.data = { companyid: 1 };
        try {
            const response = await api.fetchCompany(data.data);
            item = new Company(response.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_company', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertOwnCompany', item);
        return item;
    }
};

export default actions;

