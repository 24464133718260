<template>
    <b-container fluid style="margin-top: 60px">
        <b-card @submit.stop.prevent="handleSubmit" no-body>
            <b-alert
                v-model="showDismissibleAlert"
                variant="danger"
                dismissible
            >
                {{ alertText }}
            </b-alert>
            <b-tabs card>
                <b-tab :title="$t('com_profiel', { ns: 'common' })" active>
                    <b-card-text>
                        <b-form-group
                            :label="$t('com_gebruikersnaam', { ns: 'common' })"
                            label-for="gebruikers-naam"
                        >
                            <b-form-input
                                id="gebruikers-naam"
                                v-model="operatorName"
                                readonly
                            ></b-form-input>
                        </b-form-group>
                        <span class="label label-info">{{ email }}</span>
                        <span v-if="isAdministrator" class="label label-info"
                            >- {{ $t('com_isadmin', { ns: 'common' }) }}</span
                        >
                        <br />
                        <!-- toon rechten -->

                        <b-table
                            v-if="groupedDisplayList.length > 0 && is25plus"
                            :items="groupedDisplayList"
                            :fields="rechten"
                            stacked="md"
                            show-empty
                            :empty-text="$t('com_geen_data', { ns: 'common' })"
                            small
                            striped
                            @row-clicked="toonLijstDetail"
                        >
                            <template v-slot:row-details>
                                <b-form-checkbox-group
                                    v-model="detailPermissions"
                                    :options="detailPermissionList"
                                    class="mb-3"
                                    value-field="id"
                                    text-field="naam"
                                    stacked
                                    disabled
                                ></b-form-checkbox-group>
                            </template>
                            <template v-slot:cell(aantal)="row">
                                {{
                                    row.item.detailSelectedList.length +
                                    ' ' +
                                    $t('com_van', {
                                        ns: 'common'
                                    }).toLowerCase() +
                                    ' ' +
                                    row.item.detailPermissionList.length
                                }}
                            </template>
                        </b-table>
                    </b-card-text>
                </b-tab>
                <b-tab
                    :title="
                        $t('com_beheerx', {
                            ns: 'common',
                            val: $t('com_user', {
                                ns: 'common'
                            })
                        })
                    "
                    v-if="is25plus"
                >
                    <b-card-text>
                        <card-gebruikers v-bind:doRefresh="doRefresh" />
                    </b-card-text>
                </b-tab>
                <b-tab
                    :title="
                        $t('com_beheerx', {
                            ns: 'common',
                            val: $t('com_groepen', {
                                ns: 'common'
                            })
                        })
                    "
                    v-if="is25plus"
                >
                    <b-card-text>
                        <card-groepen v-bind:doRefresh="doRefresh" />
                    </b-card-text>
                </b-tab>
                <b-tab
                    :title="
                        $t('com_beheerx', {
                            ns: 'common',
                            val: $t('com_configuratie', {
                                ns: 'common'
                            })
                        })
                    "
                    v-if="!isVersion10"
                >
                    <b-card-text>
                        <b-card
                            no-body
                            :header="
                                $t('com_opties', {
                                    ns: 'common'
                                })
                            "
                        >
                            <b-row>
                                <b-col>
                                    <b-form-checkbox-group
                                        v-model="selectedFeatures"
                                        :options="optionsFeatures"
                                        class="mb-3"
                                        value-field="key"
                                        text-field="name"
                                        stacked
                                        @change="setFeaturesChanged()"
                                    ></b-form-checkbox-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-button
                                        v-if="
                                            checkRight('ChangeAppConfiguration')
                                        "
                                        @click="saveFeatures()"
                                        class="bgc_aux_normal"
                                        ><font-awesome-icon
                                            icon="arrow-down"
                                            size="1x"
                                        />&nbsp;{{
                                            $t('com_opslaan', {
                                                ns: 'common'
                                            })
                                        }}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>
    </b-container>
</template>

<script>
import Operator from '../../models/Operator';
import logMessage from '../errors/foutlog';
import Helper from '../../models/Helper';
import { loadList, loadItem } from '../../models/DataHelper';
import { PERMISSION_ENUM, Permission } from '../../models/Permissions';
import { FEATURES_ENUM } from '../../models/Features';
import { showGoodToast } from '../errors/toast';
import CardGroepen from './CardGroepen.vue';
import CardGebruikers from './CardGebruikers.vue';
import state from '../account/store/state';
export default {
    name: 'Profiel',
    data: function () {
        return {
            showDismissibleAlert: false,
            alertText: '',
            operatorName: '',
            errorMessage: '',
            refresh: true,
            featuresChanged: false,
            selectedFeatures: [],
            detailPermissions: null,
            detailPermissionList: [],

            rechten: [
                {
                    key: 'cat',
                    label: this.$t('com_module', { ns: 'common' }),
                    sortable: true
                },
                {
                    key: 'aantal',
                    label:
                        this.$t('com_number', { ns: 'common' }) +
                        ' ' +
                        this.$t('com_permissies', { ns: 'common' })
                }
            ]
        };
    },
    props: {
        ingelogdeOperator: {
            type: Operator
        }
    },
    components: {
        CardGroepen,
        CardGebruikers
    },
    computed: {
        optionsFeatures() {
            const arrTranslated = [];
            for (let i = 0; i < FEATURES_ENUM.length; i++) {
                if (FEATURES_ENUM[i].visible) {
                    const k = FEATURES_ENUM[i].key;
                    const trans = this.$t(k, { ns: 'permissies' });
                    arrTranslated.push({ key: k, name: trans });
                }
            }
            return arrTranslated;
        },
        is25plus() {
            return (
                this.$store.getters['account/getLoggedInUser'].isAdmin &&
                this.$store.getters['masterdata/getPackageVersion'] > 20
            );
        },
        isVersion10() {
            return (
                this.$store.getters['masterdata/getPackageVersion'] ==
                window.constants.VERSION10
            );
        },
        loggedInUser() {
            return this.$store.getters['account/getLoggedInUser'];
        },
        isAdministrator() {
            return this.loggedInUser ? this.loggedInUser.isAdmin : false;
        },
        email() {
            return this.loggedInUser ? this.loggedInUser.mail : '';
        },
        groepenlijst() {
            const thisid = this.loggedInUser ? this.loggedInUser.id : -1;
            return this.$store.getters['account/getUserInGroups'](thisid);
        },
        groupedDisplayListOld() {
            const active = this.loggedInUser ? this.loggedInUser.rechten : [];
            const permissieList =
                this.$store.getters['account/getPackagePermissions'];
            const res = Helper.overlapList1(active, permissieList, false);
            if (res == undefined) return null;
            const x = _(res.slice(0))
                .groupBy((x) => x.onderdeel)
                .map((value, key) => {
                    return {
                        cat: this.$t(key, { ns: 'permissies' }),
                        detailPermissionList: value
                    };
                })
                .value();
            return _.sortBy(x, ['cat']);
        },
        groupedDisplayList() {
            const active = this.loggedInUser ? this.loggedInUser.rechten : [];
            const permissieList =
                this.$store.getters['account/getPackagePermissions'];
            if (permissieList == undefined) return null;
            const x = _(permissieList.slice(0))
                .groupBy((x) => x.onderdeel)
                .map((value, key) => {
                    const locLijst = [];
                    for (let i = 0; i < value.length; i++) {
                        const id = value[i].id;
                        const test = active.find((x) => x.id === id);
                        if (test) locLijst.push(test);
                    }
                    return {
                        cat: this.$t(key, { ns: 'permissies' }),
                        detailPermissionList: value,
                        detailSelectedList: locLijst
                    };
                })
                .value();
            const result = _.sortBy(x, ['cat']);
            return result;
        },
        doRefresh() {
            return this.refresh;
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        setFeaturesChanged() {
            this.featuresChanged = true;
        },
        getOperatorName() {
            this.operatorName =
                this.$store.getters['account/getLoggedInUser'].naam;
        },
        async getGroupsForUser() {
            const list = await loadList(
                'account/getUsersInGroups',
                'account/loadUserInGroups',
                null,
                this.forceRefresh,
                this.$t
            );
        },
        async checkPermissions() {
            let list = this.$store.getters['account/getPermissions'];
            if (list == undefined || list.length == 0) {
                await loadItem('account/loadPermissions', null, this.$t);
                loadItem('account/loadPackagePermissions', null, this.$t);
                list = this.$store.getters['account/getPermissions'];
            }
            const logWarning = Helper.compareListsById(list, PERMISSION_ENUM);
            if (logWarning) {
                logMessage('Permissions not in sync!!', 'warning');
            }
        },
        getFeaturesList() {
            const operator = this.$store.getters['account/getLoggedInUser'];
            for (let index = 0; index < this.optionsFeatures.length; index++) {
                const constant = this.optionsFeatures[index];
                const con = operator.listContainsFeature(constant.key);
                if (con) this.selectedFeatures.push(constant.key);
            }
        },
        async saveFeatures() {
            let savedOk = true;
            for (let index = 0; index < this.optionsFeatures.length; index++) {
                const isselected = this.selectedFeatures.find(
                    (x) => x == this.optionsFeatures[index].key
                );
                const waarde = isselected == undefined ? '0' : '1';

                const savedata = {
                    value: waarde,
                    constantName: this.optionsFeatures[index].key
                };
                const ok = await loadItem(
                    'masterdata/storeApplicationConstant',
                    savedata,
                    this.$t
                );
                savedOk = savedOk ? ok : savedOk;
            }
            if (savedOk)
                showGoodToast(
                    this.$store,
                    this.$t('com_opgeslagen', {
                        ns: 'common',
                        val: this.$t('com_feature', { ns: 'common' })
                    })
                );
            if (this.featuresChanged) {
                const operator = this.$store.getters['account/getLoggedInUser'];
                loadItem(
                    'account/reloadLoggedInUser',
                    {
                        userid: operator.id
                    },
                    this.$t
                );
                this.featuresChanged = false;
            }
        },
        toonLijstDetail(row) {
            if (row._showDetails) {
                this.$set(row, '_showDetails', false);
            } else {
                this.groupedDisplayList.forEach((row) => {
                    this.$set(row, '_showDetails', false);
                });
                this.detailPermissionList = row.item
                    ? row.item.detailPermissionList
                    : row.detailPermissionList;
                // filter de geselecteerd permissies
                const magLijst = [];
                for (
                    let index = 0;
                    index < this.detailPermissionList.length;
                    index++
                ) {
                    const id = this.detailPermissionList[index].id;
                    const test = this.loggedInUser.rechten.find(
                        (x) => x.id === id
                    );
                    if (test) magLijst.push(test.id);
                }
                this.detailPermissionList = this.detailPermissionList.sort(
                    Helper.dynamicSort('naam')
                );
                this.detailPermissions = magLijst.sort(
                    Helper.dynamicSort('naam')
                );
                this.$set(row, '_showDetails', true);
            }
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        },
        async loadData() {
            const operator = this.$store.getters['account/getLoggedInUser'];
            this.refresh = true;
            loadItem(
                'account/reloadLoggedInUser',
                { userid: operator.id },
                this.$t
            );
            await loadItem('account/loadPermissions', null, this.$t);
            await this.getGroupsForUser;
            this.checkPermissions();
            this.getGroupsForUser();
        }
    },
    created() {
        this.getOperatorName();
        this.getFeaturesList();
        if (!this.isVersion10) {
            this.checkPermissions(); // moet dit hier??
            this.getGroupsForUser(); // moet dit hier??
            loadItem('account/loadPermissions', null, this.$t);
        }
    }
};
</script>

<style scoped></style>
