var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticStyle:{"margin-top":"60px"},attrs:{"fluid":""}},[_c('h1',[_vm._v(_vm._s(_vm.title))]),_c('b-card',[_c('b-tabs',{attrs:{"card":""}},[_c('b-tab',{attrs:{"lazy":""},on:{"click":function($event){return _vm.loadData(_vm.INPEKEL)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.showSpinner)?_c('b-spinner',{attrs:{"type":"border","small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('prod_inpekel', { ns: 'production' }))+" ")]},proxy:true}])}),_c('b-tab',{attrs:{"lazy":""},on:{"click":function($event){return _vm.loadData(_vm.INGREDIENT)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.showSpinner)?_c('b-spinner',{attrs:{"type":"border","small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('prod_ingredient', { ns: 'production' }))+" ")]},proxy:true}])}),_c('b-tab',{key:1,attrs:{"title":_vm.$t('prod_uitpekel', { ns: 'production' }),"active":""},on:{"click":function($event){return _vm.loadData(_vm.UITPEKEL)}}})],1),_c('div',[(_vm.showFilter)?_c('div',[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"my-1",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":_vm.$t('com_filter', {
                                    ns: 'common'
                                }),"label-for":"filter-input","label-cols-sm":"3","label-align-sm":"right","label-size":"sm"}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-input',{attrs:{"id":"filter-input","type":"search","placeholder":_vm.$t('com_typetosearch', {
                                            ns: 'common'
                                        })},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.filter},on:{"click":function($event){_vm.filter = ''}}},[_vm._v(_vm._s(_vm.$t('com_wis', { ns: 'common' })))])],1)],1)],1)],1),_c('b-col',{staticClass:"my-1",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":_vm.$t('com_perpagina', {
                                    ns: 'common'
                                }),"label-for":"per-page-select","label-cols-sm":"6","label-cols-md":"4","label-cols-lg":"3","label-align-sm":"right","label-size":"sm"}},[_c('b-form-select',{attrs:{"id":"per-page-select","options":_vm.pageOptions,"size":"sm"},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"fill","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1):_vm._e(),_c('b-table',{attrs:{"striped":"","items":_vm.reportList,"fields":_vm.tableFields,"primary-key":"","current-page":_vm.currentPage,"per-page":_vm.perPage,"filter":_vm.filter,"filter-included-fields":_vm.filterOn,"stacked":"md","show-empty":"","empty-text":_vm.$t('com_geen_data', { ns: 'common' }),"small":""},on:{"filtered":_vm.onFiltered}}),(_vm.taskCount === 0)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('com_geen_data', { ns: 'common' }))+" ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }