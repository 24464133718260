import { api } from '../api';
import { showGoodToast, showBadToast } from '../../errors/toast';
import logMessage from '../../errors/foutlog';
import { AttachmentInfo } from '../../../models/PlusProduction';

const actions = {
    async loadUnitsComboLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchUnits(data.data).then((response) => {
                tempList = response.data.Items.map((x) => ({
                    value: x.id,
                    text: x.description + ' (' + x.symbol + ')',
                    base: x.baseid,
                    symbol: x.symbol,
                    naam: x.description,
                    factor: x.factor ?? 1
                }));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_units', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertUnitsComboLijst', tempList);
        return tempList;
    },
    async loadShapesComboLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchShapes(data.data).then((response) => {
                tempList = response.data.Items.map((x) => ({
                    value: x.id,
                    text: x.description,
                    gewicht: x.weight
                }));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_kaasvormen', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertShapesComboLijst', tempList);
        return tempList;
    },
    async loadShape(context, data) {
        let naam = '';
        try {
            const res = await api.fetchShape(data.data);
            naam = res.data.description;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_kaasvorm', { ns: 'common' })
                }),
                error
            );
        }
        return naam;
    },
    async loadCheeseMarksComboLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchCheeseMarks(data.data).then((response) => {
                tempList = response.data.Items.map((x) => ({
                    value: x.id,
                    text: x.description + ' (' + x.fatclass + ')',
                    fatclass: x.fatclass
                }));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_cheesemark', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertMarkTypesComboLijst', tempList);
        return tempList;
    },
    async loadGroupsForIngredientCatComboLijst(context, data) {
        // todo
        let tempList = [];
        try {
            await api
                .fetchGroupsForIngredientCat(data.data)
                .then((response) => {
                    tempList = response.data.Items.map((x) => ({
                        value: x.id,
                        text: x.description
                    }));
                });
        } catch (error) {
            tempList = [];
            showBadToast(context, 'Melding laden ingredientgroepen', error);
        }
        switch (data.category) {
            case window.constants.INGREDIENTCAT_MILK:
                context.commit('insertMilkTypeIGComboLijst', tempList);
                break;
            default: {
                context.commit('insertIGComboLijst', tempList);
            }
        }
        return tempList;
    },
    async loadIngredientsForGroupComboLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchIngredientsForGroup(data.data).then((response) => {
                tempList = response.data.Items.map((x) => ({
                    value: x.id,
                    text:
                        data.category == undefined
                            ? x.description + ' (' + x.category + ')'
                            : x.description,
                    category: x.category,
                    naam: x.description,
                    groep: x.group,
                    baseunit: x.unitid
                }));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_ingredienten', { ns: 'production' })
                }),
                error
            );
        }
        context.commit('insertIngredientComboLijst', tempList);
        return tempList;
    },
    async loadFatClassComboLijst(context) {
        const tempList = [
            '0+',
            '10+',
            '15+',
            '20+',
            '25+',
            '30+',
            '35+',
            '40+',
            '45+',
            '48+',
            '50+',
            '60+'
        ];
        const fatclassMap = tempList.map((x) => ({
            value: x,
            text: x
        }));
        context.commit('insertFatClassComboLijst', fatclassMap);
        return tempList;
    },
    async loadCheesesComboLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchCheeses(data.data).then((response) => {
                tempList = response.data.Items.map((x) => ({
                    value: x.id,
                    text: x.description
                }));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_kazen', { ns: 'comon' })
                }),
                error
            );
        }
        context.commit('insertCheesesComboLijst', tempList);
        return tempList;
    },
    emptyCheesesWithoutRecipeComboLijst(context, data) {
        context.commit('insertCheeseWithoutRecipeComboLijst', data);
    },
    async loadCheeseTypeAlternatives(context, data) {
        let tempList = [];
        try {
            await api
                .fetchCheeseTypeAlternatives(data.data)
                .then((response) => {
                    tempList = response.data.Items.map((x) => ({
                        value: x.id,
                        text: x.description,
                        baseproductid: x.baseproductid,
                        baseproductnaam: x.baseproductnaam,
                        basecheesetypeid: x.basecheesetypeid,
                        item: x
                    }));
                });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_kazen', { ns: 'comon' })
                }),
                error
            );
        }
        context.commit('insertCheeseTypeAlternatives', tempList);
        return tempList;
    },
    async loadCheesesWithoutRecipeComboLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchCheesesWithoutRecipe(data.data).then((response) => {
                tempList = response.data.Items.map((x) => ({
                    value: x.id,
                    text: x.description
                }));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_kazen', { ns: 'comon' })
                }),
                error
            );
        }
        context.commit('insertCheeseWithoutRecipeComboLijst', tempList);
    },
    async loadFailureCheeseComboLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchFailureCheese(data.data).then((response) => {
                tempList = response.data.Items.map((x) => ({
                    value: x.id,
                    text: x.description
                }));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_uitvalkazen', { ns: 'production' })
                }),
                error
            );
        }
        context.commit('insertFailureCheeseComboLijst', tempList);
    },
    async loadCompaniesComboLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchCompanies(data.data).then((response) => {
                tempList = response.data.Items.map((x) => ({
                    value: x.id,
                    text: x.description,
                    afkort: x.shortname
                }));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_companies', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertCompaniesComboLijst', tempList);
        return tempList;
    },
    async loadCompany(context, data) {
        let naam = '';
        try {
            const res = await api.fetchCompany(data.data);
            naam = res.data.smallName ?? res.data.companyName;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_company', { ns: 'common' })
                }),
                error
            );
        }
        return naam;
    },
    async loadLocationsComboLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchLocations(data.data).then((response) => {
                tempList = response.data.Items.map((x) => ({
                    value: x.id,
                    text: x.description,
                    type: x.type,
                    add: x.addingredient > 0
                }));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_locations', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertLocationsComboLijst', tempList);
        return tempList;
    },
    async loadLocation(context, data) {
        let naam = '';
        try {
            const res = await api.fetchLocation(data.data);
            naam = res.data.description;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_location', { ns: 'common' })
                }),
                error
            );
        }
        return naam;
    },
    async loadProductsComboLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchProducts(data.data).then((response) => {
                tempList = response.data.Items.map((x) => ({
                    value: x.id,
                    text: x.description,
                    categorie: x.category,
                    catid: x.ptc_id,
                    eenheid: x.unitid
                }));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_products', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertProductsComboLijst', tempList);
        return tempList;
    },
    async loadBrineIngredientsComboLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchBrineIngredients(data.data).then((response) => {
                tempList = response.data.Items.map((x) => ({
                    value: x.id,
                    text: x.description,
                    categorie: x.category,
                    eenheid: x.unitid
                }));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_pekelingredienten', {
                        ns: 'production'
                    })
                }),
                error
            );
        }
        context.commit('insertPIComboLijst', tempList);
        return tempList;
    },
    async loadBatchGroupProductionDate(context, data) {
        let datum = null;
        try {
            const res = await api.fetchBatchGroupProductionDate(data.data);
            datum = res.data;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_proddatum', { ns: 'production' })
                }),
                error
            );
        }
        return datum;
    },

    async saveAttachment(context, data) {
        let item = null;
        try {
            const response = await api.storeAttachment(data.data);
            item = new AttachmentInfo(response.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('com_bijlage', { ns: 'common' })
                })
            );
        } catch (error) {
            const mes = await logMessage(error, 'error');
            showBadToast(
                context,
                data.func('com_errorupload', {
                    ns: 'common',
                    val: data.func('com_bijlage', { ns: 'common' })
                }),
                error
            );
        }
        return item;
    },
    // int id, int attachmentType
    async loadAttachmentInfo(context, data) {
        let tempList = [];
        try {
            await api.fetchAttachmentInfo(data.data).then((response) => {
                tempList = response.data.Items.map(
                    (x) => new AttachmentInfo(x)
                );
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_bijlagen', { ns: 'common' })
                }),
                error
            );
        }
        return tempList;
    },

    // int attachmentID, int id, int attachmentType
    async loadAttachment(context, data) {
        let item = null;
        try {
            const response = await api.fetchAttachment(data.data);
            item = response.data;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_bijlage', { ns: 'common' })
                }),
                error
            );
        }
        return item;
    },
    async deleteAttachment(context, data) {
        try {
            await api.deleteAttachment(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('com_bijlage', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('com_bijlage', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
    },
    async loadLookupLabelCategories(context, data) {
        let tempList = [];
        try {
            await api.fetchLookupLabelCategories(data.data).then((response) => {
                tempList = response.data.Items.map((x) => ({
                    value: x.ll_id,
                    text: x.label,
                    label_id: x.ll_id,
                    label: x.label,
                    cat_id: x.lc_id,
                    categorie: x.category
                }));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_label', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertLookupLabelLijst', tempList);
        return tempList;
    },
    async loadSimpleProducts(context, data) {
        let tempList = [];
        try {
            await api.fetchSimpleProducts(data.data).then((response) => {
                tempList = response.data.Items.map((x) => ({
                    value: x.id,
                    id: x.id,
                    text: x.description,
                    internID: x.internalid,
                    eenheidID: x.unitid,
                    type: x.type ?? 0
                }));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('tien_title', {
                        ns: 'tien',
                        val: data.func('com_products', { ns: 'common' })
                    })
                }),
                error
            );
        }
        context.commit('insertSimpleProductsLijst', tempList);
        return tempList;
    }
};

export default actions;

