<template>
    <b-modal
        id="modal-stockitem"
        size="xl"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
        @change="change"
    >
        <b-card
            @submit.stop.prevent="handleSubmit"
            :header="$t('com_product', { ns: 'common' })"
        >
            <div>
                <b-row>
                    <b-col sm="3">
                        <label for="input-itemnaam"
                            >{{ $t('com_product', { ns: 'common' }) }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <product-selector
                            v-if="!tienplus"
                            v-model="localItem.productID"
                            v-bind:discrim="localDiscrim"
                            :filter="filterKaas"
                            :disabled="!magEditProduct"
                            @input="setChangedProduct"
                        />
                        <lookup-combo
                            v-if="tienplus"
                            v-model="localItem.productID"
                            v-bind:combotype="lctypeProduct10"
                            v-bind:discrim="localDiscrim"
                            :productText="localItem.naam"
                            :showfilter="true"
                            :filter="filterKaas"
                            :disabled="!magEditProduct"
                            @input="setChangedProduct"
                        ></lookup-combo>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <b-card @submit.stop.prevent="handleSubmit">
            <b-alert
                v-model="showDismissibleAlert"
                variant="danger"
                dismissible
            >
                {{ alertText }}
            </b-alert>
            <b-tabs card>
                <b-tab
                    v-if="!isMarkStockItem"
                    :title="$t('com_stock', { ns: 'common' })"
                    v-bind:key="1"
                    active
                >
                    <b-card-text>
                        <div>
                            <b-row>
                                <b-col sm="6">
                                    <b-row>
                                        <b-col sm="3">
                                            <label for="input-amount"
                                                >{{
                                                    $t('com_amount', {
                                                        ns: 'common'
                                                    })
                                                }}:</label
                                            >
                                        </b-col>
                                        <b-col sm="9">
                                            <b-form-input
                                                id="input-amount"
                                                :placeholder="
                                                    $t('com_amount', {
                                                        ns: 'common'
                                                    })
                                                "
                                                type="number"
                                                v-model.number="
                                                    localItem.aantal
                                                "
                                                :disabled="!magEdit"
                                                @change="setChanged"
                                            ></b-form-input>
                                        </b-col>
                                        <b-col sm="3">
                                            <label
                                                >{{
                                                    $t('com_unit', {
                                                        ns: 'common'
                                                    })
                                                }}:
                                            </label>
                                        </b-col>
                                        <b-col sm="9">
                                            <lookup-combo
                                                :key="aangeraakt_teller"
                                                v-model="localItem.unitID"
                                                v-bind:combotype="lctypeUnits"
                                                v-bind:discrim="
                                                    localItem.baseUnit.toString()
                                                "
                                                :disabled="!magEdit"
                                            ></lookup-combo>
                                        </b-col>
                                        <b-col sm="3">
                                            <label for="input-itemloc"
                                                >{{
                                                    $t('com_location', {
                                                        ns: 'common'
                                                    })
                                                }}:</label
                                            >
                                        </b-col>
                                        <b-col sm="9">
                                            <lookup-combo
                                                id="input-itemloc"
                                                v-model="localItem.locationID"
                                                v-bind:combotype="
                                                    lctypeLocation
                                                "
                                                :disabled="!magEdit"
                                                @change="setChanged"
                                            ></lookup-combo>
                                        </b-col>
                                        <b-col sm="3">
                                            <label for="pi_starttijd"
                                                >{{
                                                    $t('com_tht', {
                                                        ns: 'common'
                                                    })
                                                }}:</label
                                            >
                                        </b-col>
                                        <b-col sm="9">
                                            <b-form-datepicker
                                                id="pi_starttijd"
                                                v-model="localItem.datum"
                                                :disabled="!magEdit"
                                                v-on:change="setChanged"
                                            ></b-form-datepicker>
                                        </b-col>
                                        <b-col sm="3" v-if="isKaas">
                                            <label for="pi_proddate"
                                                >{{
                                                    $t('prod_proddatum', {
                                                        ns: 'production'
                                                    })
                                                }}:</label
                                            >
                                        </b-col>
                                        <b-col sm="9" v-if="isKaas">
                                            <b-form-datepicker
                                                id="pi_proddate"
                                                v-model="
                                                    localItem.productiedatum
                                                "
                                                :disabled="!magEdit"
                                                v-on:change="setChanged"
                                            ></b-form-datepicker>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col sm="6">
                                    <b-row>
                                        <b-col sm="3">
                                            <label
                                                >{{
                                                    $t('log_leverancier', {
                                                        ns: 'logistics'
                                                    })
                                                }}:
                                            </label>
                                        </b-col>
                                        <b-col sm="9">
                                            <lookup-combo
                                                v-model="localItem.supplierID"
                                                v-bind:combotype="lctypeCompany"
                                                :disabled="!magEdit"
                                            ></lookup-combo>
                                        </b-col>
                                        <b-col sm="3">
                                            <label for="input-batchid"
                                                >{{
                                                    $t('com_batch', {
                                                        ns: 'common'
                                                    })
                                                }}:</label
                                            >
                                        </b-col>
                                        <b-col sm="9">
                                            <b-form-input
                                                id="input-batchid"
                                                :placeholder="
                                                    $t('log_batchref', {
                                                        ns: 'logistics'
                                                    })
                                                "
                                                v-model="localItem.batchID"
                                                :disabled="!magEdit"
                                                @change="setChanged"
                                            ></b-form-input>
                                        </b-col>
                                        <b-col sm="3">
                                            <label for="input-extnaam"
                                                >{{
                                                    $t('log_externalname', {
                                                        ns: 'logistics'
                                                    })
                                                }}:</label
                                            >
                                        </b-col>
                                        <b-col sm="9">
                                            <b-form-input
                                                id="input-extnaam"
                                                :placeholder="
                                                    $t('log_xleverancier', {
                                                        ns: 'logistics',
                                                        val: $t('com_naam', {
                                                            ns: 'common'
                                                        })
                                                    })
                                                "
                                                v-model="localItem.externNaam"
                                                :disabled="!magEdit"
                                                @change="setChanged"
                                            ></b-form-input>
                                        </b-col>
                                        <b-col sm="3">
                                            <label for="input-extid"
                                                >{{
                                                    $t('com_externalid', {
                                                        ns: 'common'
                                                    })
                                                }}:</label
                                            >
                                        </b-col>
                                        <b-col sm="9">
                                            <b-form-input
                                                id="input-extid"
                                                :placeholder="
                                                    $t('log_xleverancier', {
                                                        ns: 'logistics',
                                                        val: 'ID'
                                                    })
                                                "
                                                v-model="localItem.externID"
                                                :disabled="!magEdit"
                                                @change="setChanged"
                                            ></b-form-input>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="3">
                                    <label for="input-itemremark"
                                        >{{
                                            $t('com_opmerking', {
                                                ns: 'common'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <b-form-textarea
                                        id="input-itemremark"
                                        :placeholder="
                                            $t('com_opmerking', {
                                                ns: 'common'
                                            })
                                        "
                                        v-model="localItem.opmerking"
                                        @change="setChanged"
                                        rows="3"
                                    ></b-form-textarea>
                                </b-col>
                            </b-row>
                        </div>
                    </b-card-text>
                </b-tab>
                <b-tab
                    v-if="isMarkStockItem"
                    :title="$t('com_cheesemark', { ns: 'common' })"
                    v-bind:key="2"
                    active
                >
                    <b-card-text>
                        <div>
                            <b-row v-if="vetKlasseEdit">
                                <b-col sm="2">
                                    <label for="input-vetklasse"
                                        >{{
                                            $t('prod_vetklasse', {
                                                ns: 'production'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="10">
                                    <lookup-combo
                                        id="input-vetklasse"
                                        :placeholder="
                                            $t('prod_vetklasse', {
                                                ns: 'production'
                                            })
                                        "
                                        v-model="localItem.vetklasse"
                                        :disabled="!vetKlasseEdit"
                                        v-bind:combotype="lctypeFatclass"
                                    ></lookup-combo>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="2">
                                    <b-row>
                                        <b-col sm="12">
                                            <label for="input-letter"
                                                >{{
                                                    $t('com_letters', {
                                                        ns: 'common'
                                                    })
                                                }}:</label
                                            >
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col sm="10">
                                    <b-row>
                                        <b-col sm="2">
                                            <b-form-input
                                                id="input-letter"
                                                :placeholder="
                                                    $t('com_letters', {
                                                        ns: 'common'
                                                    })
                                                "
                                                v-model="localItem.letters"
                                                :disabled="!magEdit"
                                                @change="setChangedLetter"
                                            ></b-form-input>
                                        </b-col>
                                        <b-col sm="4">
                                            <b-form-input
                                                id="input-start"
                                                :placeholder="
                                                    $t('prod_startnummer', {
                                                        ns: 'production'
                                                    })
                                                "
                                                type="number"
                                                v-model.number="
                                                    localItem.startnummer
                                                "
                                                :disabled="!magEdit"
                                                @change="setChangedMerk"
                                            ></b-form-input>
                                        </b-col>
                                        <b-col sm="4">
                                            <b-form-input
                                                id="input-eind"
                                                :placeholder="
                                                    $t('prod_eindnummer', {
                                                        ns: 'production'
                                                    })
                                                "
                                                type="number"
                                                v-model.number="
                                                    localItem.eindnummer
                                                "
                                                :disabled="!magEdit"
                                                @change="setChangedMerk"
                                            ></b-form-input>
                                        </b-col>
                                        <b-col sm="2">
                                            <b-badge variant="info">{{
                                                merkaantaltext
                                            }}</b-badge>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col sm="6">
                                    <b-row>
                                        <b-col sm="3">
                                            <label for="input-amount"
                                                >{{
                                                    $t('com_amount', {
                                                        ns: 'common'
                                                    })
                                                }}:</label
                                            >
                                        </b-col>
                                        <b-col sm="9">
                                            <b-form-input
                                                id="input-amount"
                                                :placeholder="
                                                    $t('com_amount', {
                                                        ns: 'common'
                                                    })
                                                "
                                                type="number"
                                                v-model.number="
                                                    localItem.aantal
                                                "
                                                readonly
                                                @change="setChanged"
                                            ></b-form-input>
                                        </b-col>
                                        <b-col sm="3">
                                            <label
                                                >{{
                                                    $t('com_unit', {
                                                        ns: 'common'
                                                    })
                                                }}:
                                            </label>
                                        </b-col>
                                        <b-col sm="9">
                                            <lookup-combo
                                                v-model="localItem.unitID"
                                                v-bind:combotype="lctypeUnits"
                                                v-bind:discrim="
                                                    localItem.baseUnit.toString()
                                                "
                                                :disabled="!magEdit"
                                            ></lookup-combo>
                                        </b-col>
                                        <b-col sm="3">
                                            <label for="input-itemloc"
                                                >{{
                                                    $t('com_location', {
                                                        ns: 'common'
                                                    })
                                                }}:</label
                                            >
                                        </b-col>
                                        <b-col sm="9">
                                            <lookup-combo
                                                id="input-itemloc"
                                                v-model="localItem.locationID"
                                                v-bind:combotype="
                                                    lctypeLocation
                                                "
                                                :disabled="!magEdit"
                                                @change="setChanged"
                                            ></lookup-combo>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col sm="6">
                                    <b-row>
                                        <b-col sm="3">
                                            <label
                                                >{{
                                                    $t('log_leverancier', {
                                                        ns: 'logistics'
                                                    })
                                                }}:
                                            </label>
                                        </b-col>
                                        <b-col sm="9">
                                            <lookup-combo
                                                v-model="localItem.supplierID"
                                                v-bind:combotype="lctypeCompany"
                                                :disabled="!magEdit"
                                            ></lookup-combo>
                                        </b-col>
                                        <b-col sm="3">
                                            <label for="input-extnaam"
                                                >{{
                                                    $t('log_externalname', {
                                                        ns: 'logistics'
                                                    })
                                                }}:</label
                                            >
                                        </b-col>
                                        <b-col sm="9">
                                            <b-form-input
                                                id="input-extnaam"
                                                :placeholder="
                                                    $t('log_xleverancier', {
                                                        ns: 'logistics',
                                                        val: $t('com_naam', {
                                                            ns: 'common'
                                                        })
                                                    })
                                                "
                                                v-model="localItem.externNaam"
                                                :disabled="!magEdit"
                                                @change="setChanged"
                                            ></b-form-input>
                                        </b-col>
                                        <b-col sm="3">
                                            <label for="input-extid"
                                                >{{
                                                    $t('com_externalid', {
                                                        ns: 'common'
                                                    })
                                                }}:</label
                                            >
                                        </b-col>
                                        <b-col sm="9">
                                            <b-form-input
                                                id="input-extid"
                                                :placeholder="
                                                    $t('log_xleverancier', {
                                                        ns: 'logistics',
                                                        val: 'ID'
                                                    })
                                                "
                                                v-model="localItem.externID"
                                                :disabled="!magEdit"
                                                @change="setChanged"
                                            ></b-form-input>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="3">
                                    <label for="input-itemremark"
                                        >{{
                                            $t('com_opmerking', {
                                                ns: 'common'
                                            })
                                        }}:</label
                                    >
                                </b-col>
                                <b-col sm="9">
                                    <b-form-textarea
                                        id="input-itemremark"
                                        :placeholder="
                                            $t('com_opmerking', {
                                                ns: 'common'
                                            })
                                        "
                                        v-model="localItem.opmerking"
                                        @change="setChanged"
                                        rows="3"
                                    ></b-form-textarea>
                                </b-col>
                            </b-row>
                        </div>
                    </b-card-text>
                </b-tab>

                <special-attachments
                    v-if="localItem.id > 0"
                    :itemId="localItem.id"
                    :attType="attachmentType"
                />
            </b-tabs>
        </b-card>
    </b-modal>
</template>

<script>
import { VoorraadItem, MerkVoorraadItem } from '../../models/Voorraad';
import { LookupCombo, SpecialAttachments } from '../components';
import { PRODCAT_ENUM } from '../../models/ProductCategorie';
import { loadItem } from '@/models/DataHelper';
import ProductSelector from './ProductSelector.vue';
export default {
    name: 'ItemStockModal',
    data: function () {
        return {
            showDismissibleAlert: false,
            alertText: '',
            lctypeUnits: window.constants.LCPERUNITSBASEONLY, // voorraad mag alleen in basiseenheid
            lctypeMarktype: window.constants.LCMARKTYPE,
            lctypeLocation: window.constants.LCLOCATION,
            lctypeFatclass: window.constants.LCFATCLASS,
            lctypeProduct: window.constants.LCPRODUCT,
            lctypeProduct10: window.constants.LCSIMPLEPRODUCT,
            lctypeCompany: window.constants.LCCOMPANY,
            attachmentType: window.constants.ATT_STOCKITEM,

            perUnitsId: 0,
            melkId: 0,
            localNaam: '',
            opmerking: '',
            lokaalItem: null,
            vetKlasseEdit: false,
            merkaantal: 0,
            aangeraakt_teller: 0
        };
    },
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        inItem: {
            type: [VoorraadItem, MerkVoorraadItem],
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        },
        editAllowed: {
            type: Boolean,
            default: false
        },
        tienplus: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LookupCombo,
        SpecialAttachments,
        ProductSelector
    },
    watch: {},
    computed: {
        isMarkStockItem() {
            return this.inItem.type === 'MerkVoorraadItem';
        },
        isKaas() {
            return (
                this.inItem.prodcatID == PRODCAT_ENUM.Kaas ||
                this.inItem.prodcatID == PRODCAT_ENUM.Onvolwaardig
            );
        },
        filterKaas() {
            return this.isKaas ? 'kaas' : '';
        },
        formtitle() {
            return this.inItem.isNew
                ? this.$t('com_makenew', {
                      ns: 'common',
                      val: this.inItem.type.toLocaleLowerCase()
                  })
                : this.$t('com_edit', {
                      ns: 'common',
                      val: this.inItem.type.toLocaleLowerCase()
                  });
        },
        localItem() {
            if (this.inItem == undefined) return null;
            return this.inItem;
        },
        localDiscrim() {
            if (this.isMarkStockItem) return PRODCAT_ENUM.Kaasmerk.toString();
            if (this.isKaas) return PRODCAT_ENUM.Kaas.toString();
            return '!' + PRODCAT_ENUM.Kaasmerk.toString();
        },
        magEdit() {
            if (this.editAllowed) {
                return (
                    this.localItem.id == undefined ||
                    this.localItem.id < 0 ||
                    this.localItem.status < 2
                );
            } else {
                return false;
            }
        },
        magEditProduct() {
            if (this.editAllowed) {
                return this.localItem.id == undefined || this.localItem.id < 0;
            } else {
                return false;
            }
        },
        merkaantaltext() {
            return this.merkaantal == 0 ? '' : this.merkaantal;
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                { key: 'naam', label: 'Naam', sortable: true, visible: true },
                {
                    key: 'mimetype',
                    label: 'Type',
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.opmerking = '';
            this.$emit('hide');
        },
        change(visible) {
            this.setChanged();
            this.$emit('change', visible);
        },
        setChanged() {
            this.localItem.isChanged = true;
        },
        setChangedMerk() {
            if (this.localItem.startnummer && this.localItem.eindnummer) {
                this.merkaantal =
                    this.localItem.eindnummer - this.localItem.startnummer + 1;
                this.localItem.aantal = this.merkaantal;
            }
            this.localItem.isChanged = true;
        },
        setChangedLetter() {
            this.localItem.letters = this.localItem.letters.toUpperCase();
            this.localItem.isChanged = true;
        },
        async setChangedProduct(val) {
            if (!this.isMarkStockItem) {
                const product =
                    this.$store.getters['components/getProductsbyID'](val);
                const baseUnit = product
                    ? product.eenheid
                    : this.localItem.baseUnit;

                const oUnit =
                    this.$store.getters['components/getUnitbyID'](baseUnit);
                this.localItem.baseUnit = oUnit
                    ? oUnit.base ?? oUnit.value
                    : baseUnit;
                this.aangeraakt_teller += 1;
            }
            this.setChanged();
        },
        async checkFormValidity() {
            const invalidBasis = this.localItem.isChanged
                ? this.localItem.CheckValidity(this.$t)
                : '';
            if (invalidBasis) this.showAlert(invalidBasis);
            if (this.queuedAmount > 0) {
                const doClose = await this.$root.showConfirmationBox(
                    this.$t('com_queuedbijlagen', { ns: 'common' })
                );
                if (!doClose) {
                    return false;
                }
            }
            const valid = this.localItem != undefined && !invalidBasis;
            return valid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSubmit() {
            // Exit when the form isn't valid
            if (!(await this.checkFormValidity())) {
                return;
            }
            if (this.localItem.isChanged) this.saveThisProduct();
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-stockitem');
            });
        },
        async saveThisProduct() {
            if (this.localItem.isNew) {
                if (this.isMarkStockItem) {
                    await loadItem(
                        'stock/addMarkStockItem',
                        this.localItem.SetForAdd(),
                        this.$t
                    );
                } else {
                    await loadItem(
                        'stock/addStockItem',
                        this.localItem.SetForAdd(),
                        this.$t
                    );
                }
            } else {
                if (this.isMarkStockItem) {
                    await loadItem(
                        'stock/updateMarkStockItem',
                        this.localItem.SetForUpdate(),
                        this.$t
                    );
                } else {
                    await loadItem(
                        'stock/updateStockItem',
                        this.localItem.SetForUpdate(),
                        this.$t
                    );
                }
            }
        }
    },
    created() {
        if (this.localItem.unitID == undefined) {
            this.localItem.unitID = this.$store.getters[
                'components/getUnitIDbyName'
            ](this.localItem.eenheid);
        }
    }
};
</script>

<style scoped></style>
