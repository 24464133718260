import Api from '../../../api';
import store from '../../../store';

const fetchUnits =async (data) => {   
    return fetchInfo('/MasterData/GetUnits', data);    
};

const fetchShapes = async (data) => {   
    return fetchInfo('/MasterData/GetShapes', data);    
};

const fetchShape = async (data) => {   
    return fetchInfo('/MasterData/GetShape', data);    
};

const fetchCheeseMarks = async (data) => {   
    return fetchInfo('/CheeseMark/GetCheeseMarkTypes', data);   
};

const fetchGroupsForIngredientCat  = async (data) => {   
    return fetchInfo('/Ingredient/GetIngredientGroups', data);    
};

const fetchIngredientsForGroup  = async (data) => {   
    return fetchInfo('/Ingredient/GetIngredients', data);    
};

const fetchSimpleProducts =async (data) => {   
    return fetchInfo('/Product/GetSimpleProducts', data);    
};

const fetchCheeses = async (data) => {   
    return fetchInfo('/CheeseType/GetCheeseTypes', data);      
};

const fetchCheesesWithoutRecipe =async (data) => {   
    return fetchInfo('/CheeseType/GetCheeseTypesWithoutRecipe', data);    
};

const fetchCheeseTypeAlternatives =async (data) => {   
    return fetchInfo('/CheeseType/GetCheeseTypesWithBase', data);    
};

const fetchFailureCheese =async (data) => {   
    return fetchInfo('/FailureCheese/GetFailureCheeseProducts', data);  
};

const fetchCompanies =async (data) => {   
    return fetchInfo('/Company/GetCompanies', data);  
};

const fetchCompany =async (data) => {   
    return fetchInfo('/Company/GetCompany', data);  
};

const fetchProducts = async (data) => {   
    return fetchInfo('/Product/GetProducts', data);  
};
const fetchBrineIngredients = async (data) => {   
    return fetchInfo('/Product/GetBrineIngredients', data);  
};


const fetchLocations = async (data) => {   
    return fetchInfo('/MasterData/GetStockLocations', data);
};

const fetchLocation = async (data) => {   
    return fetchInfo('/MasterData/GetStockLocation', data);
};

const fetchBatchGroupProductionDate = async (data) => {   
    return fetchInfo('/Batch/GetProductionDate', data);
};


// FileUpLoadModel pm
const storeAttachment = async (data) => {
    const headers = {
        'Content-Type': 'multipart/form-data' //;boundary=<calculated when request is sent></calculated>
    };
    return await Api(store, headers).post(
        '/Attachment/StoreAttachment',
        data
    );
};
// int id, int attachmentType
const fetchAttachmentInfo = async (data) => {
    return fetchInfo('/Attachment/GetAttachmentInfo', data);
};
// int attachmentID, int id, int attachmentType
const fetchAttachment = async (data) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Content-Disposition': 'attachment;'
    };
    const responseType ='arraybuffer';
    return fetchInfoAtt('/Attachment/GetAttachment', data, headers, responseType);
};

//  int productionID, int attachmentID
const deleteAttachment = async (data) => {
    return deleteInfo('/Attachment/DeleteAttachment', data);
};

//
const fetchLookupLabelCategories = async (data) => {
    return fetchInfo('/Attachment/GetLookupLabels', data);
};


const deleteInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).delete(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};

const fetchInfoAtt = async (apicall, data, headers, responsetype) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: headers,
                responseType: responsetype
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: headers,
            responseType: responsetype
        });
    }
};

const fetchInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +'?' +
                Object.keys(data)
                    .map(
                        k =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};


export default {
    fetchUnits,
    fetchShapes,
    fetchShape,
    fetchCheeseMarks,
    fetchCheeseTypeAlternatives,
    fetchGroupsForIngredientCat,
    fetchIngredientsForGroup,
    fetchCheeses,
    fetchCheesesWithoutRecipe,
    fetchFailureCheese,
    fetchCompanies,
    fetchCompany,
    fetchProducts,
    fetchBrineIngredients,
    fetchLocations,
    fetchLocation,
    storeAttachment,
    fetchAttachmentInfo,
    fetchAttachment,
    deleteAttachment,
    fetchLookupLabelCategories,
    fetchSimpleProducts,
    fetchBatchGroupProductionDate
};
