<template>
    <b-modal
        id="modal-externalconn"
        size="lg"
        ref="modal"
        :title="formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>
        <b-card @submit.stop.prevent="handleSubmit">
            <b-row>
                <b-col sm="6">
                    <b-row>
                        <b-col sm="4">
                            <label
                                >{{ $t('log_inuse', { ns: 'logistics' }) }}
                            </label>
                        </b-col>
                        <b-col sm="8">
                            {{ giveTimestring }}
                        </b-col>
                    </b-row>
                    <b-row v-if="showAPIOUT || showAPIIN">
                        <b-col sm="3">
                            <label for="input-itemnaam"
                                >{{ $t('com_naam', { ns: 'common' }) }}:</label
                            >
                        </b-col>
                        <b-col sm="9">
                            <b-form-input
                                id="input-itemnaam"
                                :placeholder="$t('com_naam', { ns: 'common' })"
                                v-model="localItem.naam"
                                :disabled="!magEdit"
                                @change="setChanged()"
                            ></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="3">
                            <label for="input-itemtype"
                                >{{
                                    $t('prod_type', { ns: 'production' })
                                }}:</label
                            >
                        </b-col>
                        <b-col sm="9">
                            <b-form-select
                                v-model="localItem.ondType"
                                :options="typeLijst"
                                :disabled="!magEdit || isDevice || isEdit"
                                @change="setType"
                            >
                                <template #first>
                                    <b-form-select-option value=""
                                        >--
                                        {{
                                            $t('com_selecteer', {
                                                ns: 'common'
                                            })
                                        }}
                                        --</b-form-select-option
                                    >
                                </template>
                            </b-form-select>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col sm="6">
                    <div v-if="showDevice">
                        <b-row>
                            <b-col sm="3">
                                <label for="input-deviceid"
                                    >{{
                                        $t('com_deviceid', { ns: 'common' })
                                    }}:</label
                                >
                            </b-col>
                            <b-col sm="9">
                                <b-form-input
                                    id="input-deviceid"
                                    :placeholder="
                                        $t('com_deviceid', { ns: 'common' })
                                    "
                                    v-model="localItem.eigenid"
                                    @change="setChanged()"
                                    :disabled="!magEdit || isDevice || isEdit"
                                ></b-form-input>
                            </b-col>
                        </b-row>
                    </div>
                    <div v-else>
                        <b-row v-if="doShow">
                            <b-col sm="3">
                                <label for="input-token"
                                    >{{
                                        $t('com_token', { ns: 'common' })
                                    }}:</label
                                >
                            </b-col>
                            <b-col sm="9">
                                <b-input-group>
                                    <b-form-input
                                        id="input-token"
                                        :placeholder="
                                            $t('com_token', { ns: 'common' })
                                        "
                                        v-model="localtoken"
                                        trim
                                        :readonly="!showAPIOUT"
                                        @change="setToken"
                                    ></b-form-input>
                                    <b-input-group-append>
                                        <b-button
                                            size="sm"
                                            @click="genToken()"
                                            :disabled="showAPIOUT"
                                            class="bgc_aux_unknown"
                                            ><font-awesome-icon
                                                icon="cogs"
                                                size="1x"
                                            />
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                        </b-row>
                        <b-row v-if="showAPIOUT">
                            <b-col sm="3">
                                <label for="input-keynaam"
                                    >{{
                                        $t('com_keynaam', { ns: 'common' })
                                    }}:</label
                                >
                            </b-col>
                            <b-col sm="9">
                                <b-form-input
                                    id="input-keynaam"
                                    :placeholder="
                                        $t('com_keynaam', { ns: 'common' })
                                    "
                                    v-model="localItem.keynaam"
                                    trim
                                    :disabled="!magEdit || isEdit"
                                    @change="setChanged()"
                                ></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row v-if="showAPIOUT">
                            <b-col sm="3">
                                <label for="input-url"
                                    >{{
                                        $t('com_url', { ns: 'common' })
                                    }}:</label
                                >
                            </b-col>
                            <b-col sm="9">
                                <b-form-input
                                    id="input-url"
                                    :placeholder="
                                        $t('com_url', { ns: 'common' })
                                    "
                                    trim
                                    v-model="localItem.url"
                                    :disabled="!magEdit || isEdit"
                                    @change="setChanged()"
                                ></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row v-if="doShow">
                            <b-col sm="3">
                                <label for="input-email"
                                    >{{
                                        $t('com_mail', { ns: 'common' })
                                    }}:</label
                                >
                            </b-col>
                            <b-col sm="9">
                                <b-form-input
                                    id="input-email"
                                    :placeholder="
                                        $t('com_mail', { ns: 'common' })
                                    "
                                    v-model="localItem.email"
                                    :disabled="!magEdit"
                                    @change="setChanged()"
                                ></b-form-input>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</template>

<script>
import DateHelper from '@/models/DateHelper';
import Helper from '@/models/Helper';
import { loadItem, loadList } from '../../models/DataHelper';
import { ExternalDevice } from '../../models/Extern';
export default {
    name: 'ExternalConnectionModal',
    data: function () {
        return {
            showDismissibleAlert: false,
            localtoken: '',
            alertText: '',
            isChanged: false,
            isDevice: false,
            showDevice: false,
            showAPIOUT: false,
            showAPIIN: false,
            typeLijst: []
        };
    },
    model: {
        prop: 'visible',
        event: 'changed'
    },
    props: {
        inItem: {
            type: ExternalDevice,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        },
        editAllowed: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    watch: {},
    computed: {
        formtitle() {
            return this.$t('com_makenew', {
                ns: 'common',
                val: this.$t('com_extregistratie', {
                    ns: 'common'
                }).toLowerCase()
            });
        },
        localItem() {
            if (this.inItem == undefined) return null;
            return this.inItem;
        },
        magEdit() {
            return this.editAllowed;
        },
        doShow() {
            return this.showDevice || this.showAPIOUT || this.showAPIIN;
        },
        giveTimestring() {
            const tijd = DateHelper.MakeDateTime(
                this.localItem.tijdstip ?? this.tijdstip
            );
            return DateHelper.convertedToDateTimeShort(tijd);
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.opmerking = '';
            this.$emit('hide');
        },
        setChanged() {
            this.isChanged = true;
        },
        checkFormValidity() {
            const invalidBasis = this.localItem.CheckValidity(this.$t);
            if (invalidBasis) this.showAlert(invalidBasis);
            const valid = this.localItem != undefined && !invalidBasis;
            return valid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (this.isChanged) {
                if (!this.checkFormValidity()) {
                    return;
                }
                this.saveThisProduct();
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-externalconn');
            });
        },
        async saveThisProduct() {
            if (this.showDevice) {
                await loadItem(
                    'extern/registerDevice',
                    { deviceID: this.localItem.eigenid },
                    this.$t
                );
            } else {
                await loadItem(
                    'extern/saveExternalDevice',
                    this.localItem.SetForSave(),
                    this.$t
                );
            }
            this.$emit('saved');
        },
        async genToken() {
            const token = await loadItem('extern/generateToken', null, this.$t);
            this.localItem.token = token;
            this.localtoken = token;
            this.openMail();
        },
        openMail() {
            const version = this.$store.getters['masterdata/getPackageVersion'];
            const naam = Helper.stringformat('CHEESEIT {0}+', version ?? '-');
            const subject = Helper.stringformat('UPDATE {0}', naam);
            const body = encodeURIComponent(
                this.$t('com_newapitoken', {
                    ns: 'common',
                    val: this.localItem.token
                })
            );
            const theEmail = Helper.stringformat(
                '{0}?subject={1}&body={2}',
                this.localItem.email,
                subject,
                body
            );

            const theMail = `mailto:${theEmail}`;
            window.location.href = theMail;
        },
        setToken(value) {
            this.localItem.token = value;
            this.setChanged();
        },
        setType(value) {
            this.isEdit = !this.localItem.isNew;
            this.showDevice = value == window.constants.API_DEVICE;
            this.showAPIOUT = value == window.constants.API_OUT;
            this.showAPIIN = value == window.constants.API_IN;
        },
        async setDeviceTypes() {
            const lijst = await loadList(
                'extern/getExtDeviceTypesLijst',
                'extern/loadDeviceTypes',
                null,
                false,
                this.$t
            );
            this.typeLijst = lijst.map((x) => ({ value: x.id, text: x.naam }));
        }
    },
    mounted() {
        this.setDeviceTypes();
    },
    created() {
        this.tijdstip = new Date();
        // als binnen via de scanner
        if (this.localItem.eigenid) {
            if (this.localItem.isNew) {
                this.localItem.ondType = 1;
                this.setChanged();
            }
            this.isDevice = true;
        }
        this.setType(this.localItem.ondType);
    }
};
</script>

<style scoped></style>

