<template>
    <b-container fluid>
        <b-button v-if="!geprint" size="sm" @click="createNewItem" class="mr-1"
            ><font-awesome-icon icon="folder-plus" size="1x" />
            {{
                $t('com_newx', {
                    ns: 'common',
                    val: this.$t('com_batch', {
                        ns: 'common'
                    })
                })
            }}
        </b-button>
        <b-card no-body class="mb-1">
            &nbsp;
            <b-table
                striped
                responsive
                :fields="visibleFields"
                :items="sortedList"
                show-empty
                :empty-text="$t('com_geen_data', { ns: 'common' })"
            >
                <template v-if="!geprint" v-slot:cell(actions)="row">
                    <b-button
                        v-if="checkRight('StockUpdate')"
                        size="sm"
                        @click="editItem(row.item)"
                        class="bgc_aux_warning"
                        ><font-awesome-icon icon="edit" size="1x" />
                    </b-button>
                    <b-button
                        v-if="checkRight('StockDelete')"
                        size="sm"
                        @click="verwijderItem(row.item)"
                        class="bgc_aux_alarm"
                        ><font-awesome-icon icon="trash-alt" size="1x" />
                    </b-button>
                </template>
            </b-table>
        </b-card>
        <delivery-note-item-modal
            v-if="detailsVisible"
            :inItem="currentItem"
            v-model="detailsVisible"
            v-bind:editAllowed="true"
            @hide="reset"
            @input="addDeliveryItem"
        />
    </b-container>
</template>

<script lang="ts">
import _ from 'lodash';
import { PRODCAT_ENUM } from '../../models/ProductCategorie';
import { DeliveryItemStock } from '../../models/Delivery';
import DeliveryNoteItemModal from './DeliveryNoteItemModal.vue';
import DateHelper from '../../models/DateHelper';
import { Permission } from '../../models/Permissions';
import { VueExtension } from '../../models/VueExtension';
import { defineComponent, PropType } from 'vue';
export default defineComponent({
    name: 'DeliveryNoteItems',
    data() {
        return {
            currentItem: new DeliveryItemStock({}),
            bewerkItem: new DeliveryItemStock({}),
            detailsVisible: false
        };
    },
    props: {
        items: {
            type: Array<DeliveryItemStock>,
            required: true
        },
        geprint: {
            type: Boolean,
            default: false
        }
    },
    components: {
        DeliveryNoteItemModal
    },
    computed: {
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                {
                    key: 'id',
                    label: this.$t('prod_pakbon', { ns: 'production' }) + '#',
                    sortable: false,
                    visible: false
                },
                {
                    key: 'referentie',
                    label: this.$t('com_batch', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'productnaam',
                    label: this.$t('com_product', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'vorm',
                    label: this.$t('prod_vorm', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'aantal',
                    label: this.$t('com_number', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'eenheid',
                    label: this.$t('com_unit', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'gewicht',
                    label: this.$t('com_weight', { ns: 'common' }),
                    sortable: true,
                    visible: true,
                    tdClass: (value) =>
                        value < 0 ? 'text-danger' : 'text-success'
                }
            ];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let fieldsExtra: Array<any> = [];
            fieldsExtra = [
                {
                    key: 'productiedatum',
                    label: this.$t('prod_proddatum', { ns: 'production' }),
                    sortable: true,
                    visible: true,
                    formatter: this.displayDate
                },
                {
                    key: 'leeftijdKlasse',
                    label: this.$t('prod_leeftijd', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'merken',
                    label: this.$t('com_marks', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'externalID',
                    label: this.$t('com_externalid', { ns: 'common' }),
                    sortable: true,
                    visible: true
                }
            ];
            fieldsInit.push(...fieldsExtra);
            return fieldsInit.filter((field) => field.visible);
        },
        sortedList() {
            return this.items ? _.sortBy(this.items, ['referentie']) : [];
        }
    },
    methods: {
        reset() {
            this.detailsVisible = false;
            this.currentItem = new DeliveryItemStock({});
        },
        createNewItem() {
            this.currentItem = new DeliveryItemStock({});
            this.detailsVisible = true;
        },
        addDeliveryItem(value) {
            if (this.bewerkItem.id > -1 && this.bewerkItem.id == value.id) {
                const index = this.items.findIndex(
                    (x) =>
                        x.id == this.bewerkItem.id &&
                        x.referentie == this.bewerkItem.referentie
                );
                if (index > -1) {
                    this.items.splice(index, 1);
                }
            }
            this.items.push(value);
            this.$emit('change', true);
        },
        editItem(item) {
            this.currentItem = item;
            this.bewerkItem = item;
            this.detailsVisible = true;
        },
        async verwijderItem(item) {
            (this.$root as VueExtension)
                .showConfirmationBox(
                    this.$t('com_delete', {
                        ns: 'common',
                        val: this.$t('com_regel', { ns: 'common' })
                    }) +
                        item.id +
                        ' : ' +
                        item.referentie
                )
                .then((res) => {
                    if (res == true) {
                        const index = this.items.findIndex(
                            (x) =>
                                x.id == item.id &&
                                x.referentie == item.referentie
                        );
                        if (index > -1) {
                            this.items.splice(index, 1);
                            this.$emit('change', true);
                        }
                    }
                });
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return (this.$root as VueExtension).handleRight(id);
        },
        displayDate(value) {
            return DateHelper.convertedToDate(value, false);
        }
    },
    mounted() {}
});
</script>

<style scoped>
.overflow-class /deep/ .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
}
</style>

