<template>
    <b-card no-body :header="$t('com_groepen', { ns: 'common' })">
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>
        <div>
            <!-- maak / toon groep -->
            <b-form-group label-for="gebruikers-naam" class="form-inline">
                <b-form-input
                    id="groeps-naam"
                    :placeholder="
                        $t('com_groepsnaam', {
                            ns: 'common'
                        })
                    "
                    v-model="groupName"
                ></b-form-input>
                <b-button @click="addNewGroup()" class="bgc_aux_normal"
                    ><font-awesome-icon icon="arrow-down" size="1x" />
                </b-button>
                &nbsp;
                <b-button @click="getGroups()" class="bgc_aux_normal"
                    ><font-awesome-icon icon="sync" size="1x" />&nbsp;{{
                        $t('com_refresh', { ns: 'common' })
                    }}
                </b-button>
            </b-form-group>
            <b-table
                :items="groupList"
                :fields="groepen"
                stacked="md"
                show-empty
                :empty-text="$t('com_geen_data', { ns: 'common' })"
                small
                striped
                @row-clicked="toonDetail"
            >
                <template v-slot:cell(actions)="row">
                    <div class="form-inline">
                        <b-button
                            v-if="checkRight('UserAuthorize')"
                            size="sm"
                            @click="verwijderItem(row.item)"
                            class="bgc_aux_alarm"
                            :disabled="isAdminGroup(row.item)"
                            ><font-awesome-icon icon="trash-alt" size="1x" />
                        </b-button>
                        <b-button
                            size="sm"
                            @click="toonDetail(row.item)"
                            class="bgc_aux_unknown"
                            ><font-awesome-icon icon="info-circle" size="1x" />
                        </b-button>
                    </div>
                </template>
            </b-table>
            <br />
            <!-- zet rechten voor groep -->

            <br />
            <h2>{{ selectedGroupName }}</h2>
            <b-tabs card v-if="showGroupInfo && checkRight('UserAuthorize')">
                <b-tab :title="$t('com_members', { ns: 'common' })" active>
                    <b-card-text>
                        <b-form-group class="form-inline">
                            <b-form-select
                                v-model="selectedUserID"
                                :options="availableUserList"
                            >
                                <template #first>
                                    <b-form-select-option value=""
                                        >--
                                        {{
                                            $t('com_selecteer', {
                                                ns: 'common'
                                            })
                                        }}
                                        --</b-form-select-option
                                    >
                                </template>
                            </b-form-select>
                            <b-button
                                @click="addUserToGroup()"
                                class="bgc_aux_normal"
                                ><font-awesome-icon
                                    icon="arrow-down"
                                    size="1x"
                                />
                            </b-button>
                        </b-form-group>
                        <b-table
                            :items="filteredGroupUserList"
                            :fields="leden"
                            stacked="md"
                            show-empty
                            :empty-text="$t('com_geen_data', { ns: 'common' })"
                            small
                            striped
                        >
                            <template v-slot:cell(actions)="row">
                                <div class="form-inline">
                                    <b-button
                                        v-if="checkRight('UserAuthorize')"
                                        size="sm"
                                        @click="verwijderLid(row.item)"
                                        :disabled="isAdmin(row.item)"
                                        class="bgc_aux_alarm"
                                        ><font-awesome-icon
                                            icon="trash-alt"
                                            size="1x"
                                        />
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                    </b-card-text>
                </b-tab>
                <b-tab
                    :title="$t('com_permissies', { ns: 'common' })"
                    @click="closeAllItems()"
                >
                    <b-card-text>
                        <b-row>
                            <b-table
                                :items="groupedDisplayList"
                                :fields="rechten"
                                stacked="md"
                                show-empty
                                :empty-text="
                                    $t('com_geen_data', { ns: 'common' })
                                "
                                small
                                striped
                                @row-clicked="toonLijstDetail"
                            >
                                <template v-slot:cell(actions)="row">
                                    <div class="form-inline">
                                        <b-button
                                            size="sm"
                                            @click="toonLijstDetail(row.item)"
                                            class="bgc_aux_unknown"
                                            ><font-awesome-icon
                                                icon="info-circle"
                                                size="1x"
                                            />
                                        </b-button>
                                    </div>
                                </template>
                                <template v-slot:row-details>
                                    <b-form-checkbox-group
                                        v-model="detailPermissions"
                                        :options="detailPermissionList"
                                        class="mb-3"
                                        value-field="id"
                                        text-field="naam"
                                        stacked
                                        :disabled="adminGroup"
                                        @change="setChecked"
                                    ></b-form-checkbox-group>
                                </template>
                                <template v-slot:cell(aantal)="row">
                                    {{ 
                                        row.item.detailSelectedList.length + ' ' +
                                        $t('com_van', { ns: 'common' }).toLowerCase() + ' ' +
                                        row.item.detailPermissionList.length
                                    }}
                                </template>
                            </b-table>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-button
                                    @click="saveGroupPermissions()"
                                    class="bgc_aux_normal"
                                    ><font-awesome-icon
                                        icon="arrow-down"
                                        size="1x"
                                    />&nbsp;{{
                                        $t('com_opslaan', { ns: 'common' })
                                    }}
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-tab>
            </b-tabs>
            <!-- wie zit er in de groep -->
        </div>
    </b-card>
</template>

<script>
import Helper from '../../models/Helper';
import { PERMISSION_ENUM, Permission } from '../../models/Permissions';
import { loadItem, loadList, loadGetItem } from '../../models/DataHelper';

export default {
    name: 'CardGroepen',
    data() {
        return {
            showDismissibleAlert: false,
            alertText: '',
            detailPermissions: null,
            userInGroupList: [],
            operatorList: [],
            permissionList: [],
            detailPermissionList: [],
            groupList: null,
            groupName: null,
            selectedGroup: null,
            showGroupInfo: false,
            selectedPermissions: [],
            selectedUserID: -1,
            allPermissions: PERMISSION_ENUM,
            forAdmin: false,
            forceRefresh: true,
            groepen: [
                { key: 'actions', label: ' ' },
                {
                    key: 'text',
                    label: this.$t('com_groepsnaam', { ns: 'common' }),
                    sortable: true
                }
            ],
            leden: [
                { key: 'actions', label: ' ' },
                {
                    key: 'username',
                    label: this.$t('com_lid', { ns: 'common' }),
                    sortable: true
                }
            ],
            rechten: [
                //{ key: 'actions', label: ' ' },
                {
                    key: 'cat',
                    label: this.$t('com_module', { ns: 'common' }),
                    sortable: true
                },
                {
                    key: 'aantal',
                    label:
                        this.$t('com_number', { ns: 'common' }) +
                        ' ' +
                        this.$t('com_permissies', { ns: 'common' })
                }
            ]
        };
    },
    props: {},
    computed: {
        adminGroup() {
            return this.forAdmin;
        },
        selectedGroupName() {
            return this.selectedGroup ? this.selectedGroup.text : '';
        },
        filteredGroupUserList() {
            const filtered = this.userInGroupList.filter(
                (x) => x.groupname == this.selectedGroupName
            );
            return filtered;
        },
        groupedDisplayList() {
            if (this.permissionList == undefined) return null;
            const x = _(this.permissionList.slice(0))
                .groupBy((x) => x.onderdeel)
                .map((value, key) => {
                    const locLijst = [];
                    for (let i = 0; i < value.length; i++) {
                        const id = value[i].id;
                        const test = this.selectedPermissions.find(
                            (x) => x === id
                        );
                        if (test) locLijst.push(test);
                    }
                    return {
                        cat: this.$t(key, { ns: 'permissies' }),
                        detailPermissionList: value,
                        detailSelectedList: locLijst
                    };
                })
                .value();
            const result = _.sortBy(x, ['cat']);
            return result;
        },
        availableUserList() {
            const active = this.filteredGroupUserList;
            const activeID =
                active.length > 0
                    ? this.filteredGroupUserList.map((x) => x.userid)
                    : [];
            const result =
                active.length > 0
                    ? this.operatorList.filter((item) => {
                          return activeID.indexOf(item.id) == -1;
                      })
                    : this.operatorList;
            return result.map((x) => ({
                value: x.id,
                text: x.naam
            }));
        },
        optionsPermissions() {
            return this.forAdmin
                ? this.allPermissions.filter((x) => x.isAdminRight == true)
                : this.allPermissions.filter((x) => x.isAdminRight == false);
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        isAdminGroup(item) {
            return item.value == window.constants.ADMIN_GROUP_ID;
        },
        isAdmin(item) {
            return item.userid == window.constants.ADMIN_ID;
        },
        async toonDetail(item) {
            this.showGroupInfo = false;
            this.selectedGroup = item;
            this.forAdmin =
                this.selectedGroup.value == window.constants.ADMIN_GROUP_ID;
            await this.getGroupPermissions();
            this.showGroupInfo = true;
        },
        setChecked(checked) {
            for (let i = 0; i < this.detailPermissionList.length; i++) {
                const id = this.detailPermissionList[i].id;
                const test = checked.find((x) => x == id);
                if (test) {
                    const test2 = this.selectedPermissions.find((x) => x == id);
                    if (!test2) this.selectedPermissions.push(id);
                } else {
                    const index = this.selectedPermissions.indexOf(
                        (x) => x == id
                    );
                    if (index > -1) this.selectedPermissions.splice(i, 1);
                }
            }
        },
        closeAllItems() {
            this.groupedDisplayList.forEach((row) => {
                this.$set(row, '_showDetails', false);
            });
        },
        toonLijstDetail(row) {
            if (row._showDetails) {
                this.$set(row, '_showDetails', false);
            } else {
                this.closeAllItems();
                this.detailPermissionList = row.item
                    ? row.item.detailPermissionList
                    : row.detailPermissionList;
                // filter de geselecteerd permissies
                const magLijst = [];
                for (
                    let index = 0;
                    index < this.detailPermissionList.length;
                    index++
                ) {
                    const id = this.detailPermissionList[index].id;
                    const test = this.selectedPermissions.find((x) => x === id);
                    if (test) magLijst.push(test);
                }
                this.detailPermissions = magLijst;
                this.$set(row, '_showDetails', true);
            }
        },
        async verwijderItem(item) {
            const doClose = await this.$root.showConfirmationBox(
                this.$t('com_delete', {
                    ns: 'common',
                    val: this.$t('com_groep', { ns: 'common' })
                }) +
                    ' : ' +
                    item.text
            );
            if (doClose) {
                const selectionData = {
                    usergroupid: item.value
                };
                await loadItem(
                    'gebruiker/deleteUserGroup',
                    selectionData,
                    this.$t
                );
            }
            this.groupList = await loadList(
                'account/getGroups',
                'account/loadGroups',
                null,
                this.forceRefresh,
                this.$t
            );
        },
        async verwijderLid(item) {
            const doClose = await this.$root.showConfirmationBox(
                this.$t('com_delete', {
                    ns: 'common',
                    val: this.$t('com_lid', { ns: 'common' })
                }) + item.username
            );
            if (doClose) {
                const selectionData = {
                    userid: item.userid,
                    groupname: this.selectedGroupName
                };
                await loadItem(
                    'gebruiker/deleteUserFromGroup',
                    selectionData,
                    this.$t
                );
            }
            const loclist = await this.getGroupUserList();
        },
        async addUserToGroup() {
            if (this.selectedUserID > -1) {
                const selectionData = {
                    userid: this.selectedUserID,
                    groupname: this.selectedGroupName
                };
                await loadItem(
                    'gebruiker/addUserToGroup',
                    selectionData,
                    this.$t
                );
                const loclist = await this.getGroupUserList();
                this.selectedUserID = -1;
            }
        },
        async addNewGroup() {
            if (this.groupName == null) {
                this.showAlert(
                    this.$t('com_isrequired', {
                        ns: 'common',
                        prop: this.$t('com_groepsnaam', { ns: 'common' }),
                        val: ''
                    })
                );
                return;
            }
            const selectionData = { groupname: this.groupName };
            await loadItem('gebruiker/createUserGroup', selectionData, this.$t);
            await this.getGroups();
        },
        async getGroupPermissions() {
            if (this.selectedGroup != undefined) {
                const selectionData = { groupname: this.selectedGroup.text };
                console.log('getGroupPermissions', selectionData);
                this.selectedPermissions = [];
                const locList = await loadList(
                    'account/getGroupPermissions',
                    'account/loadGroupPermissions',
                    selectionData,
                    this.forceRefresh,
                    this.$t
                );
                if (locList) {
                    this.selectedPermissions = locList.map((x) => x.id);
                }
            } else {
                this.selectedPermissions = [];
            }
        },
        async getOperators() {
            this.operatorList = await loadList(
                'account/getOperators',
                'account/loadOperators',
                null,
                true,
                this.$t
            );
        },
        reload() {
            this.getGroups();
        },
        async getGroups() {
            const loclist = await loadList(
                'account/getGroups',
                'account/loadGroups',
                null,
                this.forceRefresh,
                this.$t
            );
            this.groupList = loclist;
        },
        async getGroupUserList() {
            this.userInGroupList = await loadList(
                'account/getUsersInGroups',
                'account/loadUserInGroups',
                null,
                this.forceRefresh,
                this.$t
            );
        },
        saveGroupPermissions() {
            const UserGroupPermissionsModel = {
                UserGroupID: this.selectedGroup.value,
                PermissionIDs: this.selectedPermissions
            };
            loadItem(
                'gebruiker/saveUserGroupPermissions',
                UserGroupPermissionsModel,
                this.$t
            );
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        }
    },
    mounted() {},
    async created() {
        await this.getOperators();
        await this.getGroups();
        await this.getGroupUserList();
        this.permissionList = await loadList(
            'account/getPackagePermissions',
            'account/loadPackagePermissions',
            null,
            this.forceRefresh,
            this.$t
        );
    }
};
</script>

