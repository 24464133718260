import { api } from '../api';
import { showGoodToast, showBadToast } from '../../errors/toast';
import { ExternalDevice, DeviceType, InProduct } from '../../../models/Extern';
import logMessage from '../../errors/foutlog';

const actions = {
    //int id
    async loadExternalDevice(context, data) {
        let item = null;
        try {
            const res = await api.fetchExternalConnection(data.data);
            item = new ExternalDevice(res.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data
                        .func('com_extregistratie', { ns: 'common' })
                        .toLowerCase()
                }),
                error
            );
        }
        return item;
    },
    async loadExternalDevices(context, data) {
        let tempList = [];
        try {
            await api.fetchExternalConnections(data.data).then((response) => {
                tempList = response.data.Items.map(
                    (x) => new ExternalDevice(x)
                );
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data
                        .func('com_extregistraties', { ns: 'common' })
                        .toLowerCase()
                }),
                error
            );
        }
        context.commit('insertDevicesLijst', tempList);
        return tempList;
    },
    async loadExternalDataSources(context, data) {
        let tempList = [];
        try {
            const res = await api.fetchExternalDataSources(data.data);
            tempList = res.data.map((x) => ({
                key: x.key,
                naam: x.name
            }));
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data
                        .func('com_extregistraties', { ns: 'common' })
                        .toLowerCase()
                }),
                error
            );
        }
        return tempList;
    },

    async loadDeviceTypes(context, data) {
        let tempList = [];
        try {
            tempList.push(new DeviceType({ id: 1 }, data.func));
            tempList.push(new DeviceType({ id: 2 }, data.func));
            tempList.push(new DeviceType({ id: 3 }, data.func));
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data
                        .func('prod_type', { ns: 'production' })
                        .toLowerCase()
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertDeviceTypesLijst', tempList);
    },
    //int id
    async deleteExternalDevice(context, data) {
        try {
            await api.deleteExternalDevice(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('com_extregistratie', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('com_extregistratie', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
    },
    //APIKeyModel akm
    async saveExternalDevice(context, data) {
        try {
            await api.storeExternalDevice(data.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('com_extregistratie', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('com_extregistratie', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
    },
    //// string deviceID
    async registerDevice(context, data) {
        try {
            await api.registerExternalDevice(data.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('com_extregistratie', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
    },
    async generateToken(context, data) {
        let strToken = '';
        try {
            const res = await api.generateToken(data.data);
            strToken = res.data;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_extregistratie', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return strToken;
    },
    async retryOrderImport(context, data) {
        let result = '';
        try {
            const res = await api.retryOrderImport(data.data);
            result = res.data;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_prodorder', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    //TODO NOG NIET GOED
    async loadExternalProducts(context, data) {
        let tempList = [];
        try {
            const res = await api.fetchExternalProducts(data.data);
            tempList = res.data.Items.map((x) => new InProduct(x));
        } catch (error) {
            tempList = []; // todo
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_extregistraties', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertLinkedLijst', tempList);
        return tempList;
    },    
    async loadExternalBatches(context, data) {
        let tempList = [];
        try {
            const res = await api.fetchExternalProducts(data.data);
            tempList = res.data.Items.map((x) => new InProduct(x));
        } catch (error) {
            tempList = []; // todo
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_extregistraties', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertLinkedLijst', tempList);
        return tempList;
    },
};

export default actions;

