import { api } from '../api';
import { showGoodToast, showBadToast } from '../../errors/toast';
import { OrderHeader, OrderInfo, OrderLine } from '../../../models/Orders';
import logMessage from '../../errors/foutlog';

const actions = {
    // DateTime from, DateTime until
    async loadSalesOrderInfo(context, data) {
        let tempList = [];
        try {
            const res = await api.fetchSalesOrderInfo(data.data);
            tempList = res.data.Items.map((x) => new OrderInfo(x));
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data
                        .func('com_salesorders', { ns: 'common' })
                        .toLowerCase()
                }),
                error
            );
        }
        return tempList;
    },
    async loadSalesOrder(context, data) {
        let item = null;
        try {
            const res = await api.fetchSalesOrder(data.data);
            item = new OrderHeader(res.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data
                        .func('com_salesorder', { ns: 'common' })
                        .toLowerCase()
                }),
                error
            );
        }
        return item;
    },
    async deleteSalesOrderLine(context, data) {
        try {
            await api.deleteSalesOrderLine(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('com_salesorder', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('com_salesorderline', { ns: 'common' })
                }),
                error
            );
        }
    },
    async saveSalesOrder(context, data) {
        try {
            await api.storeSalesOrder(data.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('com_salesorder', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('com_salesorder', { ns: 'common' }).toLowerCase()
                }),
                error
            );
        }
    },
};

export default actions;

