import { api } from '../api';
import { showBadToast, showGoodToast } from '../../errors/toast';
import logMessage from '../../errors/foutlog';
import { AttachmentInfo } from '../../../models/PlusProduction';
import {
    EntityObject,
    TemplateObject,
    TemplateView,
    QuantityView,
    QunitView,
    QLabelView,
    QualityView,
    InvestigationQuantity,
    MaesurableIQView,
    ExecutorQView,
    Maesurement,
    Assesment,
    NormView,
    NormObject,
    SampleObject,
    //ExecutorBaseView,
    Device,
    Organisation,
    Person,
    SampleType
} from '../../../models/Anayltics';
import { ActiviteitenLog } from '../../../models/ActiviteitenLog';
const actions = {
    //#region EntityObject
    async loadEntityObjectByTypename(context, data) {
        let tempList = [];
        try {
            await api
                .fetchEntityObjectByTypename(data.data)
                .then((response) => {
                    tempList = response.data.Items.map(
                        (x) => new EntityObject(x)
                    );
                });
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_eos', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertEntityObjectLijst', tempList);
        return tempList;
    },
    async loadUserDefinedTypeNames(context, data) {
        let tempList = [];
        try {
            await api.fetchUserDefinedTypeNames(data.data).then((response) => {
                tempList = response.data;
            });
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_typenamen', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertEOTypeNamesLijst', tempList);
        return tempList;
    },
    async loadEntityObjectForCheese(context, data) {
        let tempList = [];
        try {
            await api.fetchEntityObjectForCheese(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new EntityObject(x));
            });
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_eo', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return tempList;
    },
    async loadEntityObject(context, data) {
        let result = null;
        try {
            const returnObject = await api.fetchEntityObject(data.data);
            result = new EntityObject(returnObject.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_eo', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },    
    async loadEntityObjectByKeyType(context, data) {
        let result = null;
        try {
            const returnObject = await api.fetchEntityObjectKeyTypeName(data.data);
            result = new EntityObject(returnObject.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_eo', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    // async deleteEntityObject(context, data) {
    //     try {
    //         await api.deleteEntityObject(data);
    //         showGoodToast(context, 'Object verwijderd ');
    //     } catch (error) {
    //         showBadToast(context, 'Melding verwijderen object ', error);
    //         logMessage(error, 'error');
    //     }
    // },
    async addEntityObject(context, data) {
        let returnObject = null;
        try {
            returnObject = await api.addEntityObject(data.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('an_eo', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return new EntityObject(returnObject.data);
    },
    //#endregion EntityObject
    async loadTemplateLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchTemplates(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new TemplateView(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_templates', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertTemplateViewLijst', tempList);
    },
    async loadQuantityLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchQuantities(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new QuantityView(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_grootheden', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertQuantityLijst', tempList);
    },
    async loadQunitLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchQunits(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new QunitView(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_grootheden', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertQunitLijst', tempList);
        return tempList;
    },
    async loadQualityLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchQualities(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new QualityView(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_labels', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertQualityLijst', tempList);
    },
    async loadQlabelLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchQlabels(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new QLabelView(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_kenmerken', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertQlabelLijst', tempList);
        return tempList;
    },
    async loadMaesQuantityLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchMeasurableQuantities(data.data).then((response) => {
                tempList = response.data.Items.map(
                    (x) => new MaesurableIQView(x)
                );
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_meetpunten', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertMIQLijst', tempList);
        return tempList;
    },
    async loadInvestQuantityLijst(context, data) {
        let tempList = [];
        try {
            await api
                .fetchInvestigationQuantities(data.data)
                .then((response) => {
                    tempList = response.data.Items.map(
                        (x) => new MaesurableIQView(x)
                    );
                });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_meetpunten', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertIQLijst', tempList);
        return tempList;
    },

    async loadInvestigationQuantity(context, data) {
        let result = null;
        try {
            const returnObject = await api.fetchInvestigationQuantity(
                data.data
            );
            result = new InvestigationQuantity(returnObject.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_meetpunt', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    async deleteInvestigationQuantity(context, data) {
        try {
            await api.deleteInvestigationQuantity(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('an_meetpunt', { ns: 'analytics' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('an_meetpunt', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
    },
    async loadIQActiviteitenLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchIQActivities(data.data).then((response) => {
                tempList = response.data.map((x) => new ActiviteitenLog(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_activities', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return tempList;
    },
    async saveInvestigationQuantity(context, data) {
        let result = null;
        try {
            const returnObject = await api.addInvestigationQuantity(data.data);
            result = new InvestigationQuantity(returnObject.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('an_meetpunt', { ns: 'analytics' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('an_meetpunt', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    async loadExecutorLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchExecutors(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new ExecutorQView(x));
            });
            tempList.map((x) => (x.isQuantity = true));
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_uitvoerders', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertExecutorLijst', tempList);
    },
    // async loadExecutorQuantityLijst(context, data) {
    //     let tempList = [];
    //     try {
    //         await api.fetchExecutorQuantities(data).then((response) => {
    //             tempList = response.data.Items.map((x) => new ExecutorQView(x));
    //         });
    //         tempList.map((x) => (x.isQuantity = true));
    //     } catch (error) {
    //         tempList = [];
    //         showBadToast(context, 'Melding laden meetapparaten', error);
    //         logMessage(error, 'error');
    //     }
    //     context.commit('insertExecutorQuantityLijst', tempList);
    // },
    // async loadExecutorQualitiesLijst(context, data) {
    //     let tempList = [];
    //     try {
    //         await api.fetchExecutorQualities(data).then((response) => {
    //             tempList = response.data.Items.map((x) => new ExecutorQView(x));
    //         });
    //         tempList.map((x) => (x.isQuality = true));
    //     } catch (error) {
    //         tempList = [];
    //         showBadToast(context, 'Melding laden meetapparaten', error);
    //         logMessage(error, 'error');
    //     }
    //     context.commit('insertExecutorQualityLijst', tempList);
    // },
    async loadDevice(context, data) {
        let result = null;
        try {
            const returnObject = await api.fetchDevice(data.data);
            result = new Device(returnObject.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_apparaat', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    async saveDevice(context, data) {
        let result = null;
        try {
            const returnObject = await api.saveDevice(data.data);
            result = new Device(returnObject.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('prod_apparaat', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('prod_apparaat', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    async loadOrganisation(context, data) {
        let result = null;
        try {
            const returnObject = await api.fetchOrganisation(data.data);
            result = new Organisation(returnObject.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_organisatie', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    async saveOrganisation(context, data) {
        let result = null;
        try {
            const returnObject = await api.saveOrganisation(data.data);
            result = new Organisation(returnObject.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('an_organisatie', { ns: 'analytics' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('an_organisatie', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    async loadPerson(context, data) {
        let result = null;
        try {
            const returnObject = await api.fetchPerson(data.data);
            result = new Person(returnObject.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_persoon', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    async savePerson(context, data) {
        let result = null;
        try {
            const returnObject = await api.savePerson(data.data);
            result = new Person(returnObject.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('an_persoon', { ns: 'analytics' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('an_persoon', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    async deleteExecutor(context, data) {
        try {
            await api.deleteExecutor(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('an_uitvoerder', { ns: 'analytics' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('an_uitvoerder', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
    },
    async loadTemplateObject(context, data) {
        let result = null;
        try {
            const returnObject = await api.fetchTemplate(data.data);
            result = new TemplateObject(returnObject.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_template', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    async saveTemplateObject(context, data) {
        let result = null;
        try {
            const returnObject = await api.saveTemplate(data.data);
            //result = new TemplateObject(returnObject.data);
            result = returnObject.data;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('an_template', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    //#region Maesurement
    async loadMaesurementLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchMeasurements(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new Maesurement(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_metingen', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertMaesurementLijst', tempList);
    },
    async loadMaesurement(context, data) {
        let result = null;
        try {
            const returnObject = await api.fetchMeasurement(data.data);
            result = new Maesurement(returnObject.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_meting', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    async loadLastMaesurement(context, data) {
        let result = null;
        try {
            const returnObject = await api.fetchLastMeasurement(data.data);
            result = new Maesurement(returnObject.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_meting', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    async deleteMaesurement(context, data) {
        try {
            await api.deleteMeasurement(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('an_meting', { ns: 'analytics' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('an_meting', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
    },
    async saveMaesurement(context, data) {
        let result = null;
        try {
            const returnObject = await api.saveMaesurement(data.data);
            result = new Maesurement(returnObject.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('an_meting', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    async loadMaesurementActiviteitenLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchMeasurementActivities(data.data).then((response) => {
                tempList = response.data.map((x) => new ActiviteitenLog(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_activities', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return tempList;
    },
    async loadNorm(context, data) {
        let result = null;
        try {
            const returnObject = await api.fetchNorm(data.data);
            result = new NormObject(returnObject.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_norm', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    async loadNormById(context, data) {
        let result = null;
        try {
            const returnObject = await api.fetchNormById(data.data);
            result = new NormObject(returnObject.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_norm', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    //#endregion Maesurement

    async loadNormenLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchNorms(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new NormView(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_normen', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertNormenLijst', tempList);
    },
    async saveNorm(context, data) {
        let result = null;
        try {
            const returnObject = await api.saveNorm(data.data);
            result = new NormObject(returnObject.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('an_norm', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    async deleteNorm(context, data) {
        try {
            await api.deleteNorm(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('an_norm', { ns: 'analytics' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('an_norm', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
    },
    async loadNormActiviteitenLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchNormActivities(data.data).then((response) => {
                tempList = response.data.map((x) => new ActiviteitenLog(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_activities', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return tempList;
    },

    //#region Assesment
    async loadAssesmentLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchAssesments(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new Assesment(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_tests', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertAssesmentLijst', tempList);
    },
    async loadAssesment(context, data) {
        let result = null;
        try {
            const returnObject = await api.fetchAssesment(data.data);
            result = new Assesment(returnObject.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_test', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    async loadAssesmentActiviteitenLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchAssesmentActivities(data.data).then((response) => {
                tempList = response.data.map((x) => new ActiviteitenLog(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_activitylog', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return tempList;
    },
    async deleteAssesment(context, data) {
        try {
            await api.deleteAssesment(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('an_test', { ns: 'analytics' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('an_test', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
    },
    async saveAssesment(context, data) {
        let result = null;
        try {
            const returnObject = await api.saveAssesment(data.data);
            result = new Assesment(returnObject.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('an_test', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    //#endregion Assesment

    //#region Samples
    async loadSampleLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchSamples(data.data).then((response) => {
                tempList = response.data.Items.map((x) => new SampleObject(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_samples', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertSampleLijst', tempList);
    },
    async loadSampleTypeLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchSampleTypes(data.data).then((response) => {
                tempList = response.data.map((x) => new SampleType(x, data.func));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_sampletypes', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        context.commit('insertSampleTypeLijst', tempList);
        return tempList;
    },
    async loadSample(context, data) {
        let result = null;
        try {
            const returnObject = await api.fetchSample(data.data);
            result = new SampleObject(returnObject.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('an_sample', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    async deleteSample(context, data) {
        try {
            await api.deleteSample(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('an_sample', { ns: 'analytics' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('an_sample', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
    },
    async saveSample(context, data) {
        let result = null;
        try {
            const returnObject = await api.saveSample(data.data);
            result = new SampleObject(returnObject.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('an_sample', { ns: 'analytics' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return result;
    },
    async loadSampleActiviteitenLijst(context, data) {
        let tempList = [];
        try {
            await api.fetchSampleActivities(data.data).then((response) => {
                tempList = response.data.map((x) => new ActiviteitenLog(x));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_activities', { ns: 'production' })
                }),
                error
            );
            logMessage(error, 'error');
        }
        return tempList;
    },
    //#endregion Samples

    //#region Attachment
    async saveAttachment(context, data) {
        let item = null;
        try {
            const response = await api.storeAttachment(data.data);
            item = new AttachmentInfo(response.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('com_bijlage', { ns: 'common' })
                })
            );
        } catch (error) {
            const mes = await logMessage(error, 'error');
            showBadToast(
                context,
                data.func('com_errorupload', {
                    ns: 'common',
                    val: data.func('com_bijlage', { ns: 'common' })
                }),
                error
            );
        }
        return item;
    },
    // int id, int attachmentType
    async loadAttachmentInfo(context, data) {
        let tempList = [];
        try {
            await api.fetchAttachmentInfo(data.data).then((response) => {
                tempList = response.data.Items.map(
                    (x) => new AttachmentInfo(x)
                );
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_bijlagen', { ns: 'common' })
                }),
                error
            );
        }
        return tempList;
    },

    // int attachmentID, int id, int attachmentType
    async loadAttachment(context, data) {
        let item = null;
        try {
            const response = await api.fetchAttachment(data.data);
            item = response.data;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_bijlage', { ns: 'common' })
                }),
                error
            );
        }
        return item;
    },
    async deleteAttachment(context, data) {
        try {
            await api.deleteAttachment(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('com_bijlage', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('com_bijlage', { ns: 'common' })
                }),
                error
            );
            logMessage(error, 'error');
        }
    }
    //#endregion Attachment
};

export default actions;

