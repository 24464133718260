import Api from '../../../api';
import store from '../../../store';


// DateTime from, DateTime until
const fetchProductionOrderInfo = async data => {
    return fetchInfo('/ProductionOrder/GetProductionOrderInfo', data);
};
// int orderID
const fetchProductionOrder = async data => {
    return fetchInfo('/ProductionOrder/GetProductionOrder', data);
};
// int orderLineID
const fetchProductionOrderLine = async data => {
    return fetchInfo('/ProductionOrder/GetProductionOrderLine', data);
};
// int orderLineID
const fetchAssignedCheeseAmount = async data => {
    return fetchInfo('/ProductionOrder/GetAssignedCheeseAmount', data);
};


const fetchInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};
export default {
    fetchProductionOrderInfo,
    fetchProductionOrder,
    fetchProductionOrderLine,
    fetchAssignedCheeseAmount
};
