import DateHelper from './DateHelper';
import Helper from './Helper';
export {
    DeliveryNote,
    DeliveryItemStock,
    DeliveryNoteView,
    PrintedNote,
    PrintedNoteLine,
    PrintOption,
    MergeType
};

class DeliveryItemStock {
    constructor(data) {
        this.id = -1;
        data = data ?? {};
        this.id = data.stockItemID ?? -1;
        this.isNew = this.id < 0;
        this.isDeleted = false;
        this.isChanged = false;
        this.aantal = data.amount;
        this.gewicht = data.weight;
        this.referentie = data.batchNumber; // bak / productie/ kaasnaam

        this.productID = data.productID;
        this.productnaam = data.product;
        this.productiedatum = data.productionDate;
        this.leeftijdDagen = data.days;
        this.leeftijdKlasse = data.ageClass;
        this.stockItemID = -1;
        this.merken = data.cheeseMarks;
        this.eenheid = data.unitSymbol;
        this.eenheidID = data.perUnitID;
        this.vorm = data.shape;
        this.vormID = data.shapeID;
    }
    get regeltekst() {
        return this.productnaam;
    }
    CheckValidity(func) {
        let warnText = '';
        if (this.aantal) {
            if (this.aantal == undefined || this.aantal < 0)
                warnText = Helper.concatString(
                    warnText,
                    func('com_isrequired', {
                        ns: 'common',
                        val: '',
                        prop: func('com_amount', { ns: 'common' })
                    }) +
                        ' / ' +
                        func('prod_meerdannul', {
                            ns: 'production',
                            val: func('com_amount', { ns: 'common' })
                        })
                );
        }
        return warnText;
    }

    SetForSave() {
        return {
            Amount: this.aantal,
            Weight: this.gewicht,
            StockItemID: this.stockItemID > -1 ? this.stockItemID : this.id
        };
    }
}

class DeliveryNote {
    /**
     * @param data
     */
    constructor(data) {
        data = data ?? {};
        this.id = data.id ?? -1;
        this.isNew = this.id < 0;
        this.isDeleted = false;
        this.isChanged = false;
        this.gewijzigd = data.updated;
        this.opmerking = data.remark;
        this.referentie = data.reference;
        this.klanttekst = data.customerText;
        this.klantnaam = data.customer;
        this.klant = data.customerID;
        this.locatie = data.stocklocationID ?? data.stockLocationID;
        this.locatietekst = data.stockLocation;
        this.boektijdstip = data.bookDate ?? data.bookdate;
        this.eigenadres = '';
        this.pakbonID = this.id;

        this.regels = data.items
            ? data.items.map((x) => new DeliveryItemStock(x))
            : []; // todo errormelding hier typecheck
    }
    get klantadres() {
        return this.klanttekst ? this.klanttekst : this.klantnaam;
    }
    get onzeRef() {
        const tekst = Helper.stringformat('{0}-{1}', this.id, 'preview');
        return tekst;
    }

    toString() {
        const tekst = Helper.stringformat('{0}: {1}', this.id, this.referentie);
        return tekst;
    }

    SetForSave() {
        const Items = [];
        for (let i = 0; i < this.regels.length; i++) {
            const obj = this.regels[i].SetForSave();
            Items.push(obj);
        }
        const DeliveryNoteModel = {
            Updated: this.gewijzigd,
            ID: this.id,
            Reference: this.referentie,
            Remark: this.opmerking,
            CustomerText: this.klanttekst,
            CustomerID: this.klant,
            StockLocationID: this.locatie,
            BookDate: DateHelper.localDateToUTC(this.boektijdstip),
            Items: Items
        };
        return DeliveryNoteModel;
    }

    CheckValidity(func) {
        let warnText = '';
        const lijstDistinct = Helper.distinctList(this.regels, 'stockItemID');
        if (lijstDistinct.length != this.regels.length) {
            warnText = Helper.concatString(
                warnText,
                func('prod_warnuniqueonshipbill', {
                    ns: 'production'
                })
            );
        }

        if (this.regels.length > 0) {
            if (this.locatie == undefined)
                warnText = Helper.concatString(
                    warnText,
                    func('com_isrequired', {
                        ns: 'common',
                        val: '',
                        prop: func('com_location', { ns: 'common' })
                    })
                );
        }
        return warnText;
    }
}

class DeliveryNoteView {
    /**
     * @param data
     */
    constructor(data) {
        data = data ?? {};
        this.id = data.id ?? -1;
        this.partij = data.batchnumber;
        this.opmerking = data.remark;
        this.referentie = data.reference;
        this.klant = data.company ?? data.customer;
        this.locatie = data.stocklocation;
        this.boektijdstip = data.bookdate;
        this.versie = data.version ?? 0;

        this.product = data.product;
        this.aantal = data.amount ?? data.lineamount;
        this.eenheid = data.unit;
        this.vorm = data.shape;
        this.partij = data.batchnumber;
        this.attcount = data.attachments;
        this.verloopdatum = data.docexpirationdate;
        this.doctot = data.docuntil;
        this.docstatus = 0;
        this.dn_id = data.dn_id ?? data.id;
        this.regelinfo = data.lineinfo;
        this.printtijdstip = data.printdate;
        this.pakbonID = this.dn_id;
        this.regelinfocleared = data.lineinfo
            ? this.replaceTab(data.lineinfo)
            : '';
    }

    geefAttInfo(func) {
        const warningdays = window.constants.ALARM_DOCDATE;
        if (this.attcount == undefined || this.attcount < 1) return '-';
        const datum = this.verloopdatum
            ? this.doctot
                ? this.doctot > this.verloopdatum
                    ? this.verloopdatum
                    : this.doctot
                : this.verloopdatum
            : this.doctot;
        if (datum != null) {
            const comparedate = new Date();
            const diff = DateHelper.dateDiffinDays(datum, comparedate);
            this.docstatus = diff < 0 ? 2 : diff < warningdays ? 1 : 0;
            const str = DateHelper.convertedToDate(datum, false);
            return Helper.stringformat('{0}: {1}', this.attcount, str);
        }
        return this.attcount;
    }

    replaceTab() {
        //const regex = new RegExp(window.constants.STRING_SEPERATOR, "g");//
        const regex = /\^\^/g;
        const regel = this.regelinfo;
        return regel.replace(regex, ' || ');
    }
}

class PrintedNote {
    /**
     * @param data
     */
    constructor(data) {
        data = data ?? {};
        this.id = data.id ?? -1;
        this.opmerking = data.remark;
        this.referentie = data.reference;
        this.versie = data.version;
        this.klantadres = data.addressed;
        this.boektijdstip = data.printdate ?? data.printDate;
        this.pakbonID = data.deliveryNoteID ?? data.dn_id;
        this.eigenadres = '';

        // this.product = data.product;
        // this.aantal = data.amount;
        // this.eenheid = data.unit;
        // this.vorm = data.shape;
        // this.partij = data.batchnumber;

        this.regels = data.items
            ? data.items.map((x) => new PrintedNoteLine(x))
            : [];
    }

    get onzeRef() {
        const tekst = Helper.stringformat(
            '{0}-{1}',
            this.pakbonID,
            this.versie ?? 1
        );
        return tekst;
    }

    replaceTab() {
        //const regex = new RegExp(window.constants.STRING_SEPERATOR, "g");//
        const regex = /\^\^/g;
        for (let i = 0; i < this.regels.length; i++) {
            const regel = this.regels[i];
            regel.regeltekst = regel.regeltekst.replace(regex, '&emsp;&emsp;');
        }
    }
    replaceLineFeed() {
        const regex = /\r\n/i;
        for (let i = 0; i < this.regels.length; i++) {
            const regel = this.regels[i];
            regel.regeltekst = regel.regeltekst.replace(
                regex,
                '<br /> &nbsp;&nbsp;&nbsp;'
            );
        }
    }
    translateString(func) {
        for (let i = 0; i < this.regels.length; i++) {
            const regel = this.regels[i];
            regel.regeltekst = Helper.searchReplaceInString(
                regel.regeltekst,
                'Vorm:',
                'rep_delvorm',
                func
            );
            regel.regeltekst = Helper.searchReplaceInString(
                regel.regeltekst,
                'Partij:',
                'rep_delpartij',
                func
            );
            regel.regeltekst = Helper.searchReplaceInString(
                regel.regeltekst,
                'Productie:',
                'rep_delproductie',
                func
            );
            regel.regeltekst = Helper.searchReplaceInString(
                regel.regeltekst,
                'THT:',
                'rep_deltht',
                func
            );
            regel.regeltekst = Helper.searchReplaceInString(
                regel.regeltekst,
                'Merken:',
                'rep_delMerken',
                func
            );
        }
    }
}

class PrintedNoteLine {
    constructor(data) {
        this.id = -1;
        data = data ?? {};
        this.id = data.stockItemID ?? -1;
        this.isNew = this.id < 0;
        this.isDeleted = false;
        this.isChanged = false;
        this.aantal = data.amount;
        this.gewicht = data.weight;
        this.regeltekst = data.lineinfo;
        this.itemarr = data.lineinfo
            ? data.lineinfo.split(window.constants.STRING_SEPERATOR)
            : [];
        this.clearedarr = data.lineinfo ? this.clearTitles() : [];
    }

    CheckValidity(func) {
        const warnText = '';
        return warnText;
    }
    clearTitles() {
        const a = this.itemarr;
        const result = [];
        for (let i = 0; i < a.length; i++) {
            const obj = a[i];
            const arr = obj.split(':');
            const cleared = arr.length > 1 ? arr[1] : arr[0];
            result.push(cleared);
        }
        return result;
    }
}
class PrintOption {
    constructor(data) {
        this.id = -1;
        this.type = 'PrintOption';
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        this.id = data.id ?? -1;
        this.naam = data.description;
        this.trans = data.trans;
        this.isNew = this.id == undefined || this.id < 0;
    }
    get text() {
        return this.naam;
    }
    get value() {
        return this.id;
    }
}
class MergeType {
    static NoMerge = new MergeType({
        id: 0,
        description: 'a',
        trans: 'log_geensamenvoeging'
    });
    static MergeProduct = new MergeType({
        id: 1,
        description: 'b',
        trans: 'log_samenvoegingprodvorm'
    });
    static MergeProductBatchNumber = new MergeType({
        id: 2,
        description: 'c',
        trans: 'log_samenvoegingpartijvorm'
    });

    constructor(data) {
        this.id = -1;
        this.type = 'MergeType';
        this.isDeleted = false;
        this.isChanged = false;
        data = data ?? {};
        this.id = data.id ?? -1;
        this.naam = data.description;
        this.trans = data.trans;
        this.isNew = this.id == undefined || this.id < 0;
    }
    get text() {
        return this.naam;
    }
    get value() {
        return this.id;
    }
    list(func) {
        const lijst = [
            MergeType.NoMerge,
            MergeType.MergeProduct,
            MergeType.MergeProductBatchNumber
        ];
        const a = lijst.map((x) => {
            const naam = func(x.trans, { ns: 'logistics' });
            const source = { naam: naam };
            const returnedTarget = Object.assign(x, source);
            return returnedTarget;
        });
        return a;
    }
}

