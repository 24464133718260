import { api } from '../api';
import { showGoodToast, showBadToast } from '../../errors/toast';
import {
    Vorm,
    Ost,
    Location,
    Company,
    Equipment,
    Bericht
} from '../../../models/MasterData';
import { ReportGroup } from '../../../models/Anayltics';

const actions = {
    //#region ost
    async loadOsts(context, data) {
        let tempList = [];
        try {
            await api.fetchOsts(data.data).then((response) => {
                tempList = response.data.Items.map((x) => ({
                    value: x.id,
                    text: x.description,
                    id: x.id,
                    naam: x.description,
                    nummer: x.number,
                    maxvulling: x.maxfill,
                    lijn: x.pli_id,
                    object: new Ost(x)
                }));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data
                        .func('prod_ost', { ns: 'production' })
                        .toLowerCase()
                }),
                error
            );
        }
        context.commit('insertOstLijst', tempList);
        return tempList;
    },
    async loadOst(context, data) {
        let item = null;
        try {
            const response = await api.fetchOst(data.data);
            item = new Ost(response.data);
        } catch (error) {
            item = null;
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data
                        .func('prod_ost', { ns: 'production' })
                        .toLowerCase()
                }),
                error
            );
        }
        return item;
    },
    async addOst(context, data) {
        try {
            await api.addOst(data.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('prod_ost', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data
                        .func('prod_ost', { ns: 'production' })
                        .toLowerCase()
                }),
                error
            );
        }
    },
    async deleteOst(context, data) {
        try {
            await api.deleteOst(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('prod_ost', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('prod_ost', { ns: 'production' })
                }),
                error
            );
        }
    },
    //#endregion ost

    //#region vorm
    async loadShapes(context, data) {
        let tempList = [];
        try {
            await api.fetchShapes(data.data).then((response) => {
                tempList = response.data.Items.map((x) => ({
                    value: x.id,
                    text: x.description,
                    id: x.id,
                    object: new Vorm(x)
                }));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_kaasvormen', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertShapesLijst', tempList);
    },
    async loadShape(context, data) {
        let item = null;
        try {
            const response = await api.fetchShape(data.data);
            item = new Vorm(response.data);
        } catch (error) {
            item = null;
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_kaasvorm', { ns: 'common' })
                }),
                error
            );
        }
        return item;
    },
    async addShape(context, data) {
        try {
            await api.addShape(data.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('com_kaasvorm', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data
                        .func('com_kaasvorm', { ns: 'common' })
                        .toLowerCase()
                }),
                error
            );
        }
    },
    async deleteShape(context, data) {
        try {
            await api.deleteShape(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('com_kaasvorm', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('com_kaasvorm', { ns: 'common' })
                }),
                error
            );
        }
    },
    //#endregion vorm

    //#region locations
    async loadLocations(context, data) {
        let tempList = [];
        const filterSilo = data.filter == 'silo';
        const siloTypes = data.types;
        try {
            await api.fetchLocations(data.data).then((response) => {
                tempList = response.data.Items.map((x) => ({
                    value: x.id,
                    text: x.description,
                    id: x.id,
                    type: x.type,
                    inhoud: x.volume,
                    object: new Location(x, siloTypes)
                }));
                if (data.filter) {
                    if (filterSilo) {
                        tempList = tempList.filter((x) => x.type != undefined);
                    } else {
                        tempList = tempList.filter((x) => x.type == undefined);
                    }
                }
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data
                        .func('com_locations', { ns: 'common' })
                        .toLowerCase()
                }),
                error
            );
        }
        context.commit('insertLocationLijst', tempList);
    },
    async loadLocation(context, data) {
        let item = null;
        try {
            const response = await api.fetchLocation(data.data);
            item = new Location(response.data);
        } catch (error) {
            item = null;
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_location', { ns: 'common' })
                }),
                error
            );
        }
        return item;
    },
    async addLocation(context, data) {
        try {
            await api.addLocation(data.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('com_location', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data.func('com_location', { ns: 'common' })
                }),
                error
            );
        }
    },
    async deleteLocation(context, data) {
        try {
            await api.deleteLocation(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('com_location', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('com_location', { ns: 'common' })
                }),
                error
            );
        }
    },
    async loadStockLocationTypes(context, data) {
        let tempList = [];
        try {
            const response = await api.fetchStockLocationTypes(data.data);
            tempList = response.data.map((x) => ({
                value: x.id,
                text: x.description
            }));
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_locationtype', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertStockLocationTypes', tempList);
        return tempList;
    },
    //#endregion locations

    //#region company
    async loadCompanies(context, data) {
        let tempList = [];
        try {
            await api.fetchCompanies(data.data).then((response) => {
                tempList = response.data.Items.map((x) => {
                    const comp = new Company(x);
                    comp.attachmentinfo = comp.geefAttInfo(data.func);
                    return {
                        value: x.id,
                        text: x.description,
                        id: x.id,
                        object: comp
                    };
                });
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_companies', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertCompaniesLijst', tempList);
    },
    async loadCompany(context, data) {
        let item = null;
        try {
            const response = await api.fetchCompany(data.data);
            item = new Company(response.data);
        } catch (error) {
            item = null;
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_company', { ns: 'common' })
                }),
                error
            );
        }
        return item;
    },
    async addCompany(context, data) {
        try {
            await api.addCompany(data.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('com_company', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data
                        .func('com_company', { ns: 'common' })
                        .toLowerCase()
                }),
                error
            );
        }
    },
    async deleteCompany(context, data) {
        try {
            await api.deleteCompany(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('com_company', { ns: 'common' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('com_company', { ns: 'common' })
                }),
                error
            );
        }
    },
    //#endregion company

    //#region Equipment
    async loadEquipments(context, data) {
        let tempList = [];
        try {
            await api.fetchEquipments(data.data).then((response) => {
                tempList = response.data.Items.map((x) => ({
                    value: x.id,
                    text: x.description,
                    id: x.id,
                    object: new Equipment(x)
                }));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data
                        .func('prod_apparaten', { ns: 'common' })
                        .toLowerCase()
                }),
                error
            );
        }
        context.commit('insertEquipmentsLijst', tempList);
    },
    async loadEquipment(context, data) {
        let item = null;
        try {
            const response = await api.fetchEquipment(data.data);
            item = new Equipment(response.data);
        } catch (error) {
            item = null;
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data
                        .func('prod_apparaten', { ns: 'production' })
                        .toLowerCase()
                }),
                error
            );
        }
        return item;
    },
    async addEquipment(context, data) {
        try {
            await api.addEquipment(data.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data.func('prod_apparaat', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data
                        .func('prod_apparaat', { ns: 'production' })
                        .toLowerCase()
                }),
                error
            );
        }
    },
    async deleteEquipment(context, data) {
        try {
            await api.deleteEquipment(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data.func('prod_apparaat', { ns: 'production' })
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data.func('prod_apparaat', { ns: 'production' })
                }),
                error
            );
        }
    },
    //#endregion Equipment

    //#region ReportGroup
    async loadReportGroups(context, data) {
        let tempList = [];
        try {
            await api.fetchReportGroups(data.data).then((response) => {
                tempList = response.data.Items.map((x) => ({
                    value: x.id,
                    text: x.description,
                    id: x.id,
                    object: new ReportGroup(x)
                }));
            });
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data
                        .func('rep_reportgroups', { ns: 'report' })
                        .toLowerCase()
                }),
                error
            );
        }
        context.commit('insertReportGroupLijst', tempList);
    },
    async loadReportGroup(context, data) {
        let item = null;
        try {
            const response = await api.fetchReportGroup(data.data);
            item = new ReportGroup(response.data);
        } catch (error) {
            item = null;
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data
                        .func('rep_reportgroup', { ns: 'report' })
                        .toLowerCase()
                }),
                error
            );
        }
        return item;
    },
    async addReportGroup(context, data) {
        try {
            await api.addReportGroup(data.data);
            showGoodToast(
                context,
                data.func('com_opgeslagen', {
                    ns: 'common',
                    val: data
                        .func('rep_reportgroup', { ns: 'report' })
                        .toLowerCase()
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data
                        .func('rep_reportgroup', { ns: 'report' })
                        .toLowerCase()
                }),
                error
            );
        }
    },
    async deleteReportGroup(context, data) {
        try {
            await api.deleteReportGroup(data.data);
            showGoodToast(
                context,
                data.func('com_verwijderd', {
                    ns: 'common',
                    val: data
                        .func('rep_reportgroup', { ns: 'report' })
                        .toLowerCase()
                })
            );
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorverwijder', {
                    ns: 'common',
                    val: data
                        .func('rep_reportgroup', { ns: 'report' })
                        .toLowerCase()
                }),
                error
            );
        }
    },
    //#endregion ReportGroup

    async loadVersionInfo(context, data) {
        let item = null;
        try {
            await api.fetchVersionInfo(data.data).then((response) => {
                const text = response.data;
                const myStringArray = text.split('-');
                if (myStringArray.length == 2) {
                    item = {
                        tag: myStringArray[0].trim(),
                        database: myStringArray[1].trim()
                    };
                } else {
                    item = {
                        git: myStringArray[0].trim(),
                        tag: myStringArray[1].trim(),
                        database: myStringArray[2].trim()
                    };
                }
            });
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data
                        .func('prod_version', { ns: 'production' })
                        .toLowerCase()
                }),
                error
            );
        }
        context.commit('insertVersionInfo', item);
    },

    async loadGebruikInfo(context, data) {
        let item = null;
        try {
            await api.fetchGebruikInfo(data.data).then((response) => {
                const lijst = response.data.Items;
                const files = lijst[0].aantal_bestanden;
                const mb = lijst[0].totaal_MB;
                item = {
                    files: files,
                    data: mb
                };
            });
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data
                        .func('prod_version', { ns: 'production' })
                        .toLowerCase()
                }),
                error
            );
        }
        return item;
    },
    async loadDeployedPackageInfo(context, data) {
        let item = null;
        try {
            const response = await api.fetchDeployedPackageInfo(data.data);
            item = response.data;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data
                        .func('com_package', { ns: 'common' })
                        .toLowerCase()
                }),
                error
            );
        }
        context.commit('insertPackageVersion', item);
        return item;
    },

    async loadApplicationConstants(context, data) {
        let tempList = [];
        try {
            const res = await api.fetchApplicationConstants(data.data);
            tempList = res.data.map((x) => ({
                value: x.value,
                text: x.key,
                type: x.type
            }));
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_constants', { ns: 'production' })
                }),
                error
            );
        }
        context.commit('insertConstantsLijst', tempList);
        return tempList;
    },
    async loadMessages(context, data) {
        let tempList = [];
        try {
            const res = await api.fetchMessages(data.data);
            tempList = res.data.Items.map((x) => new Bericht(x));
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('com_melding', { ns: 'common' })
                }),
                error
            );
        }
        context.commit('insertMessagesLijst', tempList);
        return tempList;
    },
    async storeApplicationConstant(context, data) {
        let result = 0;
        try {
            result = await api.storeApplicationConstant(data.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_erroropslaan', {
                    ns: 'common',
                    val: data
                        .func('com_feature', { ns: 'common' })
                        .toLowerCase()
                }),
                error
            );
        }
        return result;
    }
};

export default actions;

