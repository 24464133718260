<template>
    <b-card :header="kaasmerk_productnaam" header-tag="header">
        <template #header>
            <b-col sm="6" class="mx-0 px-0 my-1 py-0">
                {{ kaasmerk_productnaam }}
            </b-col>
            <b-col sm="6" class="mx-0 px-0 my-1 py-0 float-right">
                <b-button
                    v-if="editAllowed"
                    @click="copyItem"
                    :disabled="setDisabledShape"
                    class="bgc_aux_normal"
                    ><font-awesome-icon icon="copy" size="1x" />
                </b-button>
                <b-button
                    v-if="editAllowed"
                    @click="verwijderItem"
                    :disabled="setDisabledShape"
                    class="bgc_aux_alarm"
                    ><font-awesome-icon icon="trash-alt" size="1x" />
                </b-button>
            </b-col>
        </template>

        <div>
            <b-row>
                <div class="form-inline">
                    <b-col sm="6" class="mx-0 px-0 my-0 py-0">
                        <b-input-group
                            :append="$t('com_st', { ns: 'common' })"
                            :label="$t('com_stuks', { ns: 'common' })"
                        >
                            <b-form-input
                                :placeholder="aantMerk"
                                type="number"
                                step="1"
                                min="0"
                                max="3000"
                                v-model.number="localSelected.kazen"
                                @change="set_eindmerk"
                                :disabled="!editAllowed"
                            ></b-form-input>
                        </b-input-group>
                    </b-col>

                    <b-col sm="6" class="mx-0 px-0 my-0 py-0">
                        <lookup-combo
                            v-model="localSelected.vormID"
                            v-bind:combotype="lctypeShapes"
                            :disabled="!editAllowed || setDisabledShape"
                            @change="set_input"
                        ></lookup-combo>
                    </b-col>
                </div>
            </b-row>
            <b-row>
                <div class="form-inline">
                    <b-col sm="12" class="mx-0 px-0 my-0 py-0">
                        <b-input-group
                            :prepend="$t('com_start', { ns: 'common' })"
                            :label="$t('com_mark', { ns: 'common' })"
                        >
                            <b-form-select
                                v-if="editKaasmerk"
                                v-model="localSelected.beginStockItemID"
                                :options="flexList"
                                :disabled="!editAllowed"
                                @change="set_inputStartLetter"
                            >
                                <template #first>
                                    <b-form-select-option value=""
                                        >--
                                        {{
                                            $t('com_selecteer', {
                                                ns: 'common'
                                            })
                                        }}
                                        --</b-form-select-option
                                    >
                                </template>
                            </b-form-select>
                            <b-form-input
                                v-if="!editKaasmerk"
                                placeholder="-"
                                v-model="localSelected.startLetter"
                                :disabled="!editAllowed"
                            ></b-form-input>
                            <b-form-input
                                :placeholder="$t('com_start', { ns: 'common' })"
                                type="number"
                                step="1"
                                v-model.number="localSelected.startNummer"
                                @change="set_input"
                                :disabled="!editAllowed"
                            ></b-form-input>
                        </b-input-group>
                    </b-col>
                </div>
            </b-row>
            <b-row>
                <div class="form-inline">
                    <b-col sm="12" class="mx-0 px-0 my-0 py-0">
                        <b-input-group
                            :prepend="$t('com_eind', { ns: 'common' })"
                            :label="$t('com_mark', { ns: 'common' })"
                        >
                            <b-form-select
                                v-if="editKaasmerk"
                                v-model="localSelected.eindStockItemID"
                                :options="flexList"
                                @change="set_inputEindLetter"
                                :disabled="!editAllowed"
                            >
                                <template #first>
                                    <b-form-select-option value=""
                                        >--
                                        {{
                                            $t('com_selecteer', {
                                                ns: 'common'
                                            })
                                        }}
                                        --</b-form-select-option
                                    >
                                </template>
                            </b-form-select>
                            <b-form-input
                                v-if="!editKaasmerk"
                                placeholder="-"
                                v-model="localSelected.eindLetter"
                                :disabled="!editAllowed"
                            ></b-form-input>
                            <b-form-input
                                :placeholder="$t('com_eind', { ns: 'common' })"
                                type="number"
                                step="1"
                                v-model.number="localSelected.eindNummer"
                                @change="set_input"
                                :disabled="!editAllowed"
                            ></b-form-input>
                        </b-input-group>
                    </b-col>
                </div>
            </b-row>
            <b-alert
                v-model="showDismissibleAlert"
                variant="danger"
                dismissible
            >
                {{ alertText }}
            </b-alert>
            <b-alert v-model="showWarningAlert" variant="warning" dismissible>
                {{ reminder }}
            </b-alert>
        </div>
    </b-card>
</template>
<script>
import { CheeseMarks } from '../../models/BakInfo';
import { LookupCombo } from './index';
import { Feature } from '../../models/Features';
import Helper from '../../models/Helper';

export default {
    name: 'MerkenCombo',
    data: function () {
        return {
            lctypeShapes: window.constants.LCSHAPES,
            errorMessage: '',
            eenheidID: null,
            aangeraakt: 1,
            showDismissibleAlert: false,
            showWarningAlert: false,
            alertText: '',
            reminder: '',
            aantalKazenBasis: 0,

            messageLetterBeideMerken: this.$t('prod_beideletters', {
                ns: 'production'
            }),
            messageLetterEindMerk: this.$t('prod_eindletter', {
                ns: 'production'
            }),
            messageNummerVerplicht: this.$t('prod_nummerverplicht', {
                ns: 'production'
            }),
            messageStartKleinerDanEind: this.$t('prod_startkleinereind', {
                ns: 'production'
            }),
            messageVoorstel: this.$t('prod_marksuggestion', {
                ns: 'production'
            })
        };
    },
    model: {
        prop: 'selected',
        event: 'input'
    },
    props: {
        selected: {
            type: CheeseMarks,
            required: true
        },
        editAllowed: {
            type: Boolean,
            default: false
        },
        suggestion: {
            type: Boolean,
            default: true
        },
        cheeseProductID: {
            type: Number,
            required: false
        }
    },
    components: {
        LookupCombo
    },
    computed: {
        aantMerk() {
            return Helper.stringformat(
                '{0} {1}',
                this.$t('com_number', { ns: 'common' }),
                this.$t('com_marks', { ns: 'common' }).toLowerCase()
            );
        },
        localSelected() {
            const tel = this.aangeraakt;
            return this.selected;
        },
        kaasmerk_productnaam() {
            const tel = this.aangeraakt;
            const lijst =
                this.$store.getters['components/getCheesesComboLijst'];
            const product = lijst.find(
                (x) => x.value == this.localSelected.productID
            );
            return product ? product.text : '??';
        },
        isValid() {
            return (
                this.localSelected.kazen > 0 && this.localSelected.vormID > 0
            );
        },
        flexList() {
            return this.markstockList.map((x) => ({
                value: x.id,
                text:
                    x.letters +
                    ' (' +
                    x.startnummer +
                    ' - ' +
                    x.eindnummer +
                    ' )',
                stockItemId: x.id,
                letter: x.letters,
                start: x.startnummer,
                eind: x.eindnummer
            }));
        },
        editKaasmerk() {
            return this.markstockList.length > 0;
        },
        markstockList() {
            return this.$store.getters['stock/getMarkStockItemLijst'];
        },
        showCombo() {
            let result = true;
            if (!this.suggestion) {
                const objTest = this.flexList.find(
                    (x) =>
                        x.letter == this.localSelected.startLetter &&
                        x.start <= this.localSelected.startNummer &&
                        x.eind > this.localSelected.startNummer
                );
                result = objTest != undefined;
            }
            return result;
        },
        validMark() {
            if (!this.gebruikKaasmerk) {
                return true;
            }
            let result = true;
            if (this.localSelected.startLetter == undefined) {
                result = this.localSelected.eindLetter == undefined;
                if (!result) {
                    this.showAlert(this.messageLetterBeideMerken);
                    return result;
                }
            } else {
                result = this.localSelected.eindLetter != undefined;
                if (!result) {
                    this.showAlert(this.messageLetterEindMerk);
                    return result;
                }
            }
            result =
                this.localSelected.startNummer != undefined &&
                this.localSelected.eindNummer != undefined;
            if (!result) {
                this.showAlert(this.messageNummerVerplicht);
                return result;
            }
            if (
                this.localSelected.startLetter == this.localSelected.eindLetter
            ) {
                result =
                    this.localSelected.startNummer <
                        this.localSelected.eindNummer &&
                    this.localSelected.beginStockItemID ==
                        this.localSelected.eindStockItemID;
                if (!result) {
                    this.showAlert(this.messageStartKleinerDanEind);
                    return result;
                }
            }
            return result;
        },
        setDisabledShape() {
            const disableMultipleProductShapes = this.checkFeature(
                'conf_disableMultipleProductShapes'
            );
            return disableMultipleProductShapes;
        }
    },
    methods: {
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        showReminder(tekst) {
            this.reminder = tekst;
            this.showWarningAlert = true;
        },
        copyItem() {
            this.$emit('copy', this.localSelected);
        },
        verwijderItem() {
            this.$emit('delete', this.localSelected);
        },
        set_input: function () {
            this.aangeraakt += 1;
            this.localSelected.isChanged = true;
            this.$emit('validComponent', this.isValid);
            this.$emit('change', true);
            this.$emit('input', this.localSelected);
        },
        set_inputStartLetter() {
            this.$nextTick(() => {
                const obj = this.flexList.find(
                    (x) => x.value == this.localSelected.beginStockItemID
                );
                this.localSelected.startLetter = obj ? obj.letter : null;
                if (this.localSelected.eindStockItemID == undefined) {
                    this.localSelected.eindStockItemID = this.beginStockItemID;
                    this.localSelected.eindLetter = this.startLetter;
                }
                this.set_input();
            });
        },
        set_inputEindLetter() {
            this.$nextTick(() => {
                const obj = this.flexList.find(
                    (x) => x.value == this.localSelected.eindStockItemID
                );
                this.localSelected.eindLetter = obj ? obj.letter : null;
                this.set_input();
            });
            const obj = this.flexList.find((x) => x.value == this.eindStockID);
            this.set_input();
        },
        set_eindmerk() {
            this.localSelected.eindNummer =
                this.localSelected.startNummer + this.localSelected.kazen - 1;
            this.set_input();
        },
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return this.$root.handleFeature(key);
        }
    },
    mounted() {
        this.aantalKazenBasis = this.localSelected.kazen;
    },
    created() {
        if (this.selected.suggestion) {
            this.showReminder(this.messageVoorstel);
        }
    }
};
</script>

<style scoped></style>

