0
<template>
    <b-modal
        id="modal-sample"
        size="lg"
        ref="modal"
        :title="this.formtitle"
        :visible="visible"
        @hidden="hide"
        @ok="handleOk"
    >
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
            {{ alertText }}
        </b-alert>

        <div>
            <b-card>
                <b-row>
                    <b-col sm="3">
                        <label
                            >{{ $t('an_subject', { ns: 'analytics' }) }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <entity-object-selector
                            v-model="locItem.meetplekID"
                            :disabled="!editAllowed"
                            @change="setChanged"
                        ></entity-object-selector>
                    </b-col>
                </b-row>
                <hr />
                <b-row>
                    <b-col sm="3">
                        <label
                            >{{
                                $t('an_plandatum', {
                                    ns: 'analytics'
                                })
                            }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <analytics-date-time
                            id="dateBoeking"
                            v-model="plantijdstip"
                            v-bind:editAllowed="editAllowed"
                            v-bind:startNull="false"
                            @change="setplantijdstip"
                        >
                        </analytics-date-time>
                    </b-col>
                    <b-col sm="3">
                        <label for="input-datePlan"
                            >{{
                                $t('an_sampledatum', {
                                    ns: 'analytics'
                                })
                            }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <analytics-date-time
                            id="dateNemen"
                            v-model="neemtijdstip"
                            v-bind:editAllowed="editAllowed"
                            v-bind:startNull="false"
                            @change="setneemtijdstip"
                        >
                        </analytics-date-time>
                    </b-col>
                    <b-col sm="3">
                        <label for="input-omschrijving"
                            >{{
                                $t('an_omschrijving', {
                                    ns: 'analytics'
                                })
                            }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <b-form-input
                            id="input-omschrijving"
                            :placeholder="$t('an_omschrijving', { ns: 'analytics' })"
                            v-model="locItem.naam"
                            @change="setChanged"
                            :disabled="!editAllowed"
                        ></b-form-input>
                    </b-col>
                    <b-col sm="3">
                        <label for="input-omschrijving"
                            >{{
                                $t('prod_type', {
                                    ns: 'production'
                                })
                            }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <lookup-combo
                            v-model="locItem.monstertype"
                            v-bind:combotype="lctypeSample"
                            :editAllowed="editAllowed"
                            @change="setChanged"
                        ></lookup-combo>
                    </b-col>
                    <b-col sm="3">
                        <label for="input-omschrijvingeenheid"
                            >{{ $t('com_unit', { ns: 'common' }) }}:</label
                        >
                    </b-col>
                    <b-col sm="9">
                        <b-form-input
                            id="input-omschrijvingeenheid"
                            :placeholder="$t('an_omschrijving', { ns: 'analytics' })"
                            v-model="locItem.eenheidBeschrijving"
                            @change="setChanged"
                            :disabled="!editAllowed"
                        ></b-form-input>
                    </b-col>
                </b-row>
            </b-card>
        </div>
    </b-modal>
</template>

<script>
import { SampleObject } from '../../models/Anayltics';
import LookupCombo from './LookupCombo.vue';
import AnalyticsDateTime from './AnalyticsDateTime.vue';
import { loadItem } from './utils';
import EntityObjectSelector from './EntityObjectSelector.vue';
export default {
    name: 'ModalSample',
    data: function () {
        return {
            lctypeEO: window.constants.LCA_ENTITYOBJECT,
            lctypeSample: window.constants.LCA_SAMPLETYPE,
            showDismissibleAlert: false,
            alertText: '',
            isChanged: false,
            locItem: null,
            returnObject: null,
            editAllowed: true,
            plantijdstip: null,
            neemtijdstip: null
        };
    },
    model: {
        prop: 'visible',
        event: 'changed'
    },
    props: {
        inItem: {
            type: SampleObject,
            required: false
        },
        isNew: {
            type: Boolean,
            default: true
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        EntityObjectSelector,
        LookupCombo,
        AnalyticsDateTime
    },
    watch: {},
    computed: {
        formtitle() {
            const low = this.$t('an_sample', {
                ns: 'analytics'
            }).toLowerCase();
            if (this.locItem == undefined)
                return this.$t('com_newx', {
                    ns: 'common',
                    val: low
                });
            return this.locItem.isNew
                ? this.$t('com_makenew', {
                      ns: 'common',
                      val: low
                  })
                : this.$t('com_edit', {
                      ns: 'common',
                      val: this.locItem.naam ?? low
                  });
        },
        magEdit() {
            return !(this.locItem.typenaam && this.locItem.veldid);
        }
    },
    methods: {
        printBarcode() {
            this.$htmlToPaper('targetDiv');
        },
        showAlert(tekst) {
            this.alertText = tekst;
            this.showDismissibleAlert = true;
        },
        hide() {
            this.$emit('hide', this.returnObject);
        },
        setneemtijdstip(value) {
            this.neemtijdstip = value;
            this.setChanged();
        },
        setplantijdstip(value) {
            this.plantijdstip = value;
            this.setChanged();
        },
        setChanged() {
            this.isChanged = true;
        },
        async checkFormValidity() {
            const invoerOK = true;
            const invalidBasis = this.locItem.CheckValidity(this.$t);
            if (invalidBasis) this.showAlert(invalidBasis);
            const valid =
                this.locItem != undefined && !invalidBasis && invoerOK;
            return valid;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        async handleSubmit() {
            this.locItem.neemtijdstip = this.neemtijdstip;
            this.locItem.plantijdstip = this.plantijdstip;
            // Exit when the form isn't valid
            if (this.isChanged) {
                if (!(await this.checkFormValidity())) {
                    return;
                }
                this.returnObject = await this.saveThisSample();
                this.$emit('forcerefresh', true);
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-sample');
            });
        },
        async saveThisSample() {
            const addObject = this.locItem.SetForAdd();
            return loadItem('analytics/saveSample', addObject, this.$t);
        }
    },
    mounted() {},
    created() {
        if (this.inItem) {
            this.locItem = this.inItem;
            this.neemtijdstip = this.locItem.neemtijdstip;
            this.plantijdstip = this.locItem.plantijdstip;
            if (this.isNew) {
                this.locItem.clearID();
                this.neemtijdstip = null;
                this.plantijdstip = this.locItem.plantijdstip;
            }
        } else {
            this.neemtijdstip = new Date();
            this.plantijdstip = new Date();
            this.locItem = new SampleObject();
        }
    }
};
</script>

<style scoped></style>

