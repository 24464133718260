import Api from '../../../api';
import store from '../../../store';

const fetchExternalConnections = async (data) => {
    return fetchInfo('/ExternalConnection/GetExternalConnections', data);
};
const fetchExternalDataSources = async (data) => {
    return fetchInfo('/ExternalConnection/GetExternalDataSources', data);
};
const generateToken = async (data) => {
    return fetchInfo('/ExternalConnection/GenerateToken', data);
};
//int id
const fetchExternalConnection = async (data) => {
    return fetchInfo('/ExternalConnection/GetExternalConnection', data);
};
//int id
const deleteExternalDevice = async data => {
    return putInfo('/ExternalConnection/DisableConnection', data);
};
// string deviceID
const registerExternalDevice = async data => {
    return putInfo('/ExternalConnection/RegisterDevice', data);
};
//APIKeyModel akm
const storeExternalDevice = async data => {
    return await Api(store).put('/ExternalConnection/StoreExternalConnection', data);
};
// const fetchDeviceTypes = async (data) => {
//     //return fetchInfo('/ExternalConnection/GetOst', data);
// };
const fetchExternalProducts = async (data) => {
    return fetchInfo('/Product/GetExternalProducts', data);
};

// int inProdOrderID
const retryOrderImport = async data => {
    return putInfo('/ProductionOrder/RetryOrderImport', data);
};

const fetchInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).get(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    } else {
        return await Api(store).get(apicall, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }
};
const putInfo = async (apicall, data) => {
    if (data != undefined) {
        return await Api(store).put(
            apicall +
                '?' +
                Object.keys(data)
                    .map(
                        (k) =>
                            encodeURIComponent(k) +
                            '=' +
                            encodeURIComponent(data[k] ?? '')
                    )
                    .join('&'),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    }
};
export default {
    registerExternalDevice,
    storeExternalDevice,
    fetchExternalDataSources,
    fetchExternalConnections,
    generateToken,
    fetchExternalConnection,
    retryOrderImport,
    deleteExternalDevice,
    fetchExternalProducts
};
