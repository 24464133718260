import { api } from '../api';
import { showGoodToast, showBadToast } from '../../errors/toast';
import { OrderHeader, OrderInfo, OrderLine } from '../../../models/Orders';
import logMessage from '../../errors/foutlog';

const actions = {
    // DateTime from, DateTime until
    async loadProductionOrderInfo(context, data) {
        let tempList = [];
        try {
            const res = await api.fetchProductionOrderInfo(data.data);
            tempList = res.data.Items.map((x) => new OrderInfo(x));
        } catch (error) {
            tempList = [];
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_prodorders', { ns: 'production' })
                        .toLowerCase()
                }),
                error
            );
        }
        return tempList;
    },
    async loadProductionOrder(context, data) {
        let item = null;
        try {
            const res = await api.fetchProductionOrder(data.data);
            item = new OrderHeader(res.data);
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_prodorder', { ns: 'production' })
                        .toLowerCase()
                }),
                error
            );
        }
        return item;
    },
    // async loadProductionOrderLine(context, data) {
    //     let item = null;
    //     try {
    //         const res = await api.fetchProductionOrderLine(data.data);
    //         item = new OrderLine(res.data);
    //     } catch (error) {
    //         showBadToast(
    //             context,
    //             data.func('com_errorladen', {
    //                 ns: 'common',
    //                 val: data.func('prod_prodorderregel', { ns: 'production' })
    //                     .toLowerCase()
    //             }),
    //             error
    //         );
    //     }
    //     return item;
    // },
    //int orderLineID
    async loadAssignedCheeseAmount(context, data) {
        let item = null;
        try {
            const res = await api.fetchAssignedCheeseAmount(data.data);
            item = res.data;
        } catch (error) {
            showBadToast(
                context,
                data.func('com_errorladen', {
                    ns: 'common',
                    val: data.func('prod_assignedamount', { ns: 'production' })
                        .toLowerCase()
                }),
                error
            );
        }
        return item;
    },
};

export default actions;

