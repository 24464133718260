<template>
    <b-container fluid style="margin-top: 60px">
        <b-row class="form-inline mx-0 px-0 my-0 py-0">
            <b-col sm="10" class="form-inline mx-0 px-0 my-0 py-0">
                <h1 v-on:click="flipAssigned">
                    {{ title }} &nbsp;
                    <b-button>
                        {{ btnTekst }}
                    </b-button>
                </h1>
                &nbsp;
            </b-col>
            <b-col sm="2" class="mx-0 px-0 my-0 py-0"> </b-col>
        </b-row>

        <b-row>
            <b-col sm="12">
                <div v-if="showFilter">
                    <b-row no-gutters>
                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_filter', { ns: 'common' })"
                                label-for="filter-input"
                                label-cols-sm="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                        id="filter-input"
                                        v-model="filter"
                                        type="search"
                                        :placeholder="
                                            $t('com_typetosearch', {
                                                ns: 'common'
                                            })
                                        "
                                    ></b-form-input>

                                    <b-input-group-append>
                                        <b-button
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            >{{
                                                $t('com_wis', { ns: 'common' })
                                            }}</b-button
                                        >
                                    </b-input-group-append>
                                </b-input-group>
                                <b-button
                                    v-if="
                                        !assigned && checkRight('OrderCreate')
                                    "
                                    size="sm"
                                    @click="createNewOrder"
                                    class="mr-1"
                                    ><font-awesome-icon
                                        icon="folder-plus"
                                        size="1x"
                                    />
                                    {{ strNieuw }}
                                </b-button>
                                &nbsp;
                            </b-form-group>
                        </b-col>

                        <b-col lg="6" class="my-1">
                            <b-form-group
                                :label="$t('com_perpagina', { ns: 'common' })"
                                label-for="per-page-select"
                                label-cols-sm="6"
                                label-cols-md="4"
                                label-cols-lg="3"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                    id="per-page-select"
                                    v-model="perPage"
                                    :options="pageOptions"
                                    size="sm"
                                ></b-form-select>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                ></b-pagination>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div v-else>
                    <b-button
                        v-if="!assigned && checkRight('OrderCreate')"
                        size="sm"
                        @click="createNewOrder"
                        class="mr-1"
                        ><font-awesome-icon icon="folder-plus" size="1x" />
                        {{ strNieuw }}
                    </b-button>
                    &nbsp;
                </div>
                <b-table
                    :items="groupedDisplayList"
                    :fields="visibleGroupedFields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    stacked="md"
                    show-empty
                    :empty-text="$t('com_geen_data', { ns: 'common' })"
                    small
                    striped
                    @filtered="onFiltered"
                >
                    <template v-slot:cell(actions)="row">
                        <div class="form-inline">
                            <b-button
                                v-if="allowEdit && checkRight('OrderCreate')"
                                size="sm"
                                @click="createNewOrder(row.item)"
                                class="bgc_aux_normal"
                                ><font-awesome-icon
                                    icon="folder-plus"
                                    size="1x"
                                />
                            </b-button>
                            <b-button
                                v-if="allowEdit && checkRight('OrderUpdate')"
                                size="sm"
                                @click="editOrder(row.item)"
                                class="bgc_aux_warning"
                                ><font-awesome-icon icon="edit" size="1x" />
                            </b-button>
                            <b-button
                                size="sm"
                                @click="toonDetail(row.item)"
                                class="bgc_aux_unknown"
                                ><font-awesome-icon
                                    icon="info-circle"
                                    size="1x"
                                />
                            </b-button>
                        </div>
                    </template>
                    <template v-slot:row-details>
                        <b-card no-body class="mb-1">
                            &nbsp;
                            <b-table
                                striped
                                responsive
                                :fields="visibleFields"
                                :items="localRegels"
                                primary-key
                            >
                                <template v-slot:cell(actions)="row">
                                    <div class="form-inline">
                                        <!--                       <b-button
                                            v-if="
                                                allowEdit &&
                                                checkRight('OrderCreate')
                                            "
                                            size="sm"
                                            @click="
                                                createNewOrderLine(row.item)
                                            "
                                            class="bgc_aux_normal"
                                            ><font-awesome-icon
                                                icon="folder-plus"
                                                size="1x"
                                            />
                                        </b-button> -->
                                        <b-button
                                            v-if="
                                                allowEdit &&
                                                checkRight('OrderDelete')
                                            "
                                            size="sm"
                                            @click="verwijderItem(row.item)"
                                            class="bgc_aux_alarm"
                                            ><font-awesome-icon
                                                icon="trash-alt"
                                                size="1x"
                                            />
                                        </b-button>
                                    </div>
                                </template>
                            </b-table>
                        </b-card>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <sales-order-modal
            v-if="showSalesOrderModal"
            v-model="showSalesOrderModal"
            :inID="selectedOrderID"
            :copyOrder="selectedOrder"
            :editAllowed="true"
            @hide="reset"
            @changed="refresh"
            @delete="deleteId"
        />
    </b-container>
</template>

<script>
import DateHelper from '../../models/DateHelper';
import Helper from '../../models/Helper';
import SalesOrderModal from './SalesOrderModal.vue';
import { loadItem, loadList } from '../../models/DataHelper';
import { Permission } from '../../models/Permissions';
import { Feature } from '../../models/Features';
import logMessage from '../errors/foutlog';

export default {
    name: 'SalesOrderBase',
    data: function () {
        return {
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 20, 40, { value: 100, text: '100' }],
            filter: null,
            filterOn: [],
            orderList: [],
            currentItem: null,
            detailsVisible: false,
            assigned: false,
            selectedOrderID: -1,
            selectedOrder: null,
            showSalesOrderModal: false,
            forceRefresh: false,
            activiteitenList: [],
            displayFilterCount: 10,
            aangeraakt_teller: 0,
            localRegels: []
        };
    },
    components: { SalesOrderModal },
    computed: {
        strNieuw() {
            return this.$t('com_newx', {
                ns: 'common',
                val: this.$t('com_salesorder', {
                    ns: 'common'
                }).toLocaleLowerCase()
            });
        },
        title() {
            return Helper.stringformat(
                '{0} {1}',
                this.assigned
                    ? this.$t('com_assigned', { ns: 'common' })
                    : this.$t('com_unassigned', { ns: 'common' }),
                this.$t('com_salesorders', { ns: 'common' }).toLowerCase()
            );
        },
        btnTekst() {
            return Helper.stringformat(
                '{0} {1}',
                this.$t('com_show', { ns: 'common' }),
                this.assigned
                    ? this.$t('com_open', { ns: 'common' }).toLowerCase()
                    : this.$t('com_assigned', { ns: 'common' }).toLowerCase()
            );
        },
        visibleFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                { key: 'id', label: 'Id', sortable: false, visible: false },
                {
                    key: 'pt_internalname',
                    label: this.$t('com_product', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'productiedatum',
                    label: this.$t('log_optdatum', { ns: 'logistics' }),
                    sortable: true,
                    visible: true,
                    formatter: this.displayDate
                },
                {
                    key: 'afleverdatum',
                    label: this.$t('log_leverdatum', { ns: 'logistics' }),
                    sortable: true,
                    visible: true,
                    formatter: this.displayDate
                },
                {
                    key: 'ol_requestedamount',
                    label: this.$t('prod_requested', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'toegewezen',
                    label: this.$t('prod_assigned', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'rest',
                    label: this.$t('prod_rest', { ns: 'production' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'productions',
                    label: this.$t('prod_bakken', { ns: 'production' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        visibleGroupedFields() {
            const fieldsInit = [
                { key: 'actions', label: ' ', visible: true },
                {
                    key: 'orderID',
                    label: 'Id',
                    sortable: false,
                    visible: false
                },
                {
                    key: 'klant',
                    label: this.$t('com_klant', { ns: 'common' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'klantRef',
                    label: this.$t('log_reference', { ns: 'logistics' }),
                    sortable: true,
                    visible: true
                },
                {
                    key: 'externID',
                    label: this.$t('com_externalid', { ns: 'common' }),
                    sortable: true,
                    visible: false
                },
                {
                    key: 'afleverdatum',
                    label: this.$t('log_leverdatum', { ns: 'logistics' }),
                    sortable: true,
                    visible: true,
                    formatter: this.displayDate
                },
                {
                    key: 'regels.length',
                    label: this.$t('log_lines', { ns: 'logistics' }),
                    sortable: true,
                    visible: true
                }
            ];
            return fieldsInit.filter((field) => field.visible);
        },
        displayList() {
            const lijst = this.orderList;
            return lijst;
        },
        groupedDisplayList() {
            // eslint-disable-next-line no-unused-vars
            const veranderd = this.aangeraakt_teller;
            if (this.orderList == undefined) return null;
            const x = _(this.orderList.slice(0))
                .groupBy((x) => x.orderID)
                .map((value, key) => {
                    const orderID = value[0].orderID;
                    const klant = value[0].klant;
                    const klantRef = value[0].klantRef;
                    const externID = value[0].externID;
                    const afleverdatum = value[0].afleverdatum;
                    return {
                        orderID: orderID,
                        klant: klant,
                        klantRef: klantRef,
                        externID: externID,
                        afleverdatum: afleverdatum,
                        aantal: value.length,
                        regels: value
                    };
                })
                .value();
            return x;
        },
        showFilter() {
            return this.displayList
                ? this.displayList.length > this.displayFilterCount
                : false;
        },
        allowEdit() {
            return true;
        }
    },
    methods: {
        reset() {
            this.showSalesOrderModal = false;
        },
        flipAssigned() {
            this.assigned = !this.assigned;
            this.loadData();
        },
        displayDate(value) {
            return DateHelper.convertedToDate(value, false);
        },
        async toonDetail(row) {
            if (row._showDetails) {
                this.$set(row, '_showDetails', false);
            } else {
                this.groupedDisplayList.forEach((row) => {
                    this.$set(row, '_showDetails', false);
                });
                this.localRegels = row.item ? row.item.regels : row.regels;
                this.$set(row, '_showDetails', true);
            }
        },
        async createNewOrder(item) {
            this.selectedOrderID = -1;
            this.selectedOrder = null;
            if (item != undefined) {
                // het is een nieuwe gebaseerd op een bestaande
                this.selectedOrder = item;
            }
            this.showSalesOrderModal = true;
        },
        async editOrder(item) {
            this.selectedOrderID = item.orderID;
            this.selectedOrder = item;
            this.showSalesOrderModal = true;
        },
        async refresh() {
            this.reset();
            this.loadData();
        },
        async verwijderItem(item) {
            const doClose = await this.$root.showConfirmationBox(
                this.$t('com_delete', {
                    ns: 'common',
                    val: this.$t('com_salesorderline', { ns: 'common' })
                })
            );
            if (doClose) {
                const selectionData = {
                    orderlineid: item.id
                };
                await loadItem(
                    'salesorder/deleteSalesOrderLine',
                    selectionData,
                    this.$t
                );
            }
            this.loadData();
        },
        async deleteId(id) {
            const selectionData = {
                orderlineid: id
            };
            await loadItem(
                'salesorder/deleteSalesOrderLine',
                selectionData,
                this.$t
            );
            this.loadData();
        },
        async loadData() {
            const o = DateHelper.determineVanTotUTC(null, -165, 200);
            const selectionData = {
                from: o.van,
                until: o.tot
            };
            const templist = await loadItem(
                'salesorder/loadSalesOrderInfo',
                selectionData,
                this.$t
            );
            this.orderList = this.assigned
                ? templist.filter((item) => item.toegewezen > 0)
                : templist.filter((item) => item.toegewezen == 0);
            this.aangeraakt_teller++;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        checkRight(recht) {
            const id = Permission.enum(recht);
            return this.$root.handleRight(id);
        },
        checkFeature(feature) {
            const key = Feature.enum(feature);
            return this.$root.handleFeature(key);
        }
    },
    mounted() {
        this.loadData();
        this.totalRows = this.displayList ? this.displayList.length : 0;
    },
    created() {}
};
</script>

<style scoped></style>
